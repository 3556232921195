import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastController, PopoverController, ModalController, AlertController } from '@ionic/angular';

import { DataService } from '../../services/data.service';
import { LanguageService } from '../../services/language.service';
import { BucketService } from '../../services/bucket.service';
import { FlagService } from '../../services/flag.service';
import { LoginService } from '../../services/login.service';
import { ViewHistoryService } from '../../services/view-history.service';
import { AdViewService } from '../../services/ad-view.service';
import { FavoritesService } from '../../services/favorites.service';
import { UserDataService } from '../../services/user-data.service';
import { WindowService } from '../../services/window.service';
import { SocketService } from "../../services/socket.service";
import { MetaService } from "../../services/meta.service";

import { AdDetailComponent } from "../ad-detail/ad-detail.component";
import { FlagReasonsPopoverComponent } from "../popovers/flag-reasons/flag-reasons-popover.component";
import { CurrentUser } from "../../model/current-user";
import { LoginModalComponent } from "../modals/login-modal/login-modal.component";
import { PopoverProfileComponent } from "../popovers/profile-menu/profile-menu-popover.component";
import { PopoverLanguageComponent } from "../popovers/language-menu/language-menu-popover.component";

import { take } from "rxjs/operators";
import { AdStatus } from 'src/app/model/enums/ad-status';

// Detail Page Component
@Component({
  selector: "app-detail-page",
  templateUrl: "./detail-page.component.html",
  styleUrls: ["./detail-page.component.scss"],
})
export class DetailPageComponent implements OnInit {
  lang: any;
  appLanguage = "sl";
  // adId: string;
  ad: any;
  // user: any;
  currentUser: CurrentUser;
  loggedIn = false;
  comments: any[];
  question = "";
  replyQuestion = "";
  commentCount = 0;
  replyForm = false;
  replyToReplyForm = false;
  replyCommentId = "";
  replyReplyId = "";
  deleteAlert: any;
  editCommentId = "";
  editMessage = "";
  messageMaxLength = 280;
  messageRemaining = null;
  mobileView: boolean;
  
  adViewsCount = 0;
  favoritesCount = 0;
  phoneNumber: string;
  @ViewChild("phoneLink", { read: ElementRef }) phoneLink: ElementRef;
  @ViewChild("addDetail") addDetail: AdDetailComponent;
  isFavorite = false;
  API = "";
  metaImageUrl = "";
  languageParams: string | null;
  basePath = "";
  
  constructor(
    private dataService: DataService,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private popoverCtrl: PopoverController,
    private loginService: LoginService,
    private bucketService: BucketService,
    private flagService: FlagService,
    private toastCtrl: ToastController,
    private languageService: LanguageService,
    private route: ActivatedRoute,
    private viewHistoryService: ViewHistoryService,
    private adViewService: AdViewService,
    private favoritesService: FavoritesService,
    private userDataService: UserDataService,
    private windowService: WindowService,
    private socketService: SocketService,
    private metaService: MetaService,
    // private metaService: Meta,
  ) {}
  
  ngOnInit() {
    // console.log("detail page");
    
    this.API = this.dataService.API;
    
    this.currentUser = this.loginService.currentUser();

    this.getSingleAd();
      
    this.dataService.isBrowser$.subscribe((isBrowser) => {
      // console.log(isBrowser);
      if (isBrowser) {
        this.toggleMobileView();
        // this.getUrl();
        
        // SET LANGUAGE
        this.languageService.language$.subscribe((value) => {
          this.appLanguage = value;
          this.lang = this.languageService[value];
        });
    
        this.getUrl();
      }
    });

    this.dataService.loggedIn$.subscribe((isLoggedIn: boolean) => {
      this.currentUser = this.loginService.currentUser();
      this.checkFavorite();
    });
  }
  
  getUrl() {
    this.route.url.subscribe((url) => {
      // console.log(url);
      // console.log(decodeURI(url));
      this.basePath = url[0].path;
      const pathId = url[1].path;
      
      if (this.basePath === "single-check") {
        this.getSingleAdVerify(pathId);
      } else {
        // this.getSingleAd();
        // this.setMetaTags();
        this.setHomepageLink(this.appLanguage);
       
        this.socketService.adChanges$.subscribe((msg) => {
          if (msg["itemId"] === this.ad._id) {
            if (msg["type"] === "favoriteChanges") {
              this.checkFavorite();
            }
            if (msg["type"] === "adActivated") {
              this.getSingleAd();
            }
            if (msg["type"] === "adDeactivated") {
              this.getSingleAd();
            }
            if (msg["type"] === "adUpdated") {
              this.getSingleAd();
            }
          }
        });
      }
    });
  }
  
  setHomepageLink(language: string) {
    if (language === "en") {
      this.languageParams = "en";
    } else {
      this.languageParams = null;
    }
  }
  
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.toggleMobileView();
  }
  
  toggleMobileView() {
    if (this.windowService.nativeWindow.innerWidth <= "767") {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
  }
  
  // getUrl() {
  //   this.route.url
  //     .subscribe(url => {
  //       this.getSingleAd(url[1].path);
  //     });
  // }
  
  approve(): void {
    if (!this.currentUser) {
      return;
    }
    
    this.bucketService.approveAd(this.ad._id, this.currentUser._id, this.ad.author).subscribe((result) => {
      // console.log(result);
      this.ad['approved'] = true;
      this.approvalConfirmation();
    });
  }

  async approvalConfirmation(): Promise<void> {
      const approvedAlert = await this.alertCtrl.create({
        header: "Ad is approved", // this.lang['delete'],
        subHeader: "Have a nice day!", // this.lng['deleteConfirm'],
        // subTitle: 'Removing the ad can not be undone. Ste prepričani, da želite izbrisati oglas?', // this.lng['deleteConfirm'],
        buttons: [
          {
            text: this.lang["ok"], // this.lng['cancel'],
            role: "cancel",
            handler: () => {
              // console.log('Cancel clicked');
              // this.router.navigate(["ad", this.ad._id]);
            },
          },
        ],
      });
      await approvedAlert.present();
  }

  async presentLanguagePopover(ev) {
    const popover = await this.popoverCtrl.create({
      component: PopoverLanguageComponent,
      componentProps: {
        setLang: (language) => {
          this.langChange(language);
        },
        lang: this.lang,
      },
      event: ev,
    });
    await popover.present();
  }
  
  // getSingleAd(adId) {
  getSingleAd() {
    this.appLanguage = this.route.snapshot.data["language"]["currentLanguage"];
    this.lang = this.route.snapshot.data["language"]["lang"];

    this.ad = this.route.snapshot.data["ad"];
    this.ad.photos = this.route.snapshot.data["images"]["files"];

    if (!this.ad) {
      return;
    }
    
    if (this.ad.photos.length > 0) {
      this.metaImageUrl = this.API + "/files/image/" + this.ad.photos[0]._id;
    } else {
      this.metaImageUrl = "/assets/images/condo-323780.jpg";
    }
    // this.listAdImages(this.ad._id);
    this.setMetaTags();

    this.checkAdExpiry(this.ad);
    // this.getComments(this.ad._id);
    this.ad["elapsed"] = Math.floor(
      (new Date().getTime() - new Date(this.ad["created"]).getTime()) /
      86400000
    );
    // const addr = res['location'].Address;
    // this.address = `${addr.Street}, ${addr.PostalCode} ${addr.District}`;
    if (this.currentUser) {
      this.addToViewHistory(this.ad._id, this.currentUser._id);
    }
    // wait 3 seconds in order for the view to count
    this.countAdViews(this.ad._id);
    setTimeout(() => {
      this.adViewCount(this.ad._id);
    }, 3000);
    this.countAdFavorites(this.ad._id);
    this.checkFavorite();
    this.setUserName(this.ad.author);
  }
  
  getSingleAdVerify(adId: string): void {
    if (!this.currentUser) {
      return;
    }
    this.bucketService.verifySingle(adId, this.currentUser._id).subscribe(res => {

      // SET LANGUAGE
      this.languageService.language$.subscribe((value) => {
        this.appLanguage = value;
        this.lang = this.languageService[value];
      });

      // this.lang = this.languageService[data.language['language']];
      this.ad = res;
      this.ad["adExpired"] = false;
      this.checkAdApproved(this.ad);
      this.listAdImages(this.ad._id);
      // this.getComments(this.ad._id);
      this.ad["elapsed"] = Math.floor(
        (new Date().getTime() - new Date(this.ad["created"]).getTime()) /
        86400000
      );
      
      this.setUserName(this.ad.author);
    });
    
    // this.bucketService.getSingleAd(adId, '')
    //   .subscribe(res => {
    //     // console.log(res);
    //     this.ad = res;
    //     this.checkAdExpiry(this.ad);
    //     this.listAdImages(adId);
    //     // this.getComments(this.ad._id);
    //     this.ad['elapsed'] = Math.floor((new Date().getTime() - new Date(this.ad['created']).getTime()) / 86400000);
    //     // const addr = res['location'].Address;
    //     // this.address = `${addr.Street}, ${addr.PostalCode} ${addr.District}`;
    //     if (this.currentUser) {
    //       this.addToViewHistory(this.ad._id, this.currentUser._id);
    //     }
    //     this.adViewCount(this.ad._id);
    //     this.countAdFavorites(this.ad._id);
    //     this.checkFavorite();
    //     this.setUserName(this.ad.author);
    
    //     this.setMetaTags();
    //   });
  }
  
  setMetaTags() {
    this.metaService.setMetaDataObject({
      lang: this.lang,
      title: `${this.lang.adTsPropertyType[this.ad.adPropertyType]}, ${
        this.lang[this.ad.adType === 0 ? "buy" : "forRent"]
      }, ${this.ad.located.street}, ${this.ad.located.postal} ${
        this.ad.located.district
      }`,
      description: `${this.lang.adTsPropertyType[this.ad.adPropertyType]}, ${
        this.lang[this.ad.adType === 0 ? "buy" : "forRent"]
      }, ${this.ad.located.street}, ${this.ad.located.postal} ${
        this.ad.located.district
      }`,
      keywords: `${this.lang.adTsPropertyType[this.ad.adPropertyType]}, ${
        this.lang[this.ad.adType === 0 ? "buy" : "forRent"]
      }, ${this.ad.located.street}, ${this.ad.located.postal}, ${
        this.ad.located.district
      }`,
      url: `https://kvadrat.si/ad/${this.ad._id}`,
      image: this.metaImageUrl,
      type: "website",
    });

    // this.metaService.updateTag({ name: "description", content: metaData.description });
    // this.metaService.updateTag({ name: "keywords", content: metaData.keywords });
    // this.metaService.updateTag({ name: "robots", content: metaData.robots });
    // this.metaService.updateTag({ property: "og:url", content: metaData.url });
    // this.metaService.updateTag({ property: "og:title", content: metaData.title });
    // this.metaService.updateTag({ property: "og:description", content: metaData.description });
    // this.metaService.updateTag({ property: "og:image", content: metaData.image });
    // this.metaService.updateTag({ property: "og:type", content: metaData.type || "website"});
    // this.metaService.updateTag({ property: "fb:app_id", content: "1185224969573279"});
  }
  
  checkAdExpiry(ad) {
    const lastStatus = ad.statusOfAd[ad.statusOfAd.length - 1];

    if (lastStatus.status === AdStatus.Active) {
      ad["adExpired"] = false;
      // ad["adExpired"] = new Date(lastStatus.changed).getTime() <= new Date().getTime() - 30 * 24 * 60 * 60 * 1000;
    } else {
      ad["adExpired"] = true;
    }
  }

  checkAdApproved(ad): void {
    const lastStatus = ad.statusOfAd[ad.statusOfAd.length - 1].status;
    this.ad['approved'] = lastStatus === AdStatus.Active;
  }
  
  callNumber() {
    this.userDataService.getPhone(this.ad.author).subscribe((result) => {
      this.phoneNumber = result["phone"];
      setTimeout(() => {
        this.phoneLink.nativeElement.click();
      }, 100);
    });
  }
  
  scrollTo(el: ElementRef) {
    el.nativeElement.scrollIntoView({ behavior: "smooth" });
  }
  
  async copyLinkClipboard(val: string) {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = window.location.host + "/ad/" + val + (this.appLanguage === "en" ? "?l=en" : "");
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    selBox.setSelectionRange(0, 99999); // For mobile devices
    document.execCommand("copy");
    navigator.clipboard.writeText(selBox.value);
    document.body.removeChild(selBox);
    // this.viewCtrl.dismiss();
    const toast = await this.toastCtrl.create({
      // message: `Link ${selBox.value} kopiran.`,
      message: this.lang["linkCopied"], // translate
      duration: 3000,
    });
    toast.present();
  }
  
  // flag popover
  async presentFlagReasonsPopover(ev: Event, addId: string) {
    const popover = await this.popoverCtrl.create({
      component: FlagReasonsPopoverComponent,
      componentProps: {
        flagItem: (addId: string, flagReason: number, description: string) => {
          this.sendFlag(addId, flagReason, description);
        },
        adId: addId,
        lang: this.lang,
      },
      cssClass: "flag-reasons-popover",
      event: ev,
    });
    popover.present();
  }
  
  flagAd(event, add) {
    if (this.currentUser === undefined) {
      this.presentLoginModal();
      return null;
    }
    this.presentFlagReasonsPopover(event, add);
  }
  
  sendFlag(itemId: string, flagReason: number, description: string): void {
    this.flagService
    .flagItem(
      itemId,
      this.currentUser._id,
      this.ad.author,
      0, // ad
      flagReason,
      description
    ).subscribe(async (response) => {
      const toast = await this.toastCtrl.create({
        message: response["message"],
        duration: 3000,
      });
      toast.present();
    });
  }
  
  async presentLoginModal() {
    const modal = await this.modalCtrl.create({
      component: LoginModalComponent,
      componentProps: {
        updateList: (name) => {
          // this.updateList();
        },
        // item: item,
        lang: this.lang,
        appLanguage: this.appLanguage,
      },
      cssClass: "login-modal",
    });
    modal.present();
  }
  
  openProfileAction(ev) {
    // console.log(this.user);
    if (this.currentUser) {
      this.presentProfilePopover(ev);
    } else {
      this.presentLoginModal();
    }
  }
  
  async presentProfilePopover(ev) {
    const popover = await this.popoverCtrl.create({
      component: PopoverProfileComponent,
      componentProps: {
        // showCreateModal: () => {
        //   this.openNewFolderModal();
        // },
        user: this.currentUser,
        lang: this.lang,
        appLanguage: this.appLanguage,
        langChange: (language: string) => {
          this.langChange(language);
        },
      },
      event: ev,
    });
    popover.present();
  }
  
  langChange(language: string) {
    this.languageService.langChange(language);
  }
  
  async toggleFavorite(itemId: string, authorId: string) {
    if (this.currentUser === undefined) {
      this.presentLoginModal();
      return null;
    }
    
    if (this.currentUser._id === authorId) {
      const toast = await this.toastCtrl.create({
        message: this.lang['messageToOwner'],
        duration: 3000
      });
      toast.present();
      return;
    }
    
    if (!this.isFavorite) {
      this.favoritesService.addFavorite(itemId, this.currentUser._id, "someOwnerId")
        .subscribe(() => {
          this.isFavorite = true; // shortcut to save one requst
          this.countAdFavorites(itemId);
        });
    } else {
      this.favoritesService.removeFavorite(itemId, this.currentUser._id, "someOwnerId")
        .subscribe(() => {
          this.isFavorite = false; // shortcut to save one requst
          this.countAdFavorites(itemId);
        });
    }
  }
  
  checkFavorite() {
    if (this.currentUser === undefined) {
      this.isFavorite = false;
      return
    };
    if (this.ad === undefined) {
      return;
    }
    this.favoritesService
    .checkFavorite(this.ad._id, this.currentUser._id)
    .subscribe((result) => {
      this.isFavorite = result["isFavorite"];
      // console.log(this.isFavorite);
    });
  }
  
  listAdImages(id) {
    // this.bucketService.listAdImages(id)
    //   .subscribe(images => {
    //     this.ad.photos = images['files'];
    //     if (images['files'].length > 0) {
    //       this.metaImageUrl = this.API + '/files/image/' + this.ad.photos[0]._id;
    //     }
    //   });
    
    this.route.data.subscribe(
      (data: { ad: any; language: string; images: any }) => {
        this.ad.photos = data.images["files"];
        if (data.images["files"].length > 0) {
          this.metaImageUrl =
          this.API + "/files/image/" + this.ad.photos[0]._id;
        } else {
          this.metaImageUrl = "/assets/images/condo-323780.jpg";
        }
      }
    );
  }
  
  addToViewHistory(adId: string, userId: string, meta?: any): void {
    this.viewHistoryService
    .addViewHistory(adId, userId, meta)
    .pipe(take(1))
    .subscribe((result) => {
      // console.log(result);
      return;
    });
  }
  
  adViewCount(adId: string) {
    // console.log(document.hidden); // count 10 seconds of active time
    this.adViewService
    .addAdView(adId)
    .pipe(take(1))
    .subscribe((result) => {
      // console.log(result);
      this.countAdViews(adId);
    });
  }
  
  countAdViews(adId) {
    this.adViewService.countAdView(adId).subscribe((result) => {
      this.adViewsCount = result["adViewsCount"];
    });
  }
  
  countAdFavorites(adId) {
    this.favoritesService.countAdFavorites(adId).subscribe((result) => {
      this.favoritesCount = result["favoritesCount"];
    });
  }
  
  setUserName(userId: string) {
    this.userDataService.getName(userId).subscribe((res: object) => {
      this.ad["userName"] = res["name"];
    });
  }
}

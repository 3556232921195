import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
// import { Platform } from "@ionic/angular";
// import { SplashScreen } from "@ionic-native/splash-screen/ngx";
// import { StatusBar } from "@ionic-native/status-bar/ngx";
import {
  NgcCookieConsentService,
  NgcNoCookieLawEvent,
  NgcInitializingEvent,
  NgcStatusChangeEvent,
  // NgcCookieConsentConfig,
} from "ngx-cookieconsent";

import { LanguageService } from "./services/language.service";
// import { LoginService } from "./services/login.service";
import { DataService } from "./services/data.service";
import { WindowService } from "./services/window.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-cookie",
  template: ``,
})
export class CookieComponent implements OnInit, OnDestroy, AfterViewInit {
  lang: any;
  appLanguage = "en";
  loggedIn: boolean;
  currentUserId = "";
  API: string;
  prefersDarkListener: any;
  languageParams: string | null;
  userConsent = true;
  cookie: any;

  // keep refs to subscriptions to be able to unsubscribe later
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

  constructor(
    // private platform: Platform,
    // private splashScreen: SplashScreen,
    // private statusBar: StatusBar,
    // private loginService: LoginService,
    private languageService: LanguageService,
    private dataService: DataService,
    private windowService: WindowService,
    private ccService: NgcCookieConsentService
  ) {
    // this.initializeApp();
    // this.lang = this.languageService['en'];
  }

  ngOnInit() {
    // SET LANGUAGE
    this.languageService.language$.subscribe((value) => {
      // console.log(this.languageService[value]);
      this.lang = this.languageService[value];
      this.appLanguage = value;

      if (this.ccService.getConfig()) {
        this.updateCookieLanguage();
      }
    });
  }

  ngAfterViewInit(): void {
    this.dataService.isBrowser$.subscribe((isBrowser) => {
      // console.log(isBrowser);
      if (isBrowser) {
        if (this.windowService.nativeWindow) {
          if (this.ccService.hasConsented()) {
            this.toggleGA(true);
            this.toggleHotJar(true);
          }

          // this.ccService.getConfig().cookie;
          this.loadPopup();
        }
      }
    });
  }

  updateCookieLanguage(): void {
    this.ccService.getConfig().cookie.domain = this.windowService?.nativeWindow?.location.hostname || "https://kvadrat.si";

    // let cookeLink: string;
    if (this.appLanguage === "en") {
      this.ccService.getConfig().content.href = "/privacy";
      this.ccService.getConfig().content.message =
        "We value your privacy. We use cookies for statistics and the best possible user experience on our website.";
      this.ccService.getConfig().content.allow = "Allow cookies";
      this.ccService.getConfig().content.deny = "Decline";
      this.ccService.getConfig().content.link = "Learn more";
      this.ccService.getConfig().content.policy = "🍪";
    } else {
      // cookeLink = "/privacy?l=si";
      this.ccService.getConfig().content.href = "/privacy?l=si";
      this.ccService.getConfig().content.message =
        "Spoštujemo vašo zasebnost. Naši piškoti so namenjeni za statisistiko in boljšo uporabniško izkušnjo.";
      this.ccService.getConfig().content.allow = "Dovoli";
      this.ccService.getConfig().content.deny = "Zavrni";
      this.ccService.getConfig().content.link = "Preberi";
      this.ccService.getConfig().content.policy = "🍪";
    }

    this.ccService.destroy(); // remove previous cookie bar (with default messages)
    this.ccService.init(this.ccService.getConfig()); // update config with translated messages
    // this.ccService.getConfig().content.href = cookeLink;
  }

  toggleHotJar(enable: boolean): void {
    if (enable) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.id = "hotJarScript";

      const code = `
        (function (h, o, t, j, a, r) {
          h.hj =
            h.hj ||
            function () {
              (h.hj.q = h.hj.q || []).push(arguments);
            };
          h._hjSettings = { hjid: 1907576, hjsv: 6 };
          a = o.getElementsByTagName("head")[0];
          r = o.createElement("script");
          r.async = 1;
          r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
          a.appendChild(r);
        })(
          window,
          document,
          "https://static.hotjar.com/c/hotjar-",
          ".js?sv="
        );
      `;
      try {
        script.appendChild(document.createTextNode(code));
        document.body.appendChild(script);
      } catch (e) {
        script.text = code;
        document.body.appendChild(script);
      }
    } else {
      document.getElementById("hotJarScript").remove();
    }
  }

  toggleGA(enable: boolean): void {
    if (enable) {
      this.userConsent = true;

      const script = document.createElement("script");
      script.type = "text/javascript";
      script.id = "googleScript";
      script.src = "https://www.googletagmanager.com/gtag/js?id=G-PPFY2MK1FC";
      document.body.appendChild(script);

      const script2 = document.createElement("script");
      script2.type = "text/javascript";
      script2.id = "googleScript2";
      const code = `
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag("js", new Date());

        gtag("config", "G-PPFY2MK1FC");
      `;
      try {
        script2.appendChild(document.createTextNode(code));
        document.body.appendChild(script2);
      } catch (e) {
        script2.text = code;
        document.body.appendChild(script2);
      }
    } else {
      this.userConsent = false;
      document.getElementById("googleScript").remove();
      document.getElementById("googleScript2").remove();
    }
  }

  loadPopup(): void {
    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    // this.initializeSubscription = this.ccService.initialized$.subscribe(
    //   () => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //     // console.log(event);
    //   }
    // );

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
        // console.log(event);
        if (event.status === "allow") {
          this.toggleGA(true);
          this.toggleHotJar(true);
        } else {
          this.toggleGA(false);
          this.toggleHotJar(false);
        }
      }
    );

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
        this.ccService.getConfig().cookie.expiryDays = 2;
      }
    );

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );
  }

  ngOnDestroy(): void {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    if (this.popupOpenSubscription) {
      this.popupOpenSubscription.unsubscribe();
    }
    if (this.popupCloseSubscription) {
      this.popupCloseSubscription.unsubscribe();
    }
    if (this.initializeSubscription) {
      this.initializeSubscription.unsubscribe();
    }
    if (this.statusChangeSubscription) {
      this.statusChangeSubscription.unsubscribe();
    }
    if (this.revokeChoiceSubscription) {
      this.revokeChoiceSubscription.unsubscribe();
    }
    if (this.noCookieLawSubscription) {
      this.noCookieLawSubscription.unsubscribe();
    }
  }
}

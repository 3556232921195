<ion-header class="shrinkable">
  <app-main-header
    [mobileView]="mobileView"
    [title]="title"
    [basePath]="'about'"
    [currentUser]="currentUser"
  ></app-main-header>
</ion-header>

<ion-content [fullscreen]="true" class="base-page-content">
  <div class="content-body">
    <ng-container [ngSwitch]="title">
      <ng-container *ngSwitchCase="'about'">
        <app-about-info
          [currentUser]="currentUser"
          [lang]="lang"
          [appLanguage]="appLanguage"
        ></app-about-info>
      </ng-container>
      <ng-container *ngSwitchCase="'terms'">
        <app-general-terms
          [currentUser]="currentUser"
          [lang]="lang"
          [appLanguage]="appLanguage"
        ></app-general-terms>
      </ng-container>
      <ng-container *ngSwitchCase="'privacy'">
        <app-privacy-policy
          [currentUser]="currentUser"
          [lang]="lang"
          [appLanguage]="appLanguage"
        ></app-privacy-policy>
      </ng-container>
      <ng-container *ngSwitchCase="'advertising'">
        <app-advertising
          [currentUser]="currentUser"
          [lang]="lang"
          [appLanguage]="appLanguage"
        ></app-advertising>
      </ng-container>
      <ng-container *ngSwitchCase="'contact-us'">
        <app-contact-us
          [currentUser]="currentUser"
          [lang]="lang"
          [appLanguage]="appLanguage"
        ></app-contact-us>
      </ng-container>
    </ng-container>
  </div>

  <app-footer></app-footer>
</ion-content>

import { Component, OnInit, HostListener, Input } from "@angular/core";
import { MenuController, ModalController } from "@ionic/angular";

import { LanguageService } from "../../services/language.service";
import { LocalStorageService } from "../../services/localstorage.service";
import { LoginService } from "../../services/login.service";
import { DataService } from "../../services/data.service";
import { WindowService } from "../../services/window.service";

import { CurrentUser } from "../../model/current-user";
import { ActivatedRoute, Router } from "@angular/router";
import { LocationLink } from "../../model/location-link";

// import { Location } from "@angular/common";
// import { LocationService } from "../../services/location.service";
@Component({
  selector: "app-agent-card",
  styleUrls: ["./agent-card.component.scss"],
  template: `
    <ion-card class="agent-card">
      <img
        class="profile-photo"
        alt="Silhouette of mountains"
        src="https://ionicframework.com/docs/img/demos/card-media.png"
      />
      <ion-card-header>
        <ion-card-title>{{ person.name }}</ion-card-title>
        <ion-card-subtitle>Št.: 808043432</ion-card-subtitle>
      </ion-card-header>

      <ion-card-content>
        Here's a small text description for the card content. Nothing more,
        nothing less.
      </ion-card-content>
    </ion-card>
  `,
}) /*extends CompleterBaseData*/
export class AgentCardComponent implements OnInit {
  // variables
  ads: any;
  currentUser: CurrentUser;
  loggedIn = false;
  layoutGrid: boolean;
  mobileView: boolean;
  title = "Kvadrat";
  latestAds = false;
  locations: LocationLink[] = []; // todo: Location type
  isBrowser = false;

  @Input() appLanguage: string;
  @Input() lang: any;
  @Input() person: any;

  constructor(
    private dataService: DataService,
    private localStorageService: LocalStorageService,
    public modalCtrl: ModalController,
    private loginService: LoginService,
    private languageService: LanguageService,
    private menuCtrl: MenuController,
    private windowService: WindowService,
    private route: ActivatedRoute,
    private router: Router // private locationService: LocationService, // private location: Location
  ) {
    // this.lang = this.languageService['en'];
    // languageService.language$.subscribe(value => {
    //   this.lang = this.languageService[value];
    //   console.log(value);
    //   this.setMetaInfo();
    // });
  }

  ngOnInit() {
    // this.route.data.subscribe((data: { locations: any; language: string }) => {
    //   this.lang = this.languageService[data.language["language"]];
    //   this.locations = data.locations;
    //   this.setMetaInfo();
    // });

    // SET LANGUAGE
    this.languageService.language$.subscribe((value) => {
      this.appLanguage = value;
      this.lang = this.languageService[value];
    });

    // GET VIEW
    this.dataService.isBrowser$.subscribe((isBrowser) => {
      this.isBrowser = isBrowser;
      if (isBrowser) {
        // this.getCounties();
        this.toggleMobileView();

        // SET LAYOUT
        if (
          this.localStorageService &&
          this.localStorageService.getItem("gridView")
        ) {
          this.layoutGrid = JSON.parse(
            this.localStorageService.getItem("gridView")
          );
        } else {
          // FALLBACK
          this.layoutGrid = false;
        }

        this.dataService.loggedIn$.subscribe((val) => {
          this.loggedIn = val;
          this.currentUser = this.loginService.currentUser();
        });
      }
    });
  }

  enableLatestAds(event) {
    this.latestAds = event;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.toggleMobileView();
  }

  toggleMobileView() {
    if (this.windowService.nativeWindow.innerWidth <= "767") {
      this.mobileView = true;
      this.menuCtrl.swipeGesture(true);
    } else {
      this.mobileView = false;
      this.menuCtrl.swipeGesture(false);
    }
  }
}

import { Component, OnInit, Input, HostListener } from "@angular/core";
import { AlertController, MenuController, ModalController, PopoverController, ToastController } from "@ionic/angular";
import { ActivatedRoute } from "@angular/router";

import { CurrentUser } from "../../model/current-user";
import { LanguageService } from "../../services/language.service";
import { DataService } from "../../services/data.service";
import { LoginService } from "../../services/login.service";
import { WindowService } from "../../services/window.service";
import { MetaService } from "../../services/meta.service";
import { PostsService } from "../../services/posts.service";
import { IPost } from "../../model/db/post";
import { PostModalComponent } from "../modals/post-modal/post-modal.component";
import { LoginModalComponent } from "../modals/login-modal/login-modal.component";
import { BucketService } from "../../services/bucket.service";
import { UserDataService } from "../../services/user-data.service";
import { PhotoPreviewModalComponent } from "../modals/photo-preview/photo-preview-modal.component";
import { LikesService } from "../../services/likes.service";
import { CommentService } from "../../services/comment.service";
import { PostPreviewModalComponent } from "../modals/post-preview-modal/post-preview-modal.component";
import { KebabOptionsPopoverComponent } from "../popovers/kebab-options/kebab-options-popover.component";
import { FlagReasonsPopoverComponent } from "../popovers/flag-reasons/flag-reasons-popover.component";
import { FlagService } from "../../services/flag.service";
import { debounceTime, Subject } from "rxjs";
import { SocketService } from "../../services/socket.service";

// Comment Component
@Component({
  selector: "app-wall-page",
  templateUrl: "./wall-page.component.html",
  styleUrls: ["./wall-page.component.scss"],
})
export class WallPageComponent implements OnInit {
  mobileView: boolean;
  currentUser: CurrentUser;
  appLanguage: string;
  lang: any;
  loggedIn = false;
  isBrowser = false;

  posts: IPost[] = [];
  currentPage = 1; // Current page
  limit = 10; // Items per page
  canLoadMore = true;
  loadCount = 0;
  newPostsToRefresh = 0;

  API: string;
  removeAdAlert: HTMLIonAlertElement;
  mySubject: Subject<any> = new Subject<any>();

  constructor(
    private languageService: LanguageService,
    private dataService: DataService,
    private loginService: LoginService,
    private windowService: WindowService,
    private menuCtrl: MenuController,
    private route: ActivatedRoute,
    private metaService: MetaService,
    private postsService: PostsService,
    private modalCtrl: ModalController,
    private bucketService: BucketService,
    private userDataService: UserDataService,
    private likesService: LikesService,
    private toastCtrl: ToastController,
    private commentService: CommentService,
    private alertCtrl: AlertController,
    private popoverCtrl: PopoverController,
    private flagService: FlagService,
    private socketService: SocketService,
  ) {}

  ngOnInit() {
    this.mySubject.pipe(
      debounceTime(1000)  // Wait 1000ms after the last event
    ).subscribe(() => this.getPosts());
    
    this.API = this.dataService.API;
    
    // SET LANGUAGE
    this.languageService.language$.subscribe((value) => {
      this.appLanguage = value;
      this.lang = this.languageService[value];
    });

    this.dataService.loggedIn$.subscribe((val) => {
      this.loggedIn = val;
      this.currentUser = this.loginService.currentUser();
    });

    // GET VIEW
    this.dataService.isBrowser$.subscribe((isBrowser) => {
      this.isBrowser = isBrowser;
      if (isBrowser) {
        // this.getCounties();
        this.toggleMobileView();
      }
    });

    this.setMetaInfo("Community posts");

    this.getPosts();

    this.socketService.adPhotoChanges$.subscribe((msg) => {
      const existingItem = this.posts.find((post) => post._id === msg["itemId"]);

      if (!existingItem && msg["type"] === "postChanges") {
        this.newPostsToRefresh++;
      }
    });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.toggleMobileView();
  }

  toggleMobileView() {
    if (this.windowService.nativeWindow.innerWidth <= "767") {
      this.mobileView = true;
      this.menuCtrl.swipeGesture(true);
    } else {
      this.mobileView = false;
      this.menuCtrl.swipeGesture(false);
    }
  }

  setMetaInfo(title: string) {
    this.metaService.setMetaDataObject({
      title: "Community posts",
      description: "Community posts",
      keywords: "Community posts",
      url: this.windowService?.nativeWindow?.location.origin || "https://kvadrat.si",
      image: (this.windowService?.nativeWindow?.location.origin || "https://kvadrat.si") + "/assets/images/condo-323780.jpg"
    });
  }

  getPosts(event?: any): void {
    this.postsService.getAllPosts(this.currentPage, this.limit).subscribe((posts: IPost[]) => {
      if (posts["error"] || posts?.length === 0) {
        this.canLoadMore = false;
        this.loadCount = 0;
        return;
      }

      let newPosts = posts;
      newPosts.forEach((post) => {
        this.bucketService.listAdImages(post._id, 2).subscribe(images => {
          post.images = images['files'].map(image => this.API + '/files/image/' + image._id);
          this.getUserName(post);
          this.getLikes(post._id, post);
          this.getCommentsNumber(post._id, post);
        });
      })
      
      this.posts = [...this.posts, ...newPosts];
      // this.posts = posts;
      this.canLoadMore = newPosts.length === this.limit;

      if (event) {
        event.target.complete();
      }

      // Increment the page number for the next request
      this.currentPage++;
      this.loadCount++;

      // Reset the load count after 5 loads (if needed)
      if (this.loadCount === 5) {
        this.canLoadMore = false;
      }
    });
  }

  triggerGetPosts(event: any): void {
    this.mySubject.next(event);
  }

  fetchNewPosts() {
    this.canLoadMore = true; // Re-enable infinite scroll
    this.loadCount = 0;
    this.currentPage = 1;
    this.getPosts();
  }

  manualLoad() {
    this.canLoadMore = true; // Re-enable infinite scroll
    this.loadCount = 0;
    this.getPosts();
  }

  async openPhotoPreview(post: IPost) {
    const previewModal = await this.modalCtrl.create({
      component: PhotoPreviewModalComponent,
      componentProps: {
        lang: this.lang,
        appLanguage: this.appLanguage,
        imageUrls: post.images,
        photoPreview: true,
      },
      cssClass: 'photo-preview-modal'
    });
    previewModal.present();
  }

  voteOnItem(item: IPost, itemOwnerId: string, vote: number) {
    if (this.currentUser === undefined) {
      // console.log("vpišite se");
      this.presentLoginModal();
      return null;
    }
    this.likesService
      .voteOnItem(item._id, this.currentUser._id, itemOwnerId, vote)
      .subscribe(async (res: any) => {
        // console.log("res from vote");
        // console.log(res);
        if (res["code"] === 200) {
          this.getLikes(item._id, item);
        } else if (res["code"] === 403) {
          const toast = await this.toastCtrl.create({
            message: res["message"],
            duration: 3000,
          });
          toast.present();
        }
      });
  }

  getLikes(itemId: string, item: IPost) {
    this.likesService.countItemLikes(itemId).subscribe((res: object[]) => {
      // console.log(res);
      item.likes = res["count"];
    });
  }

  getCommentsNumber(itemId: string, item: IPost) {
    // 4 - comment type post
    this.commentService.getCommentsNumberAd(itemId, 4).subscribe((res: object[]) => {
      item.commentsNumber = res["commentCount"];
    })
  }

  getUserName(post: IPost) {
    this.userDataService.getName(post.userId).subscribe((res: object) => {
      post.userName = res["name"];
    });
  }

  createNewPost() {
    if (this.currentUser) {
      this.createPostModal()
    } else {
      this.presentLoginModal();
    }
  }

  postPreviewModal(post: IPost) {
    if (this.currentUser) {
      this.openPostPreviewModal(post)
    } else {
      this.presentLoginModal();
    }
  }

  private async createPostModal(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: PostModalComponent,
      componentProps: {
        // updateAds: (queryParams) => {
        //   this.goToAds(queryParams);
        // },
        // item: item,
        lang: this.lang,
        appLanguage: this.appLanguage,
        currentUser: this.currentUser,
      },
      cssClass: 'post-modal'
    });
    modal.present();
  }

  private async openPostPreviewModal(post: IPost): Promise<void> {
    const previewModal = await this.modalCtrl.create({
      component: PostPreviewModalComponent,
      componentProps: {
        // updateAds: (queryParams) => {
        //   this.goToAds(queryParams);
        // },
        voteOnItem: (post: IPost, itemOwnerId: string, vote: number) => {
          this.voteOnItem(post, post.userId, 1);
        },
        post: post,
        lang: this.lang,
        appLanguage: this.appLanguage,
        currentUser: this.currentUser,
      },
      cssClass: 'preview-post-modal'
    });
    previewModal.present();
  }

  async presentLoginModal() {
    const modal = await this.modalCtrl.create({
      component: LoginModalComponent,
      componentProps: {
        updateList: (name) => {
          // this.updateList();
        },
        // item: item,
        lang: this.lang,
        appLanguage: this.appLanguage,
      },
      cssClass: 'login-modal'
    });
    modal.present();
  }

  async removePostAlertOpen(postId: string) {
    if (!this.removeAdAlert) {
      this.removeAdAlert = await this.alertCtrl.create({
        header: this.lang.removePost, // this.lang['delete'],
        subHeader: this.lang.removePostConfirm, // this.lng['deleteConfirm'],
        // subTitle: 'Removing the ad can not be undone. Ste prepričani, da želite izbrisati oglas?', // this.lng['deleteConfirm'],
        buttons: [
          {
            text: this.lang.cancel, // this.lng['cancel'],
            role: 'cancel',
            handler: () => {
              // console.log('Cancel clicked');
              this.removeAdAlert = undefined;
            }
          },
          {
            text: this.lang.remove, // this.lang['ok'],
            handler: () => {
              // this.makePublic(item);
              this.removePost(postId, this.currentUser._id);
              this.removeAdAlert = undefined;
            },
            cssClass: 'delete-confirm'
          }
        ]
      });

      await this.removeAdAlert.present();
    }
  }

  removePost(postId: string, userId: string) {
    this.postsService.deletePost(postId, userId)
      .subscribe(response => {
        // update list of ads
        this.getPosts();
      });
  }
  
  async presentPostOptionsPopover(ev: Event, post: IPost) {
    const popover = await this.popoverCtrl.create({
      component: KebabOptionsPopoverComponent, // general component for items
      componentProps: {
        flagComment: () => {
          this.flagComment(ev, post);
        },
        editComment: () => {
          this.editComment(post);
        },
        deleteComment: () => {
          this.removePostAlertOpen(post._id); // refactor for id
        },
        itemOwnerId: post.userId,
        lang: this.lang,
      },
      event: ev,
    });
    popover.present();
  }

  flagComment(ev: Event, post: IPost): void {
    if (this.currentUser === undefined) {
      this.presentLoginModal();
      return null;
    }

    this.presentFlagReasonsPopover(ev, post);
  }

  async presentFlagReasonsPopover(ev: Event, post: IPost): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: FlagReasonsPopoverComponent,
      componentProps: {
        flagItem: (flagComment: any, flagReason: number, description: string) => {
          this.sendFlag(post._id, post.userId, flagReason, description);
        },
        comment: post,
        lang: this.lang,
      },
      cssClass: "flag-reasons-popover",
      // bug in positioning on bottom of screen, gets cut off if description is open
      // event: ev,
    });
    popover.present();
  }

  sendFlag(postId: string, postOwnerId: string, flagReason: number, description: string): void {
    this.flagService
      .flagItem(
        postId,
        this.currentUser._id,
        postOwnerId,
        4, // 4-post
        flagReason,
        description
      ).subscribe(async (response) => {
        const toast = await this.toastCtrl.create({
          message: response["message"],
          duration: 3000,
        });
        toast.present();
      });
  }

  editComment(post: IPost) {
    if (this.currentUser === undefined) {
      this.presentLoginModal();
      return null;
    }

    // show textarea with post text
    // this.editCommentId = comment._id;
    // this.editMessage = comment.message;
  }
}

<div *ngIf="ad" class="detail-page-content">
  <article>
    <div
      *ngIf="singlePage && ad.photos"
      class="photo-section"
      (click)="openPhotoPreview()"
    >
      <ion-grid>
        <ion-row>
          <ion-col
            class="image-col"
            size="12"
            *ngIf="ad.photos.length === 1"
            [ngStyle]="{
              'background-image':
                'url(' + API + '/files/image/' + ad.photos[0]._id + ')'
            }"
          >
          </ion-col>
          <ion-col
            class="image-col"
            size="12"
            size-sm="6"
            *ngIf="ad.photos.length === 2"
            [ngStyle]="{
              'background-image':
                'url(' + API + '/files/image/' + ad.photos[0]._id + ')'
            }"
          >
          </ion-col>
          <ion-col
            class="image-col"
            size="12"
            size-sm="6"
            *ngIf="ad.photos.length === 2"
            [ngStyle]="{
              'background-image':
                'url(' + API + '/files/image/' + ad.photos[1]._id + ')'
            }"
          >
          </ion-col>
          <ion-col
            class="image-col"
            size="12"
            size-sm="8"
            *ngIf="ad.photos.length > 2"
            [ngStyle]="{
              'background-image':
                'url(' + API + '/files/image/' + ad.photos[0]._id + ')'
            }"
          >
          </ion-col>
          <ion-col
            class="image-col"
            size="12"
            size-sm="4"
            *ngIf="ad.photos.length > 2"
          >
            <div
              class="extra-photo"
              [ngStyle]="{
                'background-image':
                  'url(' + API + '/files/image/' + ad.photos[1]._id + ')'
              }"
            ></div>
            <div
              class="extra-photo"
              [ngStyle]="{
                'background-image':
                  'url(' + API + '/files/image/' + ad.photos[2]._id + ')'
              }"
            ></div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <div *ngIf="!singlePage">
      <app-image-slider
        *ngIf="!mobileView && imageUrls && imageUrls.length > 0"
        [images]="imageUrls"
        [imgHeight]="600"
      ></app-image-slider>
      <app-image-slider
        *ngIf="mobileView && imageUrls && imageUrls.length > 0"
        [images]="imageUrls"
        [imgHeight]="260"
      ></app-image-slider>
    </div>

    <div class="container">
      <ion-row class="overview-data">
        <ion-col size="12" size-sm="6">
          <h3>
            {{ ad.located.street }}, {{ ad.located.postal }}
            {{ ad.located.district }}
          </h3>
          <!-- <div> -->
          <p class="ad-prop-type">
            {{ lang.adTsPropertyType[ad.adPropertyType] }}
            <span *ngIf="ad.adPropertyType === 1 && ad.roomNum === 0">
               - {{ lang.flatTypes.garconiere }}
            </span>
            <span *ngIf="ad.adType === 0">{{ lang.buy }}</span>
          </p>

          <p *ngIf="ad.adType === 1">
            {{ lang.forRent }} <span *ngIf="ad.moveIn">{{ lang.from }} {{ (ad.moveIn | date : "dd.MM.yyyy") || "/" }}</span><span *ngIf="ad.moveOut">&nbsp;{{ lang.to }} {{ (ad.moveOut | date : "dd.MM.yyyy") || "/" }}</span>
          </p>

          <h2 class="price">
            {{ ad.price.current | number }} €
            <span class="per-month" *ngIf="ad.price.type === 1">/{{ lang.month }}</span>
            <span class="price-per-m">
              ({{ ad.price.current / ad.floorSize | number : "1.1-2" }} €/m&#178;)
            </span>
          </h2>

          <ng-container *ngIf="isNotBuilding">
            <p class="size">{{ ad.lotSize | number }} m&#178;</p>
          </ng-container>
          <ng-container *ngIf="!isNotBuilding">
            <p class="size">
              {{ ad.floorSize | number }} m&#178;, {{ ad.roomNum || "/" }}
              {{ lang.rooms }}, {{ ad.bedroomNum || "/" }} {{ lang.bedrooms }},
              {{ ad.bathNum || "/" }} {{ lang.bathrooms }}
            </p>
          </ng-container>

          <ion-row class="meta-overview">
            <ion-col>
              <p>{{ lang.published }}</p>
              <p class="meta-value">{{ ad.elapsed }} {{ lang.days }}</p>
            </ion-col>
            <ion-col>
              <p>{{ lang.views }}</p>
              <p class="meta-value">{{ adViewsCount }}</p>
            </ion-col>
            <ion-col>
              <p>{{ lang.saves }}</p>
              <p class="meta-value">{{ favoritesCount }}</p>
            </ion-col>
          </ion-row>
        </ion-col>

        <ion-col size="12" size-sm="6">
          <h3>
            {{ lang.seller }}
          </h3>
          <ion-item>
            <ion-avatar class="user-avatar">
              <app-profile-photo #profilePhoto [userId]="ad.author" [size]="40">
              </app-profile-photo>
            </ion-avatar>
            <ion-label>
              <a
                [routerLink]="['/p', ad.author]"
                (click)="modalClose.emit(true)"
                #contactSeller
              >
                <p>{{ ad.userName }}</p>
              </a>
              <p>
                <small *ngIf="userData">{{ userData.userType === 0 ? lang["private"] : lang["agent"]}}</small>
              </p>
            </ion-label>
          </ion-item>
          <h3>
            {{ lang.contactSeller }}
          </h3>
          <ion-textarea
            rows="5"
            type="text"
            class="message-textarea"
            name="contactMessage"
            [(ngModel)]="contactMessage"
          >
          </ion-textarea>
          <ion-button
            [disabled]="contactMessage.length < 5"
            (click)="sendMessage()"
          >
            <ion-icon slot="start" color="white" name="mail"></ion-icon>
            {{ lang.sendEmail }}
          </ion-button>

          <ion-button (click)="getPhone()">
            <ion-icon slot="start" color="white" name="call"></ion-icon>
            {{ lang.getPhone }}
          </ion-button>
          <span *ngIf="phoneNumber"
            ><a [href]="'tel:' + phoneNumber">{{ phoneNumber }}</a></span
          >
        </ion-col>
      </ion-row>

      <ion-row class="overview-data">
        <ion-col>
          <h1>
            {{ lang.details }}
          </h1>
          <h3>
            {{ lang.location }}
          </h3>

          <p>
            {{ ad.located.street }}, {{ ad.located.postal }}
            {{ ad.located.district }}
          </p>

          <p>
            <a target="_blank" href="https://maps.google.com/maps?q={{ ad.located.position.Latitude }},{{ ad.located.position.Longitude }}">
              {{ lang.openWithGoogleMaps }}
            </a>
          </p>

          <h3>
            {{ lang.details }}
          </h3>
          <ion-row>
            <ion-col size="12" size-sm="6">
              <p class="detail">
                <span class="label">{{ lang.yearBuilt }}:</span>
                {{ ad.yearBuilt }}
              </p>
            </ion-col>
            <ion-col size="12" size-sm="6">
              <p class="detail">
                <span class="label">{{ lang.yearAdaptation }}:</span>
                {{ ad.lastAdaptation }}
              </p>
            </ion-col>
            <ion-col size="12" size-sm="6">
              <p class="detail">
                <span class="label">{{ lang.livingSpace }} (m&#178;):</span>
                {{ ad.floorSize }}
              </p>
            </ion-col>
            <ion-col size="12" size-sm="6">
              <p class="detail">
                <span class="label">{{ lang.roomNumber }}:</span>
                {{ ad.roomNum }}
              </p>
            </ion-col>
            <ion-col size="12" size-sm="6">
              <p class="detail">
                <span class="label">{{ lang.parkingNumber }}:</span>
                {{ ad.parkingPlaces }}
              </p>
            </ion-col>
            <ion-col size="12" size-sm="6">
              <p class="detail">
                <span class="label">{{ lang.garageNumber }}:</span>
                {{ ad.garage ?? "/" }}
              </p>
            </ion-col>
            <ion-col size="12" size-sm="6">
              <p class="detail">
                <span class="label">{{ lang.adPropertyType.estate }} (m&#178;):</span>
                {{ ad.lotSize ?? "/" }}
              </p>
            </ion-col>
            <ion-col size="12" size-sm="6">
              <p class="detail">
                <span class="label">{{ lang.slopeOfEstate }}:</span>
                <span *ngIf="ad.slope === 0">{{ lang.noSlope }}</span>
                <span *ngIf="ad.slope === 1">{{ lang.moderateSlope }}</span>
                <span *ngIf="ad.slope === 2">{{ lang.steepSlope }}</span>
              </p>
            </ion-col>

            <ion-col size="12" size-sm="6">
              <p class="detail">
                <span class="label">{{ lang.slopeDirection }}:</span>
                <span *ngIf="ad.slopeDirection === 0">{{ lang.north }}</span>
                <span *ngIf="ad.slopeDirection === 1">{{ lang.east }}</span>
                <span *ngIf="ad.slopeDirection === 2">{{ lang.south }}</span>
                <span *ngIf="ad.slopeDirection === 3">{{ lang.west }}</span>
              </p>
            </ion-col>

            <ion-col size="12" size-sm="6">
              <p class="detail">
                <span class="label">{{ lang.propertyId }}:</span>
                {{ ad.propertyId ?? "/" }}
              </p>
            </ion-col>

            <ion-col size="12" size-sm="6">
              <p class="detail">
                <span class="label">{{ lang.floor }}:</span>
                {{ ad.floor ?? "/" }}
              </p>
            </ion-col>

            <ion-col size="12" size-sm="6">
              <p class="detail">
                <span class="label">{{ lang.numFloors }}:</span>
                {{ ad.numFloors ?? "/" }}
              </p>
            </ion-col>
          </ion-row>

          <h3>
            {{ lang.amenitiesTranslation }}
          </h3>
          <ion-row>
            <ion-col size="12" size-sm="6">
              <p
                class="amenities"
                [ngClass]="{ 'not-present': !ad.amenities.animalsAllowed }"
              >
                <ion-icon
                  *ngIf="ad.amenities.animalsAllowed"
                  name="checkbox-outline"
                ></ion-icon>
                <ion-icon
                  *ngIf="!ad.amenities.animalsAllowed"
                  name="square-outline"
                ></ion-icon>
                {{ lang.amenities.animalsAllowed }}
              </p>
            </ion-col>
            <ion-col size="12" size-sm="6">
              <p
                class="amenities"
                [ngClass]="{ 'not-present': !ad.amenities.constructionReady }"
              >
                <ion-icon
                  *ngIf="ad.amenities.constructionReady"
                  name="checkbox-outline"
                ></ion-icon>
                <ion-icon
                  *ngIf="!ad.amenities.constructionReady"
                  name="square-outline"
                ></ion-icon>
                {{ lang.amenities.constructionReady }}
              </p>
            </ion-col>
            <ion-col size="12" size-sm="6">
              <p
                class="amenities"
                [ngClass]="{ 'not-present': !ad.amenities.ac }"
              >
                <ion-icon
                  *ngIf="ad.amenities.ac"
                  name="checkbox-outline"
                ></ion-icon>
                <ion-icon
                  *ngIf="!ad.amenities.ac"
                  name="square-outline"
                ></ion-icon>
                {{ lang.amenities.ac }}
              </p>
            </ion-col>
            <ion-col size="12" size-sm="6">
              <p
                class="amenities"
                [ngClass]="{ 'not-present': !ad.amenities.dishwasher }"
              >
                <ion-icon
                  *ngIf="ad.amenities.dishwasher"
                  name="checkbox-outline"
                ></ion-icon>
                <ion-icon
                  *ngIf="!ad.amenities.dishwasher"
                  name="square-outline"
                ></ion-icon>
                {{ lang.amenities.dishwasher }}
              </p>
            </ion-col>
            <ion-col size="12" size-sm="6">
              <p
                class="amenities"
                [ngClass]="{ 'not-present': !ad.amenities.heatPump }"
              >
                <ion-icon
                  *ngIf="ad.amenities.heatPump"
                  name="checkbox-outline"
                ></ion-icon>
                <ion-icon
                  *ngIf="!ad.amenities.heatPump"
                  name="square-outline"
                ></ion-icon>
                {{ lang.amenities.heatPump }}
              </p>
            </ion-col>
            <ion-col size="12" size-sm="6">
              <p
                class="amenities"
                [ngClass]="{ 'not-present': !ad.amenities.internet }"
              >
                <ion-icon
                  *ngIf="ad.amenities.internet"
                  name="checkbox-outline"
                ></ion-icon>
                <ion-icon
                  *ngIf="!ad.amenities.internet"
                  name="square-outline"
                ></ion-icon>
                {{ lang.amenities.internet }}
              </p>
            </ion-col>
            <ion-col size="12" size-sm="6">
              <p
                class="amenities"
                [ngClass]="{ 'not-present': !ad.amenities.opticalFiber }"
              >
                <ion-icon
                  *ngIf="ad.amenities.opticalFiber"
                  name="checkbox-outline"
                ></ion-icon>
                <ion-icon
                  *ngIf="!ad.amenities.opticalFiber"
                  name="square-outline"
                ></ion-icon>
                {{ lang.amenities.opticalFiber }}
              </p>
            </ion-col>
            <ion-col size="12" size-sm="6">
              <p
                class="amenities"
                [ngClass]="{ 'not-present': !ad.amenities.kitchenBasic }"
              >
                <ion-icon
                  *ngIf="ad.amenities.kitchenBasic"
                  name="checkbox-outline"
                ></ion-icon>
                <ion-icon
                  *ngIf="!ad.amenities.kitchenBasic"
                  name="square-outline"
                ></ion-icon>
                {{ lang.amenities.kitchenBasic }}
              </p>
            </ion-col>
            <ion-col size="12" size-sm="6">
              <p
                class="amenities"
                [ngClass]="{ 'not-present': !ad.amenities.lift }"
              >
                <ion-icon
                  *ngIf="ad.amenities.lift"
                  name="checkbox-outline"
                ></ion-icon>
                <ion-icon
                  *ngIf="!ad.amenities.lift"
                  name="square-outline"
                ></ion-icon>
                {{ lang.amenities.lift }}
              </p>
            </ion-col>
            <ion-col size="12" size-sm="6">
              <p
                class="amenities"
                [ngClass]="{ 'not-present': !ad.amenities.pool }"
              >
                <ion-icon
                  *ngIf="ad.amenities.pool"
                  name="checkbox-outline"
                ></ion-icon>
                <ion-icon
                  *ngIf="!ad.amenities.pool"
                  name="square-outline"
                ></ion-icon>
                {{ lang.amenities.pool }}
              </p>
            </ion-col>
            <ion-col size="12" size-sm="6">
              <p
                class="amenities"
                [ngClass]="{ 'not-present': !ad.amenities.solar }"
              >
                <ion-icon
                  *ngIf="ad.amenities.solar"
                  name="checkbox-outline"
                ></ion-icon>
                <ion-icon
                  *ngIf="!ad.amenities.solar"
                  name="square-outline"
                ></ion-icon>
                {{ lang.amenities.solar }}
              </p>
            </ion-col>
            <ion-col size="12" size-sm="6">
              <p
                class="amenities"
                [ngClass]="{ 'not-present': !ad.amenities.washer }"
              >
                <ion-icon
                  *ngIf="ad.amenities.washer"
                  name="checkbox-outline"
                ></ion-icon>
                <ion-icon
                  *ngIf="!ad.amenities.washer"
                  name="square-outline"
                ></ion-icon>
                {{ lang.amenities.washer }}
              </p>
            </ion-col>
            <ion-col size="12" size-sm="6">
              <p
                class="amenities"
                [ngClass]="{ 'not-present': !ad.amenities.disability }"
              >
                <ion-icon
                  *ngIf="ad.amenities.disability"
                  name="checkbox-outline"
                ></ion-icon>
                <ion-icon
                  *ngIf="!ad.amenities.disability"
                  name="square-outline"
                ></ion-icon>
                {{ lang.amenities.disability }}
              </p>
            </ion-col>
            <ion-col size="12" size-sm="6">
              <p
                class="amenities"
                [ngClass]="{ 'not-present': !ad.amenities.firstTime }"
              >
                <ion-icon
                  *ngIf="ad.amenities.firstTime"
                  name="checkbox-outline"
                ></ion-icon>
                <ion-icon
                  *ngIf="!ad.amenities.firstTime"
                  name="square-outline"
                ></ion-icon>
                {{ lang.amenities.firstTime }}
              </p>
            </ion-col>
            <ion-col size="12" size-sm="6">
              <p
                class="amenities"
                [ngClass]="{ 'not-present': ad.balconyNumber < 1}"
              >
                <ion-icon
                  *ngIf="ad.balconyNumber > 0"
                  name="checkbox-outline"
                ></ion-icon>
                <ion-icon
                  *ngIf="ad.balconyNumber < 1"
                  name="square-outline"
                ></ion-icon>
                {{ lang.amenities.balcony }}
              </p>
            </ion-col>
          </ion-row>

          <!-- <h3>
            {{ lang.priceHistory }}
          </h3>
          <p *ngFor="let item of ad.price.updates; let i = index">
            {{ i + 1 }}. {{ item.num }}€, {{ item.date | date : "dd.MM.yyyy" }}
          </p> -->

          <h3>
            {{ lang.costs }}
          </h3>
          <ion-row>
            <ion-col size="12" size-sm="6">
              <p class="cost">
                <span class="label">{{ lang.inWinter }}:</span>
                <span *ngIf="ad.winterCost">{{ ad.winterCost }} €</span>
              </p>
            </ion-col>
            <ion-col size="12" size-sm="6">
              <p class="cost">
                <span class="label">{{ lang.inSummer }}:</span>
                <span *ngIf="ad.summerCost">{{ ad.summerCost }} €</span>
              </p>
            </ion-col>
            <ion-col size="12" size-sm="6">
              <p class="cost">
                <span class="label">{{ lang.deposit }}:</span>
                <span *ngIf="ad.deposit">{{ ad.deposit }} €</span>
              </p>
            </ion-col>
            <ion-col size="12" size-sm="6">
              <p class="cost">
                <span class="label">{{ lang.fees }}:</span><span *ngIf="ad.fee">{{ ad.fee }} €</span> 
              </p>
            </ion-col>
            <ion-col size="12" size-sm="6">
              <p class="cost">
                <span class="label">{{ lang.energyClass }}:</span>
                <span class="energy-class" [ngClass]="ad.energyClass">
                  {{ ad.energyClass || ad.energyClassManual }}
                </span>
              </p>
            </ion-col>
          </ion-row>

          <h3>
            {{ lang.descriptionAd }}
          </h3>
          <p class="description">
            {{ ad.description ?? "/" }}
          </p>
        </ion-col>
      </ion-row>

      <app-comments
        [appLanguage]="appLanguage"
        [lang]="lang"
        [adId]="ad._id"
        [authorId]="ad.author"
        [commentType]="0"
        [currentUser]="currentUser"
        [loggedIn]="loggedIn"
      ></app-comments>
    </div>
  </article>
</div>

import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ChangeDetectorRef,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { FileItem, FileUploader, FileUploaderOptions } from "ng2-file-upload";
import { ToastController } from "@ionic/angular";

// import ImageBlobReduce from "image-blob-reduce";
/*
 * hack for bug in pica library
 * https://github.com/nodeca/pica/issues/228
 * another option: set import to ./index.js inside image-blob-recuce/package.json
 */
declare function require(name: string);
const ImageBlobReduce = require("image-blob-reduce");

import { BucketService } from "../../services/bucket.service";
import { LanguageService } from "../../services/language.service";
import { DataService } from "../../services/data.service";
import { WindowService } from "../../services/window.service";

@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"],
})
export class FileUploadComponent implements OnInit {
  uploader: FileUploader;
  hasBaseDropZoneOver = false;
  // fullPath: string;
  lang: any;
  selectedStored = [];
  uploadQueueLength: number;
  SESSION_TOKEN: string;
  uploadSum = 0;
  uploadApi = "";

  // @Output() closeModal: EventEmitter<boolean> = new EventEmitter();
  @Output() uploaded: EventEmitter<string> = new EventEmitter();
  @Input() adId: string;
  @Input() userId: string;
  @Input() path: string;
  @Input() selectedItems: string[];

  constructor(
    private bucketService: BucketService,
    private toastCtrl: ToastController,
    private detector: ChangeDetectorRef,
    private languageService: LanguageService,
    private dataService: DataService,
    private sanitizer: DomSanitizer,
    private windowService: WindowService
  ) {}

  ngOnInit() {
    // SET LANGUAGE
    this.languageService.language$.subscribe((value) => {
      this.lang = this.languageService[value];
    });

    // Dispatch action to load the details here.
    // this.fullPath = this.path.replace('/home', '');

    // let uploadApi;
    // if (this.baseUrl === 'shared') {
    //   uploadApi = '/users/user/shared_user_upload_file_post'
    // } else {
    //   uploadApi = '/users/user/owner_upload_file_post';
    // }
    if (this.path === "p") {
      this.uploadApi = "/files/profile";
    } else if (this.path === "photos" || this.path === "posts") {
      this.uploadApi = "/files/upload";
    } else if (this.path === "agency-photos-cover") {
      this.uploadApi = "/files/agency-cover"
    } else if (this.path === "agency-photos-logo") {
      this.uploadApi = "/files/agency-logo"
    }


    this.dataService.sessionToken$.subscribe((sessionToken) => {
      this.SESSION_TOKEN = sessionToken;
    });

    this.uploader = new FileUploader({
      url: this.bucketService.API + this.uploadApi,
      itemAlias: "file",
      queueLimit: 12,
      // maxFileSize: 1048576,
      allowedFileType: ["image"],
    });
    // override the onAfterAddingfile property of the uploader so it doesn't authenticate with //credentials.
    this.uploader.onAfterAddingFile = (fileItem: FileItem) => {
      let resizedBlob: Blob;

      // const imageReducer = new ImageBlobReduce();
      const imageReducer = new ImageBlobReduce();

      imageReducer
        .toBlob(fileItem._file, {
          max: 1000,
          // fileType: "image/jpeg",
        })
        .then((blob: Blob) => {
          resizedBlob = blob;
          
          const imageFile = new File([resizedBlob], fileItem.alias);
          fileItem._file = imageFile;
          fileItem.withCredentials = false;
          fileItem["previewPath"] = this.sanitizer.bypassSecurityTrustUrl(
            this.windowService.nativeWindow.URL.createObjectURL(fileItem._file)
          );
          // console.log(this.uploader);
          if (this.path === "p") {
            this.uploadFiles();
          }
        });
    };

    // overide the onCompleteItem property of the uploader so we are
    // able to deal with the server response.
    this.uploader.onCompleteItem = async (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      // this.closeModal.emit(true);
      // if (JSON.parse(response).result === false) {
      //   let alert = this.alertCtrl.create({
      //     title: 'Napaka',
      //     subTitle: JSON.parse(response).msg,
      //     buttons: [{
      //       text: this.lng['ok'],
      //       role: 'cancel',
      //       handler: () => {
      //         console.log('Cancel clicked');
      //       }
      //     }]
      //   });
      //   alert.present();
      // }

      if (JSON.parse(response).error_code === 2) {
        const toast = await this.toastCtrl.create({
          message: this.lang.uploadLimit,
          duration: 3000,
        });
        toast.present();
        this.finishUploading();
        return;
      }
      this.uploadSum++;
      if (this.uploadSum === this.uploadQueueLength) {
        const responseMessage = this.lang.uploadConfirm;
        const toast = await this.toastCtrl.create({
          message: responseMessage,
          duration: 3000,
        });
        toast.present();
        this.finishUploading();
      }
      // console.log(response);
    };
  }

  finishUploading(): void {
    this.uploaded.emit(this.adId);
    this.uploadSum = 0;
    this.uploadQueueLength = 0;
    this.uploader.queue = [];
  }

  removeImage(index) {
    // console.log(index);
    this.uploader.queue.splice(index, 1);
  }

  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  uploadFiles(postId?: string) {
    const uploaderOptions: FileUploaderOptions = {
      itemAlias: "file",
      queueLimit: 12,
      // maxFileSize: 1048576,
      allowedFileType: ["image"],
      url: this.bucketService.API + this.uploadApi,
    };
    uploaderOptions.headers = [
      { name: "adId", value: postId ? postId : this.adId },
      { name: "userId", value: this.userId },
      { name: "Authorization", value: "Bearer " + this.SESSION_TOKEN },
    ];

    // 0 - cover, 1 - logo, 2 - post, 3 - ad, 4 - profile
    if (this.path === "agency-photos-cover") {
      // uploaderOptions.headers.push({ name: "cover", value: "1" });
      uploaderOptions.headers.push({ name: "type", value: "0" });
    }
    if (this.path === "agency-photos-logo") {
      // uploaderOptions.headers.push({ name: "logo", value: "1" });
      uploaderOptions.headers.push({ name: "type", value: "1" });
    }
    if (this.path === "posts") {
      uploaderOptions.headers.push({ name: "type", value: "2" });
    }
    if (this.path === "photos") {
      uploaderOptions.headers.push({ name: "type", value: "3" });
    }
    if (this.path === "p") {
      uploaderOptions.headers.push({ name: "type", value: "4" });
    }
    this.uploader.setOptions(uploaderOptions);
    // console.log(this.uploader);

    this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
      form.append("adId", this.adId);
      form.append("userId", this.userId);
      form.append("mimetype", fileItem.file.type);
      form.append("contentType", fileItem.file.type);
    };

    this.uploader.onProgressAll = (progress: any) => this.detector.detectChanges();
    this.uploadQueueLength = this.uploader.queue.length;
    this.uploader.uploadAll();
    this.uploader.onCompleteAll = () => this.finishUploading();
  }
}

/// <reference types="facebook-js-sdk" />

import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import {
  // Alert,
  AlertController,
} from "@ionic/angular";

import "url-search-params-polyfill";

import { LoginService } from "../../services/login.service";
import { UserDataService } from "../../services/user-data.service";
import { throttleTime } from "rxjs";
declare var google: any;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {
  inputType = "password";
  // termsAgreed = false;
  // rememberMe = true;
  loginForm: UntypedFormGroup;
  submitAttempt = false;
  sent = false;
  registerSubmitted = false;
  baseUrl: string;
  sub: any;
  randomString = "";
  path = [];
  languageParams: string | null;

  @Input() appLanguage: string;
  @Input() lang: any;
  @Input() formType: any;
  @Output() loggedInResponse: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private loginService: LoginService,
    private router: Router,
    private alertCtrl: AlertController,
    private route: ActivatedRoute,
    private userDataService: UserDataService,
  ) {}

  ngOnInit() {
    this.buildForm();

    this.route.url.subscribe((url) => {
      switch (url[0].path) {
        case "login":
          this.formType = "login";
          break;
        case "reg":
          this.formType = "register";
          break;
        case "lost-password":
          this.formType = "lost-password";
          break;
        case "verify":
          this.formType = "reg-verify";
          this.path = url;
          this.route.queryParams.subscribe((params) => {
            // console.log(params);
            this.handleVerification(params.email, params.random);
          });
          break;
        case "reset-password":
          this.formType = "reset-password";
          this.route.queryParams.subscribe((params) => {
            // console.log(params);
            this.randomString = params.random;
          });
          break;
        default:
          this.formType = "login";
      }
      // console.log(this.path);
    });

    this.setHomepageLink(this.appLanguage);

    this.loginForm.get("name").valueChanges.pipe(throttleTime(300)).subscribe((name) => {
      this.isUserNameTaken(name);
    });

    this.loadFacebookSDK();
  }

  ngOnDestroy() {
    // this.sub.unsubscribe();
  }

  initiateGoogleLogin() {
    google.accounts.id.initialize({
      client_id: "1065337086523-galrmbkmj3shd9k7boptnqtmkr6u3mv0.apps.googleusercontent.com",
      callback: this.oauthLogin
    });
  }

  triggerGoogleLogin() {
    google.accounts.id.initialize({
      client_id: "1065337086523-galrmbkmj3shd9k7boptnqtmkr6u3mv0.apps.googleusercontent.com",
      callback: this.oauthLogin
    });
    
    google.accounts.id.prompt();
  }

  triggerFacebookLogin() {
    FB.login(
      (response: fb.StatusResponse) => {
        if (response.status === 'connected') {
          // console.log('Logged in', response);
          // console.log(response);
          // const { accessToken, userID } = response.authResponse;
          // Use accessToken and userID in your login logic
          this.oauthFacebookLogin(response);
        } else {
          console.error('User not authenticated');
          // console.error('User not authenticated:', response);
          this.errorAlert(this.lang["userVerificationError"]);
        }
      },
      { scope: 'public_profile,email' } // Request specific permissions
    );
  }

  loadFacebookSDK(): void {
    (window as any).fbAsyncInit = () => {
      FB.init({
        appId: '1185224969573279', // Replace with your Facebook App ID
        cookie: true,
        xfbml: true,
        version: 'v21.0', // Use the latest version
      });
    };

    const script = document.createElement('script');
    script.src = 'https://connect.facebook.net/en_US/sdk.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  oauthLogin = (response) => {
    if (!response["credential"]) {
      this.errorAlert(this.lang["userVerificationError"]);
      return;
    }

    this.loginService.oauthGoogle(response["credential"], response["select_by"]).subscribe(response => {
      if (response["token"] !== undefined) {
        this.loginService.saveBrowserSession(response["token"]);
        if (this.loginForm.get("rememberMe").value) {
          this.loginService.saveToken(response["token"]);
        }
        this.loggedInResponse.emit(true);
        this.loginForm.reset();
        this.sent = true;
        this.submitAttempt = false;
      } else {
        this.errorAlert(this.lang["userVerificationError"]);
      }
    });
  }

  oauthFacebookLogin = (response: fb.StatusResponse) => {
    if (response["status"] !== "connected") {
      this.errorAlert(this.lang["userVerificationError"]);
      return;
    }

    this.loginService.oauthFacebook(response.authResponse).subscribe(response => {
      if (response["token"] !== undefined) {
        this.loginService.saveBrowserSession(response["token"]);
        if (this.loginForm.get("rememberMe").value) {
          this.loginService.saveToken(response["token"]);
        }
        this.loggedInResponse.emit(true);
        this.loginForm.reset();
        this.sent = true;
        this.submitAttempt = false;
      }
    });
  }

  setHomepageLink(language: string) {
    if (language === "en") {
      this.languageParams = "en";
    } else {
      this.languageParams = null;
    }
  }

  handleVerification(email, random) {
    this.loginService
      .verifyRegistration(email, random)
      .subscribe(async (res) => {
        const msg = res["status"];

        const alert = await this.alertCtrl.create({
          header: this.lang["registerConfirmation"],
          subHeader: this.lang[msg],
          buttons: [
            {
              text: this.lang["ok"],
              role: "cancel",
              handler: () => {
                // console.log('Cancel clicked');
                this.router.navigate(["/"], {
                  queryParams: { l: this.appLanguage === "en" ? "en" : null },
                });
              },
            },
          ],
        });
        await alert.present();
        if (res["token"]) {
          this.loginService.saveBrowserSession(res["token"]);
        }
      });
  }

  buildForm(): void {
    this.loginForm = this.formBuilder.group({
      name: [
        "",
        Validators.compose([
          Validators.minLength(3),
          Validators.maxLength(42),
          Validators.pattern(new RegExp(/^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/)), // [pattern]="'/^[a-zA-Z0-9]+$/'"
          Validators.required,
        ]),
      ],
      email: [
        "",
        Validators.compose([Validators.required, this.validateEmail]),
      ],
      password: [
        "",
        Validators.compose([Validators.required, Validators.minLength(6)]),
      ],
      passwordConfirm: [
        "",
        Validators.compose([Validators.required, Validators.minLength(6)]),
      ],
      rememberMe: [true],
      termsAgreed: [true, Validators.requiredTrue],
    });
  }

  isUserNameTaken(name: string) {
    if (!name || name?.length < 3) {
      return;
    }

    this.userDataService.checkUserNameTaken(name).subscribe((response) => {
      if (response["result"] === true) {
        let existingErros = this.loginForm.controls["name"].errors;
        this.loginForm.controls["name"].setErrors({ nameTaken: true, ...existingErros });
      } else {
        if (this.loginForm.controls["name"].hasError('nameTaken')) {
          delete this.loginForm.controls["name"].errors['nameTaken'];
        }
      }
    });
  }

  toggleRememberMe() {
    // this.rememberMe = !this.rememberMe;
    this.loginForm.get("rememberMe").setValue(!this.loginForm.get("rememberMe").value);
  }

  resetRequest() {
    this.submitAttempt = true;
    // send this.loginForm.value;
    this.loginService
      .forgotPassword(this.loginForm.get("email").value)
      .subscribe(async (message) => {
        if (message) {
          const resetRequestAlert = await this.alertCtrl.create({
            header: "",
            subHeader: message["msg"],
            buttons: [
              {
                text: this.lang["ok"],
                role: "cancel",
                handler: () => {
                  // console.log('Cancel clicked');
                  this.sent = true;
                  this.loginForm.reset();
                  this.submitAttempt = false;
                  this.router.navigate(["login"], {
                    queryParams: { l: this.appLanguage === "en" ? "en" : null },
                  });
                },
              },
            ],
          });

          await resetRequestAlert.present();
          return;
        }
      });
  }

  resetPassword() {
    this.submitAttempt = true;
    // send this.loginForm.value;
    this.loginService
      .resetPasswordRequest(
        this.loginForm.get("email").value,
        this.loginForm.get("password").value,
        this.loginForm.get("passwordConfirm").value
      )
      .subscribe(async (message) => {
        if (message) {
          const resetPasswordAlert = await this.alertCtrl.create({
            header: "",
            subHeader: message["msg"],
            buttons: [
              {
                text: this.lang["ok"],
                role: "cancel",
                handler: () => {
                  // console.log('Cancel clicked');
                  this.sent = true;
                  this.loginForm.reset();
                  this.submitAttempt = false;
                  this.router.navigate(["login"], {
                    queryParams: { l: this.appLanguage === "en" ? "en" : null },
                  });
                },
              },
            ],
          });

          await resetPasswordAlert.present();
          return;
        }
      });
  }

  // setLang(language) {
  //   this.languageService.langChange(language);
  //   this.lang = this.languageService[language];
  // }

  // // navbar add button
  // presentLanguagePopover(ev) {
  //   const popover = this.popoverCtrl.create(PopoverLanguageComponent, {
  //     setLang: (language) => {
  //       this.setLang(language);
  //     },
  //     lang: this.lang
  //   });
  //   popover.present({ ev: ev });
  // }

  validateEmail(c: UntypedFormControl) {
    const EMAIL_REGEXP =
      /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

    return EMAIL_REGEXP.test(c.value)
      ? null
      : {
          validateEmail: {
            valid: false,
          },
        };
  }

  // verify(){
  //   this.loginService.verify()
  //     .subscribe(message => {
  //       if (message['token'] !== undefined) {
  //         this.dataService.setSessionToken(message['token']);
  //         this.loginService.saveToken(message['token']);
  //         this.router.navigate(['home']); // query param
  //         // if (this.rememberMe) {
  //         // }
  //       } else {
  //         alert(message['msg']);
  //       }
  //     });
  // }

  async errorAlert(headerMessage: string, subHeaderMessage = "") {
    const loginAlert = await this.alertCtrl.create({
      header: headerMessage,
      subHeader: subHeaderMessage,
      buttons: [
        {
          text: this.lang["ok"],
          role: "cancel",
          handler: () => {
            // console.log('Cancel clicked');
          },
        },
      ],
    });
    
    await loginAlert.present();
  }

  // NORMAL LOGIN
  login() {
    this.submitAttempt = true;
    // send this.loginForm.value;
    if (this.loginForm.get("email").valid) {
      const email = this.loginForm.get("email").value;
      const password = this.loginForm.get("password").value;

      this.loginService.login(email, password).subscribe(async (response) => {
        // console.log(message);
        if (response["result"] === false) {
          let alertMessage = "";
          if (response["status"]) {
            alertMessage = this.lang[response["status"]];
          } else {
            alertMessage = response["msg"];
          }

          this.errorAlert(this.lang["error"], alertMessage);
          this.loginForm.reset();
          this.sent = true;
          this.submitAttempt = false;
          return;
        }

        if (response["token"] !== undefined) {
          this.loginService.saveBrowserSession(response["token"]);
          if (this.loginForm.get("rememberMe").value) {
            this.loginService.saveToken(response["token"]);
          }
          // todo, not if already at list
          // this.router.navigate(['list']);
        } else {
          // console.log(response);
          // console.log(response["msg"]);
        }

        this.loggedInResponse.emit(true);
        this.loginForm.reset();
        this.sent = true;
        this.submitAttempt = false;
      });
    }
    setTimeout(() => {
      this.submitAttempt = false;
    }, 2000);
  }

  register() {
    // firstName: string, lastName: string, email: string, password: string, passwordConfirm: string
    this.submitAttempt = true;
    // send this.loginForm.value;
    if (this.loginForm.valid) {
      const name = this.loginForm.get("name").value;
      const email = this.loginForm.get("email").value;
      const password = this.loginForm.get("password").value;
      const passwordConfirm = this.loginForm.get("passwordConfirm").value;
      this.loginService
        .register(name, email, password, passwordConfirm)
        .subscribe(async (message) => {
          // console.log(message);
          if (message["result"]) {
            this.registerSubmitted = true;
            this.loggedInResponse.emit(true);
          } else {
            this.registerSubmitted = false;
          }
          const responseStatus = message["status"];
          const registerAlert = await this.alertCtrl.create({
            header: this.lang["registration"],
            subHeader: `${this.lang[responseStatus]}
            ${this.lang["registrationWarning"]}
            `,
            buttons: [
              {
                text: this.lang["ok"],
                role: "cancel",
                handler: () => {
                  // console.log('Cancel clicked');
                },
              },
            ],
          });
          await registerAlert.present();
        });
      this.sent = true;
      this.loginForm.reset();
    }
  }

  toggleTermsAgreed(): void {
    console.log(this.loginForm.get("termsAgreed").value);
    this.loginForm.get("termsAgreed").setValue(!this.loginForm.get("termsAgreed").value);
  }

  passwordMatch(): boolean {
    if (
      this.loginForm.get("password").value &&
      this.loginForm.get("password").value ===
        this.loginForm.get("passwordConfirm").value
    ) {
      this.loginForm.controls["passwordConfirm"].setErrors(null);
      return true;
    } else {
      this.loginForm.controls["passwordConfirm"].setErrors({ incorrect: true });
      return false;
    }
  }

  toggleInputType(attribute: any): any {
    attribute.type = attribute.type === "password" ? "text" : "password";
  }
}

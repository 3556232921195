<ion-header class="shrinkable">
  <app-main-header
    [mobileView]="mobileView"
    [title]="title"
    [currentUser]="currentUser"
  ></app-main-header>
</ion-header>

<ion-content class="profile-component" *ngIf="user.userId && lang">
  <div class="content-body">
    <ion-grid class="page-content">
      <ion-card>
        <ion-list class="info-area" lines="inset">
          <ion-item lines="none">
            <ion-avatar class="user-avatar">
              <app-file-upload
                *ngIf="currentUser && currentUser._id === user.userId"
                [adId]="user.userId"
                [userId]="user.userId"
                [path]="path"></app-file-upload>
                <!-- (uploaded)="setProfilePhoto()" -->            
              <app-profile-photo
                #profilePhoto
                [userId]="user.userId"
                [size]="80"
                (click)="presentPhotoOptionsPopover($event)"
              ></app-profile-photo>
            </ion-avatar>
            <ion-label class="profile-label">
              <h2 *ngIf="user.name">{{ user.name }}</h2>
              <p *ngIf="!editMode && user.userType != null">
                <span *ngIf="user.userType === 0">{{ lang.typeSellerPrivate }}</span>
                <span *ngIf="user.userType === 1">{{ lang.typeSellerAgent }}</span>
              </p>
  
              <ion-radio-group
                *ngIf="editMode"
                name="userType"
                [(ngModel)]="user.userType"
                ngDefaultControl
              >
                <ion-row>
                  <ion-col class="short-radio">
                    <ion-item lines="none">
                      <ion-radio
                        labelPlacement="end"
                        [value]="0"
                        justify="start"
                        >{{ lang.typeSellerPrivate }}</ion-radio
                      >
                    </ion-item>
                  </ion-col>
  
                  <ion-col class="short-radio">
                    <ion-item lines="none">
                      <ion-radio
                        labelPlacement="end"
                        [value]="1"
                        justify="start"
                        >{{ lang.typeSellerAgent }}</ion-radio
                      >
                    </ion-item>
                  </ion-col>
                </ion-row>
              </ion-radio-group>
            </ion-label>
          </ion-item>
  
          <ion-item *ngIf="!editMode" lines="none">
            {{ user.location }}
          </ion-item>
  
          <ion-item *ngIf="editMode" lines="none">
            <ion-input
              labelPlacement="stacked"
              type="text"
              name="location"
              [(ngModel)]="user.location"
              placeholder="Location"
              >{{ lang.location }}</ion-input
            >
          </ion-item>
  
          <ion-item *ngIf="!editMode && user.userBio" lines="none" class="bio">
            {{user.userBio}}
          </ion-item>
  
          <ion-item *ngIf="editMode" lines="none">
            <ion-textarea
              labelPlacement="stacked"
              rows="5"
              type="text"
              name="bio"
              [(ngModel)]="user.userBio"
              >{{ lang.personalInfo }}</ion-textarea
            >
          </ion-item>
  
          <ng-container *ngIf="currentUser && currentUser._id === user.userId">
            <ion-item
              *ngIf="!editMode && user.phoneNumber"
              lines="none"
              class="phone"
            >
              {{ user.phoneNumber }}
            </ion-item>
  
            <ion-item *ngIf="editMode" lines="none">
              <ion-input
                labelPlacement="stacked"
                type="text"
                name="phone"
                [(ngModel)]="user.phoneNumber"
                >{{ lang.phoneNumber }}</ion-input
              >
            </ion-item>
          </ng-container>
  
          <ion-item
            *ngIf="currentUser && currentUser._id === user.userId"
            lines="none"
          >
            <div *ngIf="editMode; else noEditMode">
              <ion-button (click)="disableEdit()">
                <ion-icon slot="start" name="close"></ion-icon>
                {{ lang.cancel }}
              </ion-button>
              <ion-button (click)="saveEdit()">
                <ion-spinner *ngIf="loading" class="button-spinner"></ion-spinner>
                <ion-icon
                  slot="start"
                  *ngIf="!loading"
                  name="checkmark"
                ></ion-icon>
                {{ lang.save }}
              </ion-button>
            </div>
            <ng-template #noEditMode>
              <ion-button (click)="enableEdit()">
                <ion-icon slot="start" name="create"></ion-icon>
                {{ lang.edit }}
              </ion-button>
            </ng-template>
          </ion-item>
        </ion-list>
      </ion-card>
  
      <ion-card>
        <ion-list
          class="contact-area"
          *ngIf="!currentUser || (currentUser && currentUser._id !== user.userId)"
        >
          <ion-item-divider>
            {{ lang.contact }} {{ user.name }}
          </ion-item-divider>
  
          <ion-item *ngIf="!currentUser" lines="none">
            {{ lang.loginToContact }}
          </ion-item>
  
          <div *ngIf="currentUser && currentUser._id !== user.userId">
            <ion-item lines="none">
              <ion-textarea
                labelPlacement="stacked"
                rows="5"
                type="text"
                class="message-textarea"
                name="contactMessage"
                [(ngModel)]="contactMessage"
                >{{ lang.yourMessage }}
              </ion-textarea>
            </ion-item>
  
            <ion-item lines="none">
              <ion-button
                [disabled]="contactMessage.length < 5"
                (click)="sendMessage(currentUser._id, user.userId)"
              >
                <ion-icon slot="start" name="mail"></ion-icon>
                {{ lang.sendEmail }}
              </ion-button>
  
              <ion-button (click)="getPhone()">
                <ion-icon slot="start" name="call"></ion-icon>
                {{ lang.getPhone }}
              </ion-button>
              <span *ngIf="phoneNumber"
                ><a [href]="'tel:' + phoneNumber">{{ phoneNumber }}</a></span
              >
            </ion-item>
          </div>
        </ion-list>
      </ion-card>
  
      <ion-card>
        <ion-list>
          <ion-item-divider>
            {{ lang.adsBy }} {{ user.name }} <span *ngIf="user.userId === currentUser?._id">&nbsp;({{ currUserAds.length }})</span>
          </ion-item-divider>
          <div *ngIf="currUserAds.length === 0" class="no-items-message">
            <p>{{ lang.noItemsToShow }}</p>
            <p *ngIf="currentUser?._id === user.userId">
              <ion-button class="create-ad-button" (click)="createNewAd()">
                {{ lang.createNewAd }}
              </ion-button>
            </p>
          </div>
          <ng-container *ngFor="let ad of currUserAds">
            <ion-item *ngIf="!mobileView && (!ad.adExpired || user.userId === currentUser?._id)"
                      class="user-ad-overview-item"
                      [ngClass]="{ 'inactive-ad': ad.adExpired }">
              <ion-thumbnail>
                <img
                  *ngIf="ad.photos && ad.photos.length > 0"
                  [src]="API + '/files/image/' + ad.photos[0]._id"
                />
              </ion-thumbnail>
              <ion-label>
                <ion-text color="primary">
                  <h2>
                    <span *ngIf="ad.adExpired">[{{ lang.adInactive }}] </span>
                    <a [routerLink]="['/ad', ad._id]" [queryParams]="{ l: languageParams }">
                      {{ ad.located.street }}, {{ ad.located.postal }}
                      {{ ad.located.district }}
                    </a>
                  </h2>
                </ion-text>
                <ion-row class="overview-info">
                  <ion-col size="12" size-sm="3">
                    <span>
                      <ion-icon
                        slot="start"
                        name="create-outline"
                        class="info-icon"></ion-icon>
                      {{ lang.published }}: {{ ad.created | date: "shortDate" }}
                    </span>
                  </ion-col>
                  <ion-col *ngIf="user.userId === currentUser?._id" size="12" size-sm="3">
                    <span>
                      <ion-icon
                        slot="start"
                        name="timer-outline"
                        class="info-icon"
                      ></ion-icon>
                      <span *ngIf="ad.daysToExpiration === 1"
                        >{{ lang.expiresIn }}: {{ ad.daysToExpiration }}
                        {{ lang.day }}</span
                      >
                      <span *ngIf="ad.daysToExpiration > 1"
                        >{{ lang.expiresIn }}: {{ ad.daysToExpiration }}
                        {{ lang.days }}</span
                      >
                      <span
                        ion-text
                        color="danger"
                        *ngIf="ad.daysToExpiration === 0"
                        >{{ lang.expiresToday }}</span
                      >
                      <span
                        ion-text
                        color="danger"
                        *ngIf="ad.daysToExpiration === -1"
                        >{{ lang.expiredSince }}: {{ ad.daysToExpiration * -1 }}
                        {{ lang.day }}</span>
                      <ion-text color="danger">
                        <span *ngIf="ad.daysToExpiration < -1"
                          >{{ lang.expiredSince }}: {{ ad.daysToExpiration * -1 }}
                          {{ lang.days }}</span>
                      </ion-text>
                      <span *ngIf="ad.daysToExpiration === null">{{
                        lang.noExpirationData
                      }}</span>
                    </span>
                  </ion-col>
                  <ion-col size="12" size-sm="3">
                    <span>
                      <ion-icon slot="start" name="eye-outline" class="info-icon"></ion-icon>
                      {{ lang.views }}: {{ ad.adViewsCount }}
                    </span>
                  </ion-col>
                  <ion-col size="12" size-sm="3">
                    <span
                      ><ion-icon
                        slot="start"
                        name="heart-outline"
                        class="info-icon"
                      ></ion-icon
                      >{{ lang.saved }}: {{ ad.favoritesCount }}</span
                    >
                  </ion-col>
                </ion-row>
                <ion-row *ngIf="currentUser && currentUser._id === user.userId" class="edit-buttons">
                  <a
                    *ngIf="ad && ad._id"
                    class="preview-link"
                    target="_blank"
                    (click)="$event.stopPropagation()"
                    [href]="'/ad/' + ad._id">
                    <ion-button size="small" fill="clear" color="secondary" [disabled]="ad.adExpired">
                      <ion-icon slot="start" name="open"></ion-icon>
                      {{ lang.preview }}
                    </ion-button>
                  </a>
                  <ion-button
                    size="small"
                    fill="clear"
                    color="secondary"
                    [routerLink]="['/ed', ad._id]">
                    <ion-icon slot="start" name="hammer"></ion-icon>
                    {{ lang.edit }}
                  </ion-button>
                  <ion-button
                    size="small"
                    fill="clear"
                    color="secondary"
                    (click)="toggleActivate(ad)"
                    [disabled]="ad.activationDisabled">
                    <ion-icon *ngIf="!ad.adExpired" slot="start" name="pause">
                    </ion-icon>
                    <span *ngIf="!ad.adExpired">
                      {{ lang.deactivate }}
                    </span>
                    <ion-icon *ngIf="ad.adExpired" name="play"> </ion-icon>
                    <span *ngIf="ad.adExpired">
                      {{ lang.activate }}
                    </span>
                  </ion-button>
                  <ion-button
                    size="small"
                    fill="clear"
                    color="danger"
                    (click)="removeAlertOpen(ad)">
                    <ion-icon slot="start" name="trash"></ion-icon>
                    {{ lang.delete }}
                  </ion-button>
                </ion-row>
              </ion-label>
            </ion-item>

            <ion-card *ngIf="mobileView && (!ad.adExpired || user.userId === currentUser?._id)"
                      class="prop-card"
                      [ngClass]="{ 'inactive-ad': ad.adExpired }">
              <img
                *ngIf="ad.photos && ad.photos.length > 0"
                [src]="API + '/files/image/' + ad.photos[0]._id"
              />
              <ion-card-header>
                <ion-card-title>
                  <span *ngIf="ad.adExpired">[{{ lang.adInactive }}] </span>
                  <a [routerLink]="['/ad', ad._id]" [queryParams]="{ l: languageParams }">
                    {{ ad.located.street }}, {{ ad.located.postal }}
                    {{ ad.located.district }}
                  </a>
                </ion-card-title>
                <ion-card-subtitle>
                  <ion-row class="overview-info">
                    <ion-col size="12" size-sm="3">
                      <span>
                        <ion-icon
                          slot="start"
                          name="create-outline"
                          class="info-icon"></ion-icon>
                        {{ lang.published }}: {{ ad.created | date: "shortDate" }}
                      </span>
                    </ion-col>
                    <ion-col *ngIf="user.userId === currentUser?._id" size="12" size-sm="3">
                      <span>
                        <ion-icon
                          slot="start"
                          name="timer-outline"
                          class="info-icon"
                        ></ion-icon>
                        <span *ngIf="ad.daysToExpiration === 1"
                          >{{ lang.expiresIn }}: {{ ad.daysToExpiration }}
                          {{ lang.day }}</span
                        >
                        <span *ngIf="ad.daysToExpiration > 1"
                          >{{ lang.expiresIn }}: {{ ad.daysToExpiration }}
                          {{ lang.days }}</span
                        >
                        <span
                          ion-text
                          color="danger"
                          *ngIf="ad.daysToExpiration === 0"
                          >{{ lang.expiresToday }}</span
                        >
                        <span
                          ion-text
                          color="danger"
                          *ngIf="ad.daysToExpiration === -1"
                          >{{ lang.expiredSince }}: {{ ad.daysToExpiration * -1 }}
                          {{ lang.day }}</span
                        >
                        <ion-text color="danger">
                          <span *ngIf="ad.daysToExpiration < -1"
                            >{{ lang.expiredSince }}: {{ ad.daysToExpiration * -1 }}
                            {{ lang.days }}</span>
                        </ion-text>
                        <span *ngIf="ad.daysToExpiration === null">{{
                          lang.noExpirationData
                        }}</span>
                      </span>
                    </ion-col>
                    <ion-col size="12" size-sm="3">
                      <span>
                        <ion-icon slot="start" name="eye-outline" class="info-icon"></ion-icon>
                        {{ lang.views }}: {{ ad.adViewsCount }}
                      </span>
                    </ion-col>
                    <ion-col size="12" size-sm="3">
                      <span
                        ><ion-icon
                          slot="start"
                          name="heart-outline"
                          class="info-icon"
                        ></ion-icon
                        >{{ lang.saved }}: {{ ad.favoritesCount }}</span
                      >
                    </ion-col>
                  </ion-row>
                </ion-card-subtitle>
              </ion-card-header>
              <ion-card-content>
                <ion-row *ngIf="currentUser && currentUser._id === user.userId" class="edit-buttons">
                  <a
                    *ngIf="ad && ad._id"
                    class="preview-link"
                    target="_blank"
                    (click)="$event.stopPropagation()"
                    [href]="'/ad/' + ad._id">
                    <ion-button size="small" fill="clear" color="secondary" [disabled]="ad.adExpired">
                      <ion-icon slot="start" name="open"></ion-icon>
                      {{ lang.preview }}
                    </ion-button>
                  </a>
                  <ion-button
                    size="small"
                    fill="clear"
                    color="secondary"
                    [routerLink]="['/ed', ad._id]">
                    <ion-icon slot="start" name="hammer"></ion-icon>
                    {{ lang.edit }}
                  </ion-button>
                  <ion-button
                    size="small"
                    fill="clear"
                    color="secondary"
                    (click)="toggleActivate(ad)"
                    [disabled]="ad.activationDisabled">
                    <ion-icon *ngIf="!ad.adExpired" slot="start" name="pause">
                    </ion-icon>
                    <span *ngIf="!ad.adExpired">
                      {{ lang.deactivate }}
                    </span>
                    <ion-icon *ngIf="ad.adExpired" name="play"> </ion-icon>
                    <span *ngIf="ad.adExpired">
                      {{ lang.activate }}
                    </span>
                  </ion-button>
                  <ion-button
                    size="small"
                    fill="clear"
                    color="danger"
                    (click)="removeAlertOpen(ad)">
                    <ion-icon slot="start" name="trash"></ion-icon>
                    {{ lang.delete }}
                  </ion-button>
                </ion-row>
              </ion-card-content>
            </ion-card>
          </ng-container>
        </ion-list>
      </ion-card>
    </ion-grid>
  </div>
  <app-footer></app-footer>
</ion-content>

<ion-content class="profile-component" *ngIf="!user.userId && lang">
  <div class="content-body">
    <div class="user-not-found">
      <p>
        {{ lang.noUserFound }} <a routerLink="/h">{{ lang.goToHomepage }}</a>
      </p>
    </div>
  </div>
  <app-footer></app-footer>
</ion-content>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DataService } from '../services/data.service';

@Injectable()
export class CommentService {

  // Link to our api
  API: string;

  constructor(
    private http: HttpClient,
    private dataService: DataService
  ) {
    this.API = this.dataService.API;
  }

  getCommentsAd(adId: string, commentType: number) {
    // console.log(ad);
    return this.http.get(`${this.API}/comments/ad/${adId}/${commentType}`);
  }

  getCommentsNumberAd(adId: string, commentType: number) {
    // console.log(ad);
    return this.http.get(`${this.API}/comments/number/${adId}/${commentType}`);
  }

  getCommentsUser(userId: string) {
    // console.log(userId);
    return this.http.get(`${this.API}/comments/user/${userId}`);
  }

  getReplies(ad: string, comment: string, commentType: number) {
    // console.log(ad);
    return this.http.get(`${this.API}/comments/replies?ad=${ad}&comment=${comment}&commentType=${commentType}`);
  }

  // can be ad or post
  addComment(ad: string, user: string, comment: string, replyToComment: string, replyToUser: string, itemOwnerId: string, commentType: number) {
    // console.log(ad, user, comment, replyToComment, replyToUser);
    return this.http.post(`${this.API}/comments/addcomment`, {
      ad,
      user,
      message: comment,
      replyToComment,
      replyToUser,
      itemOwnerId,
      commentType
    });
  }

  editComment(commentId: string, newMessage: string, userId: string, commentType: number) {
    // console.log(ad, user, comment, replyToComment, replyToUser);
    return this.http.post(`${this.API}/comments/edit`, {
      _id: commentId,
      message: newMessage,
      userId,
      commentType
    });
  }

  // Delete comment
  deleteComment(commentId: string, userid: string) {
    // console.log(commentId);
    return this.http.delete(`${this.API}/comments/delete?id=${commentId}`, { headers: { userid }});
  }
}

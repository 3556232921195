import { Component, Input } from "@angular/core";

import { CurrentUser } from "../../model/current-user";

// Comment Component
@Component({
  selector: "app-advertising",
  templateUrl: "./advertising.component.html",
  styleUrls: ["./advertising.component.scss"],
})
export class AdvertisingComponent {
  @Input() currentUser: CurrentUser;
  @Input() appLanguage: string;
  @Input() lang: any;
  @Input() loggedIn = false;

  constructor() {}
}

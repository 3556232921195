<ion-header>
  <ion-toolbar color="primary">
    <ion-title>
      {{ lang.detailedSearch }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="search-modal-content">
  <app-search-form [formType]="'long'"
                   [basePath]="basePath"
                   (closeModal)="dismiss()">
  </app-search-form>
</ion-content>
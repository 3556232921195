import {
  Injectable,
} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot
} from '@angular/router';

import { BucketService } from '../services/bucket.service';

@Injectable()
export class AdImageResolver implements Resolve<any> {
  constructor(
    private bucketService: BucketService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): any {
    return this.bucketService.listAdImages(route.params['id'], 3);
  }
}

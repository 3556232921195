import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";

import { LocationService } from "../services/location.service";

@Injectable()
export class LocationLinkResolver implements Resolve<any> {

  constructor(
    private locationService: LocationService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return this.locationService.getCounties();
  }
}

<ion-grid class="loginpage max-width">
  <ion-row>
    <ion-col size="12">
      <form class="form" *ngIf="loginForm" [formGroup]="loginForm">
        <ion-list>
          <ion-text *ngIf="formType === 'login'" color="secondary">
            <h2>{{ lang.login }}</h2>
          </ion-text>
          <ion-text *ngIf="formType === 'lostPassword'" color="secondary">
            <h2>{{ lang.resetPassword }}</h2>
          </ion-text>
          <ion-text *ngIf="formType === 'register'" color="secondary">
            <h2>{{ lang.registration }}</h2>
          </ion-text>

          <div class="register-link">
            <a>
              <span *ngIf="formType === 'login'" (click)="formType = 'register'">{{ lang.registration }}</span>
              <span *ngIf="formType !== 'login'" (click)="formType = 'login'">{{ lang.login }}</span>
            </a>
          </div>

          <ion-item class="ion-no-padding" *ngIf="formType === 'register'" lines="none">
            <ion-input autocomplete="on"
                       name="name"
                       formControlName="name"
                       type="text"
                       required="true">
              {{ lang.firstName }} {{ lang.or }} {{ lang.nickName }}
            </ion-input>
          </ion-item>

          <ion-text class="error-message"
                    color="danger"
                    *ngIf="formType === 'register' &&
                           loginForm.controls.name.value !== '' &&
                           !loginForm.controls.name.valid &&
                           loginForm.controls.name.dirty">
            <span *ngIf="loginForm.controls.name.errors?.['minlength']">{{ lang.userNameTooShort }}</span>
            <span *ngIf="loginForm.controls.name.errors?.['maxlength']">{{ lang.userNameTooLong }}</span>
            <span *ngIf="loginForm.controls.name.errors?.['pattern']">{{ lang.userNameNotValid }}</span>
            <span *ngIf="loginForm.controls.name.errors?.['required']">{{ lang.userNameRequired }}</span>
            <span *ngIf="loginForm.controls.name.errors?.['nameTaken']">{{ lang.userNameTaken }}</span>
          </ion-text>

          <ion-item class="ion-no-padding" lines="none">
            <ion-input autocomplete="on"
              formControlName="email" type="email" name="email" required="true">
              <!-- <span slot="label"></span> -->
              {{ lang.email }}
            </ion-input>
          </ion-item>

          <ion-text class="error-message" color="danger"
                    *ngIf="(loginForm.controls.email.value !== '' &&
                           !loginForm.controls.email.valid &&
                           loginForm.controls.email.dirty)">
            <span>{{ lang.emailNotValid }}</span>
          </ion-text>

          <ion-item class="ion-no-padding" lines="none">
            <ion-input
              name="password"
              formControlName="password"
              #password
              required="true"
              type="password">
              {{ lang.password }}
            </ion-input>
          </ion-item>

          <ion-item
            class="error-message ion-no-padding" lines="none"
            *ngIf="
              (loginForm.controls.password.value !== '' &&
                !loginForm.controls.password.valid &&
                loginForm.controls.password.dirty) ||
              (submitAttempt && !sent && formType !== 'test')">
            <ion-text color="danger">
              <span>{{ lang.passwordTooShort }}</span>
            </ion-text>
          </ion-item>

          <ion-item *ngIf="formType === 'register' || formType === 'lostPassword'" class="ion-no-padding" lines="none">
            <ion-input
              formControlName="passwordConfirm"
              #passwordConf
              required="true"
              type="password">
              {{ lang.confirmPassword }}
            </ion-input>
          </ion-item>

          <ion-item lines="none"
            class="error-message ion-no-padding"
            *ngIf="
              (formType === 'register' || formType === 'lostPassword')&&
              loginForm.controls.passwordConfirm.value !== '' &&
              loginForm.controls.password.valid &&
              loginForm.controls.passwordConfirm.dirty &&
              !passwordMatch()">
            <ion-text color="danger">
              <span>{{ lang.passwordsNoMatch }}</span>
            </ion-text>
          </ion-item>


          <ion-item class="checkbox-item" lines="none"
                    *ngIf="formType !== 'register' && formType !== 'lostPassword'">
            <ion-checkbox
              class="remember-checkbox"
              id="rememberMe"
              color="primary"
              formControlName="rememberMe">
              {{ lang.rememberMe }}
            </ion-checkbox>
          </ion-item>
          
              <!--
              [value]="loginForm.get('rememberMe').value"
               (click)="toggleRememberMe()" -->
  
          <ion-item class="checkbox-item" lines="none"
             *ngIf="formType === 'register' && formType !== 'test'">
            <ion-checkbox class="terms-checkbox"
                          labelPlacement="start"
                          color="primary"
                          formControlName="termsAgreed">

                          <!-- 
                          [value]="loginForm.get('termsAgreed').value"
                          (click)="toggleTermsAgreed()" -->
              <span>
                {{ lang.byRegistering }} <a [routerLink]="['/terms']" [queryParams]="{ l: languageParams }"
                                            (click)="loggedInResponse.emit(true);$event.stopPropagation()">
                {{ lang.termsAndConditions }}</a>.
              </span>            
            </ion-checkbox>
          </ion-item>
        </ion-list>

        <div *ngIf="formType === 'login'">
          <ion-button expand="block"
                      color="secondary"
                      (click)="login()"
                      [disabled]="!loginForm.get('email').valid || !loginForm.get('password').valid">
            {{ lang.signIn }}
          </ion-button>
          <hr>
          <ion-button expand="block"
                      color="danger"
                      (click)="triggerGoogleLogin()">
            <ion-icon slot="start" name="logo-google"></ion-icon>
            {{ lang.signInGoogle }}
          </ion-button>
          <hr>
          <ion-button expand="block"
                      class="fb-button"
                      (click)="triggerFacebookLogin()">
            <ion-icon slot="start" name="logo-facebook"></ion-icon>
            {{ lang.signInFacebook }}
          </ion-button>
        </div>

        <div *ngIf="formType === 'register'">
          <ion-button expand="block"
                      color="secondary"
                      (click)="register()"
                      [disabled]="!loginForm.valid || !passwordMatch()">
            {{ lang.register }}
          </ion-button>
        </div>

        <div *ngIf="formType === 'lostPassword'">
          <ion-button expand="block"
                      color="secondary"
                      [disabled]="!loginForm.get('email').valid"
                      (click)="resetPassword()">
            {{ lang.resetPassword }}
          </ion-button>
        </div>

        <p class="ion-text-center forgot-password"
          *ngIf="formType === 'login'"
          (click)="formType = 'lostPassword'">
          <a>{{ lang.forgotPassword }}</a>
        </p>
      </form>
    </ion-col>
  </ion-row>
</ion-grid>

import { Component, HostListener, OnInit } from '@angular/core';
import { PopoverController, ToastController } from '@ionic/angular';

import { DataService } from '../../services/data.service';
import { LoginService } from '../../services/login.service';
import { LanguageService } from '../../services/language.service';
import { MessageService } from '../../services/message.service';
import { UserDataService } from '../../services/user-data.service';
import { WindowService } from '../../services/window.service';
import { FlagService } from "../../services/flag.service";
import { SocketService } from "../../services/socket.service";

import { CurrentUser } from '../../model/current-user';

import { FlagReasonsPopoverComponent } from "../popovers/flag-reasons/flag-reasons-popover.component";

@Component({
  selector: 'app-profile-messages',
  templateUrl: 'profile-messages.component.html',
  styleUrls: ['./profile-messages.component.scss']
})
export class ProfileMessagesComponent implements OnInit {

  title = 'Messages';
  lang: any;
  isLoggedIn = false;
  currUserMessages: any = [];
  currentUser: CurrentUser;
  selectedConversation: any;
  contactMessage = '';

  mobileView = true; // todo

  constructor(
    private loginService: LoginService,
    private dataService: DataService,
    private messageService: MessageService,
    private languageService: LanguageService,
    private userDataService: UserDataService,
    private windowService: WindowService,
    private flagService: FlagService,
    private toastCtrl: ToastController,
    private popoverCtrl: PopoverController,
    private socketService: SocketService,
  ) { }

  ngOnInit() {
    // this.getUrl();
    // this.API = this.dataService.API;
    // SET LANGUAGE
    this.languageService.language$.subscribe(value => {
      // this.appLanguage = value;
      this.lang = this.languageService[value];
      // this.updateList();
    });

    this.dataService.loggedIn$
      .subscribe(result => {
        this.isLoggedIn = result;
        this.currentUser = this.loginService.currentUser();
        if (this.currentUser && this.currentUser._id) {
          this.getCurrUserMessages(this.currentUser._id);
        }
      });

    this.dataService.isBrowser$.subscribe(isBrowser => {
      // console.log(isBrowser);
      if (isBrowser) {
        this.toggleMobileView();
      }
    });

    this.socketService.adChanges$.subscribe((msg) => {
      if (msg["from"] === this.currentUser._id || msg["to"] === this.currentUser._id) {
        if (msg["type"] === "messageAdded" || msg["type"] === "messageRemoved" ) {
          if (this.currentUser?._id) {
            this.getCurrUserMessages(this.currentUser._id);;
          }
        }
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.toggleMobileView();
  }

  toggleMobileView() {
    if (this.windowService.nativeWindow.innerWidth <= '767') {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
  }

  getCurrUserMessages(userId: string): void {
    this.messageService.listUserMessages(userId)
      .subscribe(result => {
        // console.log(result);
        this.currUserMessages = result['messages'];

        this.currUserMessages.forEach(element => {
          this.setUserName(element);
        });

        if (this.selectedConversation) {
          this.selectedConversation.messages = this.currUserMessages.find(messagePack => messagePack._id === this.selectedConversation._id).messages;
        }
      });
  }

  setUserName(element) {
    this.userDataService.getName(element._id)
      .subscribe((res: object) => {
        element['userName'] = res['name'];
      });
  }

  selectConversation(conversation) {
    this.selectedConversation = conversation;
  }

  // TODO: remove only for owner of messages
  // update after callback
  async removeConversation(conversation) {
    // return console.log('disabled');
    // this.messageService.removeConversation(this.currentUser._id, conversation._id)
    //   .subscribe(result => {
    //     console.log(result);
    //     // this.getCurrUserMessages(this.currentUser._id);
    //   });
    const toast = await this.toastCtrl.create({
      message: 'Funkcionalnost je v pripravi.',
      duration: 2000
    });
    
    toast.present();
  }

  removeMessage(message) {
    this.messageService.removeMessage(this.currentUser._id, message._id)
      .subscribe(result => {
        console.log(result);
        this.getCurrUserMessages(this.currentUser._id);
        this.removeMessageToast();
      });
  }

  async removeMessageToast(): Promise<void> {
    const toast = await this.toastCtrl.create({
      message: 'Sporočilo je bilo izbrisano.',
      duration: 2000
    });
    
    toast.present();
  }

  // flagMessage(message) {
  //   this.messageService.flagMessage(this.currentUser._id, message._id)
  //     .subscribe(async result => {
  //       console.log(result);
  //       // this.getCurrUserMessages(this.currentUser._id);
  //       const toast = await this.toastCtrl.create({
  //         message: 'Prijava je bila poslana.',
  //         duration: 3000
  //       });
        
  //       toast.present();
  //     });
  // }
      
  sendFlag(message: any, flagReason: number, description: string): void {
    this.flagService.flagItem(
        message._id,
        this.currentUser._id,
        message.from,
        3, // 3-message
        flagReason,
        description
      ).subscribe(async (response) => {
        const toast = await this.toastCtrl.create({
          message: response["message"],
          duration: 3000,
        });
        toast.present();
      });
  }

  // flag popover
  async presentFlagReasonsPopover(ev: Event, comment: any): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: FlagReasonsPopoverComponent,
      componentProps: {
        flagItem: (flagComment: any, flagReason: number, description: string) => {
          this.sendFlag(flagComment, flagReason, description);
        },
        comment,
        lang: this.lang,
      },
      cssClass: "flag-reasons-popover",
      // bug in positioning on bottom of screen, gets cut off if description is open
      event: ev,
    });
    popover.present();
  }

  flagMessage(ev: Event, message: any): void {
    this.presentFlagReasonsPopover(ev, message);
  }

  async sendMessage(from, message, to, adId, adTitle) {
    if (!this.currentUser) {
      const toast = await this.toastCtrl.create({
        message: this.lang["loginToContact"],
        duration: 3000
      });
      toast.present();
      this.contactMessage = '';
      return;
    }

    // TODO: Enable controls
    // if (this.user._id === this.ad.author) {
    //   const toast = this.toastCtrl.create({
    //     message: 'You can not send the message to yourself.',
    //     duration: 3000
    //   });
    //   toast.present();
    //   this.contactMessage = '';
    //   return;
    // }

    // const messageCheck = /^([a-z]|[A-Z]|[0-9]){2,200}$/;
    // let lines = this.contactMessage.split('\n');
    // for (var i = 0; i < lines.length; i++) {
    //   if (!lines[i].match(messageCheck)) {
    //     const toast = this.toastCtrl.create({
    //       message: 'Your message contains invalid characters.',
    //       duration: 3000
    //     });
    //     toast.present();
    //     return false;
    //   }
    // }

    if (this.contactMessage.length > 500) {
      const toastLength = await this.toastCtrl.create({
        message: 'Your message is longer than 500 characters.',
        duration: 3000
      });
      return toastLength.present();
    }

    this.messageService.sendMessage(
      from, // this.currentUser._id,
      message, // this.contactMessage,
      to, // this.user.userId,
      adId, // 'General inquiry',
      adTitle // 'Message from profile page',
    ).subscribe(response => {
      if (response['result'] === 200) {
        this.contactMessage = '';
        this.getCurrUserMessages(this.currentUser._id);
        // if (adId === null) {
        //   this.getCurrUserMessages(this.currentUser._id);

        //   // TODO: NOT GOOD, UPDATE VIA API
        //   this.selectedConversation.messages.push({
        //     from,
        //     to,
        //     message,
        //     sendDate: new Date,
        //     otherPerson: to,
        //     statusOfMessage: 'active'
        //   });
        //   return;
        // }
        // const toast = this.toastCtrl.create({
        //   message: response['message'],
        //   duration: 3000
        // });
        // toast.present();
      }
    });
  }

}
import { Component, Input, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ToastController } from "@ionic/angular";

import { MessageService } from "../../services/message.service";
import { CurrentUser } from "../../model/current-user";

// Contact Us Component
@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.scss"],
})
export class ContactUsComponent implements OnInit {
  @Input() currentUser: CurrentUser;
  @Input() appLanguage: string;
  @Input() lang: any;
  @Input() loggedIn = false;

  contactForm: UntypedFormGroup;
  captcha: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService,
    private toastCtrl: ToastController
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  resolved(captchaResponse: string) {
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.captcha = captchaResponse;
  }

  buildForm(): void {
    this.contactForm = this.formBuilder.group({
      name: [
        "",
        Validators.compose([
          Validators.maxLength(30),
          // Validators.pattern('[a-zA-Z ]*'),
          Validators.required,
        ]),
      ],
      email: [
        "",
        Validators.compose([Validators.required, this.validateEmail]),
      ],
      title: [
        "",
        Validators.compose([
          Validators.maxLength(70),
          // Validators.pattern('[a-zA-Z ]*'),
          // Validators.required,
        ]),
      ],
      message: [
        "",
        Validators.compose([
          Validators.minLength(10),
          // Validators.pattern('[a-zA-Z ]*'),
          Validators.required,
        ]),
      ],
    });
  }

  sendMessage(): void {
    if (this.contactForm.valid && this.captcha) {
      const name = this.contactForm.get("name").value;
      const email = this.contactForm.get("email").value;
      const title = this.contactForm.get("title").value;
      const message = this.contactForm.get("message").value;

      this.messageService
        .sendContactMessage(email, message, this.captcha, name, title)
        .subscribe(async (response) => {
          if (response["result"] === 200) {
            this.contactForm.reset();
            this.captcha = null;
            const toast = await this.toastCtrl.create({
              message: this.lang["messageSent"],
              duration: 3000,
            });
            toast.present();
          }
        });
    }
  }

  validateEmail(c: UntypedFormControl) {
    const EMAIL_REGEXP =
      /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

    return EMAIL_REGEXP.test(c.value)
      ? null
      : {
          validateEmail: {
            valid: false,
          },
        };
  }
}

<ion-toolbar
  color="primary"
  class="main-header"
  [ngClass]="{
    'bottom-shadow': (lang && basePath === 'r') || basePath === 'm'
  }"
  *ngIf="lang"
>
  <ion-buttons slot="start">
    <ion-menu-button></ion-menu-button>
  </ion-buttons>
  <img
    *ngIf="!mobileView"
    (click)="goToHome()"
    class="page-logo"
    src="../../../assets/images/k-logo-text-50-white.png"
    alt="kvadrat"
  />
  <img
    *ngIf="mobileView"
    (click)="goToHome()"
    class="page-logo"
    src="../../../assets/images/kvad-logo-50-white.png"
    alt="kvadrat"
  />
  <ion-buttons slot="end">
    <!-- <button *ngIf="!searchMode" icon-only color="white" (click)="toggleLayout()">
      <ion-icon *ngIf="!layoutGrid" name="md-apps"></ion-icon>
      <ion-icon *ngIf="layoutGrid" name="md-list"></ion-icon>
    </button> -->
    <!-- <button *ngIf="!searchMode" icon-only color="white" (click)="presentPopover($event)">
      <ion-icon name="md-add"></ion-icon>
    </button> -->

    <ion-button (click)="handleNewAction()">
      <ion-icon slot="start" name="add"></ion-icon>
      <span *ngIf="mobileView">{{ lang.newAdShort }}</span>
      <span *ngIf="!mobileView">{{ lang.newAd }}</span>
    </ion-button>

    <ion-button *ngIf="!searchMode" (click)="openProfileAction($event)">
      <ion-icon *ngIf="!currentUser" slot="icon-only" name="person"></ion-icon>
      <app-profile-photo class="user-avatar" *ngIf="currentUser" [userId]="currentUser._id"></app-profile-photo>
    </ion-button>

    <ion-button
      *ngIf="!currentUser"
      class="flag-button"
      (click)="presentLanguagePopover($event)"
    >
      <span *ngIf="appLanguage === 'en'">EN</span>
      <span *ngIf="appLanguage === 'sl'">SI</span>
      <img
        *ngIf="appLanguage === 'en'"
        class="language-flag"
        src="assets/images/britain-flag-round.png"
        alt="english"
      />
      <img
        *ngIf="appLanguage === 'sl'"
        class="language-flag"
        src="assets/images/slovenia-flag-round.png"
        alt="slovene"
      />
    </ion-button>
  </ion-buttons>
</ion-toolbar>

<ion-toolbar
  class="toolbar-subheader"
  *ngIf="(lang && basePath === 'r') || basePath === 'm'"
>
  <ion-text class="result-count">
    {{ resultNum }}
    <span *ngIf="resultNum === 1">{{ lang.result1 }}</span>
    <span *ngIf="resultNum === 2">{{ lang.result2 }}</span>
    <span *ngIf="resultNum === 3 || resultNum === 4">{{ lang.result3 }}</span>
    <span *ngIf="resultNum >= 5 || resultNum === 0">{{ lang.result4 }}</span>
  </ion-text>
  <ion-buttons slot="end">
    <!-- TODO: disabled when empty query or exists -->
    <ion-button
      fill="outline"
      class="toolbar-button"
      (click)="openSearchModal()">
      <ion-icon
        [slot]="mobileView ? 'icon-only' : 'start'"
        name="funnel">
      </ion-icon>
      <span *ngIf="!mobileView">{{ lang.filters }}</span> <span *ngIf="filterNumber > 0" class="filter-num">{{ filterNumber }}</span>
    </ion-button>

    <ion-button
      fill="outline"
      class="toolbar-button"
      (click)="openSortMenu($event)"
    >
      <ion-icon
        [slot]="mobileView ? 'icon-only' : 'start'"
        *ngIf="sortOrder.sort === 'pricemin'"
        class="arrow-forward up"
        name="arrow-forward"
      ></ion-icon>
      <span *ngIf="!mobileView && sortOrder.sort === 'pricemin'">{{
        lang.price
      }}</span>
      <ion-icon
        [slot]="mobileView ? 'icon-only' : 'start'"
        *ngIf="sortOrder.sort === 'pricemax'"
        class="arrow-forward down"
        name="arrow-forward"
      ></ion-icon>
      <span *ngIf="!mobileView && sortOrder.sort === 'pricemax'">{{
        lang.price
      }}</span>
      <ion-icon
        [slot]="mobileView ? 'icon-only' : 'start'"
        *ngIf="sortOrder.sort === 'sizemin'"
        class="arrow-forward up"
        name="arrow-forward"
      ></ion-icon>
      <span *ngIf="!mobileView && sortOrder.sort === 'sizemin'">{{
        lang.size
      }}</span>
      <ion-icon
        [slot]="mobileView ? 'icon-only' : 'start'"
        *ngIf="sortOrder.sort === 'sizemax'"
        class="arrow-forward down"
        name="arrow-forward"
      ></ion-icon>
      <span *ngIf="!mobileView && sortOrder.sort === 'sizemax'">{{
        lang.size
      }}</span>
      <ion-icon
        [slot]="mobileView ? 'icon-only' : 'start'"
        *ngIf="sortOrder.sort === 'datemin'"
        class="arrow-forward up"
        name="arrow-forward"
      ></ion-icon>
      <span *ngIf="!mobileView && sortOrder.sort === 'datemin'">{{
        lang.adAge
      }}</span>
      <ion-icon
        [slot]="mobileView ? 'icon-only' : 'start'"
        *ngIf="sortOrder.sort === 'datemax'"
        class="arrow-forward down"
        name="arrow-forward"
      ></ion-icon>
      <span *ngIf="!mobileView && sortOrder.sort === 'datemax'">{{
        lang.adAge
      }}</span>
    </ion-button>

    <ion-button
      fill="outline"
      class="toolbar-button"
      (click)="vieWModePopover($event)"
    >
      <ion-icon
        [slot]="mobileView ? 'icon-only' : 'start'"
        name="eye"
      ></ion-icon>
      <span *ngIf="!mobileView">{{ lang.view }}</span>
    </ion-button>
    <ion-button
      fill="solid"
      class="toolbar-button save-search"
      (click)="saveSearch()"
      [disabled]="searchQuerySaved"
    >
      <ion-icon
        [slot]="mobileView ? 'icon-only' : 'start'"
        name="star"
      ></ion-icon>
      <span *ngIf="!mobileView && !searchQuerySaved">{{
        lang.saveSearch
      }}</span>
      <span class="capitalized" *ngIf="!mobileView && searchQuerySaved">{{
        lang.saved
      }}</span>
    </ion-button>
  </ion-buttons>
</ion-toolbar>

<ion-toolbar
  class="toolbar-subheader"
  *ngIf="lang && basePath === 're'">
  <div class="container">
    <ion-input aria-label="Search" placeholder="Search agency" type="text" class="search-bar-agency"></ion-input>
    <ion-buttons slot="end">
      <ion-button fill="outline"
                  class="add-button"
                  (click)="goToNewAgencyForm()">
        <ion-icon [slot]="mobileView ? 'icon-only' : 'start'" name="add"></ion-icon>
        <span *ngIf="!mobileView">Add agency</span>
      </ion-button>
    </ion-buttons>
  </div>
</ion-toolbar>
import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { NavParams, ModalController, PopoverController, ToastController } from '@ionic/angular';

import { LoginModalComponent } from '../login-modal/login-modal.component';

import { DataService } from '../../../services/data.service';
import { LoginService } from '../../../services/login.service';
import { FlagService } from '../../../services/flag.service';
import { UserDataService } from '../../../services/user-data.service';
import { FavoritesService } from '../../../services/favorites.service';
import { ViewHistoryService } from '../../../services/view-history.service';
import { AdViewService } from '../../../services/ad-view.service';
import { WindowService } from '../../../services/window.service';
import { SocketService } from "../../../services/socket.service";

import { FlagReasonsPopoverComponent } from '../../popovers/flag-reasons/flag-reasons-popover.component';
import { AdDetailComponent } from '../../ad-detail/ad-detail.component';
import { CurrentUser } from '../../../model/current-user';

import { take } from 'rxjs/operators';

// Preview Modal Component
@Component({
  selector: 'app-preview-modal',
  templateUrl: './preview-modal.component.html',
  styleUrls: ['./preview-modal.component.scss']
})
export class PreviewModalComponent implements OnInit {

  lang: any;
  appLanguage = "sl";
  ad: any;
  currentUser: CurrentUser;
  loggedIn = false;
  comments: any[];
  question = '';
  replyQuestion = '';
  commentCount = 0;
  replyForm = false;
  replyToReplyForm = false;
  replyCommentId = '';
  replyReplyId = '';
  deleteAlert: any;
  editCommentId = '';
  editMessage = '';
  messageMaxLength = 280;
  messageRemaining = null;
  phoneNumber: string;

  @ViewChild('phoneLink', {read: ElementRef}) phoneLink: ElementRef;
  @ViewChild('addDetail') addDetail: AdDetailComponent;
  mobileView: boolean;
  isFavorite = false;
  adViewsCount = 0;
  favoritesCount = 0;

  constructor(
    public params: NavParams,
    private modalCtrl: ModalController,
    private popoverCtrl: PopoverController,
    private loginService: LoginService,
    private userDataService: UserDataService,
    private flagService: FlagService,
    private toastCtrl: ToastController,
    private favoritesService: FavoritesService,
    private viewHistoryService: ViewHistoryService,
    private adViewService: AdViewService,
    private dataService: DataService,
    private windowService: WindowService,
    private socketService: SocketService,
  ) {
    this.lang = params.get('lang');
    this.appLanguage = params.get('appLanguage');
    this.ad = params.get('ad');
    // this.getComments(this.ad._id);
  }

  ngOnInit() {
    this.currentUser = this.loginService.currentUser();
    // GET VIEW
    this.dataService.isBrowser$.subscribe(isBrowser => {
      if (isBrowser) {
        this.toggleMobileView();

        // wait 3 seconds in order for the view to count
        this.countAdViews(this.ad._id);
        setTimeout(() => {
          this.adViewCount(this.ad._id);
        }, 3000);
      }
    });
    this.checkFavorite();
    if (this.currentUser) {
      this.addToViewHistory(this.ad._id, this.currentUser._id);
    }
    this.countAdFavorites(this.ad._id);
    this.getUserName(this.ad.author, this.ad);
    this.ad['elapsed'] = Math.floor((new Date().getTime() - new Date(this.ad['created']).getTime()) / 86400000);

    this.socketService.adChanges$.subscribe((msg) => {
      if (msg["itemId"] === this.ad._id) {
        if (msg["type"] === "favoriteChanges") {
          this.checkFavorite();
        }
        if (msg["type"] === "adUpdated" || msg["type"] === "adDeactivated") {
          this.dismiss();
        }
      }
    });

    this.dataService.loggedIn$.subscribe((isLoggedIn: boolean) => {
      this.currentUser = this.loginService.currentUser();
      this.checkFavorite();
    });
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  async toggleFavorite(itemId: string, authorId: string) {
    if (this.currentUser === undefined) {
      this.presentLoginModal();
      return null;
    }

    if (this.currentUser._id === authorId) {
      const toast = await this.toastCtrl.create({
        message: this.lang['messageToOwner'],
        duration: 3000
      });
      toast.present();
      return;
    }

    if (!this.isFavorite) {
      this.favoritesService.addFavorite(itemId, this.currentUser._id, 'someOwnerId')
        .subscribe(() => {
          this.isFavorite = true; // shortcut to save one requst
          // this.params.get('updateSaveFavorite')(itemId);
          this.countAdFavorites(itemId);
        });
    } else {
      this.favoritesService.removeFavorite(itemId, this.currentUser._id, 'someOwnerId')
        .subscribe(() => {
          this.isFavorite = false; // shortcut to save one requst
          // this.params.get('updateSaveFavorite')(itemId);
          this.countAdFavorites(itemId);
        });
    }
  }

  checkFavorite() {
    if (this.currentUser === undefined) {
      this.isFavorite = false;
      return
    };
    this.favoritesService.checkFavorite(this.ad._id, this.currentUser._id)
      .subscribe(result => {
        this.isFavorite = result['isFavorite'];
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.toggleMobileView();
  }

  toggleMobileView() {
    if (this.windowService.nativeWindow.innerWidth <= '767') {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
  }

  async copyLinkClipboard(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = window.location.host + '/ad/' + val + (this.appLanguage === 'en' ? '?l=en' : '');
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    // this.viewCtrl.dismiss();
    const toast = await this.toastCtrl.create({
      // message: `Link ${selBox.value} kopiran.`,
      message: this.lang['linkCopied'], // translate
      duration: 3000
    });
    toast.present();
  }

  scrollTo(el: ElementRef) {
    el.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  countMessageRemaining(question) {
    this.messageRemaining = this.messageMaxLength - question.length;
  }

  callNumber() {
    this.userDataService.getPhone(this.ad.author)
      .subscribe(result => {
        this.phoneNumber = result['phone'];
        setTimeout(() => {
          this.phoneLink.nativeElement.click();
        }, 100);
      });
  }

  // flag popover
  async presentFlagReasonsPopover(ev, addId) {
    const popover = await this.popoverCtrl.create({
      component: FlagReasonsPopoverComponent,
      componentProps: {
        flagItem: (addId: string, flagReason: number, description: string) => {
          this.sendFlag(addId, flagReason, description);
        },
        adId: addId,
        lang: this.lang
      },
      cssClass: 'flag-reasons-popover',
      event: ev,
    });
    await popover.present();
  }

  flagAd(event, add) {
    if (this.currentUser === undefined) {
      this.presentLoginModal();
      return null;
    }
    this.presentFlagReasonsPopover(event, add);
  }

  sendFlag(itemId: string, flagReason: number, description: string): void {
    this.flagService.flagItem(
      itemId,
      this.currentUser._id,
      this.ad.author,
      0, // ad
      flagReason,
      description
    ).subscribe(async response => {
        const toast = await this.toastCtrl.create({
          message: response['message'],
          duration: 3000
        });
        toast.present();
      });
  }

  getUserName(userId, user) {
    this.userDataService.getName(userId)
      .subscribe((res: object) => {
        user['userName'] = res['name'];
      });
  }

  async presentLoginModal() {
    const modal = await this.modalCtrl.create({
      component: LoginModalComponent,
      componentProps: {
        updateList: (name) => {
          // this.updateList();
        },
        // item: item,
        lang: this.lang,
        appLanguage: this.appLanguage
      },
      cssClass: 'login-modal'
    });
    modal.present();
  }

  addToViewHistory(adId: string, userId: string, meta?: any) {
    this.viewHistoryService.addViewHistory(adId, userId, meta)
      .pipe(
        take(1)
      )
      .subscribe(result => {
        // console.log(result);
        this.updateViewHistoryIndex();
      });
  }

  updateViewHistoryIndex() {
    let historyIndex;
    this.dataService.viewHistoryIndex$
      .pipe(
        take(1)
      )
      .subscribe(index => {
        historyIndex = index;
      });

    this.dataService.setViewHistoryIndexSource(historyIndex + 1);
  }

  // TODO: Ad view counter rules
  // wait 10 seconds
  adViewCount(adId: string) {
    // console.log(document.hidden); // count 10 seconds of active time
    this.adViewService.addAdView(adId)
      .pipe(
        take(1)
      )
      .subscribe(result => {
        // console.log(result);
        this.countAdViews(adId);
      });
  }

  countAdViews(adId) {
    this.adViewService.countAdView(adId)
      .subscribe(result => {
        this.adViewsCount = result['adViewsCount'];
        this.params.get('updateAdView')(adId);
      });
  }

  countAdFavorites(adId) {
    this.favoritesService.countAdFavorites(adId)
      .subscribe(result => {
        this.favoritesCount = result['favoritesCount'];
        this.params.get('updateFavoriteCount')(adId);
      });
  }
}

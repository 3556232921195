import { Component, HostListener, OnInit } from '@angular/core';

import { DataService } from '../../services/data.service';
import { LoginService } from '../../services/login.service';
import { LanguageService } from '../../services/language.service';
import { UserQueryService } from '../../services/user-query.service';
import { WindowService } from '../../services/window.service';
import { SearchSubscriptionService } from '../../services/search-subscription.service';

import { CurrentUser } from '../../model/current-user';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-profile-saved-searches',
  templateUrl: 'profile-saved-searches.component.html',
  styleUrls: ['./profile-saved-searches.component.scss']
})
export class ProfileSavedSearchesComponent implements OnInit {
  title = 'SavedSearches';
  lang: any;
  isLoggedIn = false;
  currentUser: CurrentUser;
  currUserQueries: any = [];
  mobileView = true; // todo

  constructor(
    // private bucketService: BucketService,
    private loginService: LoginService,
    private dataService: DataService,
    private languageService: LanguageService,
    private userQueryService: UserQueryService,
    private windowService: WindowService,
    private searchSubscriptionService: SearchSubscriptionService,
    private toastCtrl: ToastController,
  ) { }

  ngOnInit() {
    // this.getUrl();
    // this.API = this.dataService.API;
    // SET LANGUAGE
    this.languageService.language$.subscribe(value => {
      // this.appLanguage = value;
      this.lang = this.languageService[value];
      // this.updateList();
      this.translateAllQueries();
    });

    this.dataService.loggedIn$
      .subscribe(result => {
        this.isLoggedIn = result;
        this.currentUser = this.loginService.currentUser();
        if (this.currentUser && this.currentUser._id) {
          this.getUserQueries(this.currentUser._id);
        }
      });
    
      this.dataService.isBrowser$.subscribe(isBrowser => {
      // console.log(isBrowser);
      if (isBrowser) {
        this.toggleMobileView();
      }
    });
  }

  ionViewDidEnter() {
    if (this.currentUser && this.currentUser._id) {
      this.getUserQueries(this.currentUser._id);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.toggleMobileView();
  }

  toggleMobileView() {
    if (this.windowService.nativeWindow.innerWidth <= '767') {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
  }

  getUserQueries(userId: string): void {
    this.userQueryService.listUserQuery(userId).subscribe(result => {
        this.currUserQueries = result['userQueries'];
        this.translateAllQueries();
        this.checkAllSubscriptions();
      });
  }

  checkAllSubscriptions(): void {
    this.currUserQueries.forEach(userQuery => {
      const { sort, l, locType, vicinity, ...cleanQuery } = JSON.parse(userQuery.queryString);
      this.checkSearchSubscription(cleanQuery, userQuery);
    });
  }

  translateAllQueries(): void {
    this.currUserQueries.forEach(userQuery => {
      this.getQueryTranslations(userQuery);
    });
  }

  getQueryTranslations(userQuery): void {
    const queryObject = JSON.parse(userQuery.queryString);
    const { nelat, nelng, swlat, swlng, l, locType, ...rest } = queryObject;
    const queryObjectEntries = Object.entries(rest) as string[][];
    userQuery["queryParams"] = queryObject;
    userQuery["translatedEntries"] = [];

    if (nelat) {
      userQuery["translatedEntries"].push(this.lang['location'] + ': ' + nelat + "...");
    }

    queryObjectEntries.forEach((queryObjectEntry: string[]) => {
      if (queryObjectEntry[0] === 'adType') {
        userQuery["translatedEntries"].push(this.lang['adType'] + ': ' + this.lang[queryObjectEntry[1]]);
        return;
      }
      if (queryObjectEntry[0] === 'propTypes') {
        const translatedValues = queryObjectEntry[1].split(',').map(prop => {
          return this.lang.adPropertyType[prop];
        });
        userQuery["translatedEntries"].push(this.lang['adPropType'] + ': ' + translatedValues.join(', '));
        return;
      }
      if (queryObjectEntry[0] === 'amenities') {
        const translatedValues = queryObjectEntry[1].split(',').map(amenity => {
          return this.lang.amenities[amenity];
        });
        userQuery["translatedEntries"].push(this.lang['amenitiesTranslation'] + ': ' + translatedValues.join(', '));
        return;
      } 
      if (queryObjectEntry[0] === 'sort') {
        // split queryObjectEntry[1] into two variables, the second string is the last 3 characters
        const sortType = queryObjectEntry[1].slice(0, -3);
        const sortOrder = queryObjectEntry[1].slice(-3);
        userQuery["translatedEntries"].push(this.lang['sortBy'] + ': ' + this.lang[sortType] + ' ' + this.lang[sortOrder]);
        return;
      }
      userQuery["translatedEntries"].push(this.lang[queryObjectEntry[0]] + ': ' + queryObjectEntry[1]);
    });    
  }

  removeUserQuery(queryId: string): void {
    this.userQueryService.removeUserQuery(this.currentUser._id, queryId)
      .subscribe(() => {
        this.getUserQueries(this.currentUser._id);
      });
  }

  async subscribeSearch(query: any): Promise<void> {
    // maybe locType should be saved as well
    const { sort, l, locType, vicinity, ...cleanQuery } = query.queryParams;
    const sortedQuery = Object.keys(cleanQuery).sort().reduce((acc, key) => { 
      acc[key] = cleanQuery[key];
      return acc;
    }, {});
    
    if (query.isSearchSubscribed) {
      this.searchSubscriptionService.removeSearchSubscription(this.currentUser.email, sortedQuery)
        .subscribe(() => this.checkSearchSubscription(cleanQuery, query));
      const toast = await this.toastCtrl.create({
        message: this.lang["subscriptionDeactivated"],
        duration: 3000,
      });
      toast.present();
    } else {
      this.searchSubscriptionService.addSearchSubscription(this.currentUser.email, sortedQuery)
        .subscribe(() => this.checkSearchSubscription(cleanQuery, query));
      const toast = await this.toastCtrl.create({
        message: this.lang["subscriptionActive"],
        duration: 3000,
      });
      toast.present();
    }
  }

  checkSearchSubscription(query: any, queryObject: any): void {
    this.searchSubscriptionService.checkSearchSubscription(this.currentUser.email, query)
      .subscribe((result) => {
        queryObject.isSearchSubscribed = result['isSearchSubscription'];
      });
  }

}

import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import {
  ModalController,
  ToastController,
} from '@ionic/angular';

import { MessageService } from '../../services/message.service';
import { UserDataService } from '../../services/user-data.service';
import { DataService } from '../../services/data.service';

import { PhotoPreviewModalComponent } from '../modals/photo-preview/photo-preview-modal.component';
import { IAd, IAdClient } from '../../model/db/ad';
import { PropertyType } from '../../model/enums/property-type';

// Ad Detail Component
@Component({
  selector: 'app-ad-detail',
  templateUrl: './ad-detail.component.html',
  styleUrls: ['./ad-detail.component.scss']
})
export class AdDetailComponent implements OnInit {

  contactMessage = '';
  phoneNumber: string;

  @Input() appLanguage: string;
  @Input() lang: any;
  @Input() adId: any;
  @Input() ad: IAdClient;
  // @Input() ad: IAd;
  @Input() adViewsCount: number;
  @Input() favoritesCount: number;
  @Input() currentUser: any;
  @Input() loggedIn: any;
  @Input() mobileView: any;
  @Input() singlePage = false;

  @Output() modalClose: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('contactSeller', {read: ElementRef}) contactSeller: ElementRef;
  API = '';
  userData: any;
  imageUrls: string[] = [];

  constructor(
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private messageService: MessageService,
    private userDataService: UserDataService,
    private dataService: DataService,
  ) { }

  ngOnInit() {
    this.API = this.dataService.API;
    this.fetchUserData(this.ad.author);

    if (this.ad.adPropertyType === PropertyType.Flat) {
      this.setFlatType(this.ad.roomNum);
    }

    this.imageUrls = this.ad.photos.map(image => this.API + '/files/image/' + image._id);
  }

  setFlatType(roomNum: number): void {
    switch (roomNum) {
      case 0:
        this.ad["flatType"] = this.lang.flatTypes.garconiere;
        break;
      case 1:
        this.ad["flatType"] = this.lang.flatTypes.flat1;
        break;
      case 1.5:
        this.ad["flatType"] = this.lang.flatTypes.flat15;
        break;
      case 2:
        this.ad["flatType"] = this.lang.flatTypes.flat2;
        break;
      case 2.5:
        this.ad["flatType"] = this.lang.flatTypes.flat25;
        break;
      case 3:
        this.ad["flatType"] = this.lang.flatTypes.flat3;
        break;
      case 3.5:
        this.ad["flatType"] = this.lang.flatTypes.flat35;
        break;
      case 4:
        this.ad["flatType"] = this.lang.flatTypes.flat4;
        break;
      case 4.5:
        this.ad["flatType"] = this.lang.flatTypes.flat45;
        break;
      case 5:
        this.ad["flatType"] = this.lang.flatTypes.flat5;
        break;
    
      default:
        this.ad["flatType"] = this.lang.flatTypes.other;
        break;
    }
  }

  get isNotBuilding(): boolean {
    return this.ad?.adPropertyType === PropertyType.Estate ||
           this.ad?.adPropertyType === PropertyType.Parking ||
           this.ad?.adPropertyType === PropertyType.Farm;
  }

  fetchUserData(userId: string) {
    this.userDataService.getUserData(userId)
      .subscribe(result => {
        this.userData = result;
      });
  }

  async openPhotoPreview() {
    const previewModal = await this.modalCtrl.create({
      component: PhotoPreviewModalComponent,
      componentProps: {
        lang: this.lang,
        appLanguage: this.appLanguage,
        // ad: this.ad,
        imageUrls: this.imageUrls,
        photoPreview: true,
      },
      cssClass: 'photo-preview-modal'
    });
    previewModal.present();
  }

  async sendMessage() {
    if (!this.currentUser) {
      const toast = await this.toastCtrl.create({
        message: this.lang["loginToContact"],
        duration: 3000
      });
      toast.present();
      this.contactMessage = '';
      return;
    }
    if (this.currentUser._id === this.ad.author) {
      const toast = await this.toastCtrl.create({
        message: this.lang['messageToOwner'],
        duration: 3000
      });
      toast.present();
      this.contactMessage = '';
      return;
    }

    // const messageCheck = /^([a-z]|[A-Z]|[0-9]){2,200}$/;
    // let lines = this.contactMessage.split('\n');
    // for (var i = 0; i < lines.length; i++) {
    //   if (!lines[i].match(messageCheck)) {
    //     const toast = this.toastCtrl.create({
    //       message: 'Your message contains invalid characters.',
    //       duration: 3000
    //     });
    //     toast.present();
    //     return false;
    //   }
    // }

    if (this.contactMessage.length > 500) {
      const toastLength = await this.toastCtrl.create({
        message: this.lang['messageTooLong'],
        duration: 3000
      });
      return toastLength.present();
    }

    this.messageService.sendMessage(
      this.currentUser._id,
      // tslint:disable-next-line:max-line-length
      `${this.contactMessage}
       (${this.lang.ad}: ${this.ad.located.street}, ${this.ad.located.postal} ${this.ad.located.district}`,
      this.ad.author,
      this.ad._id,
      this.lang.ad + ': ' + this.ad.located.street + ', ' + this.ad.located.postal + ' ' + this.ad.located.district,
    ).subscribe(async response => {
      if (response['result'] === 200) {
        this.contactMessage = '';
        const toast = await this.toastCtrl.create({
          message: this.lang['messageSent'],
          duration: 3000
        });
        toast.present();
      }
    });
  }

  getPhone() {
    this.userDataService.getPhone(this.ad.author)
      .subscribe(result => {
        // console.log(result);
        this.phoneNumber = result['phone'];
      });
  }
}

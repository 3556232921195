<ion-header class="shrinkable">
  <app-main-header
    [mobileView]="mobileView"
    [title]="title"
    [basePath]="'re'"
    [currentUser]="currentUser">
  </app-main-header>
</ion-header>

<ion-content class="agency-list-component">
  <ion-grid>
    <ion-card>
      <ion-list>
        <ion-item-divider>
          <!-- {{ lang.favorites }} -->
          {{ agencyNumber }} Agencies
        </ion-item-divider>
        <ion-item *ngIf="agencies.length === 0">
          <!-- {{ lang.noFavoritesYet }} -->
          <p>
            {{ lang.noResultsFound }} <a routerLink="/h">{{ lang.goToHomepage }}</a>
          </p>
        </ion-item>
        <ng-container *ngIf="agencies.length > 0">
          <ion-item *ngFor="let agency of agencies; let i = index">
            <ion-thumbnail>
              <img *ngIf="agency.logo"
                   [src]="API + '/files/agency-logo/' + agency.logo"/>
            </ion-thumbnail>
            <ion-label>
              <ion-text color="primary">
                <h2>
                  <a [routerLink]="['/re', agency._id]">
                    {{ agency.companyName }}
                  </a>
                </h2>
              </ion-text>
              <p>{{ agency.address }}</p>
            </ion-label>
            <ion-button *ngIf="currentUser?._id === agency.author"
                        fill="clear"
                        (click)="$event.stopPropagation()"
                        [routerLink]="['/re-edit', agency._id]">
              {{ lang.edit }}
            </ion-button>
          </ion-item>
        </ng-container>
      </ion-list>
    </ion-card>
  </ion-grid>
</ion-content>

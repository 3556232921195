import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { AlertController, ModalController, NavParams } from "@ionic/angular";
import { CurrentUser } from "../../../model/current-user";
import { PostsService } from "../../../services/posts.service";
import { FileUploadComponent } from "../../file-upload/file-upload.component";
// import { PostFormComponent } from "../../post-form/post-form.component";

// Post Modal Component
@Component({
  templateUrl: "./post-modal.component.html",
  styleUrls: ["./post-modal.component.scss"],
})
export class PostModalComponent implements OnInit {
  message = "";
  messageMaxLength = 800;
  messageRemaining = this.messageMaxLength;
  postId: string;
  
  activationAlert: HTMLIonAlertElement; // Alert;

  @Input() lang: any;
  @Input() basePath: string;
  @Input() currentUser: CurrentUser;

  @ViewChild("fileUpload") fileUpload: FileUploadComponent;

  constructor(
    private modalCtrl: ModalController, public params: NavParams,
    private postsService: PostsService,
    private alertCtrl: AlertController,
  ) {
    // this.lang = params.get('lang');
    // this.basePath = params.get('basePath');
  }

  ngOnInit() {}

  submitPost() {
    if (this.message.length < 3) {
      return;
    }
    
    this.postsService.addPost(this.currentUser._id, this.message).subscribe(response => {
      if (response["id"]) {
        // save photos
        this.postId = response["id"];
        if (this.fileUpload.uploader.queue.length > 0) {
          this.fileUpload.uploadFiles(this.postId);
        } else {
          this.finalizePosting();
        }
      }
    });
  }

  updateMessage(message: string): void {
    this.messageRemaining = this.messageMaxLength - message.length;
    this.message = message;
  }

  finalizePosting() {
    this.dismiss();
    this.showActivationAlert();
  }

  // close button
  dismiss() {
    this.message = "";
    this.modalCtrl.dismiss();
  }

  async showActivationAlert() {
    this.activationAlert = await this.alertCtrl.create({
      header: this.lang["activationPostTitle"], // this.lang['delete'],
      subHeader: this.lang["activationPostBody"], // this.lng['deleteConfirm'],
      // subTitle: 'Removing the ad can not be undone. Ste prepričani, da želite izbrisati oglas?', // this.lng['deleteConfirm'],
      buttons: [
        {
          text: "Ok", // this.lng['cancel'],
          role: "cancel",
          handler: () => {
            // console.log('Cancel clicked');
            this.activationAlert = undefined;
          },
        },
      ],
    });

    this.activationAlert.present();
  }
}

import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
} from "@angular/core";

import { Router, ActivatedRoute } from "@angular/router";

import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";

import {
  ModalController,
  MenuController,
  AlertController,
} from "@ionic/angular";

// import leaflet from 'leaflet';

import { BucketService } from "../../services/bucket.service";
import { LanguageService } from "../../services/language.service";
import { LoginService } from "../../services/login.service";
import { DataService } from "../../services/data.service";
import { UserDataService } from "../../services/user-data.service";
import { LocationService } from "../../services/location.service";
import { WindowService } from "../../services/window.service";
import { LeafletService } from "../../services/leaflet.service";
import { RealEstateAgencyService } from "../../services/re-agency.service";

import { PhoneFormComponent } from "../modals/phone-form/phone-form.component";
import { CurrentUser } from "../../model/current-user";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-agency-form-page",
  templateUrl: "./agency-form.component.html",
  styleUrls: ["./agency-form.component.scss"],
})
export class AgencyFormComponent implements OnInit {
  // variables
  adForm: UntypedFormGroup;
  // fullAddress: any;
  images: string[] = [];
  reAgencyId = "";

  currentUser: CurrentUser;
  appLanguage: string;
  lang: any;
  loggedIn: boolean;
  layoutGrid: boolean;
  mobileView: boolean;
  title = "Nov oglas";
  agencyEditMode = false;

  // maybe in the future, see ad form component for reference
  // address = "";
  // lat = 46.1403;
  // lng = 14.88;
  // currentZoomLevel = 8;
  // @ViewChild("map", { read: ElementRef }) mapContainer: ElementRef;
  // map: any;
  // mapPopup: any;
  // marker: any;

  saving = false;

  constructor(
    private bucketService: BucketService,
    private dataService: DataService,
    private loginService: LoginService,
    private languageService: LanguageService,
    private router: Router,
    private route: ActivatedRoute,
    private modalCtrl: ModalController,
    private userDataService: UserDataService,
    private realEstateAgencyService: RealEstateAgencyService,
    private alertCtrl: AlertController,
    private formBuilder: UntypedFormBuilder,
    private locationService: LocationService,
    private windowService: WindowService,
    private titleService: Title,
    private leafletService: LeafletService // private popoverCtrl: PopoverController, // private toastCtrl: ToastController, // private menuCtrl: MenuController,
  ) {}

  ngOnInit() {
    // SET LANGUAGE
    this.languageService.language$.subscribe((value) => {
      this.appLanguage = value;
      this.lang = this.languageService[value];
      // this.address = this.lang.setAddressOnMap;
    });

    // this.loggedIn = this.loginService.isLoggedIn();
    this.dataService.loggedIn$.subscribe((value) => {
      this.loggedIn = value;
      // console.log(this.loggedIn);
      this.currentUser = this.loginService.currentUser();
      if (!this.currentUser) {
        this.router.navigate(["/"]);
      }
      this.userDataService
        .checkUserValidation(this.currentUser._id)
        .subscribe(async (response) => {
          if (!response["status"]) {
            const notVerifiedAlert = await this.alertCtrl.create({
              header: "Not verified", // this.lang['delete'],
              subHeader:
                "It appears that the user is not verified. Check your email and confirm your registration",
              // this.lng['deleteConfirm'],
              // subTitle: 'Removing the ad can not be undone. Ste prepričani, da želite izbrisati oglas?', // this.lng['deleteConfirm'],
              buttons: [
                {
                  text: this.lang["ok"], // this.lng['cancel'],
                  role: "cancel",
                  handler: () => {
                    // console.log('Cancel clicked');
                    this.router.navigate(["/"], {
                      queryParams: {
                        l: this.appLanguage === "en" ? "en" : null,
                      },
                    });
                  },
                },
              ],
            });
            notVerifiedAlert.present();
          }
        });
      // console.log(this.user);
    });

    this.dataService.isBrowser$.subscribe((isBrowser) => {
      // console.log(isBrowser);
      if (isBrowser) {
        // GET VIEW
        this.toggleMobileView();
        this.titleService.setTitle(
          `Kvadrat - Register your real estate agency`
        );

        // SET LAYOUT
        if (this.windowService.nativeWindow.localStorage) {
          this.layoutGrid = JSON.parse(localStorage.getItem("gridView"));
        } else {
          // FALLBACK
          this.layoutGrid = false;
        }

        this.setForm();

        this.getIdFromUrl();

        this.checkPhone();
      }
    });

    this.onChanges();
    // this.adType = 'sell';
    // this.adPropertyType = 'house';
  }

  // ionViewDidEnter() {
  //   if (!this.map) {
  //     this.loadmap();
  //   }
  // }

  // ionViewWillLeave() {
  //   this.map.off();
  //   this.map.remove();
  //   // document.getElementById('map').outerHTML = '';
  // }

  setStateFromSession() {
    if (sessionStorage.getItem("agencyFormState")) {
      const adState = JSON.parse(sessionStorage.getItem("agencyFormState"));
      // console.log(adState);

      this.adForm.setValue(adState);
      // if (adState.location) {
      //   this.lat = adState.location.DisplayPosition.Latitude;
      //   this.lng = adState.location.DisplayPosition.Longitude;
      // }

      // if (sessionStorage.getItem("address")) {
      //   this.address = sessionStorage.getItem("address");
      // }

      // if (sessionStorage.getItem("currentZoomLevel")) {
      //   this.currentZoomLevel =
      //     parseInt(sessionStorage.getItem("currentZoomLevel"), 10) || 0;
      // }
    }
  }

  getIdFromUrl() {
    this.route.url.subscribe((url) => {
      // console.log(url);
      if (url[0].path === "re-edit") {
        this.agencyEditMode = true;
        this.reAgencyId = url[1].path;
        this.getAgencyData(url[1].path);
      } else {
        this.agencyEditMode = false;
        this.setStateFromSession();
      }
    });
  }

  getAgencyData(agencyId) {
    this.realEstateAgencyService
      .getRealEstateAgencySingle(agencyId)
      .subscribe((res) => {
        console.log(res);
        
        // this.ad = res;
        if (res && res["_id"]) {
          if (res["author"] === this.currentUser._id) {
            this.fillFormData(res);
          } else {
            this.router.navigate(["re", res["_id"]]);
          }
        }
      });
  }

  fillFormData(agencyData) {
    const formData = {
      companyName: agencyData.companyName || "",
      address: agencyData.address || "",
      phone: agencyData.phone || "",
      email: agencyData.email || "",
      website: agencyData.website || "",
      companyId: agencyData.companyId || "",
      taxNumber: agencyData.taxNumber || "",
      registryCity: agencyData.registryCity || "",
      bankName: agencyData.bankName.basicSchool || "",
      iban: agencyData.iban || "",
      swift: agencyData.swift || "",
    };
    // console.log(formData);
    sessionStorage.setItem("agencyFormState", JSON.stringify(formData));
    this.adForm.setValue(formData);
  }

  checkPhone() {
    this.userDataService
      .checkPhone(this.currentUser._id)
      .subscribe((result) => {
        // console.log(result);
        if (!result["result"]) {
          this.openPhoneFormModal();
        }
      });
  }

  setForm() {
    this.adForm = this.formBuilder.group({
      companyName: ["", Validators.required],
      address: ["", Validators.required],
      phone: "",
      email: "",
      website: "",
      companyId: ["", Validators.required],
      taxNumber: "",
      registryCity: "",
      bankName: "",
      iban: "",
      swift: "",
    });
  }

  onChanges(): void {
    this.adForm.valueChanges.subscribe((val) => {
      this.saveAdToSession();
      // console.log(this.adForm.value);
    });
  }

  async openPhoneFormModal() {
    const modal = await this.modalCtrl.create({
      component: PhoneFormComponent,
      componentProps: {
        checkPhone: () => {
          this.checkPhone();
        },
        // item: item,
        currentUserId: this.currentUser._id,
        lang: this.lang,
        appLanguage: this.appLanguage,
      },
    });
    modal.present();
  }

  createOrUpdateAgency(): void {
    if (this.agencyEditMode) {
      this.updateAgency();
    } else {
      this.createAgency();
    }
  }

  createAgency() {
    this.saving = true;
    this.realEstateAgencyService
      .addRealEstateAgency(this.adForm.value, this.currentUser._id)
      .subscribe((res) => {
        // console.log(res);
        this.setForm();
        sessionStorage.removeItem("agencyFormState");
        this.router.navigate(["agency-photos", res["added"]], {
          queryParams: { l: this.appLanguage === "en" ? "en" : null },
        });
        this.saving = false;
      });
  }

  updateAgency() {
    this.realEstateAgencyService
      .updateRealEstateAgency(this.reAgencyId, this.adForm.value, this.currentUser._id)
      .subscribe((res) => {
        // console.log(res);
        sessionStorage.removeItem("agencyFormState");
        this.router.navigate(["agency-photos", res["updated"]], {
          queryParams: { l: this.appLanguage === "en" ? "en" : null },
        });
        this.saving = false;
        this.setForm();
      });
  }

  saveLastState() {
    // let id;
    if (this.windowService.nativeWindow.localStorage) {
      localStorage.setItem("lastView", JSON.stringify(this.adForm.value));
    } else {
      // FALLBACK
    }
  }

  saveAdToSession() {
    sessionStorage.setItem(
      "agencyFormState",
      JSON.stringify(this.adForm.value)
    );
  }

  getLastState() {
    if (this.windowService.nativeWindow.localStorage) {
      const lastView = JSON.parse(localStorage.getItem("lastView"));
      if (lastView !== null) {
        // console.log(lastView);
        // this.title = lastView.title;
        // this.breadcrumbs = lastView.breadcrumbs;
        // this.folderIds = lastView.folderIds;
        // this.folderSortOrder = lastView.folderSortOrder || this.folderSortOrder;
        // this.fileSortOrder = lastView.fileSortOrder || this.fileSortOrder;
      }
    } else {
      // FALLBACK
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.toggleMobileView();
  }

  toggleMobileView() {
    if (this.windowService.nativeWindow.innerWidth <= "767") {
      this.mobileView = true;
      // this.menuCtrl.swipeEnable(true);
    } else {
      this.mobileView = false;
      // this.menuCtrl.swipeEnable(false);
    }
  }

  imgDecoded(item) {
    return encodeURI(item);
  }

  updateLayout(layout: any): void {
    this.layoutGrid = layout;
    if (this.windowService.nativeWindow.localStorage) {
      localStorage.setItem("gridView", JSON.stringify(layout));
    } else {
      // FALLBACK
    }
  }
}

<ion-header class="shrinkable">
  <app-main-header
    [mobileView]="mobileView"
    [title]="title"
    [basePath]="'h'"
    [currentUser]="currentUser"
  ></app-main-header>
</ion-header>

<ion-content [fullscreen]="true" class="agency-page" *ngIf="lang && agency?.coverPhoto">
  <ion-row class="jumbo" align-items-center [style.backgroundImage]="'url('+ this.API + '/files/agency-cover/' + agency.coverPhoto +')'"> </ion-row>

  <section class="company-info">
    <div class="container">
      <div class="logo">
        <img
          style="
            width: 180px;
            margin: 0 auto;
            position: relative;
            display: block;
          "
          [src]="API + '/files/agency-logo/' + agency.logo"
          alt="agency"
          *ngIf="agency.logo"
        />
      </div>
      <h4>{{ agency.companyName }}</h4>

      <div class="basic-info">
        <ion-row>
          <ion-col>
            <p>{{ agency.companyName }}</p>
            <p>Tel: {{ agency.phone }}</p>
            <p>Email: {{ agency.email }}</p>
            <p>Web: <a [href]="agency.website" target="_blank" rel="noopener noreferrer">{{ agency.website }}</a></p>
          </ion-col>
          <ion-col>
            <p>Davčna št.: {{ agency.taxNumber }}</p>
            <p>Mesto registracije: {{ agency.registryCity }}</p>
            <p>Banka: {{ agency.bankName }}</p>
            <p>IBAN: {{ agency.iban }}</p>
            <p>SWIFT: {{ agency.swift }}</p>
          </ion-col>
        </ion-row>
      </div>
    </div>
  </section>

  <section class="team">
    <div class="container">
      <h1>Ekipa</h1>
      <ion-row>
        <ion-col>
          <app-agent-card [person]="{ name: 'User 1' }"></app-agent-card>
        </ion-col>
        <ion-col></ion-col>
        <ion-col></ion-col>
        <ion-col></ion-col>
      </ion-row>
    </div>
  </section>

  <section class="latest-properties">
    <div class="container">
      <ion-grid>
        <ion-row>
          <ion-col>
            <h1 color="primary">Our listings</h1>
            <app-latest-properties
              [lang]="lang"
              [currentUser]="currentUser"
              [appLanguage]="appLanguage"
              (latestAdsActive)="enableLatestAds($event)"
            >
            </app-latest-properties>
            <p *ngIf="!latestAds" class="no-items-message">
              {{ lang.noItemsToShow }}
            </p>
            <p class="show-all" *ngIf="latestAds">
              <a
                [routerLink]="['/r']"
                [queryParams]="{
                  propTypes:
                    'house,flat,apartment,room,weekend,estate,commercial,farm,parking',
                  l: appLanguage
                }"
              >
                {{ lang.showAll }}
              </a>
            </p>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </section>

  <app-footer></app-footer>
</ion-content>

<ion-header class="shrinkable">
  <app-main-header
    [mobileView]="mobileView"
    [title]="title"
    [currentUser]="currentUser"
  ></app-main-header>
</ion-header>

<ion-content
  class="profile-saved-searches"
  *ngIf="currentUser && currentUser._id">
  <ion-grid>
    <ion-item-divider>
      {{ lang.savedSearches }} ({{ currUserQueries.length }})
    </ion-item-divider>
    <ion-item *ngIf="currUserQueries.length < 1">
      <p>
        {{ lang.noSearchesYet }} <a routerLink="/h">{{ lang.goToHomepage }}</a>
      </p>
    </ion-item>
    <ng-container *ngFor="let userQuery of currUserQueries">
      <ion-item *ngIf="!mobileView" class="ion-text-wrap">
        <ion-row>
          <ion-col size="12" size-sm="3">
            {{ userQuery.created | date : "short" }}:
          </ion-col>
          <ion-col size="12" size-sm="9">
            <ng-container *ngFor="let translatedEntry of userQuery.translatedEntries">
              <span style="font-style: italic">{{ translatedEntry }}</span><br>
            </ng-container>
          </ion-col>
          <!-- <ion-col size="12" size-sm="3">
            <a [routerLink]="['/r']" [queryParams]="userQuery.queryParams">{{ lang.showResults }}</a>
          </ion-col> -->
        </ion-row>
        <!-- TODO: parse json to get object, convert it to query url -->
        <ion-button
          fill="clear"
          [routerLink]="['/r']"
          [queryParams]="userQuery.queryParams">
          <ion-icon slot="start" name="open"></ion-icon>
          {{ lang.show }}
        </ion-button>
        <ion-button fill="clear" (click)="subscribeSearch(userQuery)"
                    [color]="userQuery.isSearchSubscribed ? 'success' : 'tertiary'">
          <ion-icon slot="start" name="notifications-outline"></ion-icon>
          <span *ngIf="!userQuery.isSearchSubscribed">{{ lang.notifyMe }}</span>
          <span *ngIf="userQuery.isSearchSubscribed">{{ lang.activated }}</span>
        </ion-button>
        <ion-button fill="clear" (click)="removeUserQuery(userQuery._id)">
          <ion-icon slot="icon-only" color="danger" name="trash"></ion-icon>
        </ion-button>
      </ion-item>

      <ion-card *ngIf="mobileView">
        <ion-card-header>
          <ion-card-subtitle>
            {{ userQuery.created | date : "short" }}
          </ion-card-subtitle>
        </ion-card-header>
          
        <ion-card-content>
          <ng-container *ngFor="let translatedEntry of userQuery.translatedEntries">
            <span style="font-style: italic">{{ translatedEntry }}</span><br>
          </ng-container>
          <br>
          <ion-button
            fill="clear"
            [routerLink]="['/r']"
            [queryParams]="userQuery.queryParams">
            {{ lang.showResults }}
          </ion-button>
          <ion-button fill="clear" (click)="subscribeSearch(userQuery)">
            <ion-icon slot="icon-only" color="primary" name="notifications-outline"></ion-icon>
          </ion-button>
          <ion-button fill="clear" (click)="removeUserQuery(userQuery._id)">
            <ion-icon slot="icon-only" color="danger" name="trash"></ion-icon>
          </ion-button>
        </ion-card-content>
      </ion-card>
    </ng-container>
  </ion-grid>
</ion-content>

<ion-row>
  <ion-col [attr.size]="colNum ? colNum : '3'"
            *ngFor="let item of ads; let i = index">
    <app-prop-card (openPreviewEmitter)="openPreviewModal(item)"
                  #adCard
                  [item]="item"
                  [imageUrls]="item.imageUrls"
                  [appLanguage]="appLanguage"
                  [lang]="lang">
    </app-prop-card>
  </ion-col>
</ion-row>
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { AlertController, ModalController, NavParams } from "@ionic/angular";
import { CurrentUser } from "../../../model/current-user";
import { PostsService } from "../../../services/posts.service";
import { FileUploadComponent } from "../../file-upload/file-upload.component";
import { IPost } from "../../../model/db/post";
import { PhotoPreviewModalComponent } from "../photo-preview/photo-preview-modal.component";
// import { PostFormComponent } from "../../post-form/post-form.component";

// Post Modal Component
@Component({
  templateUrl: "./post-preview-modal.component.html",
  styleUrls: ["./post-preview-modal.component.scss"],
})
export class PostPreviewModalComponent implements OnInit {
  message = "";
  messageMaxLength = 800;
  messageRemaining = this.messageMaxLength;
  postId: string;
  
  activationAlert: HTMLIonAlertElement; // Alert;

  @Input() post: IPost;
  @Input() lang: any;
  @Input() appLanguage: any;
  @Input() basePath: string;
  @Input() currentUser: CurrentUser;

  @ViewChild("fileUpload") fileUpload: FileUploadComponent;

  constructor(
    private modalCtrl: ModalController, public params: NavParams,
    private postsService: PostsService,
    private alertCtrl: AlertController,
  ) {
    // this.lang = params.get('lang');
    // this.basePath = params.get('basePath');
    // this.appLanguage = params.get('appLanguage');
  }

  ngOnInit() {}

  updateMessage(message: string): void {
    this.messageRemaining = this.messageMaxLength - message.length;
    this.message = message;
  }

  finalizePosting() {
    this.dismiss();
    this.showActivationAlert
  }

  // close button
  dismiss() {
    this.message = "";
    this.modalCtrl.dismiss();
  }

  async showActivationAlert() {
    this.activationAlert = await this.alertCtrl.create({
      header: this.lang["activationPostTitle"], // this.lang['delete'],
      subHeader: this.lang["activationPostBody"], // this.lng['deleteConfirm'],
      // subTitle: 'Removing the ad can not be undone. Ste prepričani, da želite izbrisati oglas?', // this.lng['deleteConfirm'],
      buttons: [
        {
          text: "Ok", // this.lng['cancel'],
          role: "cancel",
          handler: () => {
            // console.log('Cancel clicked');
            this.activationAlert = undefined;
          },
        },
      ],
    });

    this.activationAlert.present();
  }

  async openPhotoPreview(post: IPost) {
    const previewModal = await this.modalCtrl.create({
      component: PhotoPreviewModalComponent,
      componentProps: {
        lang: this.lang,
        // appLanguage: this.appLanguage,
        imageUrls: post.images,
        photoPreview: true,
      },
      cssClass: 'photo-preview-modal'
    });
    previewModal.present();
  }

  voteUp(item: IPost, itemOwnerId: string, vote: number) {
    this.params.get('voteOnItem')(item, itemOwnerId, vote);
  }
}

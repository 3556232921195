<ion-header>
  <ion-toolbar color="primary">
    <ion-title>
      <!-- {{ lang.createPost }} -->
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="post-modal-content">
  <ion-grid>
    <ion-item>
      <ion-avatar class="user-avatar">
        <app-profile-photo #profilePhoto [userId]="post?.userId" [size]="36">
        </app-profile-photo>
      </ion-avatar>
      <ion-label>
        <p><b>{{ post?.userName }}</b></p>
        <p class="small">{{ post?.created | date: "short" }}</p>
      </ion-label>
    </ion-item>
  
    <ion-item>
      <p class="description">
        {{ post.text ?? "/" }}
      </p>
    </ion-item>
      
    <ion-row *ngIf="post.images?.length > 0">
      <ion-grid class="image-grid" (click)="openPhotoPreview(post)">
        <ion-row>
          <ion-col class="image-col" size="12"
                    *ngIf="post.images.length === 1"
                    [ngStyle]="{'background-image':'url(' + post.images[0] + ')'}">
          </ion-col>
          <ion-col class="image-col" size="12" size-sm="6"
                    *ngIf="post.images.length === 2"
                    [ngStyle]="{'background-image':'url(' + post.images[0] + ')'}">
          </ion-col>
          <ion-col class="image-col" size="12" size-sm="6" 
                    *ngIf="post.images.length === 2"
                    [ngStyle]="{'background-image':'url(' + post.images[1] + ')'}">
          </ion-col>
          <ion-col class="image-col" size="12" size-sm="8"
            *ngIf="post.images.length > 2"
            [ngStyle]="{'background-image':'url(' + post.images[0] + ')'}">
          </ion-col>
          <ion-col class="image-col" size="12" size-sm="4"
                    *ngIf="post.images.length > 2">
            <div class="extra-photo"
                  [ngStyle]="{'background-image':'url(' + post.images[1] + ')'}">
            </div>
            <div class="extra-photo"
                  [ngStyle]="{'background-image':'url(' + post.images[2] + ')'}">
              <div class="extra-photo-overlay">
                <span>+ {{ post.images.length - 2 }}</span>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-row>

    <ion-row>
      <ion-col size="6" class="ion-text-center">
        <ion-button class="thumbs-button" fill="clear"
                    (click)="voteUp(post, post.userId, 1)">
          <ion-icon slot="icon-only" name="thumbs-up-outline"></ion-icon>
          <span *ngIf="post.likes > 0" class="likes-num">
            {{ post.likes }}
          </span>
        </ion-button>
      </ion-col>
      <ion-col size="6" class="ion-text-center">
        <ion-button class="thumbs-button" fill="clear"> <!-- focus on comment input -->
          <ion-icon slot="icon-only" name="chatbubbles-outline"></ion-icon>
          <span *ngIf="post.commentsNumber > 0" class="likes-num">
            {{ post.commentsNumber }}
          </span>
        </ion-button>
      </ion-col>
    </ion-row>

    <app-comments
        [appLanguage]="appLanguage"
        [lang]="lang"
        [adId]="post._id"
        [authorId]="post.userId"
        [commentType]="4"
        [currentUser]="currentUser"
        [loggedIn]="currentUser && currentUser._id">
    </app-comments>

  </ion-grid>
</ion-content>
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';

@Injectable()
export class SearchSubscriptionService {

  API: string;

  constructor(
    private http: HttpClient,
    private dataService: DataService,
  ) {
    this.API = this.dataService.API;
  }

  listSearchSubscription(email: string) {
    return this.http.get(`${this.API}/search-subscription/user/${email}`);
  }

  addSearchSubscription(email: string, query: any) {
    return this.http.post(`${this.API}/search-subscription/add`, {
      email,
      query
    });
  }

  removeSearchSubscription(email: string, query: any) {
    return this.http.post(`${this.API}/search-subscription/remove`, {
      email,
      query
    });
  }

  removeAllUserSearchSubscription(email: string, random: string) {
    return this.http.post(`${this.API}/search-subscription/remove-all-user`, {
      email,
      random
    });
  }

  checkSearchSubscription(email: string, query: any) {
    return this.http.post(`${this.API}/search-subscription/check`, {
      email,
      query
    });
  }
}

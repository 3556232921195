import {
  Component,
  OnInit,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';

import {
  ModalController,
} from '@ionic/angular';

import { LoginModalComponent } from '../modals/login-modal/login-modal.component';
import { User } from '../../model/user';
// import { BucketService } from '../../services/bucket.service';

@Component({
  selector: 'app-create-section',
  templateUrl: './create-section.component.html',
  styleUrls: ['./create-section.component.scss'],
})
export class CreateSectionComponent {

  @Input() lang: any;
  @Input() user: User;
  @Input() appLanguage: string;

  constructor(
    // private bucketService: BucketService,
    private modalCtrl: ModalController,
    private router: Router,
  ) {
  }

  createNewAd() {
    if (this.user) {
      this.router.navigate(
        ['/n'],
        { queryParams: { l: this.appLanguage === "en" ? "en" : null } }
      );
    } else {
      this.presentLoginModal();
    }
  }

  async presentLoginModal() {
    const modal = await this.modalCtrl.create({
      component: LoginModalComponent,
      componentProps: {
        updateList: (name) => {
          // this.updateList();
        },
        // item: item,
        lang: this.lang,
        appLanguage: this.appLanguage
      },
      cssClass: 'login-modal'
    });
    modal.present();
  }
}

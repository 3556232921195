import { Component, Input } from "@angular/core";
import { NavParams, PopoverController } from "@ionic/angular";
import { LoginService } from "../../../services/login.service";
import { DataService } from "../../../services/data.service";
import { LanguageService } from "../../../services/language.service";

// Popover Profile Component
@Component({
  templateUrl: "./profile-menu-popover.component.html",
  styleUrls: ["./profile-menu-popover.component.scss"],
})
export class PopoverProfileComponent {
  darkMode: boolean;
  lang: any;
  appLanguage: string;

  @Input() user: any;

  constructor(
    public popoverCtrl: PopoverController,
    private loginService: LoginService,
    public params: NavParams,
    private dataService: DataService,
    private languageService: LanguageService
  ) {
    this.dataService.darkMode$.subscribe((isDark) => {
      this.darkMode = isDark;
    });

    this.languageService.language$.subscribe((value) => {
      this.appLanguage = value;
      this.lang = this.languageService[value];
    });
  }

  onLangChange(language: string) {
    this.params.get("langChange")(language);
    this.popoverCtrl.dismiss();
  }

  toggleDarkMode(isDark: boolean) {
    this.dataService.checkToggle(isDark);
    this.popoverCtrl.dismiss();
  }

  logout() {
    this.loginService.logout();
    this.popoverCtrl.dismiss();
  }
}

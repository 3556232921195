import { Component, Input } from "@angular/core";

import { CurrentUser } from "../../model/current-user";

// Comment Component
@Component({
  selector: "app-privacy-policy",
  templateUrl: "./privacy-policy.component.html",
  styleUrls: ["./privacy-policy.component.scss"],
})
export class PrivacyPolicyComponent {
  @Input() currentUser: CurrentUser;
  @Input() appLanguage: string;
  @Input() lang: any;
  @Input() loggedIn = false;

  constructor() {}
}

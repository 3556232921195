import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

// Services
import { LoginService } from '../../../services/login.service';

// Popover component
@Component({
  templateUrl: './kebab-options-popover.component.html',
  styleUrls: ['./kebab-options-popover.component.scss']
})
export class KebabOptionsPopoverComponent implements OnInit {

  lang: any;
  // comment: any; // TODO: itemId
  currentUser: any;
  itemOwnerId: string;

  constructor(
    private popoverCtrl: PopoverController,
    public params: NavParams,
    private loginService: LoginService
  ) {
    this.lang = params.get('lang');
    // this.comment = params.get('comment');
    // console.log(this.comment);
    this.itemOwnerId = params.get('itemOwnerId');
  }

  ngOnInit() {
    this.currentUser = this.loginService.currentUser();
    // console.log(this.user);
  }

  flag() {
    this.params.get('flagComment')();
    this.popoverCtrl.dismiss();
  }

  edit() {
    this.params.get('editComment')();
    this.popoverCtrl.dismiss();
  }

  delete() {
    this.params.get('deleteComment')();
    this.popoverCtrl.dismiss();
  }
}

<ion-header class="shrinkable">
  <app-main-header
    [mobileView]="mobileView"
    [title]="title"
    [currentUser]="currentUser"
  ></app-main-header>
</ion-header>

<ion-content *ngIf="ad" class="photos-component">
  <ion-grid>
    <ion-row>
      <ion-col>
        <div>
          <h3>{{ address }}</h3>
          <p>
            <small>Id: {{ ad._id }}</small>
          </p>
        </div>
      </ion-col>
    </ion-row>

    <ion-item-divider><b>1.</b>&nbsp; {{ lang.addPhoto }} (max 12) </ion-item-divider>
    <ion-item class="image-upload" lines="none">
      <ion-label>
        <app-file-upload
          [adId]="ad._id"
          [userId]="currentUser._id"
          [path]="path"
          (uploaded)="listAdImages($event)"
        ></app-file-upload>
      </ion-label>
    </ion-item>

    <ion-item-divider>
      <b>2.</b>&nbsp; {{ lang.savedPhotos }}
    </ion-item-divider>
    <ion-item *ngIf="ad.photos && ad.photos.length === 0">
      {{ lang.noPhotosSavedYet }}
    </ion-item>
    <ion-item class="saved-images">
      <ion-label>
        <ion-row *ngIf="ad.photos && ad.photos.length > 0">
          <ion-col
            *ngFor="let item of ad.photos; let i = index"
            class="uploaded-image"
            size="12"
            size-sm="6"
            size-md="3"
            size-lg="2"
          >
            <div class="image-container">
              <img [src]="API + '/files/image/' + item._id" />
            </div>
            <p>
              {{ lang.photo }} {{ i + 1 }}
              <a (click)="deleteImage(item._id)">{{ lang.delete }}</a>
            </p>
          </ion-col>
        </ion-row>
      </ion-label>
    </ion-item>

    <ion-item>
      <ion-label>
        <ion-row *ngIf="currentUser" class="edit-buttons">
          <a *ngIf="ad && ad._id && !ad.adExpired && ad.photos && ad.photos.length > 0"
             class="preview-link"
             target="_blank"
             (click)="$event.stopPropagation()"
             [href]="'/ad/' + ad._id">
            <ion-button fill="clear" color="secondary">
              <ion-icon slot="start" name="open"></ion-icon>
              {{ lang.preview }}
            </ion-button>
          </a>
          <ion-button *ngIf="ad"
                      color="secondary" fill="clear" size="small"
                      [routerLink]="['/ed', ad._id]">
            <ion-icon slot="start" name="hammer"></ion-icon>
            {{ lang.edit }} {{ lang.ad}}
          </ion-button>
          <ion-button fill="clear" size="small" color="primary"
                      [routerLink]="['/p', currentUser._id]">
            <ion-icon slot="start" name="exit-outline"></ion-icon>
            {{ lang.myDashboard }}
          </ion-button>
        </ion-row>
      </ion-label>
    </ion-item>
  </ion-grid>
</ion-content>

<!-- agency photo form -->
<ion-content *ngIf="agency" class="photos-component">
  <ion-grid>
    <ion-row>
      <ion-col>
        <div>
          <h3>{{ agency.companyName }}</h3>
          <p>{{ agency.address }}</p>
        </div>
      </ion-col>
    </ion-row>

    <ion-item-divider> Upload cover photo </ion-item-divider>
    <ion-item class="image-upload" lines="none">
      <ion-label>
        <app-file-upload
          [adId]="agency._id"
          [userId]="currentUser._id"
          [path]="path + '-cover'"
          (uploaded)="listAdImages($event)"
        ></app-file-upload>
      </ion-label>
    </ion-item>

    <ion-item-divider>
      Saved cover photo
    </ion-item-divider>
    <ion-item *ngIf="!agency.coverPhoto">
      {{ lang.noPhotosSavedYet }}
    </ion-item>
    <ion-item class="saved-images" lines="none">
      <ion-label>
        <ion-row *ngIf="agency.coverPhoto">
          <ion-col class="uploaded-image"
                   size="12" size-sm="6" size-md="3" size-lg="2">
            <div class="image-container">
              <img [src]="API + '/files/agency-cover/' + agency.coverPhoto" />
            </div>
            <p>
              <a (click)="deleteImage(agency.coverPhoto)">{{ lang.delete }}</a>
            </p>
          </ion-col>
        </ion-row>
      </ion-label>
    </ion-item>

    <ion-item-divider> Upload logo </ion-item-divider>
    <ion-item class="image-upload" lines="none">
      <ion-label>
        <app-file-upload
          [adId]="agency._id"
          [userId]="currentUser._id"
          [path]="path + '-logo'"
          (uploaded)="listAdImages($event)"
        ></app-file-upload>
      </ion-label>
    </ion-item>

    <ion-item-divider>
      Saved logo
    </ion-item-divider>
    <ion-item *ngIf="!agency.logo">
      {{ lang.noPhotosSavedYet }}
    </ion-item>
    <ion-item class="saved-images" lines="none">
      <ion-label>
        <ion-row *ngIf="agency.logo">
          <ion-col
            class="uploaded-image"
            size="12"
            size-sm="6"
            size-md="3"
            size-lg="2"
          >
            <div class="image-container">
              <img [src]="API + '/files/agency-logo/' + agency.logo" />
            </div>
            <p>
              <a (click)="deleteImage(agency.logo)">{{ lang.delete }}</a>
            </p>
          </ion-col>
        </ion-row>
      </ion-label>
    </ion-item>

    <ion-item>
      <ion-label>
        <ion-row *ngIf="currentUser" class="edit-buttons">
          <a
            *ngIf="agency"
            class="preview-link"
            target="_blank"
            (click)="$event.stopPropagation()"
            [href]="'/re/' + agency._id">
            <ion-button fill="clear" color="secondary">
              <ion-icon slot="start" name="open"></ion-icon>
              {{ lang.preview }}
            </ion-button>
          </a>
          <ion-button
            fill="clear"
            size="small"
            color="secondary"
            [routerLink]="['/re-edit', agency._id]"
          >
            <ion-icon slot="start" name="hammer"></ion-icon>
            {{ lang.edit }}
          </ion-button>
          <ion-button
            size="small"
            fill="clear"
            color="danger"
            (click)="removeAgencyAlertOpen(agency)"
          >
            <ion-icon slot="start" name="trash"></ion-icon>
            {{ lang.delete }}
          </ion-button>
          <ion-button
            fill="clear"
            size="small"
            color="primary"
            [routerLink]="['/p', currentUser._id]"
          >
            <ion-icon slot="start" name="exit-outline"></ion-icon>
            {{ lang.myDashboard }}
          </ion-button>
        </ion-row>
      </ion-label>
    </ion-item>
  </ion-grid>
</ion-content>

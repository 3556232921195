<ion-grid>
  <ion-row>
    <ion-col size="12">
      <div class="container about-us-text">
        <ng-container *ngIf="appLanguage === 'sl'">
          <h1>Smernice za naročilo oglaševanja</h1>

          <h1>1. Področje uporabe teh smernic</h1>

          <p>
            Naslednja določila urejajo pogodbeno razmerje med strokovnjaki
            (poslovne stranke), in kvadrat.si (v nadaljevanju »kvadrat«) v zvezi
            s plačanimi reklamnimi naročili in služijo kot dopolnitev
            kvadratovih splošnih »Smernic za plačljive storitve«, razen če se
            stranki pisno dogovorita drugače.
          </p>
          <p>
            Oglasno naročilo je plačana zahteva poslovnih strank za umestitev
            enega oz več oglasnih gradiv na nepremičninskem portalu kvadrat,
            bodisi prek spletne strani www.kvadrat.si, mobilne aplikacije
            kvadrat ali druge publikacije.
          </p>

          <h1>2. Dodelitev, sprememba in prekinitev/cena</h1>

          <p>
            Stranke morajo potrditi dodelitev, potrditev, spremembo in začasno
            prekinitev oglasnega naročila v pisni obliki (poslovni
            naročnik/kvadrat), pri čemer zadostuje elektronska pošta za
            izpolnitev zahteve.
          </p>
          <p>
            V primerih, ko naročila ne izda neposredno poslovna stranka, temveč
            preko oglaševalske agencije – vendar brez predložitve pisne izjave
            poslovne stranke, pravno zavezujoče pooblastilo oglaševalske
            agencije, da deluje v njenem imenu – se pogodba s kvadratom sklene
            neposredno z ustrezno oglaševalsko agencijo v odsotnosti kakršnega
            koli drugega pisnega dogovora.
          </p>
          <p>
            Poslovne stranke lahko spremenijo ali prekinejo naročila oglaševanja
            do pet delovnih dni pred načrtovanim datumom objave brez dodatnih
            stroškov. Premiki na načrtovane datume objave oglasov, ki jih
            zahtevajo podjetja stranke so odvisne od razpoložljive zmogljivosti
            kvadrata. kvadrat ni dolžan odobriti prošnje za odlog.
          </p>
          <p>
            Za naročila oglaševanja veljajo cene in pogoji, ki jih vsebujejo
            medijski podatki, ki jih na spletu objavlja kvadrat — v vsakem
            primeru so veljavne cene in pogoji, ki so vidni na spletu v
            trenutku, ko je naročilo oglaševanja pisno potrjeno s kvadratom. Vse
            objavljene cene so brez zakonsko določenega DDV.
          </p>

          <h1>3. Najmanjši obseg rezervacij</h1>
          <p>
            Vse minimalne količine rezervacij, ki so potrebne za sprejem
            oglasnih naročil, so izrecno določene s kvadratom preko objavljenih
            podatkov. V odsotnosti take indikacije, minimalni obseg rezervacije
            ne obstaja.
          </p>

          <h1>4. Zahteve za umestitev, specifikacije umestitve (»ciljanje«)</h1>
          <p>
            kvadrat sprejema prošnje za umestitev poslovnih strank. Vendar brez
            kvadratovega izrecnega pisnega dogovora, takšne zahteve in
            specifikacije za umestitev niso zavezujoče za kvadrat. kvadrat si bo
            prizadeval izpolnjevati zahtevke svojih poslovnih strank v kolikor
            je to mogoče brez dodatnih stroškov.
          </p>
          <p>
            Za vse specifikacije umestitve, ki so jih predložile poslovne
            stranke in pisno potrdi kvadrat, kvadrat obračuna doplačilo. Če
            zaradi tehničnih razlogov kvadrat ne more izpolniti zahteve za
            umestitev, ki jo je že pisno potrdil, poslovno stranko vnaprej
            obvesti (kjer je mogoče) in kvadrat prekliče doplačilo za
            neizpolnjeno zahtevo za umestitev.
          </p>
          <p>
            kvadrat je izrecno upravičen, brez predhodnega posvetovanja z
            zadevnim podjetjem stranke, da objavo oglasnih naročil v kratkem
            času odloži za čas objektivno ugotovljenih tehničnih razlogov.
          </p>

          <p>
            Neobjava reklamnih materialov, njihova umestitev na drugo lokacijo
            ali na drugačen datum ali njihova zamuda pri dostavi zaradi
            tehničnih težav ne upravičuje poslovne stranke, da uveljavlja
            morebitne odškodninske zahtevke.
          </p>

          <p>
            Načeloma kvadrat ne more izključiti oglaševalskih naročil poslovnih
            strank, ki so konkurenti v zadevni panogi. Kvadrat si pridržuje
            pravico, da s pomočjo individualnih pogodb s poslovnimi strankami,
            lahko kratkoročno obdobje nudi ekskluzivnost po dogovoru proti
            sorazmerni pristojbini.
          </p>

          <h1>5. Zagotovitev reklamnega materiala s strani poslovnih strank</h1>

          <p>
            Poslovni uporabniki so odgovorni za pravočasno in popolno dostavo
            brezhibnih in primernih oglasnih materialov (na podlagi citata iz
            kvadrata v skladu z medijskimi podatki) na kvadrat najkasneje tri
            delovne dni pred načrtovanim datumom objave.
          </p>
          <p>
            Kadar je poslovna stranka zahtevala posebne oblike oglaševanja, je
            rok za dostavo 10 delovnih dni pred objavo, razen če pisni dogovor s
            kvadratom določa drugače. kvadrat bo od poslovne stranke zahteval
            zamenjavo morebitnih očitno neprimernih ali poškodovanih reklamnih
            materialov. V primeru neskladnosti, zlasti pozne dostave ali
            naknadnih sprememb, za dogovorjeno distribucijo oglaševanja ne velja
            nobeno jamstvo.
          </p>

          <p>
            Poslovna stranka mora zagotoviti, da oglasni material ne vsebuje
            zlonamerne programske opreme. V nasprotnem primeru je poslovni
            odjemalec odgovoren kvadratu za vso škodo, ki bi nastala iz
            katerekoli zlonamerne programske opreme, uvedena prek oglaševalskega
            materiala. V primeru, da poslovni naročnik zahteva zamenjavo ali
            spremembo oglasa peti dan po izteku omenjenega roka, bo kvadrat
            kljub temu preučil možnost pravočasne objave novega gradiva, vendar
            poslovna stranka ne more imeti pravnega zahtevka za takšno izvedbo.
          </p>

          <h1>6. Objava reklamnega materiala</h1>

          <p>
            kvadrat si pridržuje pravico zahtevati, da poslovne stranke
            spremenijo svoje gradivo za oglaševanje, ter zavrniti objavo ali
            začasno ustaviti oglase brez posredovanja razlogov. Poslovne stranke
            dajejo kvadratu soglasje (do preklica) za objavo in obdelavo
            reklamnega gradiva, ki je bil objavljen na spletnih straneh slednjih
            ali tretjih oseb.
          </p>

          <p>
            Poslovne stranke so odgovorne za vsebino svojega oglasnega
            materiala. Dolžni so spoštovati ustrezne zakonske določbe in se
            vzdržati kršitev pravic tretjih oseb, kot tudi za odškodnino
            kvadrata, njegovih organov in zastopnikov proti kakršnimkoli
            zahtevkom, ki jih uveljavljajo tretje osebe, kolikor je pravno
            mogoče. V primeru, da se kvadrat preganja, posamezna poslovna
            stranka posreduje v tožbo ko je bilo izdano obvestilo tretje osebe.
          </p>

          <p>
            Vsekakor pa vse morebitne stroške nosi poslovna stranka, ki jih ima
            kvadrat v zvezi s terjatvami tretjih oseb ali z drugimi pravnimi
            postopki, pa tudi morebitne izvensodne stroške.
          </p>

          <p>
            Oglasi morajo biti jasno prepoznavni kot taki in po svoji zasnovi in
            ​​pisavi se mora zlahka razlikovati od vsebinskih in uredniških
            razdelkov spletne strani. kvadrat si pridržuje pravico do dodatnega
            označevanja z dodajanjem naslovov "Oglas", "Oglaševanje" ali
            "Oglasni" kadarkoli. Logotip ali ime kvadrata, kot tudi njegove
            izdelke in spletno stran, se lahko uporablja samo v lastni poslovni
            stranki oglasnega materiala z izrecnim pisnim soglasjem kvadrata.
          </p>

          <h1>7. Prekinitve, napake</h1>

          <p>
            kvadrat si bo prizadeval reproducirati reklamni material svojih
            poslovnih strank v skladu z uveljavljenimi tehničnimi standardi, ter
            za odpravo vseh motenj, napak in pomanjkljivosti čim hitreje.
          </p>

          <h1>8. Garancija/odgovornost</h1>

          <p>
            Poslovni kupci se zavedajo tudi pri opremi, ki temelji na sedanjem
            tehnološkem stanju, da ni mogoče zagotoviti razpoložljivosti sistema
            brez napak ves čas. kvadrat torej ne zagotavlja niti
            razpoložljivosti niti odsotnosti napak, pomanjkljivosti ali
            prekinitev. kvadrat ne prevzema odgovornosti za napake, ki vplivajo
            na prenos oglasnih naročil, zlasti njihova sprememba ali prekinitev.
          </p>

          <p>
            Načeloma je kvadrat izključen iz vsake odgovornosti, kolikor je to
            zakonsko možno. V vsakem primeru je odgovornost omejena tudi na
            neposredno škodo, pri čemer je najvišji znesek povračilo enako
            znesku plačila, ki ga je stranka plačala za ustrezno naročilo
            oglaševanja. Poleg tega se ne prevzema nobene odgovornosti za čisto
            nematerialno pomanjkljivost (npr. kadar ni niti pomen niti
            oglaševalski učinek oglasnega materiala bistveno oslabljen, npr.
            reprodukcija oglasa z nečitljivo majhnim besedilom ali prekinjena
            povezava v oglasu), ali za napake, ki so posledica uporabe
            neprimerne programsko/strojne opreme uporabnika (npr. brskalnik) ali
            ki jih povzročijo tretje osebe ali založnik zaradi okvare
            računalnika ali programske opreme, ki je posledica okvare sistema
            ali linije.
          </p>

          <p>
            Če je napako povzročil oglasni strežnik in se razteza v daljšem
            časovnem obdobju (več kot 10 % trajanja časovno omejene rezervacije,
            ki jo opravi poslovna stranka), kvadrat izvede naknadno izvedbo
            medijske storitve ali podaljša obdobje, v kolikor je to v interesu
            poslovne stranke.
          </p>

          <p>
            Če okvara oglasnega strežnika nima vpliva na prikaze oglasov, torej
            če je kljub temu dosežen, naknadno izvajanje ali podaljšanje
            storitve ne kvadrat ponuja.
          </p>

          <p>
            V primeru neuspeha naknadne izvedbe v okviru prvotno rezerviranega
            oz. podaljšanega obdobja oglaševanja, se poslovni stranki ne
            zaračunajo storitve, ki niso bile opravljene ali so bile opravljene
            le delno. Nadaljnji zahtevki so tudi izključeni v tem primeru.
            Zlasti kvadrat ne prevzema odgovornosti za reklamne materiale, ki
            vsebujejo napake zaradi manjkajoče ali neprimerne predloge za
            publikacije (mrežni vzorec je prefin, črte pretanke, tudi majhna
            pisava itd.), ali za odstopanja, ki jih povzročajo tehnični pogoji
            založniškega postopka (npr. barvna odstopanja).
          </p>

          <h1>9. Obvestilo o napakah</h1>

          <p>
            V vsakem primeru mora poslovni naročnik pregledati objavljeno
            reklamno gradivo takoj po prvi objavi in ​​kvadrat takoj obvesti o
            morebitnih napakah. V primeru očitnih napak začne teči reklamacijski
            rok z objavo ustreznega oglasnega materiala in se konča najkasneje
            po izvedbi 10 % rezerviranega obdobja storitev. Če poslovni
            odjemalec ne obvesti kvadrat glede reklamacije pravočasno, je
            prejšnjega odobritev izpolnitve reklamnega naročila implicirana.
            Pritožbe, ki jih je vložila poslovna stranka v zvezi z računi, ki
            jih izda kvadrat, se sprejemajo le v roku 10 dni od datuma računa.
          </p>

          <h1>10. Mere, popusti</h1>

          <p>
            Izračun cene oglasnega materiala temelji na objavljenih podatkih. V
            obsegu da se uporabljajo meritve (npr. »prikazi oglasov«), poslovni
            uporabnik s tem sprejema, da te temeljijo na meritvah in odčitkih
            števcev, ki jih zagotavlja kvadrat. Veljajo diskontne stopnje,
            objavljene v medijih, razen če ni drugače pisno dogovorjeno.
          </p>

          <h1>11. Plačilni pogoji</h1>

          <p>
            Če ni drugače dogovorjeno, se računi plačajo brez gotovinskega
            popusta in v roku 30 dni od datuma računa. Neodobreni odbitki bodo
            obračunani naknadno.
          </p>

          <p>
            V primeru zamude pri plačilu se obračuna dodatna pristojbina za
            zamude v višini 30,00 EUR s strani kvadrata, ko je izdan drugi
            opomin.
          </p>
          <p>
            V primeru izterjave dolgov, odloga plačila ali stečaja, se vsi
            popusti in odbitki provizije odvzamejo. Morebitne predhodno
            izplačane provizije agentov se razveljavijo. Poleg tega 5 % skupne
            vsote terjatev (najmanj 50,00 EUR) kvadrat zadrži kot nadomestilo za
            nevšečnosti.
          </p>

          <p>
            kvadrat si pridržuje pravico, da kadarkoli preveri kreditno
            sposobnost svojih poslovnih strank.
          </p>

          <h1>12. Spremembe reklamnega gradiva</h1>

          <p>
            Kvadrat lahko kadarkoli spremeni obliko oglaševanja, ki je bila
            izdelana za določeno objavo, ali odstrani oglaševalske formate iz
            svojega obsega storitev v celoti ali delno. V primeru oglaševanja
            formatov, ki so že bili rezervirani, in so med načrtovano
            vstavitvijo bistveno spremenjeni ali ukinjeni, poslovni naročnik
            prejme dobropis za storitve, ki v času uveljavitve nove uredbe še
            niso bile izvedene. Ta dobropis se lahko uporablja za neposredno
            rezervacijo spremenjenih (ali drugih) oglaševalskih formatov.
          </p>

          <h1>13. Pogoji poslovne stranke so s tem izključeni</h1>

          <p>
            Vsi splošni pogoji poslovanja (»SPP«) poslovne stranke so tukaj
            izključeni, tudi v primerih, ko se poslovna stranka sklicuje na
            lastne SPP in kvadrat ni izrecno v nasprotju s splošnimi pogoji
            poslovanja poslovne stranke.
          </p>

          <h1>14. Predčasna odpoved pogodbe</h1>

          <p>
            V primeru, da kvadrat med pogodbenim obdobjem ukine obliko
            oglaševanja, je kvadrat upravičen do odstopa od pogodbe brez kakršne
            koli obveznosti za nadomestno pogodbo. Predčasna odpoved pogodbe s
            strani poslovnega odjemalca ga ne razbremeni od plačilnih obveznosti
            v zvezi z objavljenim oglasnim materialom. V takih primerih se
            dodatni popusti ne uporabljajo. Vendar se plačila izvedejo v
            primerih, ko je bila v času prekinitve pogodbe dosežena višja
            stopnja popusta.
          </p>

          <h1>15. Končne določbe</h1>

          <p>
            V vseh primerih imajo kvadratovi SPU in njegove »Smernice za
            plačljive storitve« prednost nad temi smernicami.
          </p>

          <p>Avtorske pravice kvadrat</p>

          <p>Smernice za naročila oglaševanja z dne 12. 06. 2024</p>
        </ng-container>

        <!-- English -->
        <ng-container *ngIf="appLanguage === 'en'">
          <h1>Guidelines for Advertising</h1>

          <h1>1. Scope of these guidelines</h1>

          <p>
            The following provisions govern the contractual relationship between
            professional commercial customers (hereinafter "business customers")
            and kvadrat.si (hereinafter "kvadrat") regarding paid advertising
            orders, and serve as a supplement to kvadrat's general "Guidelines
            for Paid Services", unless otherwise agreed in writing between the
            parties.
          </p>
          <p>
            An advertising order is a paid request by business customers for the
            placement of one or more advertising materials on kvadrat's property
            portal, whether via the website www.kvadrat.si, the kvadrat mobile
            app or other publications, etc.
          </p>

          <h1>2. Assignment, modification and suspension / pricing</h1>

          <p>
            The parties must confirm the assignment, confirmation, modification
            and suspension of advertising orders in writing (business
            customer/kvadrat), whereby an email is sufficient to meet this
            requirement.
          </p>
          <p>
            In cases where orders are not issued directly by the business
            customer, but instead via an advertising agency — however without
            submission of the business customer's written, legally-binding
            authorisation for the advertising agency to act on its behalf — the
            contract with kvadrat shall be concluded directly with the
            respective advertising agency in the absence of any other written
            agreement.
          </p>
          <p>
            Business customers may modify or suspend advertising orders up to
            five working days before their planned publication date without
            incurring additional fees.
          </p>
          <p>
            Postponements to advertisements' planned publication dates that are
            requested by business customers are subject to kvadrat's available
            capacity. kvadrat is not obliged to approve such requests for
            postponement.
          </p>
          <p>
            Advertising orders are subject to the prices and conditions
            contained in the media data that is published online by kvadrat — in
            each case, the respective prices and conditions that are visible
            online at the time that the advertising order is confirmed by
            kvadrat in writing shall apply. All published prices exclude
            statutory VAT.
          </p>

          <h1>3. Minimum booking volume</h1>
          <p>
            Any minimum booking volumes that are required for the acceptance of
            advertising orders are expressly stipulated by kvadrat via the
            published media data. In the absence of such an indication, no
            minimum booking volume exists.
          </p>

          <h1>4. Placement requests, placement specifications ("targeting")</h1>

          <p>
            kvadrat accepts placement requests submitted by business customers.
            However, without kvadrat's express written agreement, such placement
            requests and specifications are not binding for kvadrat.
          </p>
          <p>
            kvadrat shall endeavour to fulfil the placement requests submitted
            by its business customers to the extent that this is possible
            without additional expense. For all placement specifications that
            have been submitted by business customers and confirmed by kvadrat
            in writing, kvadrat shall levy a surcharge. If, for technical
            reasons, kvadrat is not able to honour a placement request that it
            has already confirmed in writing, the business customer shall be
            informed in advance (where possible) and kvadrat shall cancel the
            surcharge for the non-fulfilled placement request.
          </p>

          <p>
            kvadrat is expressly entitled, without prior consultation with the
            respective business customer, to postpone the publication of
            advertising orders at short notice for objectively determined
            technical reasons.
          </p>

          <p>
            The non-appearance of advertising materials, their placement at
            another location or on a different date, or their delayed delivery
            due to technical problems, shall not entitle the business customer
            to assert any claims for compensation.
          </p>

          <p>
            In principle, kvadrat is not able to exclude advertising orders from
            its business customers' competitors within the respective industry.
            This is subject to the proviso that, by means of individual
            agreements with business customers, a short-term period of
            exclusivity may be negotiable against a commensurate fee.
          </p>

          <h1>5. Delivery of advertising material by business customers</h1>

          <p>
            Business customers are responsible for the timely and complete
            delivery of defect-free and suitable advertising material (based on
            the quotation from kvadrat in accordance with the media data) to
            kvadrat at the latest three working days before the planned
            publication date.
          </p>
          <p>
            Where special forms of advertising have been requested by the
            business customer, the delivery deadline is 10 working days before
            publication, unless an individual written agreement with kvadrat
            stipulates otherwise.
          </p>

          <p>
            kvadrat will require the business customer to replace any clearly
            unsuitable or damaged advertising material. In the event of
            non-compliance, in particular late delivery or subsequent
            alterations, no guarantee shall be provided for the agreed
            distribution of the advertising material.
          </p>

          <p>
            The business customer shall ensure that the advertising material is
            free from any malware. Otherwise, the business customer shall be
            liable to kvadrat for all damages arising from any malware
            introduced via the advertising material.
          </p>

          <p>
            In the event that the business customer requests replacement or
            modification of the advertising material after the expiry of the
            aforementioned deadline, kvadrat shall nevertheless examine the
            possibility of publishing the new material in a timely manner,
            however the business customer shall have no legal claim to such
            performance.
          </p>

          <h1>6. Publication of advertising material</h1>

          <p>
            kvadrat reserves the right to request that business customers modify
            their advertising materials, and also to refuse to publish or
            suspend advertisements without providing reasons. Business customers
            hereby give their consent (until it is withdrawn) for kvadrat to
            publish the formers' advertising material on the latter's own or
            third-party online services and to process it for this purpose.
          </p>

          <p>
            Business customers are responsible for the content of their
            advertising material. They are obligated to comply with the relevant
            statutory provisions and to refrain from infringing any rights of
            third parties, as well as to indemnify kvadrat, its organs and
            vicarious agents against any claims asserted by third parties as far
            as legally possible. In the event that kvadrat is prosecuted, the
            respective business customer shall intervene in the legal action
            once a third-party notice has been issued.
          </p>

          <p>
            In any case, the business customer shall bear all costs incurred by
            kvadrat in connection with claims asserted by third parties, or with
            other legal procedures, as well as any extrajudicial costs.
          </p>

          <p>
            Advertisements must be clearly recognisable as such and, via their
            design and the fonts used, must be easily distinguishable from the
            content-based and editorial sections of the websites. kvadrat
            reserves the right to carry out additional labelling by adding the
            headings "Advertisement", "Advertising" or "Advertorial" at any
            time. The logo or the name of kvadrat, as well as its products and
            website, may only be used in the business customer's own advertising
            material with the express written consent of kvadrat.
          </p>

          <h1>7. Interruptions, faults</h1>

          <p>
            kvadrat shall endeavour to reproduce its business customers'
            advertising material in accordance with the established technical
            standards, and to remedy all interruptions, faults and deficiencies
            as quickly as possible.
          </p>

          <h1>8. Guarantee/liability</h1>

          <p>
            Business customers are aware that, even with equipment that is based
            on the current technological state of the art, it is not possible to
            ensure system availability and error-free reproduction at all times.
            kvadrat therefore guarantees neither availability nor the absence of
            faults, deficiencies or interruptions. kvadrat assumes no liability
            for errors that affect the transmission of advertising orders, in
            particular their modification or suspension.
          </p>

          <p>
            In principle, kvadrat shall be excluded from all liability, insofar
            as this is legally possible. In any case, liability shall also be
            limited to direct damages, whereby the maximum amount of the
            reimbursement shall equal the amount of the remuneration paid by the
            client for the respective advertising order. In addition, no
            liability shall be assumed for purely immaterial defects (e.g. where
            neither the meaning nor the advertising effect of the advertising
            material is significantly impaired, e.g. reproduction of the
            advertisement with illegibly small text, or a broken link in the
            advertisement), or for defects that are due to the use of
            non-suitable software/hardware by the user (e.g. browser), or which
            are caused by third parties or the publisher due to computer or
            software failure resulting from system or line failure.
          </p>

          <p>
            Where a failure has been caused by the ad server and extends over a
            significant period (more than 10% of the duration of a time-limited
            booking made by the business customer), kvadrat shall carry out
            subsequent performance of the media service or extend the insertion
            period, insofar as this is in the business customer's interest.
          </p>

          <p>
            If the failure of the ad server has no influence on the ad
            impressions, i.e. if these are nevertheless achieved, no subsequent
            performance or extension of the service shall be offered by kvadrat.
          </p>

          <p>
            In the event of the failure of the subsequent performance within the
            originally booked or extended insertion period, the business
            customer shall not be charged for the media services that were not
            delivered or only partially delivered. Further claims are also
            excluded in this case.
          </p>

          <p>
            In particular, kvadrat accepts no liability for advertising
            materials that contain defects due to missing or unsuitable
            publication templates (grid pattern too fine, lines too thin, fonts
            too small, etc.), or for deviations caused by the technical
            conditions of the publishing process (e.g. colour deviations).
          </p>

          <h1>9. Notice of defects</h1>

          <p>
            In each case, the business customer must review the published
            advertising material immediately after its initial publication and
            immediately notify kvadrat of any defects. In the case of obvious
            defects, the complaint period begins with the publication of the
            relevant advertising material, and ends at the latest after
            performance of 10% of the booked media service. If the business
            customer fails to notify kvadrat of a complaint on time, the
            former's approval of the fulfilment of the advertising order shall
            be implied. Complaints filed by business customers regarding
            invoices issued by kvadrat shall only be accepted within 10 days of
            the invoice date.
          </p>

          <h1>10. Measurements, discounts</h1>

          <p>
            The price calculation for the advertising material is based on the
            media data. To the extent that measurements (e.g. of "ad
            impressions") are used, the business customer hereby accepts that
            these shall be based on the measurements and counter readings
            provided by kvadrat. The discount rates published in the media data
            shall apply, unless otherwise agreed in writing.
          </p>

          <h1>11. Payment terms</h1>

          <p>
            Unless otherwise agreed, the invoices shall be paid without cash
            discount and within 30 days of the invoice date. Unauthorised
            deductions will be subsequently requested.
          </p>

          <p>
            In the event of a default of payment, an additional arrears fee of
            EUR 30.00 will be charged by kvadrat when the second reminder notice
            is issued.
          </p>
          <p>
            In the event of debt recovery, deferred payment or bankruptcy, all
            discounts and agents' commission fees shall be forfeited. Any
            previously disbursed agents' commission fees shall be reclaimed. In
            addition, 5% of the total sum of the receivables (at least EUR
            50.00) shall be charged as compensation for the inconvenience.
          </p>

          <p>
            kvadrat reserves the right to check the creditworthiness of its
            business customers at any time.
          </p>

          <h1>12. Changes to the advertising material</h1>

          <p>
            At any time, kvadrat shall be entitled to modify the advertising
            format that was made available for the respective booking, as well
            as to remove advertising formats from its range of services in whole
            or in part. In the event that advertising formats, which have
            already been booked, are significantly modified or discontinued by
            kvadrat during the planned insertion period, the business customer
            shall receive a credit note for the media services which have not
            yet been delivered at the time of the new regulation's
            implementation. This credit note may be used for direct booking of
            the modified (or other) advertising formats.
          </p>

          <h1>
            13. The terms and conditions of the business customer are hereby
            excluded
          </h1>

          <p>
            Any general terms and conditions of business ("GTCs") of the
            business customer are hereby excluded by the parties, even in cases
            where the business customer refers to its own GTCs and kvadrat does
            not expressly contradict said GTCs of the business customer.
          </p>

          <h1>14. Premature contract termination</h1>

          <p>
            In the event that kvadrat discontinues an advertising format during
            the contract period, kvadrat shall be entitled to withdraw from the
            contract without any obligation to replace the contract. A premature
            termination of the contract does not relieve the business customer
            from its payment obligations in respect of the published advertising
            material. In such cases, no supplementary discount fees shall be
            applied. However, payments shall be made in cases where a higher
            discount level had been reached at the time of the contract's
            termination.
          </p>

          <h1>15. Final provisions</h1>

          <p>
            In all cases, kvadrat's GTCs and it's "Guidelines for Paid Services"
            shall take precedence over these guidelines.
          </p>

          <p>Copyright by kvadrat</p>

          <p>Guidelines for Advertising Orders as of 12th of june 2024</p>
        </ng-container>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { DataService } from "../services/data.service";

@Injectable()
export class MessageService {
  // Link to our api
  API: string;

  constructor(private http: HttpClient, private dataService: DataService) {
    this.API = this.dataService.API;
  }

  listUserMessages(userId: string) {
    const headers = new HttpHeaders().set("userId", userId);
    return this.http.get(`${this.API}/messages/user/${userId}`, {
      headers,
    });
  }

  sendMessage(
    from: string,
    message: string,
    to: string,
    adId = "",
    adTitle = ""
  ) {
    // console.log(ad, from, message, adTitle, to);
    return this.http.post(`${this.API}/messages/send`, {
      sendDate: new Date(),
      from,
      message,
      to,
      adId,
      adTitle,
    });
  }

  sendContactMessage(
    from: string,
    message: string,
    captcha: string,
    contactName = "",
    messageTitle = ""
  ) {
    // console.log(ad, from, message, messageTitle, to);
    return this.http.post(`${this.API}/messages/send-contact`, {
      from,
      message,
      sendDate: new Date(),
      contactName,
      captcha,
      messageTitle,
    });
  }

  removeConversation(userId: string, otherUserId: string) {
    const headers = new HttpHeaders().set("userId", userId);
    return this.http.post(`${this.API}/messages/conversation-remove`, {
      headers,
      userId,
      otherUserId,
    });
  }

  removeMessage(userId: string, messageId: string) {
    return this.http.post(`${this.API}/messages/message-remove`, {
      messageId,
      userId
    });
  }
}

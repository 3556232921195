<div class="image-slider-component">
  <swiper-container #swiper (slidechange)="slideChanged()" [ngClass]="{'slides-photo-preview': photoPreview}">
    <swiper-slide *ngFor="let image of images; let i = index" (click)="$event.preventDefault();$event.stopPropagation()">
      <img *ngIf="photoPreview" [src]="image" class="preview-slide-image" />
      <div *ngIf="!photoPreview" class="slide-image"
           [ngStyle]="{'background-image': 'url('+ image + ')','height': imgHeight + 'px'}">
      </div>
      <!-- <h2 class="slide-title" [innerHTML]="slide.title"></h2>
      <p [innerHTML]="slide.description"></p> -->
    </swiper-slide>
  </swiper-container>
  <div class="slide-number">{{ slideIndex }} / {{ images.length }}</div>
  <div *ngIf="images.length > 1" class="slide slide-left" (click)="slidePrev();$event.preventDefault();$event.stopPropagation()">
    <div class="arrow arrow-left"></div>
  </div>
  <div *ngIf="images.length > 1" class="slide slide-right" (click)="slideNext();$event.preventDefault();$event.stopPropagation()">
    <div class="arrow arrow-right"></div>
  </div>
</div>

<form action="">
  <ion-grid>
    <ion-row *ngIf="formType === 'short'">
      <ion-col size="12" size-sm="7">
        <!-- <ng2-completer
          name="searchStr"
          (selected)="onSelectedLocation($event)"
          [(ngModel)]="searchStr"
          (keyup)="location = ''; locType = ''"
          [datasource]="dataRemote"
          [inputId]="'_id'"
          [maxChars]="24"
          inputClass="autocomplete-field"
          [textNoResults]="lang.noResults + '.'"
          [textSearching]="lang.searching + '...'"
          [placeholder]="lang.locationCityZip + '...'"
          [minSearchLength]="3">
        </ng2-completer> -->
        <ion-input [value]="searchStr"
                   fill="outline"
                   aria-label="Search"
                   [clearInput]="true"
                   class="search-control"
                   (ionInput)="updateSearchStr($event)"
                   (ionClear)="clearSearch()"
                   (ionFocus)="searchResultsEnabled = true"
                   (ionBlur)="searchFocusOut()"
                   [placeholder]="lang.locationCityZip + '...'">
        </ion-input>
        <ion-list class="search-results" *ngIf="searchResults.length > 0 && searchResultsEnabled">
          <ng-container *ngFor="let searchResult of searchResults">
            <ion-item (click)="onSelectedLocation(searchResult)" class="dropdown-item">
              <ion-text>{{ searchResult.title }}, {{ searchResult.description }}</ion-text>
            </ion-item>
          </ng-container>
        </ion-list>
      </ion-col>

      <ion-col size="12" size-sm="5" class="property-type">
        <ion-radio-group name="adType" [(ngModel)]="adType">
          <ion-row>
            <ion-col size="6" class="short-radio">
              <ion-item>
                <ion-radio justify="start" labelPlacement="end" value="buy">
                  {{ lang?.buy }}</ion-radio>
              </ion-item>
            </ion-col>

            <ion-col size="6" class="short-radio">
              <ion-item>
                <ion-radio justify="start" labelPlacement="end" value="rent">
                  {{ lang?.forRent }}</ion-radio>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-radio-group>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="formType === 'short'">
      <ion-col size="12" size-sm="7">
        <ion-item class="property-type-select">
          <!-- 
            [(ngModel)]="adPropertyTypes"
            (ngModelChange)="setAdPropertyTypeObject()"
            [cancelText]="lang.cancel"
            [okText]="lang.ok"
           -->
          <ion-select
            (ionChange)="updatePropertyType($event)"
            [value]="adPropertyTypes"
            interface="popover"
            name="adPropertyTypes"
            [label]="null"
            aria-label="Property type"
            [multiple]="true">
            <ion-select-option value="house">{{ lang?.adPropertyType?.house }}</ion-select-option>
            <ion-select-option value="flat">{{ lang?.adPropertyType?.flat }}</ion-select-option>
            <ion-select-option value="apartment">{{ lang?.adPropertyType?.apartment }}</ion-select-option>
            <ion-select-option value="room">{{ lang?.adPropertyType?.room }}</ion-select-option>
            <ion-select-option value="weekend">{{ lang?.adPropertyType?.weekend }}</ion-select-option>
            <ion-select-option value="estate">{{ lang?.adPropertyType?.estate }}</ion-select-option>
            <ion-select-option value="commercial">{{ lang?.adPropertyType?.commercial }}</ion-select-option>
            <ion-select-option value="farm">{{ lang?.adPropertyType?.farm }}</ion-select-option>
            <ion-select-option value="parking">{{ lang?.adPropertyType?.parking }}</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col>

      <ion-col size="12" size-sm="5">
        <ion-button expand="block" (click)="sendRequest()" class="search-short">
          <ion-icon slot="start" name="search"></ion-icon>
          {{ lang?.search }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>

  <!-- <ion-grid> -->
  <div class="long-form">
    <ion-row *ngIf="formType === 'long'">
      <ion-col size="12">
        <h4>{{ lang.where }}</h4>
      </ion-col>

      <ion-col size="12">
        <ion-input [value]="searchStr"
                   fill="outline"
                   aria-label="Search"
                   [clearInput]="true"
                   class="search-control"
                   (ionInput)="updateSearchStr($event)"
                   (ionClear)="clearSearch()"
                   (ionFocus)="searchResultsEnabled = true"
                   (ionBlur)="searchFocusOut()"
                   [placeholder]="lang.locationCityZip + '...'">
        </ion-input>
        <ion-list class="search-results" *ngIf="searchResults.length > 0 && searchResultsEnabled">
          <ng-container *ngFor="let searchResult of searchResults">
            <ion-item (click)="onSelectedLocation(searchResult)" class="dropdown-item">
              <ion-text>{{ searchResult.title }}, {{ searchResult.description }}</ion-text>
            </ion-item>
          </ng-container>
        </ion-list>
      </ion-col>

      <ion-col size="12">
        <h4>{{ lang.offerType }}</h4>
      </ion-col>

      <ion-col size="12">
        <ion-radio-group name="adType" [(ngModel)]="adType">
          <ion-row>
            <ion-col size="6">
              <ion-item class="property-type-item">
                <ion-radio justify="start" labelPlacement="end" value="buy">
                  {{ lang.buy }}</ion-radio>
              </ion-item>
            </ion-col>

            <ion-col size="6">
              <ion-item class="property-type-item">
                <ion-radio justify="start" labelPlacement="end" value="rent">
                  {{ lang.forRent }}</ion-radio>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-radio-group>
      </ion-col>

      <ion-col size="12">
        <h4>{{ lang.typeLong }}</h4>
      </ion-col>

      <ion-col size="12">
        <ion-row>
          <ion-col size="6">
            <ion-item>
              <ion-checkbox
                justify="start"
                labelPlacement="end"
                name="house"
                [(ngModel)]="adPropertyTypeObject.house">
                {{ lang.adPropertyType.house }}
              </ion-checkbox>
            </ion-item>
            <ion-item>
              <ion-checkbox
                justify="start"
                labelPlacement="end"
                name="flat"
                [(ngModel)]="adPropertyTypeObject.flat">
                {{ lang.adPropertyType.flat }}
              </ion-checkbox>
            </ion-item>
            <ion-item>
              <ion-checkbox
                justify="start"
                labelPlacement="end"
                name="apartment"
                [(ngModel)]="adPropertyTypeObject.apartment">
                {{ lang.adPropertyType.apartment }}
              </ion-checkbox>
            </ion-item>
            <ion-item>
              <ion-checkbox
                justify="start"
                labelPlacement="end"
                name="room"
                [(ngModel)]="adPropertyTypeObject.room">
                {{ lang.adPropertyType.room }}
              </ion-checkbox>
            </ion-item>
            <ion-item>
              <ion-checkbox
                justify="start"
                labelPlacement="end"
                name="weekend"
                [(ngModel)]="adPropertyTypeObject.weekend">
                {{ lang.adPropertyType.weekend }}</ion-checkbox>
            </ion-item>
            <!-- <ion-item>
              <ion-checkbox
                justify="start"
                labelPlacement="end"
                name="duplex"
                [(ngModel)]="adPropertyTypeObject.duplex"
                >{{ lang.adPropertyType.duplex }}</ion-checkbox
              >
            </ion-item> -->
          </ion-col>
          <ion-col size="6">
            <ion-item>
              <ion-checkbox
                justify="start"
                labelPlacement="end"
                name="estate"
                [(ngModel)]="adPropertyTypeObject.estate">
                {{ lang.adPropertyType.estate }}
              </ion-checkbox>
            </ion-item>
            <ion-item>
              <ion-checkbox
                justify="start"
                labelPlacement="end"
                name="commercial"
                [(ngModel)]="adPropertyTypeObject.commercial">
                {{ lang.adPropertyType.commercial }}
                </ion-checkbox>
            </ion-item>
            <ion-item>
              <ion-checkbox
                justify="start"
                labelPlacement="end"
                name="farm"
                [(ngModel)]="adPropertyTypeObject.farm">
                {{ lang.adPropertyType.farm }}
              </ion-checkbox>
            </ion-item>
            <ion-item>
              <ion-checkbox
                justify="start"
                labelPlacement="end"
                name="parking"
                [(ngModel)]="adPropertyTypeObject.parking">
                {{ lang.adPropertyType.parking }}
              </ion-checkbox>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-col>

      <!-- (change)="updatePrice($event.target.value)" -->
      <ion-col size="12">
        <h4 *ngIf="adType === 'buy'">{{ lang.price }}</h4>
        <h4 *ngIf="adType === 'rent'">{{ lang.rent }}</h4>
      </ion-col>
      <ion-row>
        <ion-col size-md="6">
          <ion-item class="input-item">
            <ion-input
              type="number"
              name="priceFrom"
              placeholder="min €"
              [(ngModel)]="priceFrom"
            ></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size-md="6">
          <ion-item class="input-item">
            <ion-input
              type="number"
              name="priceTo"
              placeholder="max €"
              [(ngModel)]="priceTo"
            ></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>

      <ion-col size="12">
        <h4>{{ lang.roomNumber }}</h4>
      </ion-col>
      <ion-row>
        <ion-col size-md="6">
          <ion-item class="input-item">
            <ion-input
              type="number"
              name="roomsFrom"
              placeholder="min"
              [(ngModel)]="roomsFrom"
            ></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size-md="6">
          <ion-item class="input-item">
            <ion-input
              type="number"
              name="roomsTo"
              placeholder="max"
              [(ngModel)]="roomsTo"
            ></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>

      <ion-col size="12">
        <h4>{{ lang.livingSpace }}</h4>
      </ion-col>
      <ion-row>
        <ion-col size-md="6">
          <ion-item class="input-item">
            <ion-input
              type="number"
              name="adSizeFrom"
              placeholder="min m&#178;"
              [(ngModel)]="adSizeFrom"
            ></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size-md="6">
          <ion-item class="input-item">
            <ion-input
              type="number"
              name="adSizeTo"
              placeholder="max m&#178;"
              [(ngModel)]="adSizeTo"
            ></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>

      <ion-col size="12">
        <h4>{{ lang.amenitiesTranslation }}</h4>
      </ion-col>

      <ion-row>
        <ion-col>
          <ion-item>
            <ion-checkbox
              justify="start"
              labelPlacement="end"
              name="kitchenBasic"
              [(ngModel)]="amenities.kitchenBasic">
              {{ lang.amenities.kitchenBasic }}
            </ion-checkbox>
          </ion-item>
          <ion-item>
            <ion-checkbox
              justify="start"
              labelPlacement="end"
              name="internet"
              [(ngModel)]="amenities.internet">
              {{ lang.amenities.internet }}
            </ion-checkbox>
          </ion-item>
          <ion-item>
            <ion-checkbox
              justify="start"
              labelPlacement="end"
              name="cool"
              [(ngModel)]="amenities.ac">
              {{ lang.amenities.ac }}
            </ion-checkbox>
          </ion-item>
          <ion-item>
            <ion-checkbox
              justify="start"
              labelPlacement="end"
              name="lift"
              [(ngModel)]="amenities.lift">
              {{ lang.amenities.lift }}
            </ion-checkbox>
          </ion-item>
          <ion-item>
            <ion-checkbox
              justify="start"
              labelPlacement="end"
              name="disability"
              [(ngModel)]="amenities.disability">
              {{ lang.amenities.disability }}
            </ion-checkbox>
          </ion-item>
          <ion-item>
            <ion-checkbox
              justify="start"
              labelPlacement="end"
              name="animalsAllowed"
              [(ngModel)]="amenities.animalsAllowed">
              {{ lang.amenities.animalsAllowed }}
            </ion-checkbox>
          </ion-item>
          <ion-item>
            <ion-checkbox
              justify="start"
              labelPlacement="end"
              name="firstTime"
              [(ngModel)]="amenities.firstTime"
            >
              {{ lang.amenities.firstTime }}
            </ion-checkbox>
          </ion-item>
        </ion-col>
        <ion-col>
          <ion-item>
            <ion-checkbox
              justify="start"
              labelPlacement="end"
              name="balcony"
              [(ngModel)]="amenities.balcony">
              {{ lang.amenities.balcony }}
            </ion-checkbox>
          </ion-item>
          <ion-item>
            <ion-checkbox
              justify="start"
              labelPlacement="end"
              name="parking"
              [(ngModel)]="amenities.parking">
              {{ lang.amenities.parking }}
            </ion-checkbox>
          </ion-item>
          <ion-item>
            <ion-checkbox
              justify="start"
              labelPlacement="end"
              name="pool"
              [(ngModel)]="amenities.pool">
              {{ lang.amenities.pool }}
            </ion-checkbox>
          </ion-item>
          <ion-item>
            <ion-checkbox
              justify="start"
              labelPlacement="end"
              name="groundFloor"
              [(ngModel)]="amenities.groundFloor">
              {{ lang.amenities.groundFloor }}
            </ion-checkbox>
          </ion-item>
          <ion-item>
            <ion-checkbox
              justify="start"
              labelPlacement="end"
              name="feeFree"
              [(ngModel)]="amenities.feeFree">
              {{ lang.amenities.feeFree }}
            </ion-checkbox>
          </ion-item>
          <ion-item>
            <ion-checkbox
              justify="start"
              labelPlacement="end"
              name="garage"
              [(ngModel)]="amenities.garage">
              {{ lang.amenities.garage }}
            </ion-checkbox>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-col size="12" class="submit-button">
        <ion-button expand="block" (click)="sendRequest()">
          <ion-icon slot="start" name="search"></ion-icon>
          {{ lang.search }}
        </ion-button>
      </ion-col>
    </ion-row>
  </div>
  <!-- </ion-grid> -->
</form>

<ion-header class="shrinkable">
  <app-main-header
    [mobileView]="mobileView"
    [title]="title"
    [basePath]="'h'"
    [currentUser]="currentUser"
  ></app-main-header>
</ion-header>

<ion-content #homePage [fullscreen]="true" class="home-page" *ngIf="lang">
  <ion-grid>
    <ion-row class="jumbo" align-items-center>
      <ion-col>
        <h1>{{ lang.findYourNewHome }}</h1>
        <div class="search-box">
          <app-search-form
            [formType]="'short'"
            [basePath]="''">
          </app-search-form>
          <!-- 
            (updateAds)="goToAds($event)" -->
          <div class="advanced-search">
            <ion-row>
              <ion-col size="12">
                <a (click)="openSearchModal()">{{ lang.detailedSearch }}</a>
              </ion-col>
              <!-- <ion-col size="12" size-sm="6">
                <a>{{ lang.mapSearch }}</a>
              </ion-col> -->
            </ion-row>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>

  <section class="latest-properties">
    <div class="container">
      <ion-grid>
        <ion-row>
          <ion-col>
            <h1 color="primary">
              {{ lang.latestAds }}
            </h1>
            <app-latest-properties
              [lang]="lang"
              [appLanguage]="appLanguage"
              (latestAdsActive)="enableLatestAds($event)"> 
            </app-latest-properties>
            <div *ngIf="!latestAdsEnabled" class="no-items-message">
              <p>{{ lang.noItemsToShow }}</p>
              <p>{{ lang.beTheFirstSeller }}</p>
              <p>{{ lang.freeAdvertising }}</p>
              <p>
                <ion-button class="create-ad-button" (click)="createNewAd()">
                  {{ lang.createNewAd }}
                </ion-button>
              </p>
            </div>
            <p class="show-all" *ngIf="latestAdsEnabled">
              <a
                [routerLink]="['/r']"
                [queryParams]="{
                  propTypes:
                    'house,flat,apartment,room,weekend,estate,commercial,farm,parking',
                  l: appLanguage
                }"
              >
                {{ lang.showAll }}
              </a>
            </p>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </section>

  <section class="last-viewed" *ngIf="currentUser">
    <div class="container">
      <ion-grid>
        <ion-row>
          <ion-col>
            <h1 color="primary">
              {{ lang.lastViewedAds }}
            </h1>
            <app-last-viewed
              [lang]="lang"
              [currentUser]="currentUser"
              [appLanguage]="appLanguage"
            ></app-last-viewed>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </section>

  <section class="create-section">
    <div class="container">
      <app-create-section
        [lang]="lang"
        [user]="currentUser"
        [appLanguage]="appLanguage"
      >
      </app-create-section>
    </div>
  </section>

  <section class="links-section">
    <div class="container">
      <app-location-links
        [lang]="lang"
        [locations]="locations"
        [appLanguage]="appLanguage"
      >
      </app-location-links>
    </div>
  </section>

  <app-footer></app-footer>
</ion-content>

import { Component } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';

// viewMode Popover Component
@Component({
  templateUrl: './map-view-popover.component.html',
  styleUrls: ['./map-view-popover.component.scss']
})
export class MapViewMenuComponent {

  lang: any;
  mapView: string;

  constructor(
    private popoverCtrl: PopoverController,
    public params: NavParams
  ) {
    this.lang = params.get('lang');
    this.mapView = params.get('mapView');
  }

  onSetMapView(mapViewVal: string) {
    this.params.get('setMapViewMenu')(mapViewVal);
    this.popoverCtrl.dismiss();
  }
}

import { Injectable } from '@angular/core';
import { DataService } from './data.service';

declare var require: any;

@Injectable()
export class LeafletService {

  public L = null;

  constructor(
    private dataService: DataService,
  ) {
    this.dataService.isBrowser$.subscribe(isBrowser => {
      // console.log(isBrowser);
      if (isBrowser) {
        this.L = require('leaflet');
      }
    });
  }
}

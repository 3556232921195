import { Component, Input } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

// viewMode Popover Component
@Component({
  templateUrl: './view-mode-popover.component.html',
  styleUrls: ['./view-mode-popover.component.scss']
})
export class ViewModeMenuComponent {

  @Input() lang: any;
  @Input() mobileView: boolean;

  constructor(
    public popoverCtrl: PopoverController,
    public params: NavParams
  ) {
    // this.lang = params.get('lang');
    // this.mobileView = params.get('mobileView');
  }

  onSetViewMode(viewModeValue: string) {
    this.params.get('setViewMode')(viewModeValue);
    this.popoverCtrl.dismiss();
  }
}

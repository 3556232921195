import { Component, Input } from "@angular/core";
import { LocationLink } from "../../model/location-link";

import { LocationService } from "../../services/location.service";
// import { DataService } from '../../services/data.service';
// import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: "app-location-links",
  templateUrl: "./location-links.component.html",
  styleUrls: ["./location-links.component.scss"],
})
export class LocationLinksComponent {
  mobileView: boolean;
  _locations: LocationLink[] = [];
  colNum = "3";
  loadingResults = true;

  @Input() lang: any;
  @Input() appLanguage: string;

  // todo: Location type
  @Input() set locations(value: any[]) {
    this._locations = value;
    if (this._locations?.length > 0) {
      this._locations.forEach((location) => {
        this.setQueryParams(location);
      });
    }
  }

  get locations() {
    return this._locations;
  }

  constructor(
    private locationService: LocationService // private dataService: DataService, // private router: Router, // private activeRoute: ActivatedRoute,
  ) {}

  // getCounties() {
  //   // this.locationService.getCounties()
  //   //   .subscribe(result => {
  //   //     this.locations = result as any[];
  //   //     this.locations.forEach(location => {
  //   //       this.setQueryParams(location);
  //   //     });
  //   //   });

  //   this.dataService.locationLinks$
  //     .subscribe(result => {
  //       this.locations = result as any[];
  //       console.log(this.locations);
  //       // this.locations.forEach(location => {
  //       //   this.setQueryParams(location);
  //       // });
  //     });
  // }

  setQueryParams(location: LocationLink): void {
    location.queryParams = {
      propTypes: "house,flat",
      location: location["q"].split(" ").join("-"), // .replace(/ /g, "-"),
      locType: "c",
      l: this.appLanguage === "en" ? "en" : null,
    };
  }
}

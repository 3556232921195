import { Component, OnInit } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';

// Services
import { LoginService } from '../../../services/login.service';

// Popover component
@Component({
  templateUrl: './photo-options-popover.component.html',
  styleUrls: ['./photo-options-popover.component.scss']
})
export class PhotoOptionsPopoverComponent implements OnInit {

  lang: any;
  userId: any;
  // user: any;

  constructor(
    private popoverCtrl: PopoverController,
    public params: NavParams,
    // private loginService: LoginService
  ) {
    this.lang = params.get('lang');
    this.userId = params.get('userId');
    // console.log(this.comment);
  }

  ngOnInit() {
    // this.user = this.loginService.currentUser();
    // console.log(this.user);
  }

  view() {
    this.params.get('viewPhoto')();
    this.popoverCtrl.dismiss();
  }

  delete() {
    this.params.get('deletePhoto')();
    this.popoverCtrl.dismiss();
  }
}

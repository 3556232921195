import { Injectable  } from '@angular/core';
// import { BehaviorSubject } from 'rxjs';
import { Title, Meta } from "@angular/platform-browser";

import { IMetaData } from '../model/meta-data';

export class MetaData implements IMetaData {
  lang: ITranslations;
  title: string;
  description: string;
  keywords: string;
  url: string;
  image: string;
  robots: string;

  constructor(
    lang: ITranslations,
    title: string,
    description: string,
    keywords: string,
    url: string,
    image: string,
    robots = "index, follow",
  ) {
    this.lang = lang;
    this.title = title;
    this.description = description;
    this.keywords = keywords;
    this.url = url;
    this.image = image;
    this.robots = robots;
  }
}

@Injectable()
export class MetaService {
  initLang: ITranslations;
  // metaDataSource = new BehaviorSubject<IMetaData>(null);
  // metaData$ = this.metaDataSource.asObservable();

  constructor(
    private titleService: Title,
    private metaService: Meta,
  ) {
    // this.initLang = this.languageService["sl"];
    // this.setMetaDataObject(new MetaData(
    //   this.initLang,
    //   `Kvadrat - ${this.initLang["findYourNewHome"]}`,
    //   this.initLang.findYourNewHome.toString(),
    //   "buy, sell, rent, condo, flat, apartment, house",
    //   "https://kvadrat.si",
    //   "https://kvadrat.si/assets/images/condo-323780.jpg",
    // ));
  }

  setMetaDataObject(metaData: IMetaData) {
    if (metaData) {
      // this.metaDataSource.next(metaData);
      this.setMetaInfo(metaData);
    };
  }

  setMetaInfo(metaData: IMetaData) {    
    this.titleService.setTitle(metaData.title);

    this.metaService.updateTag({ name: "title", content: metaData.title });
    this.metaService.updateTag({ name: "description", content: metaData.description });
    this.metaService.updateTag({ name: "keywords", content: metaData.keywords });
    this.metaService.updateTag({ name: "robots", content: metaData.robots });
    this.metaService.updateTag({ property: "og:url", content: metaData.url });
    this.metaService.updateTag({ property: "og:title", content: metaData.title });
    this.metaService.updateTag({ property: "og:description", content: metaData.description });
    this.metaService.updateTag({ property: "og:image", content: metaData.image });

    // those never change
    // this.metaService.updateTag({ property: "og:type", content: metaData.type || "website"});
    // this.metaService.updateTag({ property: "fb:app_id", content: "1185224969573279"});    
  }
}

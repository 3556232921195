import { Component, Input } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';

// viewMode Popover Component
@Component({
  templateUrl: './sort-ads-popover.component.html',
  styleUrls: ['./sort-ads-popover.component.scss']
})
export class SortAdsPopoverComponent {

  @Input() lang: any;
  // sortOrder: string;

  constructor(
    private popoverCtrl: PopoverController,
    public params: NavParams
  ) {
    this.lang = params.get('lang');
    // this.sortOrder = params.get('sortOrder');
  }

  setSortOrder(sortOrder: string) {
    this.params.get('setSortOrder')(sortOrder);
    this.popoverCtrl.dismiss();
  }
}

<ion-header class="shrinkable">
  <app-main-header
    [mobileView]="mobileView"
    [title]="title"
    [currentUser]="currentUser"
  ></app-main-header>
</ion-header>

<ion-content
  class="profile-comments-component"
  *ngIf="currentUser && currentUser._id">
  <ion-grid>
    <ion-card>
      <ion-list>
        <ion-item-divider>
          {{ lang.myComments }} ({{ currUserComments.length }})
        </ion-item-divider>
        <ion-item *ngIf="currUserComments.length < 1">
          <ion-label>
            {{ lang.noCommentsYet }} <a routerLink="/h">{{ lang.goToHomepage }}</a>
          </ion-label>
        </ion-item>
        <ion-item *ngFor="let comment of currUserComments">
          <ion-label>
            <p>
              {{ comment.lastChange | date : "short" }} - <span style="font-style: italic">"{{ comment.message }}" &nbsp;</span>
            </p>
          </ion-label>
          <ion-button fill="clear" [routerLink]="['/ad', comment.itemId]">
            {{ lang.goToAd }}
          </ion-button>
        </ion-item>
      </ion-list>
    </ion-card>
  </ion-grid>
</ion-content>

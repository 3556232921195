import { Component, Input, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

// Login Modal Component
@Component({
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit {

  @Input() lang: any;
  @Input() appLanguage: any;
  formType = 'login';

  constructor(
    public modalCtrl: ModalController,
    public params: NavParams
  ) {
    // this.lang = params.get('lang');
    // this.appLanguage = params.get('appLanguage');
  }

  ngOnInit() { }

  loggedInResponse(event) {
    this.modalCtrl.dismiss();
  }

  // close button
  dismiss() {
    this.modalCtrl.dismiss();
  }
}

<!-- AD PHOTO UPLOADER TEMPLATE -->
<ion-item class="file-upload" lines="none"
          *ngIf="path === 'photos' || path === 'agency-photos-cover' || path === 'agency-photos-logo'">
  <ion-label>
    <ion-row>
      <ion-col size="6" size-sm="6" size-md="3" size-lg="2">
        <div
          class="preview-img"
          ng2FileDrop
          id="file"
          [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
          (fileOver)="fileOverBase($event)"
          [uploader]="uploader"
          class="well my-drop-zone"
        >
          <div class="item fileUpload">
            <ion-icon name="add"></ion-icon>
            <input
              type="file"
              name="single"
              id="file2"
              class="upload"
              ng2FileSelect
              [uploader]="uploader"
              multiple
            />
          </div>
          <!-- <p [ngClass]="{'nv-file-over-font': hasBaseDropZoneOver}">{{ lang.dropFileHere }}</p> -->
        </div>
      </ion-col>
      <ion-col
        *ngFor="let item of uploader.queue; let i = index"
        size="6"
        size-sm="6"
        size-md="3"
        size-lg="2"
      >
        <img
          *ngIf="item?.file && item.file['type'].indexOf('image') >= 0"
          appImgPreview
          [src]="item?.previewPath"
          class="upload-preview"
        />
        <p>
          {{ lang.photo }} {{ i + 1 }}
          <small
            ><a class="remove-image" (click)="removeImage(i)">{{
              lang.remove
            }}</a></small
          >
        </p>
      </ion-col>
    </ion-row>

    <ion-item lines="none" class="ion-text-center">
      <ion-button color="primary"
                  expand="block"
                  type="button"
                  class="upload-button"
                  (click)="uploadFiles()"
                  [disabled]="!uploader.getNotUploadedItems().length">
        {{ lang.upload }}
        <span *ngIf="uploader.queue.length === 1">&nbsp;{{ lang.photoAccusative }}</span>
        <span *ngIf="uploader.queue.length === 2">&nbsp;{{ lang.photoAccusativeTwo }}</span>
        <span *ngIf="uploader.queue.length > 2">&nbsp;{{ lang.photoAccusativeMulti }}</span>
      </ion-button>
    </ion-item>
  </ion-label>
</ion-item>

<!-- PROFILE PHOTO UPLOADER TEMPLATE -->
<div *ngIf="path === 'p'"
      color="light-gray"
      class="item profile-upload">
  <span>
    <ion-icon name="camera"></ion-icon>
  </span>
  <input type="file"
         name="single"
         id="file2"
         class="upload"
         ng2FileSelect
         [uploader]="uploader"
         multiple/>
</div>

<div *ngIf="path === 'posts'"
      color="light-gray"
      class="item post-images-upload">
  <ion-item *ngIf="uploader.queue.length < 12">
    <ion-thumbnail>
      <img class="photos-thumbnail" src="../../../assets/images/image.png" alt="photos"/>
    </ion-thumbnail>
    <ion-label>
      <span *ngIf="uploader.queue.length === 0">{{ lang.addPhoto }}</span>
      <span *ngIf="uploader.queue.length > 0">{{ lang.uploadMorePhotos }}</span>
    </ion-label>
    <input type="file"
           name="single"
           id="file2"
           class="post-images-upload-input"
           ng2FileSelect
           [uploader]="uploader"
           multiple/>
  </ion-item>

  <ion-row *ngIf="uploader.queue.length > 0">
    <ion-col *ngFor="let item of uploader.queue; let i = index"
             size="6"
             size-sm="4"
             size-md="3"
             size-lg="3">
      <div class="upload-preview-container">
        <img *ngIf="item?.file && item.file['type'].indexOf('image') >= 0"
             appImgPreview
             [src]="item?.previewPath"
             class="upload-preview" />
      </div>
      <ion-fab *ngIf="item?.file && item.file['type'].indexOf('image') >= 0"
                vertical="top" horizontal="end" (click)="removeImage(i);$event.stopPropagation()">
        <ion-fab-button size="small">
          <ion-icon name="remove"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ion-col>
  </ion-row>
</div>

<ion-list class="profile-menu" lines="full">
  <ion-item>
    <ion-avatar>
      <!-- <img src="assets/images/profile-300x300.jpg"> -->
      <app-profile-photo class="user-avatar" *ngIf="user" [userId]="user._id" [size]="40"></app-profile-photo>
    </ion-avatar>
    <ion-label>
      <h2 *ngIf="user">{{ user.name | truncate : ["18", "..."] }}</h2>
    </ion-label>
  </ion-item>
  <ion-item
    button
    *ngIf="user"
    (click)="onLangChange(appLanguage === 'sl' ? 'en' : 'sl')"
  >
    <ion-icon slot="start" name="flag-outline"></ion-icon>
    <ion-label>
      <span *ngIf="appLanguage === 'en'">SI</span
      ><span *ngIf="appLanguage === 'sl'">EN</span>
    </ion-label>
  </ion-item>
  <ion-item button (click)="toggleDarkMode(!darkMode)">
    <ion-icon *ngIf="darkMode" slot="start" name="sunny-outline"></ion-icon>
    <ion-icon *ngIf="!darkMode" slot="start" name="moon-outline"></ion-icon>
    <ion-label *ngIf="darkMode">
      {{ lang.lightModeToggle }}
    </ion-label>
    <ion-label *ngIf="!darkMode">
      {{ lang.darkModeToggle }}
    </ion-label>
  </ion-item>
  <ion-item button *ngIf="user" (click)="logout()">
    <ion-icon slot="start" name="lock-closed-outline"></ion-icon>
    <ion-label>
      {{ lang.logout }}
    </ion-label>
  </ion-item>
</ion-list>

<footer class="page-footer">
  <div class="container">
    <ion-grid>
      <ion-row>
        <ion-col>
          <p>
            <a [routerLink]="['/about']" [queryParams]="{ l: languageParams }">
              {{ lang.aboutUs }}
            </a>
          </p>
          <p>
            <a [routerLink]="['/terms']" [queryParams]="{ l: languageParams }">
              {{ lang.termsAndConditions2 }}
            </a>
          </p>
          <p>
            <a
              [routerLink]="['/privacy']"
              [queryParams]="{ l: languageParams }"
            >
              {{ lang.privacyPolicy }}
            </a>
          </p>
        </ion-col>
        <ion-col>
          <p>
            <a
              [routerLink]="['/advertising']"
              [queryParams]="{ l: languageParams }"
            >
              {{ lang.advertising }}
            </a>
          </p>
          <p>
            <a
              [routerLink]="['/contact-us']"
              [queryParams]="{ l: languageParams }"
            >
              {{ lang.contact2 }}
            </a>
          </p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</footer>

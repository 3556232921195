<ion-list class="comment-menu-popover" lines="full">
  <!-- refactor this condition -->
  <ion-item [button]="true"
            *ngIf="currentUser?._id !== itemOwnerId"
            class="comment-menu-button"
            (click)="flag()">
    <ion-icon slot="start" name="flag-outline"></ion-icon>
    <span>{{ lang.flag }}</span>
  </ion-item>
  <ion-item [button]="true"
            *ngIf="currentUser?._id === itemOwnerId"
            class="comment-menu-button"
            (click)="edit()">
    <ion-icon slot="start" name="create-outline"></ion-icon>
    <span>{{ lang.edit }}</span>
  </ion-item>
  <ion-item [button]="true"
            *ngIf="currentUser?._id === itemOwnerId"
            class="comment-menu-button"
            (click)="delete()">
    <ion-icon slot="start" name="trash-outline"></ion-icon>
    <span>{{ lang.delete }}</span>
  </ion-item>
</ion-list>
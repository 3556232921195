<ion-header class="preview-modal-header">
  <ion-toolbar color="primary" mode="md">
    <ion-title>
      <a *ngIf="ad && ad._id"
         class="new-tab-link"
         target="_blank"
         (click)="$event.stopPropagation()"
         [href]="'/ad/' + ad._id + (appLanguage === 'en' ? '?l=en' : '')">
        <ion-icon slot="start" name="open"></ion-icon>
      </a>
      <span *ngIf="!mobileView">
        {{ ad.located.street }}, {{ ad.located.postal }}
        {{ ad.located.district }}
      </span>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="callNumber()">
        <ion-icon
          slot="start"
          [ngClass]="{ 'icon-only': mobileView }"
          name="call">
        </ion-icon>
        <span *ngIf="!mobileView">{{ lang.call }}</span>
      </ion-button>
      <ion-button (click)="scrollTo(addDetail.contactSeller)">
        <ion-icon
          slot="start"
          [ngClass]="{ 'icon-only': mobileView }"
          name="mail">
        </ion-icon>
        <span *ngIf="!mobileView">{{ lang.email }}</span>
      </ion-button>
      <ion-button (click)="toggleFavorite(ad._id, ad.author)">
        <ion-icon
          slot="start"
          *ngIf="!isFavorite"
          [ngClass]="{ 'icon-only': mobileView }"
          name="heart-outline">
        </ion-icon>
        <ion-icon
          slot="start"
          *ngIf="isFavorite"
          class="saved"
          [ngClass]="{ 'icon-only': mobileView }"
          name="heart">
        </ion-icon>
        <span *ngIf="!mobileView && !isFavorite">{{ lang.save }}</span>
        <span *ngIf="!mobileView && isFavorite">{{ lang.saved }}</span>
      </ion-button>
      <ion-button (click)="copyLinkClipboard(ad._id)">
        <ion-icon
          slot="start"
          [ngClass]="{ 'icon-only': mobileView }"
          name="share">
        </ion-icon>
        <span *ngIf="!mobileView">{{ lang.share }}</span>
      </ion-button>
      <ion-button (click)="flagAd($event, ad._id)">
        <ion-icon
          slot="start"
          [ngClass]="{ 'icon-only': mobileView }"
          name="flag">
        </ion-icon>
        <span *ngIf="!mobileView">{{ lang.flag }}</span>
      </ion-button>
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
      <span *ngIf="phoneNumber">
        <a #phoneLink [href]="'tel:' + phoneNumber"></a>
      </span>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-ad-detail
    *ngIf="ad && ad._id"
    #addDetail
    [appLanguage]="appLanguage"
    [lang]="lang"
    [mobileView]="mobileView"
    [ad]="ad"
    [adViewsCount]="adViewsCount"
    [favoritesCount]="favoritesCount"
    [currentUser]="currentUser"
    [loggedIn]="loggedIn"
    (modalClose)="dismiss()">
  </app-ad-detail>
</ion-content>

import { Component, HostListener, OnInit } from "@angular/core";

import { BucketService } from "../../services/bucket.service";
import { DataService } from "../../services/data.service";
import { LoginService } from "../../services/login.service";
import { LanguageService } from "../../services/language.service";
import { RealEstateAgencyService } from "../../services/re-agency.service";
import { WindowService } from "../../services/window.service";
import { CurrentUser } from "../../model/current-user";

@Component({
  selector: "app-agency-list",
  templateUrl: "agency-list.component.html",
  styleUrls: ["./agency-list.component.scss"],
})
export class AgencyListComponent implements OnInit {
  title = "Agencies";
  lang: any;
  isLoggedIn = false;
  currentUser: CurrentUser;
  agencies: any = [];
  agencyNumber = 0;

  mobileView = true; // todo
  API = "";

  constructor(
    private bucketService: BucketService,
    private loginService: LoginService,
    private dataService: DataService,
    private languageService: LanguageService,
    private realEstateAgencyService: RealEstateAgencyService,
    private windowService: WindowService
  ) {}

  ngOnInit() {
    this.API = this.dataService.API;
    // this.getUrl();
    // this.API = this.dataService.API;
    // SET LANGUAGE
    this.languageService.language$.subscribe((value) => {
      // this.appLanguage = value;
      this.lang = this.languageService[value];
      // this.updateList();
    });

    this.dataService.loggedIn$.subscribe(result => {
      this.isLoggedIn = result;
      this.currentUser = this.loginService.currentUser();
    });

    this.dataService.isBrowser$.subscribe((isBrowser) => {
      // console.log(isBrowser);
      if (isBrowser) {
        this.toggleMobileView();
      }
    });

    this.getAgencies();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.toggleMobileView();
  }

  toggleMobileView() {
    if (this.windowService.nativeWindow.innerWidth <= "767") {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
  }

  getAgencies() {
    this.realEstateAgencyService
      .getRealEstateAgencyAll()
      .subscribe((result) => {
        this.agencies = result["reAgencies"];
        this.agencyNumber = result["reAgencyCount"];
        console.log(result);
        this.agencies.forEach(element => {
          this.getAgencyCoverImage(element);
          this.getAgencyLogoImage(element);
        });
      });
  }

  // TODO: get directly by adId and Type
  getAgencyCoverImage(agency): void {
    this.bucketService.listAgencyImages(agency._id, 0).subscribe((images) => {      
      let agencyImages = images["files"];
      agency["coverPhoto"] = agencyImages.find(image => image.metadata.type === 0)?._id;
    });
  }

  getAgencyLogoImage(agency): void {
    this.bucketService.listAgencyImages(agency._id, 1).subscribe((images) => {      
      let agencyImages = images["files"];
      agency["logo"] = agencyImages.find(image => image.metadata.type === 1)?._id;
    });
  }
}

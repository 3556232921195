
<ion-row *ngIf="currUserViewHistory.length > 0">
  <ion-col [attr.size]="colNum ? colNum : '3'"
            *ngFor="let item of currUserViewHistory | slice:0:8; let i = index">
    <app-prop-card (openPreviewEmitter)="openPreviewModal(item)"
                  #adCard
                  [item]="item"
                  [imageUrls]="item.imageUrls"
                  [appLanguage]="appLanguage"
                  [lang]="lang">
    </app-prop-card>
  </ion-col>
</ion-row>
<p *ngIf="currUserViewHistory.length === 0" class="no-items-message">{{ lang.noItemsToShow }}</p>
<p *ngIf="currUserViewHistory.length !== 0" class="show-all">
  <a [routerLink]="['/history']" [queryParams]="{ l: appLanguage }">
    {{ lang.showAll }}
  </a>
</p>
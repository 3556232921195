<ion-list class="set-sort-popover" lines="full">
  <ion-item button class="set-sort-button" (click)="setSortOrder('pricemin')">
    <ion-icon class="arrow-forward up" name="arrow-forward"></ion-icon><span>{{ lang.price }}</span>
  </ion-item>
  <ion-item button class="set-sort-button" (click)="setSortOrder('pricemax')">
    <ion-icon class="arrow-forward down" name="arrow-forward"></ion-icon><span>{{ lang.price }}</span>
  </ion-item>
  <ion-item button class="set-sort-button" (click)="setSortOrder('sizemin')">
    <ion-icon class="arrow-forward up" name="arrow-forward"></ion-icon><span>{{ lang.size }}</span>
  </ion-item>
  <ion-item button class="set-sort-button" (click)="setSortOrder('sizemax')">
    <ion-icon class="arrow-forward down" name="arrow-forward"></ion-icon><span>{{ lang.size }}</span>
  </ion-item>
  <ion-item button class="set-sort-button" (click)="setSortOrder('datemin')">
    <ion-icon class="arrow-forward up" name="arrow-forward"></ion-icon><span>{{ lang.adAge }}</span>
  </ion-item>
  <ion-item button class="set-sort-button" (click)="setSortOrder('datemax')">
    <ion-icon class="arrow-forward down" name="arrow-forward"></ion-icon><span>{{ lang.adAge }}</span>
  </ion-item>
  
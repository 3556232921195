import { Component, OnInit, Input, HostListener } from "@angular/core";
import { AlertController, MenuController, ModalController } from "@ionic/angular";
import { ActivatedRoute } from "@angular/router";

import { CurrentUser } from "../../model/current-user";
import { LanguageService } from "../../services/language.service";
import { DataService } from "../../services/data.service";
import { LoginService } from "../../services/login.service";
import { WindowService } from "../../services/window.service";
import { PostsService } from "../../services/posts.service";
import { IPost } from "../../model/db/post";
import { BucketService } from "../../services/bucket.service";
import { UserDataService } from "../../services/user-data.service";

// Comment Component
@Component({
  selector: "app-post-check-page",
  templateUrl: "./post-check-page.component.html",
  styleUrls: ["./post-check-page.component.scss"],
})
export class PostCheckPageComponent implements OnInit {
  mobileView: boolean;
  currentUser: CurrentUser;
  appLanguage: string;
  lang: any;
  loggedIn = false;
  isBrowser = false;

  post: IPost;
  basePath = "";
  images: string[] = [];

  API: string;

  constructor(
    private languageService: LanguageService,
    private dataService: DataService,
    private loginService: LoginService,
    private windowService: WindowService,
    private menuCtrl: MenuController,
    private route: ActivatedRoute,
    private postsService: PostsService,
    public modalCtrl: ModalController,
    private bucketService: BucketService,
    private userDataService: UserDataService,
    private alertCtrl: AlertController,
  ) {}

  ngOnInit() {
    this.API = this.dataService.API;
    // SET LANGUAGE
    this.languageService.language$.subscribe((value) => {
      this.appLanguage = value;
      this.lang = this.languageService[value];
    });

    this.dataService.loggedIn$.subscribe((val) => {
      this.loggedIn = val;
      this.currentUser = this.loginService.currentUser();
    });

    // GET VIEW
    this.dataService.isBrowser$.subscribe((isBrowser) => {
      this.isBrowser = isBrowser;
      if (isBrowser) {
        // this.getCounties();
        this.toggleMobileView();
      }
    });

    this.getPost();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.toggleMobileView();
  }

  toggleMobileView() {
    if (this.windowService.nativeWindow.innerWidth <= "767") {
      this.mobileView = true;
      this.menuCtrl.swipeGesture(true);
    } else {
      this.mobileView = false;
      this.menuCtrl.swipeGesture(false);
    }
  }


  getPost(): void {
    this.route.url.subscribe((url) => {
      // console.log(url);
      // console.log(decodeURI(url));
      this.basePath = url[0].path;
      const pathId = url[1].path;
      
      if (this.basePath === "post-check") {
        this.postsService.verifySinglePost(pathId, this.currentUser._id).subscribe((post: IPost) => {
          this.post = post;

          if (post.statusOfPost[post.statusOfPost.length - 1]?.status === 1) {
            post.approved = true;
          }

          if (post.statusOfPost[post.statusOfPost.length - 1]?.status === 3) {
            post.rejected = true;
          }

          this.userDataService.getName(this.post.userId).subscribe((res: object) => {
            this.post.userName = res["name"];
          });
        });

        this.bucketService.listAdImages(pathId, 2).subscribe(images => {
          this.images = images['files'].map(image => this.API + '/files/image/' + image._id);
        });
      }
    });
  }
  
  approvePost(): void {
    if (!this.currentUser) {
      return;
    }
    
    this.postsService.approvePost(this.post._id, this.currentUser._id, this.post.userId).subscribe((result) => {
      // console.log(result);
      this.post['approved'] = true;
      this.approvalConfirmation(true);
    });
  }

  rejectPost(): void {
    if (!this.currentUser) {
      return;
    }
    
    this.postsService.rejectPost(this.post._id, this.currentUser._id, this.post.userId).subscribe((result) => {
      // console.log(result);
      this.post['rejected'] = true;
      this.approvalConfirmation(true);
    });
  }

  async approvalConfirmation(approved: boolean): Promise<void> {
    const approvedAlert = await this.alertCtrl.create({
      header: approved ? "Post is approved." : "Post is rejected.", // this.lang['delete'],
      subHeader: "Have a nice day!", // this.lng['deleteConfirm'],
      // subTitle: 'Removing the ad can not be undone. Ste prepričani, da želite izbrisati oglas?', // this.lng['deleteConfirm'],
      buttons: [
        {
          text: this.lang["ok"], // this.lng['cancel'],
          role: "cancel",
          handler: () => {
            // console.log('Cancel clicked');
            // this.router.navigate(["ad", this.ad._id]);
          },
        },
      ],
    });
    await approvedAlert.present();
}
}

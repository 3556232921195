import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { DataService } from '../services/data.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  private SESSION_TOKEN: string;

  constructor(private dataService: DataService) {
    this.dataService.sessionToken$.subscribe(sessionToken => {
      this.SESSION_TOKEN = sessionToken;
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      headers: request.headers.set('Authorization', 'Bearer ' + this.SESSION_TOKEN)
    });
    return next.handle(request);
  }
}

<ion-app ngSkipHydration>
  <ion-menu type="overlay" menuId="first" contentId="main" class="left-menu">
    <ion-header>
      <ion-toolbar color="primary">
        <ion-title>Menu</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list lines="full">
        <ion-menu-toggle>
          <ion-item [routerLink]="['']" [queryParams]="{ l: languageParams }">
            <ion-icon slot="start" name="home-outline"></ion-icon>
            <ion-text>
              {{ lang.home }}
            </ion-text>
          </ion-item>
        </ion-menu-toggle>

        <ion-menu-toggle>
          <ion-item [routerLink]="['posts']" [queryParams]="{ l: languageParams }">
            <!-- chatbubbles -->
            <ion-icon slot="start" name="megaphone-outline"></ion-icon>
            <ion-text>
              {{ lang.posts }}
            </ion-text>
          </ion-item>
        </ion-menu-toggle>

        <ion-menu-toggle>
          <ion-item
            *ngIf="loggedIn"
            [routerLink]="['/p', currentUserId]"
            [queryParams]="{ l: languageParams }"
          >
            <ion-icon slot="start" name="apps-outline"></ion-icon>
            <ion-text>
              {{ lang.myDashboard }}
            </ion-text>
          </ion-item>
        </ion-menu-toggle>

        <ion-menu-toggle>
          <ion-item
            *ngIf="loggedIn"
            [routerLink]="['/fav']"
            [queryParams]="{ l: languageParams }"
          >
            <ion-icon slot="start" name="heart-outline"></ion-icon>
            <ion-text>
              {{ lang.favorites }}
            </ion-text>
          </ion-item>
        </ion-menu-toggle>

        <ion-menu-toggle>
          <ion-item
            *ngIf="loggedIn"
            [routerLink]="['/history']"
            [queryParams]="{ l: languageParams }"
          >
            <ion-icon slot="start" name="time-outline"></ion-icon>
            <ion-text>
              {{ lang.viewHistory }}
            </ion-text>
          </ion-item>
        </ion-menu-toggle>

        <ion-menu-toggle>
          <ion-item
            *ngIf="loggedIn"
            [routerLink]="['/searches']"
            [queryParams]="{ l: languageParams }"
          >
            <ion-icon slot="start" name="search-outline"></ion-icon>
            <ion-text>
              {{ lang.savedSearches }}
            </ion-text>
          </ion-item>
        </ion-menu-toggle>

        <ion-menu-toggle>
          <ion-item
            *ngIf="loggedIn"
            [routerLink]="['/msg']"
            [queryParams]="{ l: languageParams }"
          >
            <ion-icon slot="start" name="mail-outline"></ion-icon>
            <ion-text>
              {{ lang.messages }}
            </ion-text>
          </ion-item>
        </ion-menu-toggle>

        <ion-menu-toggle>
          <ion-item
            *ngIf="loggedIn"
            [routerLink]="['/comments']"
            [queryParams]="{ l: languageParams }"
          >
            <ion-icon slot="start" name="chatbubbles-outline"></ion-icon>
            <ion-text>
              {{ lang.comments }}
            </ion-text>
          </ion-item>
        </ion-menu-toggle>

        <ion-menu-toggle>
          <ion-item
            *ngIf="!loggedIn"
            routerLink="/login"
            [queryParams]="{ l: languageParams }"
          >
            <ion-icon slot="start" name="lock-closed-outline"></ion-icon>
            <ion-text>
              {{ lang.login }}
            </ion-text>
          </ion-item>
        </ion-menu-toggle>

        <ion-menu-toggle>
          <ion-item button="true" *ngIf="loggedIn" (click)="logout()">
            <ion-icon slot="start" name="lock-closed-outline"></ion-icon>
            <ion-text>
              {{ lang.logout }}
            </ion-text>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>
  <!-- the main content -->
  <ion-router-outlet id="main"></ion-router-outlet>
  <app-cookie></app-cookie>
  <div *ngIf="loader" class="loader" [@loaderAnimation]>
    <img class="loader-logo"
         src="../../assets/images/kvad-w-text-blue-50.png"
         alt="kvadrat"/>
  </div>
</ion-app>

<ion-list class="mapview-popover" lines="full">
  <ion-item button class="mapviewmode-button" (click)="onSetMapView('pin')">
    <span>{{ lang.location }}</span>
  </ion-item>
  <ion-item button class="mapviewmode-button" (click)="onSetMapView('price')">
    <span>{{ lang.price }}</span>
  </ion-item>
  <ion-item button class="mapviewmode-button" (click)="onSetMapView('adSize')">
    <span>{{ lang.livingSpace }}</span>
  </ion-item>
  <ion-item button class="mapviewmode-button" (click)="onSetMapView('pricePerM')">
    <span>{{ lang.price }}/m&#178;</span>
  </ion-item>
</ion-list>
import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import {
  UntypedFormGroup,
  UntypedFormBuilder,
} from "@angular/forms";

import {
  ModalController,
  AlertController,
  DatetimeCustomEvent,
} from "@ionic/angular";

// import leaflet from 'leaflet';

import { BucketService } from "../../services/bucket.service";
import { LanguageService } from "../../services/language.service";
import { LoginService } from "../../services/login.service";
import { DataService } from "../../services/data.service";
import { UserDataService } from "../../services/user-data.service";
import { LocationService } from "../../services/location.service";
import { WindowService } from "../../services/window.service";
import { LeafletService } from "../../services/leaflet.service";

import { PhoneFormComponent } from "../modals/phone-form/phone-form.component";
import { CurrentUser } from "../../model/current-user";
import { IAd } from "../../model/db/ad";
import { PriceType } from "../../model/enums/price-type";
// import { LatLng, LatLngBounds, Map } from "leaflet";

@Component({
  selector: "app-ad-form-page",
  templateUrl: "./ad-form.component.html",
  styleUrls: ["./ad-form.component.scss"]
})
export class AdFormComponent implements OnInit {
  // variables
  adForm: UntypedFormGroup;
  address = "";
  // fullAddress: any;
  images: string[] = [];
  adId = "";
  moveInOutMinDate = "2024";
  moveInOutMaxDate = "2100";
  // moveInOutMinDate = new Date().toISOString();
  // moveInOutMaxDate = new Date(2100).toISOString();

  currentUser: CurrentUser;
  appLanguage: string;
  lang: any;
  loggedIn: boolean;
  layoutGrid: boolean;
  mobileView: boolean;
  title = "Nov oglas";
  adEditMode = false;

  lat = 46.1403;
  lng = 14.88;
  currentZoomLevel = 8;

  @ViewChild("map", { read: ElementRef }) mapContainer: ElementRef;
  map: any; // Map;

  mapPopup: any;
  marker: any;
  saving = false;
  locale = "sl-SI";

  constructor(
    private bucketService: BucketService,
    private dataService: DataService,
    private loginService: LoginService,
    private languageService: LanguageService,
    private router: Router,
    private route: ActivatedRoute,
    private modalCtrl: ModalController,
    private userDataService: UserDataService,
    private alertCtrl: AlertController,
    private formBuilder: UntypedFormBuilder, // FormBuilder,
    private locationService: LocationService,
    private windowService: WindowService,
    private leafletService: LeafletService // private popoverCtrl: PopoverController, // private toastCtrl: ToastController,
  ) // private menuCtrl: MenuController,
  {}

  get moveInString(): string {
    return this.adForm.controls.moveIn?.value?.toISOString();
  }

  get moveOutString(): string {
    return this.adForm.controls.moveOut?.value?.toISOString();
  }

  ngOnInit() {
    // SET LANGUAGE
    this.languageService.language$.subscribe((value) => {
      this.appLanguage = value;
      this.lang = this.languageService[value];
      this.address = this.lang.setAddressOnMap;
      
      this.locale = this.appLanguage === "en" ? "en-US" : "sl-SI";
    });

    // this.loggedIn = this.loginService.isLoggedIn();
    this.dataService.loggedIn$.subscribe((value) => {
      this.loggedIn = value;
      // console.log(this.loggedIn);
      this.currentUser = this.loginService.currentUser();
      if (!this.currentUser) {
        this.router.navigate(["/"]);
      }
      // this.userDataService
      //   .checkUserValidation(this.currentUser._id)
      //   .subscribe(async (response) => {
      //     if (!response["status"]) {
      //       const notVerifiedAlert = await this.alertCtrl.create({
      //         header: "Not verified", // this.lang['delete'],
      //         subHeader:
      //           "It appears that the user is not verified. Check your email and confirm your registration",
      //         // this.lng['deleteConfirm'],
      //         // subTitle: 'Removing the ad can not be undone. Ste prepričani, da želite izbrisati oglas?', // this.lng['deleteConfirm'],
      //         buttons: [
      //           {
      //             text: this.lang["ok"], // this.lng['cancel'],
      //             role: "cancel",
      //             handler: () => {
      //               // console.log('Cancel clicked');
      //               this.router.navigate(["/"], {
      //                 queryParams: {
      //                   l: this.appLanguage === "en" ? "en" : null,
      //                 },
      //               });
      //             },
      //           },
      //         ],
      //       });
      //       notVerifiedAlert.present();
      //     }
      //   });
      // console.log(this.user);
    });

    this.dataService.isBrowser$.subscribe((isBrowser) => {
      // console.log(isBrowser);
      if (isBrowser) {
        // GET VIEW
        this.toggleMobileView();

        // SET LAYOUT
        if (this.windowService.nativeWindow.localStorage) {
          this.layoutGrid = JSON.parse(localStorage.getItem("gridView"));
        } else {
          // FALLBACK
          this.layoutGrid = false;
        }

        this.setForm();

        this.getIdFromUrl();

        // this.checkPhone();
      }
    });

    this.onChanges();
    // this.adType = 'sell';
    // this.adPropertyType = 'house';
  }

  ionViewDidEnter() {
    if (!this.map) {
      this.loadmap();
    }
  }

  ionViewWillLeave() {
    if (this.map) {
      this.map.off();
      this.map.remove();
    }
    this.map = undefined;
  }

  setStateFromSession() {
    if (sessionStorage.getItem("adState")) {
      const adState = JSON.parse(sessionStorage.getItem("adState"));
      // console.log(adState);

      this.adForm.setValue(adState);
      if (adState.location?.DisplayPosition) {
        this.lat = adState.location.DisplayPosition.Latitude;
        this.lng = adState.location.DisplayPosition.Longitude;
      }

      if (sessionStorage.getItem("address")) {
        this.address = sessionStorage.getItem("address");
      }

      if (sessionStorage.getItem("currentZoomLevel")) {
        this.currentZoomLevel =
          parseInt(sessionStorage.getItem("currentZoomLevel"), 10) || 0;
      }
    }
  }

  getIdFromUrl() {
    this.route.url.subscribe((url) => {
      // console.log(url);
      if (url[0].path === "ed") {
        this.adEditMode = true;
        this.adId = url[1].path;
        this.getSingleAd(url[1].path);
      } else {
        this.adEditMode = false;
        this.setStateFromSession(); // TODO: ENABLE LATER
      }
    });
  }

  getSingleAd(adId: string): void {
    this.bucketService.getSingleAd(adId, "").subscribe((res) => {
      // this.ad = res;
      if (res["_id"]) {
        this.fillFormData(res); // TODO: ENABLE LATER
        this.lat = res["located"].position.Latitude;
        this.lng = res["located"].position.Longitude;
        this.getAddress(this.lat, this.lng);
        setTimeout(() => {
          this.map.setView([this.lat, this.lng], 14);
          this.marker.setLatLng([this.lat, this.lng]);
        }, 2000);
      }
    });
  }

  fillFormData(ad) {
    const formData: Partial<IAd> = {
      adType: ad.adType || 0,
      adPropertyType: ad.adPropertyType || 0,
      amenities: ad.amenities || {
        alarm: false,
        animalsAllowed: false,
        constructionReady: false,
        ac: false,
        dishwasher: false,
        disability: false,
        heatPump: false,
        internet: false,
        kitchenBasic: false,
        lift: false,
        opticalFiber: false,
        pool: false,
        solar: false,
        washer: false,
        firstTime: false
      },
      flatType: ad.flatType || null,
      // author: ad.author || null,
      balconyNumber: ad.balconyNumber || null,
      balconySize: ad.balconySize || null,
      bathNum: ad.bathNum || null,
      bedroomNum: ad.bedroomNum || null,
      deposit: ad.deposit || null,
      description: ad.description || "",
      energyClass: ad.energyClass || null,
      energyClassManual: ad.energyClassManual || null,
      fee: ad.fee || 0,
      floor: ad.floor || null,
      floorSize: ad.floorSize || null,
      furniture: ad.furniture || null,
      garage: ad.garage || null,
      heating: ad.heating || {
        electricityHeating: false,
        oilHeating: false,
        gasHeating: false,
        woodCarbon: false,
        floorHeating: false,
      },
      lastAdaptation: ad.lastAdaptation || null,
      located: ad.located || {
        mapView: null,
        position: null,
        district: null,
        postal: null,
        street: null,
      },
      location: ad.location || {},
      lotSize: ad.lotSize || null,
      moveIn: ad.moveIn ? new Date(ad.moveIn) : null, // 
      moveOut: ad.moveOut ? new Date(ad.moveOut) : null, // 
      numFloors: ad.numFloors || null,
      parkingPlaces: ad.parking || null,
      price: ad.price || {
        type: PriceType.SalesPrice,
        current: null,
        currency: "EUR",
      },
      propertyId: ad.propertyId || "",
      roomNum: ad.roomNum || null,
      slope: ad.slope || null,
      slopeDirection: ad.slopeDirection || null,
      summerCost: ad.summerCost || null,
      winterCost: ad.winterCost || null,
      vicinity: ad.vicinity || {
        basicSchool: null,
        busStation: null,
        fitnessAndSport: null,
        highway: null,
        higherSchool: null,
        kindergarden: null,
        medicalStation: null,
        pharmacy: null,
        postOffice: null,
        trainStation: null,
      },
      yearBuilt: ad.yearBuilt || null,
    };
    // console.log(formData);
    this.adForm.setValue(formData);
  }

  adTypeChanged(event: number) {
    // console.log(event);
    if (event === 0) {
      this.adForm.get("price.type").setValue(PriceType.SalesPrice);
    } else {
      this.adForm.get("price.type").setValue(PriceType.RentMonthly);
    }
  }

  flatTypeChanged(event: number) {
    // console.log(event);
    this.adForm.get("roomNum").setValue(event);
  }

  checkPhone() {
    this.userDataService
      .checkPhone(this.currentUser._id)
      .subscribe((result) => {
        // console.log(result);
        if (!result["result"]) {
          this.openPhoneFormModal();
        }
      });
  }

  setForm() {
    this.adForm = this.formBuilder.group({
      adType: 0, // [0, Validators.required], // sell, rent
      adPropertyType: 0, // [0, Validators.required], // house, flat, apartment, room
      amenities: this.formBuilder.group({
        kitchenBasic: false,
        internet: false,
        opticalFiber: false,
        ac: false,
        alarm: false,
        heatPump: false,
        lift: false,
        disability: false,
        animalsAllowed: false,
        washer: false,
        dishwasher: false,
        pool: false,
        solar: false,
        storageArea: false,
        constructionReady: false,
        firstTime: false
      }),
      flatType: null, // 2
      balconyNumber: null,
      balconySize: null,
      bathNum: null,
      bedroomNum: null,
      deposit: null,
      description: null,
      energyClass: null, // 'a1',
      energyClassManual: null,
      fee: null,
      floor: null,
      floorSize: null,
      furniture: null,
      garage: null,
      heating: this.formBuilder.group({
        electricityHeating: false,
        oilHeating: false,
        gasHeating: false,
        woodCarbon: false,
        floorHeating: false,
      }),
      lastAdaptation: null, // new Date().getFullYear(), // 1970
      located: {}, // this.formBuilder.group({}),
      location: {}, // [{}, Validators.required],
      lotSize: null,
      moveIn: null,
      moveOut: null,
      numFloors: null,
      parkingPlaces: null,
      price: this.formBuilder.group({
        type: PriceType.SalesPrice,
        current: null,
        currency: "EUR",
      }),
      propertyId: null,
      roomNum: null,
      slope: 0,
      slopeDirection: null,
      summerCost: null,
      vicinity: this.formBuilder.group({
        kindergarden: null,
        basicSchool: null,
        higherSchool: null,
        postOffice: null,
        medicalStation: null,
        pharmacy: null,
        market: null,
        busStation: null,
        trainStation: null,
        highway: null,
        park: null,
        fitnessAndSport: null,
      }),
      winterCost: null,
      yearBuilt: null, // new Date().getFullYear(),
    });
  }

  onChanges(): void {
    this.adForm.valueChanges.subscribe((val) => {
      // this.saveAdToSession(); // TODO: ENABLE LATER
      // console.log(this.adForm.value);
    });
  }

  async openPhoneFormModal() {
    const modal = await this.modalCtrl.create({
      component: PhoneFormComponent,
      componentProps: {
        checkPhone: () => {
          this.checkPhone();
        },
        // item: item,
        currentUserId: this.currentUser._id,
        lang: this.lang,
        appLanguage: this.appLanguage,
      },
    });
    modal.present();
  }

  setMovieInAndMoveOut(event: DatetimeCustomEvent, term: string) {
    // console.log(event);
    let currentDate = new Date(event.detail.value.toString());
    
    if (term === "moveIn") {
      this.adForm.controls.moveIn.setValue(currentDate);
    } else if (term === "moveOut") {
      this.adForm.controls.moveOut.setValue(currentDate);
    }
  }

  postAd() {
    this.saving = true;
    if (this.adEditMode) {
      this.bucketService
        .updateAd(this.adId, this.adForm.value, this.currentUser._id)
        .subscribe((res) => {
          // console.log(res);
          sessionStorage.removeItem("adState");
          sessionStorage.removeItem("address");
          this.router.navigate(["photos", res["updated"]], {
            queryParams: { l: this.appLanguage === "en" ? "en" : null },
          });
          this.saving = false;
          this.setForm();
        });
    } else {
      this.bucketService
        .postAd(this.adForm.value, this.currentUser._id, this.currentUser.email)
        .subscribe((res) => {
          // console.log(res);
          this.setForm();
          sessionStorage.removeItem("adState");
          sessionStorage.removeItem("address");
          this.router.navigate(["photos", res["added"]], {
            queryParams: { l: this.appLanguage === "en" ? "en" : null },
          });
          this.saving = false;
        });
    }
    this.locationService
      .addLocation({
        postalCode: this.adForm.value["location"].Address.PostalCode,
        district: this.adForm.value["location"].Address.District,
        county: this.adForm.value["location"].Address.County,
        country: this.adForm.value["location"].Address.AdditionalData[0].value,
      })
      .subscribe((addLocationResponse) => {
        // location added
      });
  }

  getAddress(lat, lng) {
    this.bucketService.getAddress(lat, lng).subscribe((result: any) => {
      // console.log(result.Response.View[0].Result[0].Location);
      // console.log(result);
      if (result.items && result.items.length > 0) {
        // mapping of object from new api structure to old
        const newLocation = result.items[0];

        if (!newLocation.mapView) {
          this.address = this.lang.addressNotFound;
          this.adForm.controls.location.setValue("xxx");
          return;
        }

        const location =  {
          MapReference: {
            RoadLinkId: "",
            AddressId: "",
            DistrictId: "",
            CityId: "",
            CountyId: "",
            CountryId: "",
            SideOfStreet: "",
            Spot: 0,
            ReferenceId: "",
          },
          Address: {
            AdditionalData: [
              {
                key: "CountryName",
                value: newLocation.address.countryName,
              },
              {
                key: "CountyName",
                value: newLocation.address.county,
              },
            ],
            PostalCode: newLocation.address.postalCode,
            HouseNumber: newLocation.address.houseNumber,
            Street: newLocation.address.street,
            District: newLocation.address.district,
            City: newLocation.address.city,
            County: newLocation.address.county,
            Country: newLocation.address.countryCode,
            Label: newLocation.title,
          },
          MapView: {
            BottomRight: {
              Longitude: newLocation.mapView.south,
              Latitude: newLocation.mapView.east,
            },
            TopLeft: {
              Longitude: newLocation.mapView.north,
              Latitude: newLocation.mapView.west,
            },
          },
          DisplayPosition: {
            Longitude: newLocation.position.lng,
            Latitude: newLocation.position.lat,
          },
          LocationType: newLocation.resultType,
          LocationId: newLocation.id,
        };

        const addr = location.Address;
        if (addr.Street === undefined) {
          this.address = this.lang.addressNotFound;
          this.adForm.controls.location.setValue("xxx");
        } else {
          this.address = `${addr.Street}, ${addr.PostalCode} ${addr.District}`;
          if (this.windowService.nativeWindow.localStorage) {
            sessionStorage.setItem("address", this.address);
          } else {
            // FALLBACK
          }
          this.adForm.controls.location.setValue(location);
        }
      } else {
        this.address = this.lang.addressNotFound;
        this.adForm.controls.location.setValue("xxx");
      }
    });
  }

  setMarker(event) {
    this.marker.setLatLng([event.latlng.lat, event.latlng.lng]);
    this.map.setView([event.latlng.lat, event.latlng.lng]);
    this.getAddress(event.latlng.lat, event.latlng.lng);
    sessionStorage.setItem(
      "currentZoomLevel",
      this.currentZoomLevel.toString()
    );
  }

  saveLastState() {
    // let id;
    if (this.windowService.nativeWindow.localStorage) {
      localStorage.setItem(
        "lastView",
        JSON.stringify({
          // folderIds: this.folderIds,
          // title: this.title,
          // breadcrumbs: this.breadcrumbs,
          // // id: id,
          // folderSortOrder: this.folderSortOrder,
          // fileSortOrder: this.fileSortOrder
        })
      );
    } else {
      // FALLBACK
    }
  }

  saveAdToSession() {
    sessionStorage.setItem("adState", JSON.stringify(this.adForm.value));
  }

  getLastState() {
    if (this.windowService.nativeWindow.localStorage) {
      const lastView = JSON.parse(localStorage.getItem("lastView"));
      if (lastView !== null) {
        // console.log(lastView);
        // this.title = lastView.title;
        // this.breadcrumbs = lastView.breadcrumbs;
        // this.folderIds = lastView.folderIds;
        // this.folderSortOrder = lastView.folderSortOrder || this.folderSortOrder;
        // this.fileSortOrder = lastView.fileSortOrder || this.fileSortOrder;
      }
    } else {
      // FALLBACK
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.toggleMobileView();
  }

  toggleMobileView() {
    if (this.windowService.nativeWindow.innerWidth <= "767") {
      this.mobileView = true;
      // this.menuCtrl.swipeEnable(true);
    } else {
      this.mobileView = false;
      // this.menuCtrl.swipeEnable(false);
    }
  }

  imgDecoded(item) {
    return encodeURI(item);
  }

  updateLayout(layout: any): void {
    this.layoutGrid = layout;
    if (this.windowService.nativeWindow.localStorage) {
      localStorage.setItem("gridView", JSON.stringify(layout));
    } else {
      // FALLBACK
    }
  }

  loadmap() {
    // console.log('loading map')
    // console.log(this.map);
    this.map = this.leafletService.L.map("form-map").setView(
      [this.lat, this.lng],
      this.currentZoomLevel
    );

    // angular ssr problems with leaflet
    // let southWest = new LatLng(45.404235401683344, 13.372979164123537);
    // let northEast = new LatLng(46.893985460925514, 16.59821748733521);
    // this.map.setMaxBounds(new LatLngBounds(southWest, northEast));
    this.map.setMaxBounds([45.404235401683344, 13.372979164123537], [46.893985460925514, 16.59821748733521]);

    this.leafletService.L.tileLayer(
      "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      {
        // attributions: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors,
        // <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery ©
        // <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 18,
        // accessToken: 'your.mapbox.access.token'
      }
    ).addTo(this.map);

    this.map.attributionControl.addAttribution(
      'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
    );
    // var marker = leaflet.marker([this.lat, this.lng]).addTo(this.map);
    this.marker = this.leafletService.L.marker([this.lat, this.lng]).addTo(
      this.map
    );

    this.map.on("click", (event) => {
      // console.log(event);
      this.setMarker(event);
    });

    this.map.on("zoomend", (event) => {
      // console.log(event);
      this.currentZoomLevel = event.target._animateToZoom;
    });
  }
}

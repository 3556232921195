import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { SearchFormComponent } from "../../search-form/search-form.component";

// Search Modal Component
@Component({
  templateUrl: "./search-modal.component.html",
  styleUrls: ["./search-modal.component.scss"],
})
export class SearchModalComponent implements OnInit {
  @Input() lang: any;
  @Input() basePath: string;

  @ViewChild("searchForm") searchForm: SearchFormComponent

  constructor(private modalCtrl: ModalController, public params: NavParams) {
    // this.lang = params.get('lang');
    // this.basePath = params.get('basePath');
  }

  ngOnInit() {}

  submitSearch() {
    this.searchForm.sendRequest();
  }

  // close button
  dismiss() {
    this.modalCtrl.dismiss();
  }
}

export enum PropertyType {
  House = 0,
  Flat = 1,
  Apartment = 2, // Tourism
  Room = 3,
  Weekend = 4,
  Estate = 5,
  Commercial = 6,
  Farm = 7,
  Parking = 8,
}

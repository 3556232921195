<ion-list class="flag-reasons-popover" lines="full">
  <ion-list-header>{{ lang.flag }}</ion-list-header>
  <ion-radio-group name="flagReason" [(ngModel)]="flagReason">
    <ion-item *ngIf="adId" class="radio-button-item">
      <ion-radio justify="start" labelPlacement="end" [value]="0">{{
        lang.adExpired
      }}</ion-radio>
    </ion-item>
    <ion-item class="radio-button-item">
      <ion-radio justify="start" labelPlacement="end" [value]="1">{{
        lang.profanity
      }}</ion-radio>
    </ion-item>
    <ion-item class="radio-button-item">
      <ion-radio justify="start" labelPlacement="end" [value]="2">{{
        lang.offensive
      }}</ion-radio>
    </ion-item>
    <ion-item class="radio-button-item">
      <ion-radio justify="start" labelPlacement="end" [value]="3">{{
        lang.spam
      }}</ion-radio>
    </ion-item>
    <ion-item class="radio-button-item other-item">
      <ion-radio justify="start" labelPlacement="end" [value]="4">{{
        lang.other
      }}</ion-radio>
    </ion-item>
  </ion-radio-group>
  <ion-item *ngIf="flagReason === 4" class="textarea-item">
    <ion-textarea
      [(ngModel)]="flagExplanation"
      name="explanation"
      class="explanation"
      (ngModelChange)="countMessageRemaining(flagExplanation)"
      cols="24"
      rows="5"
      [placeholder]="lang.describeTheIssue"
      fill="outline"
    >
    </ion-textarea>
  </ion-item>
  <ion-item
    class="message-counter"
    [ngClass]="{
      'length-warning': messageMaxLength - flagExplanation.length < 50
    }"
    *ngIf="flagExplanation.length > 140"
  >
    {{ messageRemaining }}
  </ion-item>
  <ion-button
    [disabled]="flagExplanation.length > 280"
    expand="block"
    class="flag-reason-button"
    color="primary"
    (click)="flag()"
  >
    <ion-icon slot="start" name="flag-outline"></ion-icon>
    <span>{{ lang.flag }}</span>
  </ion-button>
</ion-list>

import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PopoverController, ToastController, AlertController } from '@ionic/angular';

// Services
import { BucketService } from '../../services/bucket.service';
import { LoginService } from '../../services/login.service';
import { DataService } from '../../services/data.service';
import { UserDataService } from '../../services/user-data.service';
import { MessageService } from '../../services/message.service';
import { LanguageService } from '../../services/language.service';
import { FavoritesService } from '../../services/favorites.service';
import { AdViewService } from '../../services/ad-view.service';
import { WindowService } from '../../services/window.service';
import { MetaService } from "../../services/meta.service";

import { User } from '../../model/user';
import { CurrentUser } from '../../model/current-user';

import { PhotoOptionsPopoverComponent } from '../popovers/photo-options/photo-options-popover.component';
import { ProfilePhotoComponent } from '../profile-photo/profile-photo.component';
import { AdStatus } from 'src/app/model/enums/ad-status';

@Component({
  selector: 'app-profile',
  templateUrl: 'profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  title: string;
  mobileView = true;
  user: User = {
    userId: null,
    name: null,
    location: null,
    userBio: null,
    phoneNumber: null,
    userType: null,
    socialLinks: null
  };
  currentUser: CurrentUser;
  userBackup: User; // for cancelling editing
  address: string;
  path = 'p';
  // profilePhoto = '';
  // API: string;
  timeStamp = (new Date()).getTime();
  editMode = false;
  loading = false;
  appLanguage: string;
  lang: any;
  isLoggedIn = false;
  contactMessage = '';
  phoneNumber: string;
  currUserAds: any[] = [];
  removeAdAlert: HTMLIonAlertElement;
  adLimitAlert: HTMLIonAlertElement;
  API = '';
  languageParams = "";

  @ViewChild('profilePhoto') profilePhotoComponent: ProfilePhotoComponent;

  constructor(
    private bucketService: BucketService,
    private loginService: LoginService,
    private dataService: DataService,
    private userDataService: UserDataService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private router: Router,
    private popoverCtrl: PopoverController,
    private toastCtrl: ToastController,
    private languageService: LanguageService,
    private alertCtrl: AlertController,
    private adViewService: AdViewService,
    private favoritesService: FavoritesService,
    private windowService: WindowService,
    private metaService: MetaService,
  ) {
    this.title = 'Profile';
  }

  ngOnInit() {
    this.API = this.dataService.API;
    // this.getUrl();
    // this.API = this.dataService.API;
    // SET LANGUAGE
    this.languageService.language$.subscribe(value => {
      this.appLanguage = value;
      this.lang = this.languageService[value];
      // this.updateList();
      this.languageParams = value === "en" ? "en" : null;
    });

    this.dataService.loggedIn$
      .subscribe(result => {
        this.isLoggedIn = result;
        this.currentUser = this.loginService.currentUser();
      });

    this.getIdFromUrl();

    this.dataService.isBrowser$.subscribe(isBrowser => {
      // console.log(isBrowser);
      if (isBrowser) {
        this.toggleMobileView();
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.toggleMobileView();
  }

  toggleMobileView() {
    if (this.windowService.nativeWindow.innerWidth <= '767') {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
  }

  toggleActivate(ad) {
    const lastStatus = ad.statusOfAd[ad.statusOfAd.length -1];
    if (lastStatus.status === AdStatus.Created) {
      return;
    }
    
    this.userDataService.getUserAdLimit(this.currentUser._id)
      .subscribe(result => {
        if (ad.adExpired) {
          // check user ad limit first
          const activeAds = this.currUserAds.filter(item => item['adExpired'] === false);
          if (result['userAdLimit'] > activeAds.length) {

            this.bucketService.activateAd(ad._id, this.currentUser._id)
              .subscribe(res => {
                if (!res['statusOfAd']) {
                  return;
                };

                const updateIndex = this.currUserAds.findIndex(item => item._id === ad._id);
                this.currUserAds[updateIndex].statusOfAd = res['statusOfAd'];
                this.checkAdExpiry(this.currUserAds[updateIndex]);
                this.getExpirationDays(this.currUserAds[updateIndex]);
              });
          } else {
            this.adLimitAlertOpen(result['userAdLimit']);
          }
        // deactivate
        } else {
          this.bucketService.deactivateAd(ad._id, this.currentUser._id)
            .subscribe(res => {
              const updateIndex = this.currUserAds.findIndex(item => item._id === ad._id);
              this.currUserAds[updateIndex].statusOfAd = res['statusOfAd'];
              this.checkAdExpiry(this.currUserAds[updateIndex]);
              this.getExpirationDays(this.currUserAds[updateIndex]);
            });
        }
      });
  }

  createNewAd() {
    this.router.navigate(
      ['/n'],
      { queryParams: { l: this.appLanguage === "en" ? "en" : null } }
    );
  }

  getAdsByUser(userId) {
    this.bucketService.getAdsByUser(userId)
      .subscribe((result: any[]) => {
        this.currUserAds = result;
        for (let i = 0; i < this.currUserAds.length; i++) {
          const element = this.currUserAds[i];
          element['photos'] = [];
          this.listAdImages(element);
          this.checkAdExpiry(element);
          this.getExpirationDays(element);
          this.getCreatedDate(element);
          this.countAdViews(element);
          this.countAdFavorites(element);
        }
        // console.log(this.currUserAds);
      });
  }

  countAdViews(ad: any) {
    this.adViewService.countAdView(ad._id)
      .subscribe(result => {
        ad['adViewsCount'] = result['adViewsCount'];
      });
  }

  countAdFavorites(ad: any) {
    this.favoritesService.countAdFavorites(ad._id)
      .subscribe(result => {
        ad['favoritesCount'] = result['favoritesCount'];
      });
  }

  listAdImages(ad): void {
    this.bucketService.listAdImages(ad._id, 3)
      .subscribe(images => {
        ad['photos'] = images['files'];
      });
  }

  checkAdExpiry(ad): void {
    const lastStatus = ad.statusOfAd[ad.statusOfAd.length -1];

    if (lastStatus.status === AdStatus.Active) {
      // ad['adExpired'] = new Date(lastStatus.changed).getTime() <= new Date().getTime() - (30 * 24 * 60 * 60 * 1000);
      ad['adExpired'] = false;
      ad['activationDisabled'] = false;
    }

    if (lastStatus.status === AdStatus.Created || lastStatus.status === AdStatus.Blocked || lastStatus.status === AdStatus.Deleted) {
      ad['adExpired'] = true;
      ad['activationDisabled'] = true;
    }
    
    if (lastStatus.status === AdStatus.Inactive) {
      ad['adExpired'] = true;
      ad['activationDisabled'] = false;
    }   
  }

  async removeAlertOpen(ad) {
    if (!this.removeAdAlert) {
      this.removeAdAlert = await this.alertCtrl.create({
        header: 'Delete ad', // this.lang['delete'],
        subHeader: 'Deleting the ad can not be undone. Are you sure you want to delete it?', // this.lng['deleteConfirm'],
        // subTitle: 'Removing the ad can not be undone. Ste prepričani, da želite izbrisati oglas?', // this.lng['deleteConfirm'],
        buttons: [
          {
            text: 'Cancel', // this.lng['cancel'],
            role: 'cancel',
            handler: () => {
              // console.log('Cancel clicked');
              this.removeAdAlert = undefined;
            }
          },
          {
            text: 'Delete ad', // this.lang['ok'],
            handler: () => {
              // this.makePublic(item);
              this.removeAd(ad._id, this.currentUser._id);
              this.removeAdAlert = undefined;
            },
            cssClass: 'delete-confirm'
          }
        ]
      });

      await this.removeAdAlert.present();
    }
  }

  async adLimitAlertOpen(adLimit = 1) {
    if (!this.adLimitAlert) {
      this.adLimitAlert = await this.alertCtrl.create({
        header: 'Ad limit reached', // this.lang['delete'],
        subHeader: 'Your current ad limit is ' + adLimit + '. You can not have more active ads at this time.', // this.lng['deleteConfirm'],
        // subTitle: 'Removing the ad can not be undone. Ste prepričani, da želite izbrisati oglas?', // this.lng['deleteConfirm'],
        buttons: [
          {
            text: 'Ok', // this.lng['cancel'],
            role: 'cancel',
            handler: () => {
              // console.log('Cancel clicked');
              this.adLimitAlert = undefined;
            }
          }
        ]
      });

      this.adLimitAlert.present();
    }
  }

  removeAd(adId, userId) {
    this.bucketService.removeAd(adId, userId)
      .subscribe(response => {
        // update list of ads
        this.getAdsByUser(userId);
      });
  }

  getExpirationDays(ad) {
    if (ad.statusOfAd[ad.statusOfAd.length - 1].status === AdStatus.Active) {
      const expirationTime = new Date().getTime() / (24 * 60 * 60 * 1000) - 30;
      const adActivationTime = new Date(ad.statusOfAd[ad.statusOfAd.length - 1].changed).getTime() / (24 * 60 * 60 * 1000);
      const delta = Math.ceil(adActivationTime - expirationTime);
      ad['daysToExpiration'] = delta;
    } else {
      ad['daysToExpiration'] = null;
    }
  }

  getCreatedDate(ad) {
    ad['created'] = ad.statusOfAd[0].changed;
  }

  async sendMessage(senderId: string, recepientId: string) {
    if (!this.currentUser) {
      const toast = await this.toastCtrl.create({
        message: this.lang["loginToContact"],
        duration: 3000
      });
      toast.present();
      this.contactMessage = '';
      return;
    }

    // TODO: Enable controls
    // if (this.user._id === this.ad.author) {
    //   const toast = await this.toastCtrl.create({
    //     message: 'You can not send the message to yourself.',
    //     duration: 3000
    //   });
    //   toast.present();
    //   this.contactMessage = '';
    //   return;
    // }

    // const messageCheck = /^([a-z]|[A-Z]|[0-9]){2,200}$/;
    // let lines = this.contactMessage.split('\n');
    // for (var i = 0; i < lines.length; i++) {
    //   if (!lines[i].match(messageCheck)) {
    //     const toast = await this.toastCtrl.create({
    //       message: 'Your message contains invalid characters.',
    //       duration: 3000
    //     });
    //     toast.present();
    //     return false;
    //   }
    // }

    if (this.contactMessage.length > 500) {
      const toastLength = await this.toastCtrl.create({
        message: 'Your message is longer than 500 characters.',
        duration: 3000
      });
      return toastLength.present();
    }

    this.messageService.sendMessage(
      senderId, // this.currentUser._id,
      this.contactMessage, // this.contactMessage,
      recepientId, // this.user.userId,
      'General inquiry', // ad ID
      'Message from profile page' // ad title,
    ).subscribe(async response => {
      if (response['result'] === 200) {
        this.contactMessage = '';
        // if (adId === null) {
        //   this.getCurrUserMessages(this.currentUser._id);

        //   // TODO: NOT GOOD, UPDATE VIA API
        //   this.selectedConversation.messages.push({
        //     from,
        //     to,
        //     message,
        //     sendDate: new Date,
        //     otherPerson: to,
        //     statusOfMessage: AdStatus.Active
        //   });
        //   return;
        // }
        const toast = await this.toastCtrl.create({
          message: response['message'],
          duration: 3000
        });
        toast.present();
      }
    });

  }

  getPhone() {
    this.userDataService.getPhone(this.user.userId)
      .subscribe(result => {
        if (result['phone']) {
          this.phoneNumber = result['phone'];
        } else {
          this.phoneNumber = this.lang["noPhoneProvided"];
        }
      });
  }

  getIdFromUrl() {
    this.route.url
      .subscribe(url => {
        this.getUserData(url[1].path);
      });
  }

  // comment options popover
  async presentPhotoOptionsPopover(ev) {
    if (this.currentUser && this.currentUser._id === this.user.userId) {
      const popover = await this.popoverCtrl.create({
        component: PhotoOptionsPopoverComponent,
        componentProps: {
          viewPhoto: () => {
            this.viewPhoto(this.user.userId);
          },
          deletePhoto: () => {
            this.deletePhoto(this.user.userId);
          },
          userId: this.user.userId,
          lang: this.lang
        },
        event: ev,
      });
      popover.present();
    } else {
      this.viewPhoto(this.user.userId);
    }
  }

  enableEdit() {
    if (!this.isLoggedIn && this.currentUser._id !== this.user.userId) { return; }

    this.userBackup = {...this.user};
    this.editMode = true;
  }

  disableEdit() {
    this.user = {...this.userBackup};
    this.editMode = false;
  }

  saveEdit() {
    if (!this.isLoggedIn && this.currentUser._id !== this.user.userId) { return; }

    this.loading = true;

    const userData = {
      location: this.user.location,
      userBio: this.user.userBio,
      phoneNumber: this.user.phoneNumber,
      userType: this.user.userType,
      socialLinks: this.user.socialLinks
    }

    this.userDataService.updateUserData(userData, this.user.userId)
      .subscribe(result => {
        this.loading = false;
        this.editMode = false;
      });
  }

  setProfilePhoto() {
    // this.profilePhotoComponent.setProfilePhoto();
  }

  deletePhoto(userId) {
    this.userDataService.deletetUserPhoto(userId, this.currentUser._id)
      .subscribe(result => {
        // this.setProfilePhoto();
        // this.profilePhotoComponent.setProfilePhoto();
      });
  }

  viewPhoto(userId) {
    console.log('coming soon');
  }

  getUserData(userId) {
    this.userDataService.getUserData(userId).subscribe((userData: User) => {
        if (userData.userId) {
          this.user.userId = userData.userId;
          this.user.location = userData.location;
          this.user.userBio = userData.userBio;
          this.user.phoneNumber = userData.phoneNumber;
          this.user.userType = userData.userType;
          this.user.socialLinks = userData.socialLinks;

          this.getAdsByUser(this.user.userId);
          // this.getUserComments(this.user.userId);
          this.userDataService.getName(userId).subscribe(result => {
            this.user.name = result['name'];
            this.setMetaInfo();
          });
        }
      });
  }

  setMetaInfo() {
    this.metaService.setMetaDataObject({
      lang: this.lang,
      title: `Kvadrat uporabnik - ${this.user.name}`,
      description: `Stran uporabnika: ${this.user.name}`,
      keywords: "buy, sell, rent, condo, flat, apartment, house",
      url: this.windowService?.nativeWindow?.location.origin || "https://kvadrat.si",
      image: (this.windowService?.nativeWindow?.location.origin || "https://kvadrat.si") + "/assets/images/condo-323780.jpg"
    });
  }

  // pushAdToList(adId, itemList, updatedDate) {
  //   this.bucketService.getSingleAd(adId, '')
  //     .subscribe(res => {
  //       const ad = res;
  //       this.listAdImages(ad);
  //       this.setUpdatedDate(ad, updatedDate);
  //       itemList.push(ad);
  //     });
  // }

  // setUpdatedDate(ad, updatedDate) {
  //   ad['updatedDate'] = updatedDate;
  // }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';

@Injectable()
export class ViewHistoryService {

  API: string;

  constructor(
    private http: HttpClient,
    private dataService: DataService,
  ) {
    this.API = this.dataService.API;
  }

  listUserViewHistory(userId: string) {
    return this.http.get(`${this.API}/view-history/user/${userId}`);
  }

  addViewHistory(adId: string, viewerId: string, meta?: any) {
    return this.http.post(`${this.API}/view-history/add`, {
      adId,
      viewerId,
      meta
    });
  }

  removeViewHistory(adId: string, viewerId: string) {
    return this.http.post(`${this.API}/view-history/remove`, {
      adId,
      viewerId
    });
  }

  checkViewHistory(adId: string, viewerId: string) {
    return this.http.post(`${this.API}/view-history/check`, {
      adId,
      viewerId
    });
  }

  countAdViewHistory(adId: string) {
    return this.http.get(`${this.API}/view-history/count?adId=${adId}`);
  }
}

import { Component, OnInit, HostListener } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

import { DataService } from '../../../services/data.service';
import { WindowService } from '../../../services/window.service';

// Photo Preview Modal Component
@Component({
  selector: 'app-photo-preview-modal',
  templateUrl: './photo-preview-modal.component.html',
  styleUrls: ['./photo-preview-modal.component.scss']
})
export class PhotoPreviewModalComponent implements OnInit {

  // ad: any;
  lang: any;
  appLanguage = "sl"; // TODO: obsolete
  mobileView: boolean;
  photoPreview: boolean;
  imageUrls: string[] = [];

  constructor(
    public params: NavParams,
    private modalCtrl: ModalController,
    private windowService: WindowService,
    private dataService: DataService,
  ) {
    this.lang = params.get('lang');
    this.appLanguage = params.get('appLanguage');
    // this.ad = params.get('ad');
    this.photoPreview = params.get('photoPreview');
    this.imageUrls = params.get('imageUrls');
  }

  ngOnInit(): void {
    this.toggleMobileView();
    this.dataService.isBrowser$.subscribe(isBrowser => {
      // console.log(isBrowser);
      if (isBrowser) {
        this.toggleMobileView();
      }
    });
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.toggleMobileView();
  }

  toggleMobileView() {
    if (this.windowService.nativeWindow.innerWidth <= '767') {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
  }
}

import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

// services
import { LocationService } from "../../services/location.service";
import { LanguageService } from "../../services/language.service";
import { DataService } from "../../services/data.service";
// import { WindowService } from "../../services/window.service";

import { take } from "rxjs/operators";
import { ISarchAmenities } from "../../model/other/amenities";
@Component({
  selector: "app-search-form",
  templateUrl: "search-form.component.html",
})
export class SearchFormComponent implements OnInit {
  searchStr = "";
  dataRemote: LocationService;

  // headers: any;
  adType = "buy";
  adPropertyTypes = [ "house", "flat"]; // property types
  adPropertyTypeObject = {
    house: true,
    flat: true,
    apartment: false,
    room: false,
    weekend: false,
    estate: false,
    commercial: false,
    farm: false,
    parking: false,
  };

  amenities: ISarchAmenities = {
    alarm: false,
    animalsAllowed: false,
    constructionReady: false,
    ac: false,
    disability: false,
    dishwasher: false,
    firstTime: false,
    heatPump: false,
    internet: false,
    kitchenBasic: false,
    lift: false,
    opticalFiber: false,
    pool: false,
    solar: false,
    storageArea: false,
    washer: false,
    balcony: false,
    parking: false,
    groundFloor: false,
    feeFree: false,
    garage: false,
  };

  location = ""; // [];
  locType = "";
  priceFrom: number = null;
  priceTo: number = null;
  roomsFrom: number = null;
  roomsTo: number = null;
  adSizeFrom: number = null;
  adSizeTo: number = null;
  // images: boolean;
  // prices: boolean;
  lang: any;
  appLanguage: string;
  searchResults = [];
  searchResultsEnabled = false;

  // @Input() currentUrl: string;
  @Input() formType: string;
  @Input() basePath: string;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();
  // @Output() updateAds: EventEmitter<object> = new EventEmitter();

  constructor(
    // private route: ActivatedRoute,
    // private http: HttpClient,
    private router: Router,
    private languageService: LanguageService,
    private dataService: DataService,
    private locationService: LocationService,
    // private windowService: WindowService
  ) {
    // this.dataRemote = new LocationService(this.http, this.languageService, this.dataService);
    this.dataRemote = this.locationService;
  }

  ngOnInit() {
    this.languageService.language$.subscribe((value) => {
      this.appLanguage = value;
      this.lang = this.languageService[value];
    });

    // this.adPropertyTypeObjectToAdPropertyTypes();

    this.dataService.queryParamObject$
      .pipe(take(1)) // todo, test // error, nested services
      .subscribe((params) => {
        this.setFormFromQueryParams(params);
      });
  }

  onSelectedLocation(event) {
    // console.log(event);
    if (event) {
      this.searchStr = event.title;
      // console.log(this.searchStr);
          
      this.location = event.originalObject.q.replace(/\s/g, "-");
      this.locType = event.originalObject.des;
    }
  }

  updateSearchStr(event: CustomEvent) {
    // console.log(event);
    this.searchStr = event.detail.value;
    
    if (this.searchStr === "" || this.searchStr.length < 2) {
      this.searchResults = [];
      this.location = null;
      this.locType = null;
      return;
    }

    this.location = this.searchStr;

    this.locationService.search(this.searchStr).subscribe(results => {
      // console.log(results);
      this.searchResults = results;
    });
  }

  searchFocusOut() {
    setTimeout(() => {
      this.searchResultsEnabled = false
    }, 200);
  }

  clearSearch(): void {
    this.searchStr = "";
    this.searchResults = [];
    this.location = null;
    this.locType = null;
    // console.log(this.searchResults);
  }

  setAdPropertyTypeObject() {
    Object.keys(this.adPropertyTypeObject).forEach(
      (key) => (this.adPropertyTypeObject[key] = false)
    );
    this.adPropertyTypes.forEach((element) => {
      this.adPropertyTypeObject[element] = true;
    });
  }

  updatePropertyType(event: CustomEvent): void {
    // console.log(event);
    this.adPropertyTypes = event.detail.value;
    this.setAdPropertyTypeObject();
  }

  setFormFromQueryParams(queryParamsObject) {
    const { nelat, nelng, swlat, swlng, ...queryParams } = queryParamsObject;
    // console.log(queryParams);
    // adType
    if (queryParams.adType) {
      this.adType = queryParams.adType;
    } else {
      this.adType = "buy";
    }

    // propTypes
    if (queryParams.propTypes) {
      this.adPropertyTypes = queryParams.propTypes.split(",");
      this.setAdPropertyTypeObject();
    } else {
      this.adPropertyTypes = ["house", "flat"];
    }

    // location
    if (queryParams.location) {
      this.location = queryParams.location;
    }

    // locType
    if (queryParams.locType) {
      this.locType = queryParams.locType;
    }

    // priceFrom
    if (queryParams.priceFrom) {
      this.priceFrom = queryParams.priceFrom;
    }

    // priceTo
    if (queryParams.priceTo) {
      this.priceTo = queryParams.priceTo;
    }

    // adSizeFrom
    if (queryParams.adSizeFrom) {
      this.adSizeFrom = queryParams.adSizeFrom;
    }

    // adSizeTo
    if (queryParams.adSizeTo) {
      this.adSizeTo = queryParams.adSizeTo;
    }

    // roomsFrom
    if (queryParams.roomsFrom) {
      this.roomsFrom = queryParams.roomsFrom;
    }

    // roomsTo
    if (queryParams.roomsTo) {
      this.roomsTo = queryParams.roomsTo;
    }

    // amenities
    if (queryParams.amenities) {
      const amenitiesArray = queryParams.amenities.split(",");
      Object.keys(this.amenities).forEach((key) => {
        this.amenities[key] = amenitiesArray.includes(key);
      });
    }
  }

  adPropertyTypeObjectToAdPropertyTypes() {
    this.adPropertyTypes = Object.entries(this.adPropertyTypeObject)
      .filter(([name, value]) => value)
      .map(([name, value]) => name);
  }

  sendRequest() {
    const queryParamObject = {};

    // locType
    queryParamObject["adType"] = this.adType;

    // property types
    // let propTypes: string;
    // const propTypesArray = Object.entries(this.adPropertyTypeObject)
    //   .filter(([name, value]) => value)
    //   .map(([name, value]) => name);
    // if (propTypesArray.length > 0) {
    //   propTypes = propTypesArray.join();
    // } else {
    //   propTypes = "";
    // }

    // if (propTypes !== "") {
    //   queryParamObject["propTypes"] = propTypes;
    // }

    const propTypesArray = Object.keys(this.adPropertyTypeObject).filter(name => this.adPropertyTypeObject[name]);
    const propTypes = propTypesArray.join();

    if (propTypes !== "") {
      queryParamObject["propTypes"] = propTypes;
    }

    // location
    if (this.location === "") {
      this.location = this.searchStr.toString().replace(/\s/g, "-");
    }
    if (this.location !== "") {
      queryParamObject["location"] = this.location;
    }

    // locType
    if (this.locType !== "") {
      queryParamObject["locType"] = this.locType;
    }

    // priceFrom
    if (this.priceFrom !== null) {
      if (this.adType === "buy") {
        queryParamObject["priceFrom"] = this.priceFrom;
      } else {
        queryParamObject["rentFrom"] = this.priceFrom;
      }
    }

    // priceTo
    if (this.priceTo !== null) {
      if (this.adType === "buy") {
        queryParamObject["priceTo"] = this.priceTo;
      } else {
        queryParamObject["rentTo"] = this.priceTo;
      }
    }

    // adSizeFrom
    if (this.adSizeFrom !== null) {
      queryParamObject["adSizeFrom"] = this.adSizeFrom;
    }

    // adSizeTo
    if (this.adSizeTo !== null) {
      queryParamObject["adSizeTo"] = this.adSizeTo;
    }

    // roomsFrom
    if (this.roomsFrom !== null) {
      queryParamObject["roomsFrom"] = this.roomsFrom; // .replace(',', '.');
    }

    // roomsTo
    if (this.roomsTo !== null) {
      queryParamObject["roomsTo"] = this.roomsTo; // .replace(',', '.');
    }

    queryParamObject["sort"] = "datemin";

    // amenities
    let amenities: string;
    const amenitiesArray = Object.entries(this.amenities)
      .filter(([name, value]) => value !== false)
      .map(([name, value]) => name);
    if (amenitiesArray.length > 0) {
      amenities = amenitiesArray.join();
    } else {
      amenities = "";
    }
    if (amenities !== "") {
      queryParamObject["amenities"] = amenities;
    }

    // language param
    queryParamObject["l"] = this.appLanguage === "en" ? "en" : null;
    // console.log(queryParamObject);

    // this.dataService.queryParamObject$
    //   .pipe(
    //     take(1) // todo, test // error, nested services
    //   )
    //   .subscribe((params) => {
    //     this.dataService.setQueryParamObject({
    //       ...params,
    //       ...queryParamObject,
    //     });
    //   });

    

    // const newUrlString = this.router.createUrlTree(
    //   ["/r"], // Get uri
    //   { queryParams: { ...queryParamObject } } // Pass all parameters inside queryParamsObj
    // ).toString();
    // this.windowService.nativeWindow.history.pushState({}, "", newUrlString);
    // this.router.navigateByUrl(newUrlString);



    // this.dataService.setQueryParamObject({ ...queryParamObject });

    const newUrlString = this.router
      .createUrlTree(
        ["/r"], // Get uri
        { queryParams: { ...queryParamObject } } // Pass all parameters inside queryParamsObj
      )
      .toString();

    this.router.navigateByUrl(newUrlString);
    // this.updateAds.emit({ ...params, ...queryParamObject });

    this.closeModal.emit(true);
  }
}

<ion-header class="shrinkable">
  <app-main-header
    [mobileView]="mobileView"
    [title]="'community wall'"
    [basePath]="'about'"
    [currentUser]="currentUser"
  ></app-main-header>
</ion-header>

<ion-content [fullscreen]="true" class="base-page-content">
  <div *ngIf="post" class="content-body container">
    <ion-card class="agent-card">
      <ion-item>
        <ion-avatar class="user-avatar">
          <app-profile-photo #profilePhoto [userId]="currentUser?._id" [size]="36">
          </app-profile-photo>
        </ion-avatar>
        
        <ion-label>
          <p>{{ post.userName }}</p>
          <p>{{ post.created | date: "short" }}</p>
        </ion-label>
      </ion-item>

      <ion-item>
        <p class="post-text">{{ post.text }}</p>
      </ion-item>

      <ion-row *ngIf="images.length > 0">
        <ion-col *ngFor="let item of images; let i = index"
                 size="6"
                 size-sm="4"
                 size-md="3"
                 size-lg="3">
          <div class="upload-preview-container">
            <img [src]="item"
                 class="upload-preview" />
          </div>
          <!-- <ion-fab *ngIf="item?.file && item.file['type'].indexOf('image') >= 0"
                    vertical="top" horizontal="end" (click)="removeImage(i);$event.stopPropagation()">
            <ion-fab-button size="small">
              <ion-icon name="remove"></ion-icon>
            </ion-fab-button>
          </ion-fab> -->
        </ion-col>
      </ion-row>
      <ion-button fill="outline"
                  [class.approve-button]="true" [disabled]="post.approved || post.rejected"
                  (click)="approvePost()">
                  <!-- -->
        <ion-icon [slot]="mobileView ? 'icon-only' : 'start'" name="checkmark-done-outline"></ion-icon>
        <span *ngIf="!mobileView && !post?.approved">Approve</span>
        <span *ngIf="!mobileView && post?.approved">Approved</span>
      </ion-button>

      <ion-button fill="outline"
                  [class.reject-button]="true"
                  (click)="rejectPost()">
                  <!-- [disabled]="post.statusOfPost[post.statusOfPost.length - 1]?.status === 3" -->
        <ion-icon [slot]="mobileView ? 'icon-only' : 'start'" name="close"></ion-icon>
        <span *ngIf="!mobileView && !post?.rejected">Reject</span>
        <span *ngIf="!mobileView && post?.rejected">Rejected</span>
      </ion-button>
    </ion-card>

  </div>

  <app-footer></app-footer>
</ion-content>

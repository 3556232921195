<ion-header class="shrinkable">
  <app-main-header
    [mobileView]="mobileView"
    [title]="title"
    [currentUser]="currentUser"
  ></app-main-header>
</ion-header>

<ion-content fullscreen class="form-content">
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <form *ngIf="currentUser" [formGroup]="adForm" (ngSubmit)="createOrUpdateAgency()">


          <ion-list>
            <ion-item-divider>
              {{ lang.basicInformation }}
            </ion-item-divider>

            <ion-item lines="none">
              <ion-input type="text" formControlName="companyName" required="true">{{ lang.companyName }} *</ion-input>
            </ion-item>

            <ion-item lines="none">
              <ion-input type="text" formControlName="address" required="true">{{ lang.address }} *</ion-input>
            </ion-item>

            <ion-item lines="none">
              <ion-input type="text" formControlName="phone">{{ lang.phoneNumber }}</ion-input>
            </ion-item>

            <ion-item lines="none">
              <ion-input type="text" formControlName="email">{{ lang.email }}</ion-input>
            </ion-item>

            <ion-item lines="none">
              <ion-input type="text" formControlName="website">{{ lang.website }}</ion-input>
            </ion-item>

            <ion-item lines="none">
              <ion-input type="text" formControlName="companyId">{{ lang.companyId }}</ion-input>
            </ion-item>

            <ion-item lines="none">
              <ion-input type="text" formControlName="taxNumber">{{ lang.taxNumber }}</ion-input>
            </ion-item>

            <ion-item lines="none">
              <ion-input type="text" formControlName="registryCity">{{ lang.registryCity }}</ion-input>
            </ion-item>


            
            <ion-item-divider>
              {{ lang.bank }}
            </ion-item-divider>

            <ion-item lines="none">
              <ion-input type="text" formControlName="bankName">{{ lang.bankName }}</ion-input>
            </ion-item>

            <ion-item lines="none">
              <ion-input type="text" formControlName="iban">{{ lang.iban }}</ion-input>
            </ion-item>

            <ion-item lines="none">
              <ion-input type="text" formControlName="swift">{{ lang.swift }}</ion-input>
            </ion-item>

            <ion-item lines="none">
              <ion-button [disabled]="adForm.get('companyName').value == '' ||
                                      adForm.get('address').value == ''"
                          expand="block"
                          type="submit"
                          size="default">
                {{ lang.saveAndPhotos }}
                <ion-spinner *ngIf="saving" name="bubbles"></ion-spinner>
              </ion-button>
            </ion-item>
            <p *ngIf="adForm.get('companyName').value == '' ||
                      adForm.get('address').value == ''">
              {{ lang.requiredFields }}
            </p>
          </ion-list>
        </form>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';

@Injectable()
export class FavoritesService {

  API: string;

  constructor(
    private http: HttpClient,
    private dataService: DataService,
  ) {
    this.API = this.dataService.API;
  }

  listUserFavorites(userId) {
    return this.http.get(`${this.API}/favorites/user/${userId}`);
  }

  addFavorite(itemId: string, authorId: string, itemOwnerId: string) {
    return this.http.post(`${this.API}/favorites/add`, {
      itemId,
      authorId,
      itemOwnerId
    });
  }

  removeFavorite(itemId: string, authorId: string, itemOwnerId: string) {
    return this.http.post(`${this.API}/favorites/remove`, {
      itemId,
      authorId,
      itemOwnerId
    });
  }

  checkFavorite(itemId: string, userId: string) {
    return this.http.get(`${this.API}/favorites/check/${itemId}/${userId}`);
  }

  countAdFavorites(itemId: string) {
    return this.http.get(`${this.API}/favorites/count?itemId=${itemId}`);
  }

  // dislikeItem(itemId: string, authorId: string, itemOwnerId: string) {
  //   // console.log(itemId, authorId, itemOwnerId, vote, replyToUser);
  //   return this.http.post(`${this.API}/favorites/dislike`, {
  //     itemId: itemId,
  //     authorId: authorId,
  //     itemOwnerId: itemOwnerId,
  //     vote: -1
  //   });
  // }
}

<ion-grid>
  <ion-row>
    <ion-col size="12">
      <h1 color="primary">
        {{ lang.createAdFree }}
      </h1>
    </ion-col>
    <ion-col size="12" size-md="6">
      <div class="create-text">
        <div>
          <p>{{ lang.propertyOnlineInMinutes }}</p>
          <p>{{ lang.increaseYourChances }}</p>
          <p>{{ lang.useOurTools }}</p>
          <ion-button class="create-ad-button" (click)="createNewAd()">
            {{ lang.createNewAd }}
          </ion-button>
        </div>
      </div>
    </ion-col>
    <ion-col size="12" size-md="6">
      <div class="create-text">
        <img src="../../../assets/images/realtor-3261160_640.png" alt="sell a house">
      </div>
    </ion-col>
  </ion-row>
</ion-grid>



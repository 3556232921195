<ion-header class="shrinkable">
  <app-main-header
    [mobileView]="mobileView"
    [title]="title"
    [currentUser]="currentUser"
  ></app-main-header>
</ion-header>

<ion-content
  class="profile-messages-component"
  *ngIf="currentUser && currentUser._id"
>
  <ion-grid>
    <ion-card>
      <ion-list>
        <div *ngIf="!selectedConversation">
          <ion-item-divider *ngIf="currUserMessages">
            {{ lang.myConversations }} ({{ currUserMessages.length }})
          </ion-item-divider>
          <ion-item *ngIf="currUserMessages.length < 1">
            <p>
              {{ lang.noMessagesYet }} <a routerLink="/h">{{ lang.goToHomepage }}</a>
            </p>
          </ion-item>
          <ion-item
            *ngFor="let conversation of currUserMessages"
            class="conversation"
            (click)="selectConversation(conversation)">
            <ion-label>
              <p>
                <b>{{ conversation.userName }}</b> -
                <span>{{ conversation.messages[conversation.messages.length - 1].sendDate | date : "short" }}</span>
              </p>
              <p>
                <span style="font-style: italic">"{{ conversation.messages[conversation.messages.length - 1].message }}"</span>
              </p>
            </ion-label>
            <ion-button fill="clear" (click)="removeConversation(conversation); $event.stopPropagation()">
              {{ lang.remove }}
            </ion-button>
          </ion-item>
        </div>
        <div *ngIf="selectedConversation">
          <ion-item-divider>
            {{ lang.myConversationWith }} {{ selectedConversation.userName }}
            <ion-button fill="clear" (click)="selectedConversation = null; contactMessage = ''" slot="end">
              <ion-icon slot="icon-only" name="arrow-back-outline"></ion-icon>
              {{ lang.back }}
            </ion-button>
          </ion-item-divider>
          <ion-item>
            <ion-label>
              <div
                *ngFor="let message of selectedConversation.messages"
                class="message-bubble"
                [ngClass]="{ owner: message.from === this.currentUser._id }"
              >
                <p>
                  <small>{{ message.sendDate | date : "short" }}</small>
                </p>
                <p class="message-body">{{ message.message }}</p>
                <div
                  class="action-bubble"
                  *ngIf="message.from === this.currentUser._id"
                  (click)="removeMessage(message); $event.stopPropagation()"
                >
                  <ion-icon
                    slot="icon-only"
                    color="danger"
                    name="trash"
                  ></ion-icon>
                </div>
                <div class="action-bubble"
                     *ngIf="message.from !== this.currentUser._id"
                     (click)="flagMessage($event, message); $event.stopPropagation()">
                  <ion-icon slot="icon-only" name="flag"></ion-icon>
                </div>
              </div>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-input
              item-start
              type="text"
              [placeholder]="lang.yourMessage"
              [(ngModel)]="contactMessage"
            ></ion-input>
            <ion-button
              size="default"
              [disabled]="contactMessage.length < 1"
              (click)="sendMessage(
                this.currentUser._id,
                this.contactMessage,
                selectedConversation._id,
                null,
                'Sporočilo je:')">
              {{ lang.send }}
              <ion-icon slot="end" name="send"></ion-icon>
            </ion-button>
          </ion-item>
        </div>
      </ion-list>
    </ion-card>
  </ion-grid>
</ion-content>

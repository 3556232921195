<ion-header>
  <ion-toolbar color="primary">
    <ion-title>
      {{ lang.providePhoneNumber }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="phone-modal-content">
  <p>{{ lang.phoneRequirement }}</p>
  <ion-item>
    <ion-input labelPlacement="stacked" name="phone" [(ngModel)]="phoneNumber" type="text">{{ lang.phoneNumber }}</ion-input>
  </ion-item>
  <br>
  <ion-button [disabled]="phoneNumber.length < 9"
              expand="block"
              color="primary"
              (click)="submit()">
    {{ lang.submitNumber }}
  </ion-button>
  <p class="ion-text-center">
    {{ lang.orGoToYour }} <a [routerLink]="['/p', currentUserId]" (click)="dismiss()"><span style="text-transform: lowercase;">{{ lang.profile }}</span></a>.
  </p>  
</ion-content>
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CurrentUser } from '../../model/current-user';
import { ModalController, ToastController } from '@ionic/angular';

import { LoginModalComponent } from '../../components/modals/login-modal/login-modal.component';
import { FavoritesService } from '../../services/favorites.service';
import { AdViewService } from '../../services/ad-view.service';
import { DataService } from '../../services/data.service';
import { SocketService } from "../../services/socket.service";
import { PropertyType } from '../../model/enums/property-type';

@Component({
  selector: 'app-card-wide',
  templateUrl: 'ad-banner.component.html',
  styleUrls: ['./ad-banner.component.scss']
})
export class AdBannerComponent implements OnInit {

  isFavorite = false;
  favoritesCount = 0;
  adViewsCount = 0;

  @Input() item: any;
  @Input() imageUrls: string[] = []; // ad images
  @Input() lang: any;
  @Input() appLanguage: any;
  @Input() currentUser: CurrentUser;
  @Input() selectedAdId: string;
  @Output() openPreviewEmitter: EventEmitter<string> = new EventEmitter();

  constructor(
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private favoritesService: FavoritesService,
    // private changeDetectorRef: ChangeDetectorRef,
    private adViewService: AdViewService,
    private dataService: DataService,
    private socketService: SocketService,
  ) {}

  ngOnInit() {
    // console.log(this.item);
    if (this.currentUser) {
      this.checkFavorite();
    }

    this.countAdFavorites();
    this.countAdViews();

    this.socketService.adChanges$.subscribe((msg) => {
      if (msg["itemId"] === this.item._id) {
        if (msg["type"] === "favoriteChanges") {
          this.checkFavorite();
        }
      }
    });
  }

  get isNotBuilding(): boolean {
    return this.item?.adPropertyType === PropertyType.Estate ||
           this.item?.adPropertyType === PropertyType.Parking ||
           this.item?.adPropertyType === PropertyType.Farm;
  }

  setActiveProperty(propertyId: string): void {
    this.dataService.setSelectedAdSource(propertyId);
  }

  openPrevieModal() {
    this.openPreviewEmitter.emit(this.item);
  }

  openNewTab(itemId) {
    window.open('/ad/' + itemId, '_blank');
  }

  async toggleFavorite(itemId: string, authorId: string) {
    if (this.currentUser === undefined) {
      this.presentLoginModal();
      return null;
    }

    if (this.currentUser._id === authorId) {
      const toast = await this.toastCtrl.create({
        message: this.lang['messageToOwner'],
        duration: 3000
      });
      toast.present();
      return;
    }

    if (!this.isFavorite) {
      this.favoritesService.addFavorite(itemId, this.currentUser._id, 'someOwnerId')
      .subscribe(() => {
        this.isFavorite = true; // shortcut to save one requst
        this.countAdFavorites();
      });
    } else {
      this.favoritesService.removeFavorite(itemId, this.currentUser._id, 'someOwnerId')
        .subscribe(() => {
          this.isFavorite = false;
          this.countAdFavorites();
        });
    }
  }

  checkFavorite() {
    if (this.currentUser === undefined) {
      this.isFavorite = false;
      return
    };
    this.favoritesService.checkFavorite(this.item._id, this.currentUser._id)
      .subscribe(result => {
        this.isFavorite = result['isFavorite'];
        // this.changeDetectorRef.detectChanges();
      });
  }

  countAdFavorites() {
    this.favoritesService.countAdFavorites(this.item._id)
      .subscribe(result => {
        this.favoritesCount = result['favoritesCount'];
      });
  }

  countAdViews() {
    this.adViewService.countAdView(this.item._id)
      .subscribe(result => {
        this.adViewsCount = result['adViewsCount'];
      });
  }

  async presentLoginModal() {
    const modal = await this.modalCtrl.create({
      component: LoginModalComponent,
      componentProps: {
        updateList: (name) => {
          // this.updateList();
        },
        // item: item,
        lang: this.lang,
        appLanguage: this.appLanguage
      },
      cssClass: 'login-modal'
    });
    modal.present();
  }
}

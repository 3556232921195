<ion-grid>
  <ion-row>
    <ion-col size="12">
      <div class="container contact-section">
        <script src="https://www.google.com/recaptcha/api.js" async defer></script>
        <h1>{{ lang.sendUsMessage }}</h1>

        <ng-container *ngIf="appLanguage === 'sl'">
          <p>
            Imaš vprašanje glede naših storitev in cenikov ali imaš predlog za
            nas? Pošlji nam sporočilo s pomočjo spodnjega obrazca in odgovorili
            ti bomo v najkrajšem možnem roku.
          </p>
        </ng-container>

        <!-- English -->
        <ng-container *ngIf="appLanguage === 'en'">
          <p>
            If you have a question regarding our services, pricing or have a
            suggestion, don't hesitate to contact us with the help of the
            contact form below, and we will get back to you as soon as possible.
          </p>
        </ng-container>

        <form
          class="form"
          *ngIf="contactForm"
          [formGroup]="contactForm"
          (ngSubmit)="sendMessage()">
          <ion-item class="ion-no-padding">
            <ion-input
              class="contact-form-field" 
              labelPlacement="stacked"
              autocomplete="on"
              name="name"
              formControlName="name"
              type="text">
              {{ lang.firstName }}
            </ion-input>
          </ion-item>

          <ion-item class="ion-no-padding">
            <ion-input
              class="contact-form-field" 
              labelPlacement="stacked"
              autocomplete="on"
              formControlName="email"
              type="email"
              name="email">
              {{ lang.email }}
            </ion-input>
          </ion-item>

          <ion-item class="ion-no-padding">
            <ion-input
              class="contact-form-field" 
              labelPlacement="stacked"
              autocomplete="on"
              name="title"
              formControlName="title"
              type="text">
              {{ lang.messageTitle }}
            </ion-input>
          </ion-item>

          <ion-item class="ion-no-padding">
            <ion-textarea
              class="contact-form-field message-textarea"
              labelPlacement="stacked"
              rows="7"
              type="message"
              name="message"
              formControlName="message">
              {{ lang.yourMessage }}
            </ion-textarea>
          </ion-item>

          <ion-item lines="none">
            <re-captcha (resolved)="resolved($event)" siteKey="6LeOEsUkAAAAADzfqBPAIRcnYfQQ1qZb9-0bOO91"></re-captcha>
          </ion-item>

          <ion-button [disabled]="!captcha || !contactForm.valid" type="submit">
            <ion-icon slot="start" color="white" name="mail"></ion-icon>
            {{ lang.sendEmail }}
          </ion-button>
        </form>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>

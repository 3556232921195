import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';

@Injectable()
export class LikesService {

  API: string;

  constructor(
    private http: HttpClient,
    private dataService: DataService,
  ) {
    this.API = this.dataService.API;
  }

  // listItemLikes(itemId) {
  //   return this.http.get(`${this.API}/likes/list?itemId=${itemId}`);
  // }

  countItemLikes(itemId: string) {
    return this.http.get(`${this.API}/likes/count?itemId=${itemId}`);
  }

  voteOnItem(itemId: string, authorId: string, itemOwnerId: string, vote: number) {
    // console.log(itemId, authorId, itemOwnerId, vote);
    return this.http.post(`${this.API}/likes/vote`, {
      itemId,
      authorId,
      itemOwnerId,
      vote
    });
  }

  // dislikeItem(itemId: string, authorId: string, itemOwnerId: string) {
  //   // console.log(itemId, authorId, itemOwnerId, vote, replyToUser);
  //   return this.http.post(`${this.API}/likes/dislike`, {
  //     itemId: itemId,
  //     authorId: authorId,
  //     itemOwnerId: itemOwnerId,
  //     vote: -1
  //   });
  // }
}

<ion-grid class="location-links">
  <ion-row>
    <ion-col size="12">
      <h1 color="primary">
        {{ lang.findHomeIn }}
      </h1>
    </ion-col>
    <!-- <p *ngIf="locations.length < 1" class="no-items-message">{{ lang.noItemsToShow }}</p> -->
    <ion-col *ngFor="let location of locations | slice:0:42; let i = index"
              size="6"
              size-sm="4"
              size-md="3">
      <p class="location-link"><a [routerLink]="['/r']" [queryParams]="location.queryParams">{{ location.q }}</a></p>
    </ion-col>
  </ion-row>
</ion-grid>

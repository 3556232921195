import { Component } from "@angular/core";
import { NavParams, PopoverController } from "@ionic/angular";

@Component({
  templateUrl: "./language-menu-popover.component.html",
  styleUrls: ["./language-menu-popover.component.scss"],
})
export class PopoverLanguageComponent {
  lang: any;

  constructor(
    private popoverCtrl: PopoverController,
    public params: NavParams
  ) {
    this.lang = params.get("lang");
  }

  setLanguage(language: string) {
    this.params.get("setLang")(language);
    this.popoverCtrl.dismiss();
  }
}

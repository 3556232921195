import {
  Injectable,
} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot
} from '@angular/router';

import { LanguageService } from '../services/language.service';
import { combineLatest, map, Observable, of } from 'rxjs';

@Injectable()
export class CurrentLanguageResolver implements Resolve<any> {
  constructor(
    private languageService: LanguageService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): any {
    // return this.languageService.getLangFromServer(route.queryParams);
    // add switch for more languages in the future
    // let currentLanguage = route.queryParams["l"] === "en" ? "en" : "sl";
    // return of(currentLanguage);

    return this.getLanguages(route, state);
  }

  getLanguages (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

      let languageFromParam = route.queryParams["l"] === "en" ? "en" : "sl";
      let currentLanguage = of(languageFromParam);
      let lang = of(this.languageService[languageFromParam]);
  
      return combineLatest([currentLanguage, lang]).pipe(
        map(
          ([currentLanguage, lang]) => {
  
            return { currentLanguage: currentLanguage, lang: lang };
          }
        ));
  }
}

import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { io } from "socket.io-client";
import { DataService } from "./data.service";

@Injectable()
export class SocketService {
  socket: any = null;

  adChangesSource = new BehaviorSubject<object>({});
  adChanges$ = this.adChangesSource.asObservable();

  adPhotoChangesSource = new BehaviorSubject<object>({});
  adPhotoChanges$ = this.adPhotoChangesSource.asObservable();

  profilePhotoChangesSource = new BehaviorSubject<object>({});
  profilePhotoChanges$ = this.profilePhotoChangesSource.asObservable();

  messageChangesSource = new BehaviorSubject<object>({});
  messageChanges$ = this.messageChangesSource.asObservable();

  viewHistoryChangesSource = new BehaviorSubject<object>({});
  viewHistoryChanges$ = this.viewHistoryChangesSource.asObservable();

  commentChangesSource = new BehaviorSubject<object>({});
  commentChanges$ = this.commentChangesSource.asObservable();

  likeChangesSource = new BehaviorSubject<object>({});
  likeChanges$ = this.likeChangesSource.asObservable();

  postChangesSource = new BehaviorSubject<object>({});
  postChanges$ = this.postChangesSource.asObservable();

  constructor(private dataService: DataService) {
    this.dataService.isBrowser$.subscribe((isBrowser) => {
      // console.log(isBrowser);
      if (isBrowser) {
        this.initSocket();
      }
    });
  }

  initSocket(): void {
    let apiString = this.dataService.API;
    const stringToReplace = "/api";
    const newString = "";
    const n = apiString.lastIndexOf(stringToReplace);
    apiString =
      apiString.slice(0, n) +
      apiString.slice(n).replace(stringToReplace, newString);
    this.socket = io(apiString);
    this.initSocketSwitch();
  }

  initSocketSwitch(): void {
    this.socket.on("updatedPropertiesEvent", (msg) => {
      switch (msg["type"]) {
        case "adActivated":
        case "adDeactivated":
        case "favoriteChanges":
          this.notifyAdChanges(msg);
          break;
        case "photosUpdated":
          this.notifyAdPhotoChanges(msg);
          break;
        case "profilePhotoUpdated":
          this.notifyProfilePhotoChanges(msg);
          break;
        case "messageAdded":
        case "messageRemoved":
          this.notifyMessageChanges(msg);
          break;
        case "userViewHistoryUpdated":
          this.notifyViewHistoryChanges(msg);
          break;
        case "commentChanges":
          this.notifyCommentChanges(msg);
          break;
        case "likeChanges":
          this.notifyLikeChanges(msg);
          break;
        case "postChanges":
          this.notifyPostChanges(msg);
          break;

        default:
          break;
      }
    });
  }

  notifyAdChanges(queryParamObject: object) {
    this.adChangesSource.next(queryParamObject);
  }

  notifyAdPhotoChanges(queryParamObject: object) {
    this.adPhotoChangesSource.next(queryParamObject);
  }

  notifyProfilePhotoChanges(queryParamObject: object) {
    this.profilePhotoChangesSource.next(queryParamObject);
  }

  notifyMessageChanges(messageChangedObject: object) {
    this.messageChangesSource.next(messageChangedObject);
  }

  notifyViewHistoryChanges(viewHistoryChangedObject: object) {
    this.viewHistoryChangesSource.next(viewHistoryChangedObject);
  }

  notifyCommentChanges(commentChangedObject: object) {
    this.commentChangesSource.next(commentChangedObject);
  }

  notifyLikeChanges(likeChangedObject: object) {
    this.likeChangesSource.next(likeChangedObject);
  }

  notifyPostChanges(postChangedObject: object) {
    this.postChangesSource.next(postChangedObject);
  }
}

import { APP_ID, CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import {
  BrowserModule,
  provideClientHydration,
  // BrowserTransferStateModule,
} from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { HttpClientModule, HTTP_INTERCEPTORS, provideHttpClient } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FileUploadModule } from "ng2-file-upload";
import { DecimalPipe } from "@angular/common";
import { RecaptchaModule, RecaptchaFormsModule } from "ng-recaptcha";
import {
  NgcCookieConsentModule,
  NgcCookieConsentConfig,
} from "ngx-cookieconsent";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
// import { Ng2CompleterModule } from "ng2-completer";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserStateInterceptor } from "./interceptors/browser-state.interceptor";
import { TruncatePipe } from "./pipes/truncate.pipe";
import { HeaderInterceptor } from "./interceptors";

// Services
import { BucketService } from "./services/bucket.service";
import { LoginService } from "./services/login.service";
import { LanguageService } from "./services/language.service";
import { DataService } from "./services/data.service";
import { LocationService } from "./services/location.service";
import { LikesService } from "./services/likes.service";
import { CommentService } from "./services/comment.service";
import { FlagService } from "./services/flag.service";
import { UserDataService } from "./services/user-data.service";
import { MessageService } from "./services/message.service";
import { FavoritesService } from "./services/favorites.service";
import { ViewHistoryService } from "./services/view-history.service";
import { AdViewService } from "./services/ad-view.service";
import { UserQueryService } from "./services/user-query.service";
import { LeafletService } from "./services/leaflet.service";
import { RealEstateAgencyService } from "./services/re-agency.service";
import { SocketService } from "./services/socket.service";
import { MetaService } from "./services/meta.service";
import { SearchSubscriptionService } from "./services/search-subscription.service";
import { PostsService } from "./services/posts.service";

// Components
import { AppComponent } from "./app.component";
import { HomeComponent } from "./components/home/home.component";
import { LoginComponent } from "./components/login/login.component";
import { MainHeaderComponent } from "./components/header/header.component";
import { SearchModalComponent } from "./components/modals/search-modal/search-modal.component";
import { LoginModalComponent } from "./components/modals/login-modal/login-modal.component";
import { SortAdsPopoverComponent } from "./components/popovers/sort-ads/sort-ads-popover.component";
import { ViewModeMenuComponent } from "./components/popovers/view-mode/view-mode-popover.component";
import { PopoverProfileComponent } from "./components/popovers/profile-menu/profile-menu-popover.component";
import { ProfilePhotoComponent } from "./components/profile-photo/profile-photo.component";
import { LatestAdsComponent } from "./components/latest-ads/latest-ads.component";
import { SearchFormComponent } from "./components/search-form/search-form.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { PhotoOptionsPopoverComponent } from "./components/popovers/photo-options/photo-options-popover.component";
import { FileUploadComponent } from "./components/file-upload/file-upload.component";
import { FlagReasonsPopoverComponent } from "./components/popovers/flag-reasons/flag-reasons-popover.component";
import { PreviewModalComponent } from "./components/modals/preview-modal/preview-modal.component";
import { AdDetailComponent } from "./components/ad-detail/ad-detail.component";
import { AdCardComponent } from "./components/ad-card/ad-card.component";
import { LastViewedComponent } from "./components/last-viewed/last-viewed.component";
import { CreateSectionComponent } from "./components/create-section/create-section.component";
import { LocationLinksComponent } from "./components/location-links/location-links.component";
import { ImageSliderComponent } from "./components/image-slider/image-slider.component";
import { ProfileFavoritesComponent } from "./components/profile-favorites/profile-favorites.component";
import { ProfileViewHistoryComponent } from "./components/profile-view-history/profile-view-history.component";
import { ProfileSavedSearchesComponent } from "./components/profile-saved-searches/profile-saved-searches.component";
import { ProfileMessagesComponent } from "./components/profile-messages/profile-messages.component";
import { ProfileCommentsComponent } from "./components/profile-comments/profile-comments.component";
import { CommentsComponent } from "./components/comments/comments.component";
import { KebabOptionsPopoverComponent } from "./components/popovers/kebab-options/kebab-options-popover.component";
import { PopoverLanguageComponent } from "./components/popovers/language-menu/language-menu-popover.component";
import { RegisterComponent } from "./components/register/register.component";
import { DetailPageComponent } from "./components/detail-page/detail-page.component";
import { PhotoPreviewModalComponent } from "./components/modals/photo-preview/photo-preview-modal.component";
import { AdBannerComponent } from "./components/ad-banner/ad-banner.component";
import { MapViewMenuComponent } from "./components/popovers/map-view/map-view-popover.component";
import { ResultPageComponent } from "./components/result-page/result-page.component";
import { PhoneFormComponent } from "./components/modals/phone-form/phone-form.component";
import { AdFormComponent } from "./components/ad-form/ad-form.component";
import { PhotosComponent } from "./components/photos/photos.component";
import { BasePageComponent } from "./components/base-page/base-page.component";
import { FooterComponent } from "./components/footer/footer.component";
import { AboutInfoComponent } from "./components/about-info/about-info.component";
import { GeneralTermsComponent } from "./components/general-terms/general-terms.component";
import { PrivacyPolicyComponent } from "./components/privacy-policy/privacy-policy.component";
import { AdvertisingComponent } from "./components/advertising/advertising.component";
import { ContactUsComponent } from "./components/contact-us/contact-us.component";
import { AgencyListComponent } from "./components/agency-list/agency-list.component";
import { AgencyPageComponent } from "./components/agency-page/agency-page.component";
import { AgentCardComponent } from "./components/agent-card/agent-card.component";
import { AgencyFormComponent } from "./components/agency-form/agency-form.component";
import { UnsubscribeComponent } from "./components/unsubscribe/unsubscribe.component";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { WallPageComponent } from "./components/wall-page/wall-page.component";
import { PostModalComponent } from "./components/modals/post-modal/post-modal.component";
import { PostCheckPageComponent } from "./components/post-check-page/post-check-page.component";
import { PostPreviewModalComponent } from "./components/modals/post-preview-modal/post-preview-modal.component";

// Resolvers
import { LocationLinkResolver } from "./resolvers/location-link.resolver";
import { SingleAdResolver } from "./resolvers/single-ad.resolver";
import { CurrentLanguageResolver } from "./resolvers/current-language.resolver";
import { AdImageResolver } from "./resolvers/ad-image.resolver";
import { CookieComponent } from "./cookie.component";

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
    // domain: "localhost", // or 'https://kvadrat.si'
    domain: 'https://kvadrat.si'
  },
  palette: {
    popup: {
      background: "#3880ff",
    },
    button: {
      background: "#4fffb4",
    },
  },
  position: "bottom-right",
  theme: "classic",
  type: "opt-in",
  content: {
    href: "/privacy",
  },
};

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        SearchModalComponent,
        LoginModalComponent,
        SortAdsPopoverComponent,
        ViewModeMenuComponent,
        PopoverProfileComponent,
        LoginComponent,
        MainHeaderComponent,
        ProfilePhotoComponent,
        TruncatePipe,
        LatestAdsComponent,
        SearchFormComponent,
        ProfileComponent,
        PhotoOptionsPopoverComponent,
        FileUploadComponent,
        FlagReasonsPopoverComponent,
        PreviewModalComponent,
        AdDetailComponent,
        AdCardComponent,
        LastViewedComponent,
        CreateSectionComponent,
        LocationLinksComponent,
        ImageSliderComponent,
        ProfileFavoritesComponent,
        ProfileViewHistoryComponent,
        ProfileSavedSearchesComponent,
        ProfileMessagesComponent,
        ProfileCommentsComponent,
        CommentsComponent,
        KebabOptionsPopoverComponent,
        RegisterComponent,
        PopoverLanguageComponent,
        PhotoPreviewModalComponent,
        MapViewMenuComponent,
        PhoneFormComponent,
        DetailPageComponent,
        AdBannerComponent,
        ResultPageComponent,
        AdFormComponent,
        PhotosComponent,
        BasePageComponent,
        FooterComponent,
        AboutInfoComponent,
        GeneralTermsComponent,
        PrivacyPolicyComponent,
        AdvertisingComponent,
        ContactUsComponent,
        CookieComponent,
        AgencyListComponent,
        AgencyPageComponent,
        AgentCardComponent,
        AgencyFormComponent,
        UnsubscribeComponent,
        PageNotFoundComponent,
        WallPageComponent,
        PostModalComponent,
        PostCheckPageComponent,
        PostPreviewModalComponent,
    ],
    imports: [
        BrowserModule, // .withServerTransition({ appId: "serverApp" }),
        // BrowserTransferStateModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        // Ng2CompleterModule,
        FileUploadModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        NgcCookieConsentModule.forRoot(cookieConfig),
        BrowserAnimationsModule,
        // CalendarModule.forRoot({
        //   closeIcon: true
        // }),
    ],
    providers: [
        { provide: APP_ID, useValue: 'serverApp' },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        BucketService,
        LoginService,
        LanguageService,
        DataService,
        LocationService,
        LikesService,
        CommentService,
        FlagService,
        UserDataService,
        MessageService,
        FavoritesService,
        ViewHistoryService,
        AdViewService,
        UserQueryService,
        LeafletService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BrowserStateInterceptor,
            multi: true,
        },
        DecimalPipe,
        LocationLinkResolver,
        SingleAdResolver,
        CurrentLanguageResolver,
        AdImageResolver,
        RealEstateAgencyService,
        SocketService,
        MetaService,
        SearchSubscriptionService,
        provideClientHydration(),
        provideHttpClient(), // withFetch() prop,
        PostsService,
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    bootstrap: [AppComponent]
})
export class AppModule {}

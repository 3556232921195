<ion-header class="shrinkable">
  <app-main-header
    [title]="title"
    [basePath]="'not-found'"
  ></app-main-header>
</ion-header>

<ion-content>
  <ion-grid>
    <h1>404 - Page Not Found</h1>
  </ion-grid>
</ion-content>

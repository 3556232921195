<ion-list class="view-mode" lines="full">
  <ion-item button *ngIf="!mobileView" class="viewmode-button" (click)="onSetViewMode('list-map')">
    <span>{{ lang.list }} + {{ lang.map }}</span>
  </ion-item>
  <ion-item button *ngIf="!mobileView" class="viewmode-button" (click)="onSetViewMode('card-map')">
    <span>{{ lang.cards }} + {{ lang.map }}</span>
  </ion-item>
  <ion-item button *ngIf="!mobileView" class="viewmode-button" (click)="onSetViewMode('list')">
    <span>{{ lang.list }}</span>
  </ion-item>
  <ion-item button class="viewmode-button" (click)="onSetViewMode('card')">
    <span>{{ lang.cards }}</span>
  </ion-item>
  <ion-item button class="viewmode-button" (click)="onSetViewMode('map')">
    <span>{{ lang.map }}</span>
  </ion-item>
</ion-list>
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, CanActivate } from '@angular/router';

// Import RxJs required methods
import { map } from 'rxjs/operators';
import 'url-search-params-polyfill';

import { DataService } from '../services/data.service';
import { LocalStorageService } from './localstorage.service';
import { SessionStorageService } from './sessionstorage.service';
import { LanguageService } from '../services/language.service';

import { CurrentUser } from '../model/current-user';

@Injectable()
export class LoginService implements CanActivate {

  JSON_REQUEST_HEADERS = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  // loggedInSource = new BehaviorSubject<boolean>(false);
  // loggedIn$ = this.loggedInSource.asObservable();

  API: string;
  SESSION_TOKEN: string;
  appLanguage: string;

  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    public router: Router,
    private languageService: LanguageService,
  ) {
    this.API = this.dataService.API;

    this.SESSION_TOKEN = this.getToken();
    // console.log(window.location.href);
    // this.isLoggedIn();

    this.languageService.language$.subscribe(value => {
      this.appLanguage = value;
    });
  }

  register(name: string, email: string, password: string, passwordConfirm: string) {
    const body = JSON.stringify({
      name,
      email,
      password,
      passwordConfirm
    });
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.http.post(this.API + '/users/register', body, {
      headers
    });
  }

  login(email: string, password: string) {
    const body = JSON.stringify({
      email,
      password
    });
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.http.post(this.API + '/users/login', body, {
      headers
    })
    .pipe(
      map(res => {
        if (res['token']) {
          this.checkTokenAndSetLoggedIn(res['token']);
        }
        return res;
      })
    );
  }

  verifyRegistration(email: string, random: string) {
    // console.log(email, random);
    return this.http.get(`${this.API}/users/verify/?email=${email}&random=${random}`);
  }

  forgotPassword(email: string) {
    const body = JSON.stringify({
      email
    });
    // console.log(body);

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.http.post(this.API + '/users/user/reset-forgot-password', body, {
      headers
    });
      // .toPromise()
      // .then(this.checkResponse)
      // .catch(this.logError);
  }

  // resetPasswordRequest(email: string, random: string, password: string) {
  resetPasswordRequest(email: string, password: string, passwordConfirm: string) {
    const body = {
      email,
      password,
      passwordConfirm,
    };
    // console.log(body);

    return this.http.post(this.API + '/users/reset-password', body);
      // .toPromise()
      // .then(this.checkResponse)
      // .catch(this.logError);
  }

  currentUser(): CurrentUser {
    const controlValue = this.isLoggedIn();
    if (controlValue) {
      // let token = this.getToken();
      const token = this.SESSION_TOKEN;
      // console.log(token);
      if (token) {
        const payload: CurrentUser = JSON.parse(this.b64DecodeUnicode(token.split('.')[1]));
        // console.log(payload);
        return {
          email: payload.email,
          name: payload.name,
          _id: payload._id,
        };
      } else {
        return {
          email: null,
          name: null,
          _id: null,
        };
      }
    }
  }

  isLoggedIn(): boolean {
    // let token = this.getToken();
    const token = this.SESSION_TOKEN;
    // this.dataService.sessionToken$
    //   .take(1)
    //   .subscribe(sessionToken => {
    //     token = sessionToken;
    //     console.log(token);
    //     // console.log(this.SESSION_TOKEN);
    //     if (token === '' || token === null) {
    //       token
    //       // console.log(token);
    //     }
    //   });
    // console.log(token);
    if (token) {
      const payload = JSON.parse(this.b64DecodeUnicode(token.split('.')[1]));
      // this.loggedInSource.next(payload.exp > Date.now() / 1000);
      // this.SESSION_TOKEN = token;
      // this.checkTokenAndSetLoggedIn(token);
      // if (payload.exp > Date.now() / 1000) {
      //   return true;
      // } else {
      //   this.logout();
      //   return false
      // }
      return payload.exp > Date.now() / 1000;
    } else {
      // this.loggedInSource.next(false);
      return false;
    }
  }

  canActivate(): boolean {
    if (!this.isLoggedIn()) {
      this.dataService.isBrowser$.subscribe((isBrowser) => {
        if (isBrowser) {
          this.router.navigate(
            [''],
            { queryParams: { l: this.appLanguage === "en" ? "en" : null } }
          );
        }
      });
      return false;
    }
    return true;
  }

  logout() {
    // console.log('LOG OUT');
    this.sessionStorageService.removeItem('token');
    this.localStorageService.removeItem('token');

    // if (localStorage) {
    //   // this.loggedInSource.next(false);
    // } else {
    //   // FALLBACK
    // }
    this.SESSION_TOKEN = null;
    this.dataService.setSessionToken(null);
    this.dataService.setLoggedInSource(false);
    this.canActivate();
  }

  // save to localStorage
  saveToken(token: string): void {
    if (token !== '') {
      // return localStorage.setItem('token', token);
      this.localStorageService.setItem('token', token);
      return null;
    } else {
      // FALLBACK
      return null;
    }
  }

  // save to sessionStorage and observable
  saveBrowserSession(token: string) {
    this.sessionStorageService.setItem('token', token);
    this.saveSessionToken(token);
    this.checkTokenAndSetLoggedIn(token);
    // this.SESSION_TOKEN = token;
  }

  saveSessionToken(token) {
    this.dataService.setSessionToken(token);
  }

  checkTokenAndSetLoggedIn(token: string) {
    // console.log(token);
    if (token && token !== '') {
      const payload = JSON.parse(this.b64DecodeUnicode(token.split('.')[1]));
      // console.log(payload.exp > Date.now() / 1000);
      if (payload.exp > Date.now() / 1000) {
        this.SESSION_TOKEN = token;
        this.dataService.setSessionToken(token);
        this.dataService.setLoggedInSource(payload.exp > Date.now() / 1000);
      }
    } else {
      // this.loggedInSource.next(false);
      this.logout();
    }
  }

  getToken() {
    // console.log('GET TOKEN');
    const token = this.SESSION_TOKEN ||
                this.sessionStorageService.getItem('token') ||
                this.localStorageService.getItem('token');

    if (token === null || token === '') {
      // FALLBACK
      // console.log('there is no token');
      return null;
    } else {
      // this.SESSION_TOKEN = token;
      this.checkTokenAndSetLoggedIn(token);
      // this.dataService.setSessionToken(token);
      return token;
    }
  }

  // fix to decode utf-8 from base64
  private b64DecodeUnicode(str) {
    return decodeURIComponent(Array.prototype.map.call(atob(str), (c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

  oauthGoogle(credential: string, select_by: string) {
    return this.http.post(`${this.API}/users/oauth-google`, {
      credential,
      select_by,
    });
  }

  oauthFacebook(authResponse: any) {
    return this.http.post(`${this.API}/users/oauth-facebook`, {
      authResponse
    });
  }
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DataService } from "./data.service";

@Injectable()
export class RealEstateAgencyService {
  // Link to our api
  API: string;

  constructor(private http: HttpClient, private dataService: DataService) {
    this.API = this.dataService.API;
  }

  getRealEstateAgencyAll() {
    // console.log(ad);
    return this.http.get(`${this.API}/re-agency/all`);
  }

  getRealEstateAgencySingle(agencyId: string) {
    // console.log(agencyId);
    return this.http.get(`${this.API}/re-agency/single/${agencyId}`);
  }

  addRealEstateAgency(data: any, userId: string) {
    return this.http.post(`${this.API}/re-agency/add`, {
      data,
      userId,
    });
  }

  updateRealEstateAgency(agencyId: string, data: any, userId: string) {
    // console.log(ad, user, comment, replyToRealEstateAgency, replyToUser);
    return this.http.post(`${this.API}/re-agency/update`, {
      agencyId,
      data,
      userId,
    });
  }

  // Delete real estate agency
  // todo validate user, userId: string
  deleteRealEstateAgency(agencyId: string) {
    // console.log(agencyId);
    return this.http.delete(`${this.API}/re-agency/delete?id=${agencyId}`);
  }
}

<ion-header class="detail-page-header">
  <ion-toolbar color="primary" class="main-header">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <img
      *ngIf="!mobileView"
      routerLink=""
      [queryParams]="{ l: languageParams }"
      class="page-logo"
      src="../../../assets/images/k-logo-text-50-white.png"
      alt="kvadrat"
    />
    <img
      *ngIf="mobileView"
      routerLink=""
      [queryParams]="{ l: languageParams }"
      class="page-logo"
      src="../../../assets/images/kvad-logo-50-white.png"
      alt="kvadrat"
    />

    <ion-buttons slot="end" *ngIf="ad && ad._id && !ad['adExpired']">
      <ion-button class="action-button" (click)="callNumber()">
        <ion-icon
          slot="start"
          [ngClass]="{ 'icon-only': mobileView }"
          name="call"
        ></ion-icon>
        <span *ngIf="!mobileView">{{ lang.call }}</span>
      </ion-button>
      <ion-button
        *ngIf="!mobileView || currentUser"
        class="action-button"
        (click)="scrollTo(addDetail.contactSeller)">
        <ion-icon
          slot="start"
          [ngClass]="{ 'icon-only': mobileView }"
          name="mail"
        ></ion-icon>
        <span *ngIf="!mobileView">{{ lang.email }}</span>
      </ion-button>
      <ion-button class="action-button" (click)="toggleFavorite(ad._id, ad.author)">
        <ion-icon
          *ngIf="!isFavorite"
          slot="start"
          [ngClass]="{ 'icon-only': mobileView }"
          name="heart-outline"
        ></ion-icon>
        <ion-icon
          *ngIf="isFavorite"
          slot="start"
          class="saved"
          [ngClass]="{ 'icon-only': mobileView }"
          name="heart"
        >
        </ion-icon>
        <span *ngIf="!mobileView && !isFavorite">{{ lang.save }}</span>
        <span *ngIf="!mobileView && isFavorite">{{ lang.saved }}</span>
      </ion-button>
      <ion-button class="action-button" (click)="copyLinkClipboard(ad._id)">
        <ion-icon
          slot="start"
          [ngClass]="{ 'icon-only': mobileView }"
          name="share"
        ></ion-icon>
        <span *ngIf="!mobileView">{{ lang.share }}</span>
      </ion-button>
      <ion-button class="action-button" (click)="flagAd($event, ad._id)">
        <ion-icon
          slot="start"
          [ngClass]="{ 'icon-only': mobileView }"
          name="flag"
        ></ion-icon>
        <span *ngIf="!mobileView">{{ lang.flag }}</span>
      </ion-button>
    </ion-buttons>

    <ion-buttons slot="end">
      <ion-button (click)="openProfileAction($event)">
        <ion-icon *ngIf="!currentUser" slot="icon-only" name="person"></ion-icon>
        <app-profile-photo class="user-avatar" *ngIf="currentUser" [userId]="currentUser._id"></app-profile-photo>
      </ion-button>
      <ion-button *ngIf="!currentUser"
                  class="flag-button"
                  (click)="presentLanguagePopover($event)">
        <span *ngIf="appLanguage === 'en'">EN</span>
        <span *ngIf="appLanguage === 'sl'">SI</span>
        <img
          *ngIf="appLanguage === 'en'"
          class="language-flag"
          src="assets/images/britain-flag-round.png"
          alt="english" />
        <img *ngIf="appLanguage === 'sl'"
          class="language-flag"
          src="assets/images/slovenia-flag-round.png"
          alt="slovene" />
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <ion-toolbar class="toolbar-subheader"
               *ngIf="lang && basePath === 'single-check'">
    <div class="container">
      <ion-buttons slot="end">
        <ion-button *ngIf="ad"
                    fill="outline"
                    [class.approve-button]="ad?.approved"
                    (click)="approve()"
                    [disabled]="ad?.approved">
          <ion-icon [slot]="mobileView ? 'icon-only' : 'start'" name="checkmark-done-outline"></ion-icon>
          <span *ngIf="!mobileView && !ad?.approved">Approve</span>
          <span *ngIf="!mobileView && ad?.approved">Approved</span>
        </ion-button>
      </ion-buttons>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content class="detail-page-content">
  <div class="content-body">
    <app-ad-detail
      *ngIf="ad && ad._id && !ad['adExpired']"
      #addDetail
      [appLanguage]="appLanguage"
      [lang]="lang"
      [ad]="ad"
      [mobileView]="mobileView"
      [adViewsCount]="adViewsCount"
      [favoritesCount]="favoritesCount"
      [singlePage]="true"
      [currentUser]="currentUser"
      [loggedIn]="loggedIn"
    >
    </app-ad-detail>
    <div *ngIf="ad && ad._id && ad['adExpired'] || !ad" class="inactive-message">
      <p>
        {{ lang.adCurrentlyNotActive }} <a routerLink="/" [queryParams]="{ l: languageParams }">{{ lang.goToHomepage }}</a>
      </p>
    </div>
    <span *ngIf="phoneNumber"
      ><a #phoneLink [href]="'tel:' + phoneNumber"></a
    ></span>
  </div>

  <app-footer></app-footer>
</ion-content>

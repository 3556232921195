import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';

@Injectable()
export class UserQueryService {

  API: string;

  constructor(
    private http: HttpClient,
    private dataService: DataService,
  ) {
    this.API = this.dataService.API;
  }

  listUserQuery(userId: string) {
    return this.http.get(`${this.API}/user-query/user/${userId}`);
  }

  addUserQuery(userId: string, query: any) {
    return this.http.post(`${this.API}/user-query/add`, {
      userId,
      query
    });
  }

  removeUserQuery(userId: string, queryId: string) {
    return this.http.post(`${this.API}/user-query/remove`, {
      userId,
      queryId
    });
  }

  checkUserQuery(userId: string, query: any) {
    return this.http.post(`${this.API}/user-query/check`, {
      userId,
      query
    });
  }
}

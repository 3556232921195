import { Component, HostListener, OnInit } from "@angular/core";

import { DataService } from "../../services/data.service";
import { LoginService } from "../../services/login.service";
import { LanguageService } from "../../services/language.service";
import { CommentService } from "../../services/comment.service";
import { WindowService } from "../../services/window.service";

import { CurrentUser } from "../../model/current-user";

// import { ToastController } from "@ionic/angular";
// import { UserDataService } from "../../services/user-data.service";
// import { MessageService } from "../../services/message.service";
@Component({
  selector: "app-profile-comments",
  templateUrl: "profile-comments.component.html",
  styleUrls: ["./profile-comments.component.scss"],
})
export class ProfileCommentsComponent implements OnInit {
  title = "Comments";
  lang: any;
  isLoggedIn = false;
  currentUser: CurrentUser;
  currUserComments: any = [];
  mobileView = true; // todo

  constructor(
    private loginService: LoginService,
    private dataService: DataService,
    private languageService: LanguageService,
    private commentService: CommentService,
    private windowService: WindowService
  ) // private toastCtrl: ToastController,
  // private userDataService: UserDataService,
  // private messageService: MessageService,
  {}

  ngOnInit() {
    // this.getUrl();
    // this.API = this.dataService.API;
    // SET LANGUAGE
    this.languageService.language$.subscribe((value) => {
      // this.appLanguage = value;
      this.lang = this.languageService[value];
      // this.updateList();
    });

    this.dataService.loggedIn$.subscribe((result) => {
      this.isLoggedIn = result;
      this.currentUser = this.loginService.currentUser();
      if (this.currentUser && this.currentUser._id) {
        this.getUserComments(this.currentUser._id);
      }
    });

    this.dataService.isBrowser$.subscribe((isBrowser) => {
      // console.log(isBrowser);
      if (isBrowser) {
        this.toggleMobileView();
      }
    });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.toggleMobileView();
  }

  toggleMobileView() {
    if (this.windowService.nativeWindow.innerWidth <= "767") {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
  }

  getUserComments(userId) {
    this.commentService.getCommentsUser(userId).subscribe((response) => {
      this.currUserComments = response["comments"];
    });
  }
}

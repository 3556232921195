<ion-card class="app-card"
          [ngClass]="{'active': selectedAdId === item._id}"
          (click)="openPrevieModal()"
          (mouseenter)="setActiveProperty(item._id)"
          (mouseleave)="setActiveProperty('')"
          #cardRef>
  <app-image-slider *ngIf="isBrowser && item && item.photos && item.photos.length > 0"
                    [images]="imageUrls"
                    [imgHeight]="imgHeight">
  </app-image-slider>

  <ion-fab vertical="top" horizontal="end" (click)="toggleFavorite(item._id, item.author);$event.stopPropagation()">
    <ion-fab-button size="small">
      <ion-icon *ngIf="!isFavorite" name="heart-outline"></ion-icon>
      <ion-icon *ngIf="isFavorite" name="heart" class="saved"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <div class="card-detail">
    <ion-item lines="none" class="location">
      <ion-label>
        <h2>
          <ion-icon name="location" slot="start"></ion-icon>
          {{ item.located.street }}, {{ item.located.postal }} {{ item.located.district }}
        </h2>
      </ion-label>
    </ion-item>
    
    <ion-item lines="none" class="card-info">
      <span item-start class="info-entry">
        <b>{{ item.price.current | number }}</b> <small>€</small><span *ngIf="item.price.type === 1"> <small>/{{ lang.month }}</small></span>,
      </span>
      <span item-start class="info-entry">
        <b *ngIf="isNotBuilding">{{ item.lotSize | number }} m&#178;</b>
        <b *ngIf="!isNotBuilding">{{ item.floorSize | number }} <small>m&#178;</small></b>, 
      </span>
      <span *ngIf="!isNotBuilding" item-start class="info-entry">
        <span *ngIf="item.roomNum !== 0"><b>{{ (item.roomNum || '/')}}</b> <small>{{ lang.rooms }}</small></span>
        <span *ngIf="item.roomNum === 0"><small>garsonjera</small></span>
      </span>
    </ion-item>
  </div>
</ion-card>

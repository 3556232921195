import { Component, OnInit, Input } from "@angular/core";
import { DataService } from "../../services/data.service";
import { SocketService } from "../../services/socket.service";

@Component({
  selector: "app-profile-photo",
  templateUrl: "profile-photo.component.html",
  styleUrls: ["./profile-photo.component.scss"],
})
export class ProfilePhotoComponent implements OnInit {
  API: string;
  timeStamp = new Date().getTime();

  @Input() userId: string;
  @Input() size = 32;

  constructor(
    private dataService: DataService,
    private socketService: SocketService
  ) {}

  ngOnInit() {
    this.API = this.dataService.API;

    this.socketService.profilePhotoChanges$.subscribe((msg) => {
      if (msg["itemId"] === this.userId) {
        this.timeStamp = new Date().getTime();
      }
    });
  }

  get profileImageUrl(): string {
    // check if file exists in the server

    if (this.userId) {
      return this.API + "/files/profile/" + this.userId + "?" + this.timeStamp;
    }

    return "assets/images/profile-300x300.jpg" + "?" + this.timeStamp;
  }
}

import { Component, OnInit, Input } from "@angular/core";
import { CurrentUser } from "src/app/model/current-user";

import { LanguageService } from "../../services/language.service";

// Comment Component
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  appLanguage: string;
  lang: any;
  languageParams: string | null;

  constructor(private languageService: LanguageService) {}

  ngOnInit() {
    // SET LANGUAGE
    this.languageService.language$.subscribe((value) => {
      this.appLanguage = value;
      this.lang = this.languageService[value];
      this.setHomepageLink(value);
    });
  }

  setHomepageLink(language: string) {
    if (language === "en") {
      this.languageParams = "en";
    } else {
      this.languageParams = null;
    }
  }
}

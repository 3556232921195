import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';

import { CurrentUser } from '../../model/current-user';
import { FavoritesService } from '../../services/favorites.service';
import { DataService } from '../../services/data.service';
import { SocketService } from "../../services/socket.service";
import { LoginService } from "../../services/login.service";

import { LoginModalComponent } from '../../components/modals/login-modal/login-modal.component';
import { PropertyType } from '../../model/enums/property-type';

@Component({
  selector: 'app-prop-card',
  templateUrl: 'ad-card.component.html',
  styleUrls: ['./ad-card.component.scss']
})
export class AdCardComponent implements OnInit, AfterViewInit {

  @Input() item: any; // ad
  @Input() imageUrls: string[] = []; // ad images
  @Input() lang: any;
  @Input() appLanguage: any;
  @Output() openPreviewEmitter: EventEmitter<string> = new EventEmitter();
  @Input() selectedAdId: string;
  @ViewChild('cardRef', {read: ElementRef}) cardRef: ElementRef;
  isFavorite = false;
  currentUser: CurrentUser;
  isBrowser = false;
  imgHeight = 200;

  constructor(
    private favoritesService: FavoritesService,
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private dataService: DataService,
    private socketService: SocketService,
    private loginService: LoginService,
  ) { }

  ngOnInit() {
    // console.log(this.item);
    this.dataService.isBrowser$.subscribe(isBrowser => {
      this.isBrowser = isBrowser;
    });

    this.socketService.adChanges$.subscribe((msg) => {
      if (msg["itemId"] === this.item._id) {
        if (msg["type"] === "favoriteChanges") {
          this.checkFavorite(this.currentUser?._id);
        }
      }
    });

    this.dataService.loggedIn$.subscribe(() => {
      this.currentUser = this.loginService.currentUser();
      if (this.currentUser) {
        this.checkFavorite(this.currentUser?._id);
      } else {
        this.isFavorite = false;
      }
    });
  }

  get isNotBuilding(): boolean {
    return this.item?.adPropertyType === PropertyType.Estate ||
           this.item?.adPropertyType === PropertyType.Parking ||
           this.item?.adPropertyType === PropertyType.Farm;
  }

  ngAfterViewInit() {
    // set slider image height relative to card width. 3:4 aspect ratio
    if (this.cardRef && this.cardRef.nativeElement?.clientWidth) {
      this.imgHeight = Math.floor(this.cardRef.nativeElement.clientWidth * 0.75);
    }
  }

  openPrevieModal() {
    this.openPreviewEmitter.emit(this.item);
  }

  setActiveProperty(propertyId: string): void {
    this.dataService.setSelectedAdSource(propertyId);
  }

  async toggleFavorite(itemId: string, authorId: string) {
    if (this.currentUser === undefined) {
      this.presentLoginModal();
      return null;
    }

    if (this.currentUser._id === authorId) {
      const toast = await this.toastCtrl.create({
        message: this.lang['messageToOwner'],
        duration: 3000
      });
      toast.present();
      return;
    }

    if (!this.isFavorite) {
      this.favoritesService.addFavorite(itemId, this.currentUser._id, 'someOwnerId')
        .subscribe(() => {
          this.isFavorite = true;
        });
    } else {
      this.favoritesService.removeFavorite(itemId, this.currentUser._id, 'someOwnerId')
        .subscribe(() => {
          this.isFavorite = false;
        });
    }
  }

  checkFavorite(userId: string) {
    if (userId == null) {
      this.isFavorite = false;
      return
    };
    this.favoritesService.checkFavorite(this.item._id, this.currentUser._id)
      .subscribe(result => {
        // console.log(result);
        this.isFavorite = result['isFavorite'];
        // this.changeDetectorRef.detectChanges();
      });
  }

  // TODO: hack to refresh data on tab change
  // remove after implementing socket backend updates
  // @HostListener('window:visibilitychange', ['$event'])
  // visibilityChange(event) {
  //   // console.log(document.hidden);
  //   if (!document.hidden) {
  //     this.checkFavorite();
  //   }
  // }

  async presentLoginModal() {
    const modal = await this.modalCtrl.create({
      component: LoginModalComponent,
      componentProps: {
        updateList: (name) => {
          // this.updateList();
        },
        // item: item,
        lang: this.lang,
        appLanguage: this.appLanguage
      },
      cssClass: 'login-modal'
    });
    modal.present();
  }
}

import { Injectable, Inject, PLATFORM_ID  } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {isPlatformBrowser} from '@angular/common';
import { environment } from '../../environments/environment';
import { WindowService } from './window.service';

@Injectable()
export class DataService {

  isBrowserSource = new BehaviorSubject<boolean>(false);
  isBrowser$ = this.isBrowserSource.asObservable();
  // localstorage string and it's observable variable
  baseUrlSource = new BehaviorSubject<string>('');
  baseUrl$ = this.baseUrlSource.asObservable();

  // localstorage string and it's observable variable
  sessionTokenSource = new BehaviorSubject<string>('');
  sessionToken$ = this.sessionTokenSource.asObservable();

  loggedInSource = new BehaviorSubject<boolean>(false);
  loggedIn$ = this.loggedInSource.asObservable();

  darkModeSource = new BehaviorSubject<boolean>(false);
  darkMode$ = this.darkModeSource.asObservable();

  queryParamObjectSource = new BehaviorSubject<object>({});
  queryParamObject$ = this.queryParamObjectSource.asObservable();

  viewHistoryIndexSource = new BehaviorSubject<number>(0);
  viewHistoryIndex$ = this.viewHistoryIndexSource.asObservable();

  selectedAdSource = new BehaviorSubject<string>('');
  selectedAd$ = this.selectedAdSource.asObservable();

  locationLinksSource = new BehaviorSubject<any[]>([]);
  locationLinks$ = this.locationLinksSource.asObservable();

  // API = 'http://localhost:3000/api';
  // API = 'https://api01.kvadrat.si/api'; // si
  // API = 'https://api.kvadrat.si/api'; // ssr
  API = environment.API_URL;
  prefersDarkListener: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private windowService: WindowService,
  ) {
    this.isBrowser$.subscribe(isBrowser => {
      // console.log(isBrowser);
      if (isBrowser) {
        const lightMode = localStorage.getItem('darkMode');
        if (lightMode) {
          document.body.classList.toggle('dark', JSON.parse(lightMode) === true);
          this.setDarkModeSource(JSON.parse(lightMode) === true);
        }
        this.initDarkMode();
      }
    });
    
    this.setIsBrowserSource(isPlatformBrowser(this.platformId));
    // this.API = 'http://localhost:3000/api';
    // this.API = 'https://beta.kvadrat.si/api';
  }

  initDarkMode() {
    this.prefersDarkListener = this.windowService.nativeWindow.matchMedia('(prefers-color-scheme: dark)');
    this.prefersDarkListener.addListener((e) => this.checkToggle(e.matches));
  }

  checkToggle(isDark: boolean) {
    document.body.classList.toggle('dark', isDark);
    this.setDarkModeSource(isDark);
    localStorage.setItem('darkMode', isDark.toString());
  }

  setQueryParamObject(queryParamObject: object) {
    this.queryParamObjectSource.next(queryParamObject);
  }

  setBaseUrl(url: string) {
    this.baseUrlSource.next(url);
  }

  setSessionToken(token: string) {
    this.sessionTokenSource.next(token);
  }

  setLoggedInSource(loggedIn: boolean) {
    this.loggedInSource.next(loggedIn);
  }

  setViewHistoryIndexSource(historyIndexCount: number) {
    this.viewHistoryIndexSource.next(historyIndexCount);
  }

  setIsBrowserSource(isBrowser: boolean) {
    this.isBrowserSource.next(isBrowser);
  }

  setDarkModeSource(darkMode: boolean) {
    this.darkModeSource.next(darkMode);
  }

  setSelectedAdSource(adId: string) {
    this.selectedAdSource.next(adId);
  }

  setLocationLinksSource(locations: any[]) {
    this.locationLinksSource.next(locations);
  }
}

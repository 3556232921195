<ion-grid>
  <ion-row>
    <ion-col size="12">
      <div class="container about-us-text">
        <ng-container *ngIf="appLanguage === 'sl'">
          <p>Nova različica 2024, veljavna od 12. 06. 2024 do preklica</p>

          <h1>1 Splošne informacije</h1>

          <p>
            kvadrat.si (v nadaljevanju kvadrat) zelo skrbi za varstvo osebnih
            podatkov svojih uporabnikov. Ta dokument pojasnjuje, kako poteka
            shranjevanje, obdelava in prenos osebnih podatkov poteka v skladu s
            zakonodajo o varstvu podatkov in - če je primerno - Splošno uredbo
            Evropske unije o varstvu podatkov (GDPR) za katere namene kvadrat
            uporablja te podatke.
          </p>
          <p>
            Ta pravilnik o zasebnosti dopolnjuje kvadratove splošne pogoje
            poslovanja (SPP) in velja na vse storitve, ki jih ponuja kvadrat.
            Takšne storitve so lahko zlasti spletne strani in mobilne aplikacije
            (aplikacije).
          </p>
          <p>
            Upravljavec podatkov je kvadrat.si. Če se uporabljajo storitve
            tretjih oseb, veljajo izključno politike zasebnosti teh tretjih
            oseb.
          </p>
          <p>
            Ko uporabniki potrdijo, da sprejemajo kvadratove splošne pogoje
            uporabe (SPU) s predložitvijo svoje izjave s klikom na ustrezne
            ikone (kar se potrdi s kljukico), ti SPU postanejo izrecna vsebina
            pogodbe.
          </p>
          <p>
            Ta pravilnik o zasebnosti si lahko ogledate na platformi kvadrat.si
            in ga lahko prenesete.
          </p>
          <p>
            Uporabniki kvadrat.si, ki se ne strinjajo s Politiko zasebnosti,
            morajo tako zapustiti spletno stran kvadrat.si.
          </p>
          <p>
            Če imate kakršnakoli vprašanja ali predloge v zvezi s tem
            pravilnikom o zasebnosti, nas kontaktirajte (gl razdelek 6 spodaj).
          </p>

          <h2>Osnovna skladnost</h2>
          <p>
            kvadrat je predmet zakona o varstvu podatkov - GDPR in je v tem kontekstu zavezan k varovanju zasebnosti
            svojih uporabnikov in obdeluje njihove osebne podatke v skladu z določbami GDPR.
          </p>
          <p><a href="https://gdpr-info.eu/art-13-gdpr/">https://gdpr-info.eu/art-13-gdpr/</a></p>
          <p>
            kvadrat se zavezuje: - izpolnjevati zakonske zahteve za obdelavo
            osebnih podatkov - obdelovati osebne podatke v dobri veri po načelu
            sorazmernosti in pridobiti soglasje prizadetih uporabnikov, kjer je
            to potrebno - zagotoviti, da se osebni podatki obdelujejo samo za
            namen, ki je bil naveden med njegovo pridobitvijo - pridobljene
            osebne podatke uporabiti za izpolnjevanje svojih pogodbenih
            obveznosti in zagotavljanje visoke kakovost storitev - razvijati in
            vzdrževati odnose s strankami - zagotoviti točnost podatkov
            (celovitost podatkov) - zagotoviti varstvo podatkov s tehničnimi in
            organizacijskimi ukrepi (varnost podatkov) - navesti razloge in
            namen pridobivanja in obdelave podatkov
          </p>
          <p>
            Čeprav kvadrat spoštuje določila Zakona o varstvu podatkov, in GDPR,
            ne more v celoti zagotoviti zaupnosti, integritete, pristnost in
            razpoložljivost osebnih podatkov. Uporabnik osebno potrjuje tudi
            podatke, ki so razkriti na kvadrat.si (za namen vzpostavitve
            kontaktne zahteve).
          </p>
          <p>
            Podatki so lahko dostopni tudi v državah, ki nimajo zakonodaje, ki
            bi zagotavljala ustrezno raven zaščite podatkov. Poleg tega lahko
            iskalniki registrirajo osebne podatke in jih reproducirajo enako z
            ustreznimi raziskavami.
          </p>

          <h1>2 Ravnanje z osebnimi podatki na kvadrat.si</h1>

          <p>
            Z uporabo te spletne strani uporabnik priznava kvadratove postopke
            za ravnanje z osebnimi podatki, kot je opisano spodaj, in poda svoje
            soglasje za uporabo osebnih podatkov po kvadratu v obsegu in za
            opisane namene.
          </p>

          <h2>2.1 Zbiranje podatkov</h2>
          <p>
            Za zagotavljanje storitev v ekosistemu kvadrat in za optimizacijo
            spletne lastnine platforma kvadrat.si za uporabnike na najboljši
            možni način, zbira podatke na različnih točkah.
          </p>
          <p>
            To vključuje zlasti naslednje bistvene osebne podatke: <br />
            - e-naslov in geslo, pozdrav, priimek, ime, naslov (ulica, poštna
            številka, kraj), telefonsko številko in jezik.
          </p>

          <h2>2.2 Shranjevanje</h2>

          <p>
            Osebni podatki so shranjeni v varovanem okolju baze podatkov v
            Nemčiji.
          </p>

          <p>
            Obdobje hrambe: <br />

            Obdobje hrambe je samo toliko, kolikor je potrebno za ustrezno
            obdelavo, ali v primeru pogodb, vsaj za čas trajanja pogodbenega
            razmerja. To upošteva tudi obstoj upravičenega interesa s strani
            kvadrata v shranjevanje podatkov (npr. zastaralni roki za morebitne
            pravne zahtevke kvadrata, jamstvo za IT varnost, varnost podatkov in
            nadzor varstva podatkov), kakor tudi vse pogodbene ali zakonske
            obveznosti hrambe.
          </p>

          <h2>2.3 Varnost podatkov</h2>

          <p>
            kvadrat daje visoko prednost varovanju podatkov pred nepooblaščenim
            dostopom. Da bi povečati varnost podatkov: <br />
            - kvadrat po potrebi uporablja standardne tehnike šifriranja (na
            primer SSL). - kvadrat preverja svoje prakse glede zbiranja,
            shranjevanja, uporabe in ravnanja s podatki vključno s fizičnimi
            varnostnimi ukrepi za preprečevanje nepooblaščenega dostopa do
            njenih sistemov - kvadrat omogoča dostop do osebnih podatkov le
            zaposlenim in izvajalcem, ki to zahtevajo. Tak dostop z namenom
            obdelave podatkov za kvadrat za navedeni namen in ki so dolžni
            spoštovati stroge zahteve glede zaupnosti Spletne strani kvadrat.si,
            ki gostuje v Nemčiji.
          </p>
          <p>
            Uporabniki so izključno odgovorni za varno dostavo svojih podatkov
            in za zagotovitev, da niso prestrežene, posnete, ukradene ali kako
            drugače dane na razpolago tretjim osebam med njegovim prenosom med
            uporabnikom in kvadrat.si.
          </p>

          <h2>2.4 Uporaba podatkov</h2>

          <h3>2.4.1 Registrirani uporabniki</h3>

          <p>
            Kvadrat ima dostop do osebnih podatkov, ki jih izrecno izbere
            registrirani uporabnik in ima dostop do osebnih podatkov, zbranih ob
            registraciji, naročilu izdelka ali uporabi storitev kvadrat.
          </p>

          <p>
            Ti osebni podatki se uporabljajo za zagotavljanje in izboljšanje
            storitev kvadrata in se lahko uporabljajo za komercialne namene.
          </p>

          <p>
            Registrirani uporabniki tudi sprejemajo, da lahko kvadrat podatke
            uporablja za namene trženja. V okviru navedenega registrirani
            uporabniki dajo soglasje, da kvadrat stopi v stik z njimi
            neposredno.
          </p>

          <h3>2.4.2 Neregistrirani uporabnik</h3>

          <p>
            Osebni podatki, ki jih posredujejo neregistrirani uporabniki preko
            kontaktnega obrazca za vnos, lahko kvadrat posreduje neposredno
            ustreznemu registriranemu uporabniku ali oglaševalcu.
          </p>

          <h3>2.4.3 Registrirani osebni podatki</h3>

          <p>
            Na tej točki se zbirajo naslednji osebni podatki: <br />
            - Splošno: kontaktni datum <br />
            - Neregistrirani uporabniki: vsi podatki iz kontaktnega obrazca, kot
            so pozdrav, priimek, ime, naslov (ulica, poštna številka, kraj),
            telefonska številka, elektronski naslov <br />
            - Registrirani uporabniki: vsi podatki iz kontaktnega obrazca, kot
            so podjetje, pozdrav, priimek, ime, naslov (ulica, poštna številka,
            kraj), telefonska številka, elektronski naslov in sporočilo ter
            vsebino uporabnikovega predala (njihovega osebnega uporabniškega
            računa, za namen preiskave suma kaznivih dejanj) <br />
            - Oglaševalci: vsi podatki iz kontaktnega obrazca, kot so podjetje,
            ime, priimek, naslov (ulica, poštna številka, kraj), telefonska
            številka, elektronski naslov. vsebino uporabnikov nabiralnik
            (njihovega osebnega uporabniškega računa, za namene preiskave suma
            kazniva dejanja) <br />
            - Dodatno lastnosti, ki jo navede/shrani uporabnik: koda, cena,
            lastnost, vrsta, naslov (ulica, poštna številka, kraj) ter obrazci
            računov za vse plačane storitve
          </p>

          <p>
            Ti osebni podatki se uporabljajo za zagotavljanje in izboljšanje
            storitev kvadrata in se lahko uporabljajo za komercialne namene.
          </p>

          <h2>2.5 Obdelava</h2>

          <p>
            Registrirani uporabniki lahko kadarkoli popravijo ali spremenijo
            podatke, shranjene v njihovem uporabniškem profil. Uporabniške
            profile ureja posamezni uporabnik. kvadrat si pridržuje le pravico,
            da izbriše vse profile, ki vsebujejo očitno napačne podatke in
            ponastavitev podatkov za prijavo (npr. gesla) na izrecno zahtevo
            uporabnika.
          </p>
          <p>
            kvadrat si pridržuje tudi pravico, da nastavi status registriranih
            uporabnikov, katerih zadnja prijava je bila več kot 12 mesecev prej,
            da v neaktiven in pozneje izbriše vse podatke ali lastnosti,
            povezane z ustreznimi računi. S strani kvadrata ni nobene obveznosti
            obvestiti uporabnike o teh postopkih čiščenja.
          </p>

          <h1>3 Ravnanje z osebnimi podatki v zvezi s tretjimi osebami</h1>

          <p>
            Zbranih in shranjenih osebnih podatkov ne bomo prodajali, dajali v
            zakup, oddajali, ali dajali na voljo tretjim osebam s strani
            kvadrata. To ne velja za obdelavo, ki se izvaja:
          </p>
          <p>
            - za namen, ki je neposredno povezan z namenom, za katerega so bili
            osebni podatki prvotno zbrani, npr. uvoz in izvoz seznamov na
            platformah partnerjev ali drugih tretjih oseb <br />
            storitve, za katere je zadevni uporabnik predhodno privolil. - v
            primeru zlorabe storitve <br />
            - za boj proti goljufijam
          </p>

          <p>
            Poleg tega kvadrat in uporabnikova izbrana banka ne bosta delila
            osebnih podatkov ustreznega uporabnika s tretjimi osebami brez
            privolitve omenjenega uporabnika. Vendar si pridržujejo pravico
            deliti informacije nezaželenih oglaševalcev z vsemi povezanimi
            partnerji platforme.
          </p>

          <h2>3.1 Zloraba storitve</h2>

          <p>
            V primeru zlorabe storitve, zlasti pa v primeru suma kaznivega
            dejanja, zakonske obveznosti ali vladne in sodne odredbe, si kvadrat
            pridržuje pravico do delitve osebnih podatkov uporabnikov. V tem
            kontekstu se lahko osebni podatki analizirajo, v namen da se
            razjasni situacija in na utemeljeno zahtevo posreduje pristojnim
            organom ali tretjim, ki jih je prizadela zloraba.
          </p>

          <h2>3.2 Prenos podatkov drugim ponudnikom storitev</h2>

          <p>
            Poleg tega se uporabnik strinja, da kvadrat lahko posreduje prejete
            osebne podatke tretjim osebam: <br />
            Zlasti ponudniki plačil (npr. izdajatelji kreditnih kartic, banke,
            PayPal, mobilni telefoni ponudniki telefonskih storitev) in
            ponudniki storitev IT (npr. podatkovni centri, ponudniki
            gostovanja). Te ponudniki storitev bodo imeli dostop do osebnih
            podatkov uporabnikov le v obsegu, ki je potreben opravljati povezane
            storitve. Podatki se lahko v ta namen pošljejo tudi v tujino (ob
            upoštevanju ravnanje skladno z varstvom podatkov in samo ponudniku
            storitev, ki ga je naročil kvadrat).
          </p>

          <h2>3.3 Oglaševanje, prilagojeno oglaševanje</h2>

          <p>
            kvadrat lahko prikazuje oglaševanje uporabnikom, ki uporabljajo
            njegove storitve. kvadrat lahko prikaže tudi prilagojeno
            oglaševanje, ki ga ustvarijo tretje osebe, katerih vsebina temelji
            na ustreznih osebnih podatkih uporabnika.
          </p>

          <h2>3.4 Soregistracija</h2>

          <p>
            Ob registraciji na kvadrat se daje uporabnikom tudi možnost
            registracije partnerska podjetja. Če se uporabniki odločijo za
            dokončanje tovrstne soregistracije, bo kvadrat osebne podatke,
            zbrane za soregistracijo, posredoval ustreznemu partnerju, ki bo
            postal lastnik podatkov.
          </p>

          <h2>3.5 Povezave do tretjih ponudnikov storitev</h2>

          <p>
            Spletno mesto kvadrat.si lahko vsebuje povezave do ponudb tretjih
            ponudnikov. kvadrat nima vpliva na način, kako ti ponudniki sami
            obdelujejo podatke o strankah spletne strani. kvadrat ne odgovarja
            za skladnost s predpisi o varstvu podatkov teh tretjih oseb.
          </p>

          <h2>3.6 Zaščita botov</h2>

          <p>
            Za zaščito platforme in vaših podatkov kvadrat uporablja storitev
            zaščite botov, ki lahko skenira dohodni aplikacijski promet in
            blokira neželen zlonamerni dostop do platforme. Ustrezna preverjanja
            se izvajajo v skladu z zakonodajo o varstvu podatkov.
          </p>

          <h1>4 Uporaba piškotkov in sistemov za sledenje</h1>

          <p>
            Z uporabo spletne strani kvadrat.si uporabniki dajejo soglasje k
            uporabi piškotkov in sledilnih sistemov po kvadratu v obsegu in za
            namene, opisane spodaj.
          </p>
          <p>
            kvadrat uporablja <br />
            - piškotki za optimizacijo platforme in določanje števila ogledov
            strani. <br />
            - po drugi strani sistemi za sledenje (npr. skripti za zbiranje
            spletnih podatkov) za izboljšanje ponudb in dostavo prilagojenih
            priporočil in ponudb.
          </p>
          <p>
            kvadrat si pridržuje pravico do uporabe sistemov za sledenje (glej
            razdelek 4.2 spodaj).
          </p>
          <p>
            kvadrat bo usklajeval sisteme sledenja, ki se uporabljajo, s
            posodobitvijo določb o varstvu podatkov v naprej in tako zagotovil,
            da so sistemi sledenja, ki delujejo, vedno izrecno navedeni v
            določbah o varstvu podatkov.
          </p>

          <h2>4.1 Uporaba piškotkov</h2>

          <p>
            Piškotki so anonimne besedilne datoteke, ki so shranjene na
            terminalu vsakega uporabnika.
          </p>

          <p>
            Piškotki se uporabljajo za naslednje namene: <br />
            - Za statistične namene. <br />
            - Za samodejno prijavo registriranih uporabnikov, pod pogojem, da je
            obiskovalec/uporabnik to določil med postopkom prijave. <br />
            - Za prikaz vsebine in oglaševanja na podlagi interesov
            oglaševalca/uporabnika. Uporabniki lahko preprečijo namestitev
            piškotkov prek nastavitev svojega brskalnika. Vendar kvadrat želi
            poudariti, da lahko s tem omeji uporabnikovo zmožnost dostopa do
            vseh storitev.
          </p>

          <h3>Piškotki tretjih oseb</h3>

          <p>
            Da bi svojim uporabnikom zagotovil platformo, ki je načeloma
            brezplačna za uporabo, je kvadrat odvisen od oglaševanja. Kvadrat
            torej omogoča izbranim partnerjem postavitev oglasov na spletni
            strani.
          </p>

          <p>
            V tem kontekstu se lahko drugi piškotki (piškotki tretjih oseb)
            namestijo na uporabniške terminale, ko gledajo te oglase. To uporabo
            piškotkov lahko uporabniki preprečijo tudi prek ustrezne nastavitve
            brskalnika.
          </p>

          <h2>4.2 Uporaba sistemov za sledenje</h2>

          <p>
            kvadrat zbira tako imenovane sledilne podatke, ki zagotavljajo
            informacije o uporabi svoje spletne strani.
          </p>

          <p>Kvadrat uporablja naslednje sisteme sledenja.</p>

          <h3>4.2.1 Sistemi za sledenje</h3>

          <p>
            kvadrat uporablja orodje, da izboljša svoje storitve, obogati
            obstoječe profile in zagotovi prilagojena priporočila za oglaševalce
            in uporabnike tako na platformi kot po e-pošti.
          </p>

          <p>
            Kvadrat hrani podatke v varni bazi podatkov v skladu z varstvom
            podatkov in predpisi.
          </p>

          <h4>Spletna razširitev</h4>

          <p>
            Poteka tudi zbiranje podatkov o (neregistriranem) uporabniku na
            kvadrat.si anonimno (pred prijavo) ali v psevdonimizirani obliki in
            vključuje elektronski naslov kot identifikacijo že registriranega
            uporabnika in na primer jezika strani, uporabljena lokacija
            njegovega iskanja, ki je pomembna za uporabnika, in informacije o
            ogledu oglasov.
          </p>

          <p>
            Anketa med drugim zahteva nastavitev piškotkov v brskalnikih
            uporabnikov, ki zabeležijo tudi IP naslov, uporabniški agent
            brskalnika, identifikatorje piškotkov in psevdonimizirane
            identifikatorje.
          </p>

          <p>
            Uporabnik lahko prepreči namestitev piškotkov v nastavitvah svojega
            posameznega brskalnika.
          </p>

          <h4>Obogatitev profila</h4>

          <p>
            Poleg tega kvadrat zbira različne spletne aktivnosti registriranega
            uporabnika.
          </p>

          <p>
            Zbirka vključuje elektronski naslov kot identifikacijo
            registriranega uporabnika ter, na primer datum objave/shranjevanja
            na seznamu za spremljanje oglasa, rezervacija izdelka, zadnja
            registracija in shranjevanje iskalne naročnine.
          </p>

          <p>
            Registrirani uporabniki lahko nasprotujejo spletnemu sledenju,
            obogatitvi profila in pošiljanju priporočila tako, da deaktivirajo
            ustrezno funkcijo v ustreznih uporabniških nastavitvah ali s
            sporočilom na
            &#105;&#110;&#102;&#111;&#064;&#107;&#118;&#097;&#100;&#114;&#097;&#116;&#046;&#115;&#105;.
          </p>

          <h3>4.2.2 Google Analytics</h3>

          <p>
            kvadrat uporablja Google Analytics, storitev spletne analitike, ki
            jo zagotavlja Google Inc., 1600 Amphitheatre Parkway, Mountain View,
            CA 94043, ZDA (Google).
          </p>

          <p>
            Google Analytics za pomoč spletnemu mestu uporablja piškotke, ki so
            shranjeni na uporabnikovem računalniku ter analizira, kako
            uporabniki uporabljajo spletno mesto za izboljšanje njenega
            delovanja.
          </p>

          <p>
            Podatki, ki jih ustvarijo piškotki o uporabi tega spletnega mesta
            (vključno z IP naslovom uporabnika) bo Google prenesel in shranil na
            strežnikih v Združenih državah države. Google bo te podatke uporabil
            za namene ocenjevanja uporabe spletnega mesta s strani uporabnikov,
            sestavljanje poročil o dejavnosti spletne strani za upravljalce
            spletnih mest in zagotavljanje drugih storitev v zvezi z dejavnostjo
            spletne strani in uporabo interneta. Te informacije so lahko tudi
            posredovane tretjim osebam, kot zahteva zakon, ali če so tretje
            osebe pooblaščene za obdelavo teh podatkov v imenu prej omenjenih
            storitev spletne analitike. V nobenem primeru ne bo uporabnikov IP
            naslov povezan z drugimi podatki, ki jih obdeluje ta spletna
            analitika storitve
          </p>

          <p>
            Uporabnik lahko zavrne uporabo piškotkov z izbiro ustreznih
            nastavitev v svojem brskalniku ali lahko zavrne zbiranje in obdelavo
            piškotkov s strani Googla, vključno z naslovi IP in druge podatke v
            zvezi z uporabo spletne strani. Potreben vtičnik brskalnika lahko
            najdete na naslednji povezavi
            (https://www.google.com/settings/ads/plugin?hl=sl) V tem primeru pa
            kvadrat želi poudariti, da morda niso vse funkcije platforme v
            celoti na voljo uporabniku. Dodatne informacije o pogojih uporabe in
            varstvu podatkov najdete na
            https://www.google.com/analytics/terms/en.html ali manj
            https://www.google.com/intl/en/policies/
          </p>

          <h3>4.2.3 Doubleclick podjetja Google Inc</h3>

          <p>
            kvadrat uporablja Doubleclick podjetja Google Inc, 1600 Amphitheatre
            Parkway, Mountain View, CA 94043, ZDA (»Google«). Ustvarjene
            informacije Google posreduje strežniku v ZDA za oceno in tam
            shranjuje.
          </p>

          <p>
            Uporabniki si lahko ogledajo trenutni profil, ki ga določi Google
            pod https://www.google.com/ads/preferences. Profil je možno tudi
            urediti ali deaktivirati določene funkcije na omenjeni spletni
            strani.
          </p>

          <h3>4.2.4 Googlovo ponovno trženje</h3>

          <p>
            kvadrat za ustvarjanje uporablja storitve Googlovega ponovnega
            trženja, funkcije programa Google AdWords in prikazovati posamezne,
            interesno pomembne oglase prek Googlovega oglaševalskega omrežja.
          </p>

          <p>Upravljavec storitev Google Remarketing je Google.</p>

          <p>
            Za prepoznavanje uporabnika Google Remarketing shrani piškotek v
            brskalnik uporabnika. Identifikacija po IP naslovu poteka samodejno
            pri Googlu, če so internetne strani dostopne, ki so tudi člani
            Googlove oglaševalske mreže. Google lahko tudi posreduje zbrane
            informacije tretjim osebam, kot to zahteva zakon ali če so tretje
            osebe pooblaščene za obdelavo teh podatkov v imenu zgoraj omenjenih
            storitev spletne analitike.
          </p>

          <p>
            Uporabniki lahko kadar koli preprečijo nastavitev piškotkov z
            ustrezno nastavitvijo v brskalnik, kot je opisano zgoraj. Prav tako
            je mogoče preprečiti oglaševanju v zvezi z interesi s strani Googla,
            pod www.google.com/settings/ads in shranite želene nastavitve.
          </p>

          <p>
            Več informacij in Googlov trenutni pravilnik o zasebnosti najdete na
            https://www.google.com/intl/en/policies/privacy/.
          </p>

          <h3>4.2.5 Facebook ponovno trženje/ponovno ciljanje</h3>

          <p>
            kvadrat uporablja funkcijo ponovnega trženja »Custom Audiences«
            družbenega omrežja Facebook Ireland Ltd., 4 Grand Canal Square,
            Grand Canal Harbour, Dublin 2, Irska (Facebook).
          </p>

          <p>
            Tako pridobljene informacije o straneh, ki jih obiskujejo
            uporabniki, lahko uporablja kvadrat, med drugim, za predvajanje
            oglasov, povezanih z zanimanjem (»Facebook Ads«) na socialno omrežje
            Facebook.
          </p>

          <p>
            Ko uporabniki obiščejo kvadrat.si, se nastavi piškotek in vzpostavi
            neposredna povezava med brskalnikom in strežnikom Facebook, da
            dodeli obisk Facebook računu. kvadrat nima vpliva na obseg in
            nadaljnjo uporabo podatkov, ki jih Facebook zbira z uporabo tega
            orodja. Z integracijo občinstva po meri Facebook lahko Facebook
            prejema podatke, s katerimi so uporabniki poklicali kvadrat.si ali
            kliknili na ogla. Če so uporabniki registrirani v storitvi Facebook,
            lahko Facebook obisku dodeli račun.
          </p>

          <p>
            Tudi če uporabniki niso registrirani na Facebooku ali niso
            prijavljeni, ponudnik lahko zbira in shrani naslove IP in druge
            identifikatorje.
          </p>

          <p>
            Uporabniki lahko preprečijo namestitev piškotkov prek nastavitev
            svojega brskalnika ali - če so prijavljeni Facebook - onemogočite
            funkcijo na https://www.facebook.com/settings/?tab=ads#_.
          </p>

          <p>
            kvadrat želi poudariti, da lahko s tem omejite uporabnikovo zmožnost
            dostopa do vseh vsebin platforme. Več informacij lahko najdete v
            Facebookovi politiki zasebnosti na
            https://www.facebook.com/about/privacy/.
          </p>

          <h3>4.2.6 Hotjar</h3>

          <p>
            kvadrat uporablja Hotjar Analytics, storitev spletne analitike, ki jo zagotavlja
            Hotjar Ltd. Dragonara Business Centre. 5th Floor, Dragonara Road, Paceville St Julian's STJ 3141, Malta.
          </p>

          <h1>5 Uporaba glasila</h1>

          <p>
            kvadrat ponuja možnost naročanja na novice z namenom prejemanja
            relevantnih informacij o trgu nepremičnin ali aktualnih produktov in
            storitev.
          </p>
          <p>
            Naslednji podatki, shranjeni med registracijo za prejemanje novic,
            bodo lahko poslani za tehnično obdelavo pošiljke: <br />
            - Naročnina na novice: pozdrav, ime, priimek, elektronski naslov,
            poštna številka, potrdilo o registraciji. <br />
            - Shrani naročnino na iskanje: e-poštni naslov, potrditev
            registracije. <br />
            - Registracija na kvadrat.si/uporabniški podatki: e-naslov,
            potrditev registracije, ura registracije in vse informacije iz
            uporabniških podatkov.
          </p>
          <p>
            V okviru dostave glasila kvadrat ocenjuje stopnjo odpiranja in klike
            uporabnikov v glasilu, da bi sklepali o dostavljeni ponudbi in
            pošiljali uporabniku ustreznejše ponudbe. Uporabniki se lahko
            kadarkoli odjavijo od novic s klikom na ustrezno povezavo na koncu
            posameznega glasila ali po e-pošti na
            &#105;&#110;&#102;&#111;&#064;&#107;&#118;&#097;&#100;&#114;&#097;&#116;&#046;&#115;&#105;.
          </p>

          <h1>6 Pravica v zvezi z obdelavo osebnih podatkov</h1>

          <p>
            Uporabniki imajo pravico zahtevati podatke o svojih osebnih
            podatkih, ki jih uporablja kvadrat kadarkoli. Uporabniki imajo tudi
            pravico do popravka napačnih podatkov in do prekinitve (izbrisa).
          </p>
          <p>
            Izbris osebnih podatkov se izvede, razen če so zakonite določbe in
            odredbe državnih organov, npr. sodišča, ki zahtevajo, da kvadrat
            shrani in omogoči dostop do teh osebnih podatkov ali če za podatke
            veljajo katerikoli zakonski roki hrambe.
          </p>
          <p>
            Poleg tega lahko uporabniki ugovarjajo obdelavi podatkov ali
            prekličejo predhodno odobreno soglasje. V takih primerih kakršna
            koli obdelava podatkov, ki je potekala pred preklicem ohrani svojo
            zakonitost.
          </p>

          <p>
            Za uveljavljanje katere koli od zgornjih pravic je treba zahteve
            poslati po e-pošti na naslov
            &#105;&#110;&#102;&#111;&#064;&#107;&#118;&#097;&#100;&#114;&#097;&#116;&#046;&#115;&#105;
            ali pisno pooblaščeni osebi za varstvo podatkov.
          </p>

          <p>
            Kvadrat lahko kadarkoli zahteva dokazilo o identifikaciji v okviru
            zahteve za izbris.
          </p>

          <p>
            Če je primerno, imajo uporabniki tudi pravico do vložitve pritožbe
            pri pristojnem nadzornem organu za obdelavo podatkov. Uporabniki
            lahko to storijo tako, da se obrnejo na nadzorni organ za svoje
            prebivališče, delovno mesto ali lokacijo osumljenca kršitev varstva
            podatkov.
          </p>

          <p>
            Pristojni nadzorni organ v Sloveniji je Informacijski pooblaščenec,
            Dunajska cesta 22, 1000 Ljubljana, Slovenija
          </p>

          <h1>7 Spremembe politike zasebnosti</h1>

          <p>
            Vsebina te politike zasebnosti se lahko kadarkoli spremeni. kvadrat
            bo zagotovil, da pravice do zasebnosti prizadetih uporabnikov niso
            nezakonito kršene in da njihove pravice niso omejene brez izrecnega
            soglasja posameznih uporabnikov.
          </p>
          <p>
            kvadrat se tudi zavezuje, da bo vse spremembe svoje politike
            zasebnosti takoj objavil na svoji spletne strani z namenom
            obveščanja uporabnikov o spremembah. kvadrat uporabnikom tudi
            priporoča, da redno preverijo politiko zasebnosti.
          </p>

          <h1>8 Soglasje</h1>
          <p>
            Uporabnik se strinja, da se njegovi osebni podatki lahko zbirajo v
            skladu z zgornjo izjavo ter shranijo in uporabijo za prej opisane
            namene.
          </p>

          <p>Avtorske pravice kvadrat</p>

          <p>Smernice za politike zasebnosti z dne 12. 06. 2024</p>
        </ng-container>

        <!-- English -->
        <ng-container *ngIf="appLanguage === 'en'">
          <p>New version 2024, valid from 12th of june 2024 until revoked</p>

          <h1>1 General information</h1>

          <p>
            kvadrat.si (hereinafter "kvadrat") takes the protection of its
            users' personal data very seriously. This document explains how the
            storage, processing and transfer of personal data takes place in
            compliance with slovenian data protection legislation and - if
            applicable - the European Union's General Data Protection Regulation
            (GDPR), and for which purposes kvadrat uses this data.
          </p>
          <p>
            This Privacy Policy supplements kvadrat's General Terms & Conditions
            (GTCs) and applies to all services offered by kvadrat. In
            particular, such services may be both websites and mobile
            applications (apps).
          </p>
          <p>
            The data controller is kvadrat.si. If third-party services are used,
            the privacy policies of these third parties apply exclusively.
          </p>
          <p>
            When users confirm their acceptance of kvadrat's GTCs by submitting
            their declaration of intent by clicking the corresponding icons
            (which is confirmed via the appearance of a check mark), these GTCs
            become explicit contents of the contract.
          </p>
          <p>
            This Privacy Policy can be viewed on the kvadrat.si platform and can
            be downloaded by users.
          </p>
          <p>
            Users of kvadrat.si who do not agree with the following Privacy
            Policy are required to exit the website kvadrat.si immediately.
          </p>
          <p>
            If you have any questions or suggestions about this Privacy Policy,
            please contact us (see Section 6 below).
          </p>

          <h2>Basic compliance</h2>
          <p>
            kvadrat is subject to the Data Protection Act as well as - if
            applicable - the GDPR and, within this context, is committed to
            protecting the privacy of its users and to processing their personal
            data in accordance with the provisions of the Data Protection Act
            and, if applicable, the GDPR.
          </p>
          <p><a href="https://gdpr-info.eu/art-13-gdpr/">https://gdpr-info.eu/art-13-gdpr/</a></p>
          <p>
            kvadrat hereby undertakes: <br />
            - to comply with the statutory requirements for the processing of
            personal data <br />
            - to process personal data in good faith using the proportionality
            principle and to obtain the consent of the affected users where
            necessary <br />
            - to ensure that personal data is processed only for the purpose
            that was specified during its procurement <br />
            - to use the acquired personal data to fulfil its contractual
            obligations and ensure a high quality of service <br />
            - to develop and maintain customer relationships <br />
            - to ensure the accuracy of the data (data integrity) <br />
            - to ensure data protection through technical and organisational
            measures (data security) <br />
            - to indicate the reason and purpose for the acquisition and
            processing of the data
          </p>
          <p>
            Although kvadrat respects the provisions of the Data Protection Act,
            and where applicable also the GDPR, it cannot comprehensively
            guarantee the confidentiality, integrity, authenticity and
            availability of personal data. The user hereby acknowledges that
            personal data that is disclosed on kvadrat.si (for the purpose of
            making a contact request) may also be accessed in countries which
            lack legislation that guarantees an adequate level of data
            protection. In addition, personal data can be registered by search
            engines and reproduced on the same with appropriate research.
          </p>

          <h1>2 Handling of personal data by kvadrat</h1>

          <p>
            Through their use of this website, users acknowledge kvadrat's
            process for handling personal data as described below and provide
            their consent to the use of their personal data by kvadrat to the
            extent and for the purposes described.
          </p>

          <h2>2.1 Collection of data</h2>
          <p>
            In order to provide services in the ecosystem of kvadrat and to
            optimise the online property platform kvadrat.si for users in the
            best possible way, kvadrat collects data at various points.
          </p>
          <p>
            This includes in particular the following essential personal data:
            <br />
            - Email address and password, salutation, surname, first name,
            address (street, postal code, locality), telephone number and
            language.
          </p>

          <h2>2.2 Storage</h2>

          <p>
            The personal data is stored in a secured database environment in
            Germany.
          </p>

          <p>
            Retention period: <br />

            The retention period shall only be as long as is necessary for the
            respective processing purpose or, in the case of contracts, at least
            for the duration of the contractual relationship. It shall also take
            into account the existence of a justified interest on the part of
            kvadrat in the data storage (e.g. limitation periods for possible
            legal claims of kvadrat, guarantee of IT security, data security and
            data protection control) as well as any contractual or statutory
            retention obligations.
          </p>

          <h2>2.3 Data security</h2>

          <p>
            kvadrat gives high priority to the protection of data against
            unauthorised access. In order to increase data security: <br />
            - kvadrat uses standard encryption techniques (for example, SSL)
            where necessary <br />
            - kvadrat verifies its practices regarding the collection, storage,
            use and handling of data including physical security measures to
            prevent unauthorised access to its systems <br />
            - kvadrat only grants access to personal data to employees and
            contractors who require such access in order to process the data for
            kvadrat for the stated purpose, and who have been obligated to
            uphold strict confidentiality requirements <br />
            The website kvadrat.si is hosted in Germany.
          </p>
          <p>
            Users are solely responsible for the safe delivery of their data and
            for ensuring that it is not intercepted, recorded, stolen or
            otherwise made available to third parties during its transmission
            between the respective user and kvadrat.
          </p>

          <h2>2.4 Use of data</h2>

          <h3>2.4.1 Registered users</h3>

          <p>
            In addition kvadrat shall also have access to the personal data
            collected when registering, ordering a product or using a kvadrat
            service.
          </p>

          <p>
            This personal data is used to provide and improve the services of
            kvadrat and may be used for commercial purposes.
          </p>

          <p>
            Registered users also accept that both kvadrat may use users
            submitted personal data internally for marketing purposes. Within
            the scope of said commercial use, registered users give their
            consent to contact them directly.
          </p>

          <h3>2.4.2 Non-registered users</h3>

          <p>
            Personal data submitted by non-registered users via a listing's
            contact form will be forwarded by kvadrat directly to the respective
            registered user or advertiser.
          </p>

          <h3>2.4.3 Registered personal data</h3>

          <p>
            The following personal data is collected at this point: <br />
            - General: contact data <br />
            - Non-registered users: all information from the contact form, such
            as salutation, surname, first name, address (street, postal code,
            locality), telephone number, email address <br />
            - Registered users: all information from the contact form, such as
            company, salutation, surname, first name, address (street, postal
            code, locality), telephone number, email address and message, and
            the contents of the user's inbox (of their personal user account,
            for the purpose of investigating suspected offences) <br />
            - Advertisers: all information from the contact form, such as
            company, name, surname, address (street, postal code, locality),
            telephone number, email address. the contents of the user's inbox
            (of their personal user account, for the purpose of investigating
            suspected offences) <br />
            - Additionally, for each property that is listed/saved by the user:
            immocode, price, property type, address (street, postal code,
            locality) as well as the invoice forms for any paid services
            provided by kvadrat
          </p>

          <p>
            This personal data is used to provide and improve the services of
            kvadrat and may be used for commercial purposes.
          </p>

          <h2>2.5 Processing</h2>

          <p>
            Registered users can correct or change the information saved in
            their user profile at any time. User profiles are edited by the
            respective user. kvadrat and the user's selected cantonal bank
            merely reserve the right to delete any profiles containing obviously
            incorrect information and to reset login information (e.g.
            passwords) at the express request of the user concerned.
          </p>
          <p>
            kvadrat also reserves the right to set the status of registered
            users whose last login was more than 12 months previously to
            inactive and subsequently to delete all data/properties associated
            with the respective accounts. There is no obligation on the part of
            kvadrat to inform the users concerned about these clean-up
            processes.
          </p>

          <h1>3 Handling of personal data with regard to third parties</h1>

          <p>
            The collected and stored personal data will not be sold, leased,
            given away, published or otherwise made available to third parties
            by kvadrat or the bank selected by the user.
          </p>
          <p>
            This does not apply to processing that is carried out: <br />
            - for a purpose directly related to the purpose for which the
            personal data was originally collected, e.g. the import and export
            of listings on partner platforms, or other third-party services to
            which the respective user has previously consented. <br />
            - in the case of misuse of the service <br />
            - to combat fraud <br />
          </p>

          <p>
            Furthermore, kvadrat will not share the respective user's personal
            data with third parties without said user's consent. However, they
            reserve the right to share information about undesirable advertisers
            with all affiliated partner platforms.
          </p>

          <h2>3.1 Misuse of the service</h2>

          <p>
            In the case of misuse of the service, and in particular in the case
            of suspicion of a criminal act, a legal obligation or a governmental
            or judicial order, kvadrat reserves the right to share users'
            personal data. In this context, personal data may be analysed in
            order to clarify the situation and, following a justified request,
            passed to the relevant authorities or the third parties who have
            been affected by the misuse.
          </p>

          <h2>3.2 Transmission of data to other service providers</h2>

          <p>
            Furthermore, the user agrees that kvadrat may share the personal
            data received to the following third parties: <br />
            payment providers (e.g. credit card companies, banks, PayPal, mobile
            phone providers) and IT service providers (e.g. data centres,
            hosting providers). These service providers will only be granted
            access to users' personal data to the extent required to carry out
            the associated tasks. The data may also be sent abroad for this
            purpose (subject to data protection compliant handling and only to a
            service provider commissioned by kvadrat).
          </p>

          <h2>3.3 Advertising, personalised advertising</h2>

          <p>
            kvadrat may show advertising to users who are using its services.
            kvadrat may also show personalised advertising created by third
            parties whose content is based on the respective user's personal
            data.
          </p>

          <h2>3.4 Co-registration</h2>

          <p>
            During registration on kvadrat, kvadrat also gives users the
            opportunity to register with its partner companies. Should users
            choose to complete a co-registration of this kind, kvadrat will
            forward the personal data collected for the co-registration to the
            respective partner, who will become the owner of the data.
          </p>

          <h2>3.5 Links to third-party service providers</h2>

          <p>
            The kvadrat.si website may contain links to offers by third-party
            providers. kvadrat has no influence over the way these providers
            process the customer data collected on their own websites. kvadrat
            is not responsible for compliance with data protection regulations
            on the part of these third parties.
          </p>

          <h2>3.6 Bot protection</h2>

          <p>
            To protect the platform and your data, kvadrat uses the bot
            protection service which scans the incoming application traffic and
            blocks unwanted malicious access to the platform. The corresponding
            checks are performed in compliance with data protection legislation.
          </p>

          <h1>4 Use of cookies and tracking systems</h1>

          <p>
            Through their use of this website kvadrat.si, users give their
            consent to the use of cookies and tracking systems by kvadrat to the
            extent and for the purposes described below.
          </p>
          <p>
            kvadrat uses <br />
            - cookies to optimise the platform and to determine the number of
            page views. <br />
            - on the other hand, tracking systems (e.g. scripts for the
            collection of web data) for the improvement of offers and the
            delivery of personalised recommendations and offers.
          </p>
          <p>
            kvadrat reserves the right to use tracking systems (see Section 4.2
            below).
          </p>
          <p>
            kvadrat will combine these tracking systems used with an update of
            the data protection provisions in advance and thus ensure that the
            tracking systems in operation are always explicitly listed in the
            data protection provisions.
          </p>

          <h2>4.1 Use of cookies</h2>

          <p>
            Cookies are anonymous text files that are stored on each user’s
            terminal.
          </p>

          <p>
            Cookies are used for the following purposes: <br />
            - For statistical purposes. <br />
            - For automatic login of registered users, provided that the
            visitor/user has specified this during the login process. <br />
            - To display content and advertising based on the
            advertiser's/user's interests. <br />
            Users can prevent the installation of cookies via their browser's
            settings. However, kvadrat wishes to point out that doing so may
            limit the user's ability to access all of the platform's features.
          </p>

          <h3>Third-party cookies</h3>

          <p>
            In order to provide its users with a platform that is free to use in
            principle, kvadrat is dependent upon advertising. kvadrat therefore
            allows selected partners to place advertisements on the website.
          </p>

          <p>
            In this context, other cookies (third-party cookies) may be placed
            on users' terminals when they view these advertisements. This use of
            cookies can also be prevented by users via the respective browser
            settings.
          </p>

          <h2>4.2 Use of tracking systems</h2>

          <p>
            kvadrat collects so-called tracking data, which provides information
            about the use of its website.
          </p>

          <p>The following tracking systems are used by kvadrat.</p>

          <h3>4.2.1 Tracking systems</h3>

          <p>
            kvadrat uses tools to improve its services, enrich existing profiles
            and deliver personalised recommendations to advertisers and users
            both on the platform and by email.
          </p>

          <p>
            Kvadrat stores the data on a secure database in Germany in
            compliance with data protection regulations.
          </p>

          <h4>Data collection module</h4>

          <p>
            The collection of information about the (unregistered) user on
            kvadrat.si takes place either anonymously (before login) or in a
            pseudonymised form and includes the email address as identification
            of the already registered user as well as, for example, the language
            of the page called up, the location of his search relevant to the
            user as well as information about the advertisement called up.
          </p>

          <p>
            The survey requires, among other things, the setting of cookies in
            the browsers of users who can also record IP address, browser user
            agent, cookie identifiers and pseudonymised identifiers.
          </p>

          <p>
            The user can prevent the placement of cookies in the settings of his
            respective browser.
          </p>

          <h4>Profile enrichment</h4>

          <p>
            In addition, kvadrat collects various website activities of the
            registered user.
          </p>

          <p>
            The collection includes the email address as identification of the
            registered user as well as, for example, the date of
            publication/storage in the watch list of an advertisement, the
            booking of a product, the last registration and the storage of a
            search subscription.
          </p>

          <p>
            Registered users can object to web tracking, profile enrichment and
            the sending of recommendations by deactivating the corresponding
            function in the respective user data settings or by sending a
            message to
            &#105;&#110;&#102;&#111;&#064;&#107;&#118;&#097;&#100;&#114;&#097;&#116;&#046;&#115;&#105;.
          </p>

          <h3>4.2.2 Google Analytics</h3>

          <p>
            kvadrat uses Google Analytics, a web analytics service provided by
            Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA
            (Google).
          </p>

          <p>
            Google Analytics uses cookies, which are stored on the user's
            computer, to help the website analyse how users use the site to
            improve its performance.
          </p>

          <p>
            The information generated by the cookies about the use of this
            website (including the IP address of the user) will be transmitted
            to and stored by Google on servers in the United States. Google will
            use this information for the purpose of evaluating the use of the
            website by users, compiling reports on website activity for website
            operators and providing other services relating to website activity
            and internet usage. This information may also be transmitted to
            third parties as required by law or if third parties are
            commissioned to process this data on behalf of the aforementioned
            web analytics services. In no case will the user's IP address be
            associated with the other data being processed by these web
            analytics services.
          </p>

          <p>
            The user may refuse the use of cookies by selecting the appropriate
            settings on his browser or may refuse the collection and processing
            of cookies by Google, including IP addresses and other data relating
            to the use of the website. The necessary browser plug-in can be
            found under the following link
            (https://www.google.com/settings/ads/plugin?hl=en) In this case,
            however, kvadrat would like to point out that not all functions of
            the platform may be fully available to the user.
          </p>

          <p>
            Further information on terms of use and data protection can be found
            at http://www.google.com/analytics/terms/en.html or under
            https://www.google.com/intl/en/policies/
          </p>

          <h3>4.2.3 Doubleclick from Google Inc</h3>

          <p>
            kvadrat uses Doubleclick from Google Inc, 1600 Amphitheatre Parkway,
            Mountain View, CA 94043, USA ("Google"). The information generated
            is transmitted by Google to a server in the USA for evaluation and
            stored there.
          </p>

          <p>
            Users can view the current profile determined by Google under
            https://www.google.com/ads/preferences. It is also possible to edit
            the profile or deactivate certain functions on the aforementioned
            website.
          </p>

          <h3>4.2.4 Google Remarketing</h3>

          <p>
            kvadrat uses services from Google Remarketing, a feature of Google
            AdWords, to create and display individual, interest-relevant
            advertisements via the Google advertising network.
          </p>

          <p>
            The operating company of the services of Google Remarketing is
            Google.
          </p>

          <p>
            To recognise a user, Google Remarketing stores a cookie in the
            browser of the user. Identification by IP address takes place
            automatically at Google if Internet pages are accessed that are also
            members of the Google advertising network. Google may also pass on
            the collected information to third parties as required by law or if
            third parties are commissioned to process this data on behalf of the
            aforementioned web analytics services.
          </p>

          <p>
            Users can prevent the setting of cookies at any time by means of an
            appropriate setting in the browser, as described above. It is also
            possible to object to interest-related advertising by Google by
            calling up www.google.com/settings/ads and saving the desired
            settings.
          </p>

          <p>
            More information and Google's current privacy policy can be found at
            https://www.google.com/intl/en/policies/privacy/.
          </p>

          <h3>4.2.5 Facebook Remarketing / Retargeting</h3>

          <p>
            kvadrat uses the remarketing function "Custom Audiences" of the
            social network Facebook Ireland Ltd., 4 Grand Canal Square, Grand
            Canal Harbour, Dublin 2, Ireland (Facebook).
          </p>

          <p>
            The information obtained in this way about the pages visited by
            users may be used by kvadrat, among other things, to play
            interest-related advertisements ("Facebook Ads") on the social
            network Facebook.
          </p>

          <p>
            When users visit kvadrat.si, a cookie is set and a direct link is
            established between the browser and the Facebook server to assign
            the visit to a Facebook account. kvadrat has no influence on the
            extent and further use of the data collected by Facebook through the
            use of this tool. By integrating Facebook Custom Audiences, it is
            possible for Facebook to receive the information that users have
            called up kvadrat.si or clicked on an advertisement from kvadrat. If
            users are registered with a Facebook service, Facebook can assign
            the visit to an account.
          </p>

          <p>
            Even if users are not registered on Facebook or have not logged in,
            the provider may learn and store IP addresses and other identifiers.
          </p>

          <p>
            Users can prevent the placement of cookies via their browser's
            settings or - if logged into Facebook - disable the function at
            https://www.facebook.com/settings/?tab=ads#_.
          </p>

          <p>
            kvadrat wants to point out that doing so may limit the user's
            ability to access all of the platform's features. You can find more
            information about this in Facebook's privacy policy at
            https://www.facebook.com/about/privacy/.
          </p>

          <h3>4.2.6 Hotjar</h3>

          <p>
            kvadrat uses services from Hotjar Analytics, a feature of
            Hotjar Ltd. Dragonara Business Centre. 5th Floor, Dragonara Road, Paceville St Julian's STJ 3141, Malta.
          </p>

          <h1>5 Use of the Newsletter</h1>

          <p>
            kvadrat offers the possibility to subscribe to a newsletter for the
            purpose of receiving relevant information on the real estate market
            or current products and services from kvadrat.
          </p>
          <p>
            The following data stored during registration for the newsletter
            receipt will be transmitted for the technical processing of the
            dispatch: <br />
            - Newsletter subscription: Salutation, first name, surname, email
            address, postal code, confirmation of registration. <br />
            - Save search subscription: Email address, confirmation of
            registration. <br />
            - Registration on kvadrat.si/user data: Email address, confirmation
            of registration, time of registration and all information from the
            user data. <br />
          </p>
          <p>
            As part of the delivery of the newsletter, kvadrat evaluates the
            opening rate and clicks of the users in the newsletter in order to
            draw conclusions about the delivered offer and to send the user
            further, more suitable offers. <br />
            Users can unsubscribe from the newsletter at any time by clicking on
            the corresponding link at the end of the respective newsletter or by
            sending an email to
            &#105;&#110;&#102;&#111;&#064;&#107;&#118;&#097;&#100;&#114;&#097;&#116;&#046;&#115;&#105;.
          </p>

          <h1>6 Rights in relation to the processing of personal data</h1>

          <p>
            Users are entitled to request information about their personal data
            which is used by kvadrat at any time. Users also have the right to
            correct erroneous data and to terminate (delete) the collection of
            their personal data.
          </p>
          <p>
            The deletion shall take place unless there are legal provisions and
            orders from state authorities, e.g. courts, which require kvadrat to
            store and make available this personal data, or if the data is
            subject to any statutory retention periods.
          </p>
          <p>
            Furthermore, users may object to the data processing or revoke their
            previously granted consent. In such cases, any processing of the
            data that took place before the revocation shall retain its
            legality.
          </p>

          <p>
            To exercise any of the above rights, requests must be sent by email
            to
            &#105;&#110;&#102;&#111;&#064;&#107;&#118;&#097;&#100;&#114;&#097;&#116;&#046;&#115;&#105;
            or in writing to the Data Protection Officer at kvadrat.si.
          </p>

          <p>
            At any time, kvadrat may request proof of identification in the
            context of information and deletion requests.
          </p>

          <p>
            If applicable, users also have the right to lodge an appeal with the
            competent supervisory authority regarding data processing. Users can
            do so by contacting the supervisory authority with responsibility
            for their place of residence, workplace or the location of the
            suspected data-protection violation.
          </p>

          <p>
            The competent supervisory authority is Informacijski pooblaščenec,
            Dunajska cesta 22, 1000 Ljubljana, Slovenia
          </p>

          <h1>7 Changes to the Privacy Policy</h1>

          <p>
            The contents of this Privacy Policy are subject to modification at
            any time. kvadrat will thereby ensure that the privacy rights of the
            affected users are not unlawfully infringed and that their rights
            are not restricted without the explicit consent of the respective
            users.
          </p>
          <p>
            kvadrat also undertakes to publish any changes to its Privacy Policy
            immediately on its website in order to inform users about the
            changes. kvadrat also recommends that users read this Privacy Policy
            at regular intervals.
          </p>

          <h1>8 Consent</h1>
          <p>
            The user hereby agrees that their personal data may be collected in
            accordance with the above statements and stored and used for the
            purposes described therein.
          </p>

          <p>Copyright by kvadrat</p>

          <p>Guidelines for privacy policies as of 12th of june 2024</p>
        </ng-container>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>

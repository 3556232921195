import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { DataService } from "../services/data.service";

@Injectable()
export class BucketService {
  // Link to our api
  API: string;
  MAIN_FOLDER: string;
  SHARED_FOLDER: string;

  constructor(private http: HttpClient, private dataService: DataService) {
    this.API = this.dataService.API;
  }

  // List all ads
  getAllAds() {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    return this.http.get(`${this.API}/properties`, {
      headers,
    });
  }

  // List contents of PUBLIC folder
  getLatestAds() {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    return this.http.get(`${this.API}/properties/latest`, {
      headers,
    });
  }

  // List filtered ads
  getFiltered(params) {
    return this.http.get(`${this.API}/properties/filter`, {
      params,
    });
  }
  listAdImages(id: string, type: number) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    return this.http.get(`${this.API}/files/list/${id}/${type}`, {
      headers,
    });
  }

  // TODO: refactor to use listAdImages
  listAgencyImages(id: string, type: number) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    return this.http.get(`${this.API}/files/list-agency/${id}/${type}`, {
      headers,
    });
  }

  getAddress(lat: string, lng: string) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.get(
      `${this.API}/locations/geoLocation?lat=${lat}&lng=${lng}`,
      {
        headers,
      }
    );
  }

  // Create new Ad
  postAd(formData, userId: string, userEmail: string) {
    return this.http.post(`${this.API}/properties/property`, {
      data: formData,
      user: userId,
      userEmail: userEmail,
    });
  }
  // Create new Ad
  updateAd(adId: string, formData: any, userId: string) {
    // console.log(formData);
    
    return this.http.post(`${this.API}/properties/update/${adId}`, {
      data: formData,
      userId,
    });
  }

  // get single Ad
  getSingleAd(adId: string, userId: string) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.get(`${this.API}/properties/${adId}`, {
      headers,
    });
  }

  // get single Ad
  verifySingle(adId: string, userId: string) {
    const headers = new HttpHeaders().set("Content-Type", "application/json").set("userId", userId);
    return this.http.get(`${this.API}/properties/single-check/${adId}`, {
      headers
    });
  }

  // get single Ad preview
  getSinglePreview(adId: string, userId: string) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.get(`${this.API}/properties/preview/${adId}`, {
      headers,
    });
  }

  // get user ads
  getAdsByUser(userId: string) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.get(`${this.API}/properties/user/${userId}`, { headers });
  }

  getFilteredAds(query: string) {
    return this.http.get(`${this.API}/properties${query}`);
  }

  // Create new Ad
  deleteImage(id: string, userId: string) {
    let headers = new HttpHeaders().set("userid", userId);
    return this.http.delete(`${this.API}/files/image/${id}`, { headers });
  }

  deleteAllItemImages(id: string, userId: string, type: number) {
    let headers = new HttpHeaders().set("userid", userId);
    return this.http.delete(`${this.API}/files/item/${id}/${type}`, { headers });
  }

  // Approve ad
  approveAd(adId: string, userId: string, authorId: string) {
    return this.http.post(`${this.API}/properties/approve/${adId}`, {
      userId,
      authorId
    });
  }

  // Activate ad
  activateAd(adId: string, userId: string) {
    return this.http.post(`${this.API}/properties/activate/${adId}`, {
      userId,
    });
  }

  // Deactivate ad
  deactivateAd(adId: string, userId: string) {
    return this.http.post(`${this.API}/properties/deactivate/${adId}`, {
      userId,
    });
  }

  // Remove ad by user
  removeAd(adId: string, userId: string) {
    return this.http.post(`${this.API}/properties/remove/${adId}`, {
      userId,
    });
  }
}

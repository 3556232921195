<ion-header class="shrinkable">
  <app-main-header
    [mobileView]="mobileView"
    [title]="'community wall'"
    [basePath]="'about'"
    [currentUser]="currentUser"
  ></app-main-header>
</ion-header>

<ion-content [fullscreen]="true" class="base-page-content">
  <div class="content-body posts-container">
    <ion-card>
      <ion-item>
        <ion-avatar class="user-avatar">
          <app-profile-photo #profilePhoto [userId]="currentUser?._id" [size]="36">
          </app-profile-photo>
        </ion-avatar>
        <ion-label>
          <p (click)="createNewPost()">
            {{ lang.whatsOnYourMind }}<span *ngIf="currentUser?.name">, {{ currentUser?.name }}</span>?
          </p>
        </ion-label>
      </ion-item>
    </ion-card>

    <ion-list *ngIf="newPostsToRefresh > 0" class="load-more-button fetch-new-posts">
      <ion-button (click)="fetchNewPosts()">
        <!-- {{ lang.more }} -->
        {{ lang.loadNewPosts }} - {{ newPostsToRefresh }}
      </ion-button>
    </ion-list>

    <ng-container *ngFor="let post of posts">
      <ion-card class="agent-card">
        <ion-item>
          <ion-avatar class="user-avatar">
            <app-profile-photo #profilePhoto [userId]="post.userId" [size]="36">
            </app-profile-photo>
          </ion-avatar>
          
          <ion-label>
            <p><b><a [routerLink]="['/p', post.userId]">
              {{ post.userName }}
            </a></b></p>
            <p class="small">{{ post.created | date: "short" }}</p>
          </ion-label>

          <ion-button fill="clear" class="more-button" (click)="presentPostOptionsPopover($event, post)">
            <ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
          </ion-button>
        </ion-item>
  
        <ion-item (click)="postPreviewModal(post)">
          <p class="post-text" [class.no-images]="post.images?.length === 0">{{ post.text }}</p>
        </ion-item>
  
        <ion-row *ngIf="post.images?.length > 0">
          <ion-grid class="image-grid" (click)="openPhotoPreview(post)">
            <ion-row>
              <ion-col class="image-col" size="12"
                        *ngIf="post.images.length === 1"
                        [ngStyle]="{'background-image':'url(' + post.images[0] + ')'}">
              </ion-col>
              <ion-col class="image-col" size="12" size-sm="6"
                        *ngIf="post.images.length === 2"
                        [ngStyle]="{'background-image':'url(' + post.images[0] + ')'}">
              </ion-col>
              <ion-col class="image-col" size="12" size-sm="6" 
                        *ngIf="post.images.length === 2"
                        [ngStyle]="{'background-image':'url(' + post.images[1] + ')'}">
              </ion-col>
              <ion-col class="image-col" size="12" size-sm="8"
                *ngIf="post.images.length > 2"
                [ngStyle]="{'background-image':'url(' + post.images[0] + ')'}">
              </ion-col>
              <ion-col class="image-col" size="12" size-sm="4"
                        *ngIf="post.images.length > 2">
                <div class="extra-photo"
                      [ngStyle]="{'background-image':'url(' + post.images[1] + ')'}">
                </div>
                <div class="extra-photo"
                      [ngStyle]="{'background-image':'url(' + post.images[2] + ')'}">
                  <div class="extra-photo-overlay">
                    <span>+ {{ post.images.length - 2 }}</span>
                  </div>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-row>

        <ion-row>
          <ion-col size="6" class="ion-text-center">
            <ion-button class="thumbs-button" fill="clear"
                        (click)="voteOnItem(post, post.userId, 1)">
              <ion-icon slot="icon-only" name="thumbs-up-outline"></ion-icon>
              <span *ngIf="post.likes > 0" class="likes-num">
                {{ post.likes }}
              </span>
            </ion-button>
          </ion-col>
          <ion-col size="6" class="ion-text-center">
            <ion-button class="thumbs-button" fill="clear"
                        (click)="postPreviewModal(post)">
              <ion-icon slot="icon-only" name="chatbubbles-outline"></ion-icon>
              <span *ngIf="post.commentsNumber > 0" class="likes-num">
                {{ post.commentsNumber }}
              </span>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-card>
    </ng-container>
    <ion-spinner *ngIf="posts.length === 0 && canLoadMore"></ion-spinner>
  </div>

  <ion-infinite-scroll threshold="100px" (ionInfinite)="triggerGetPosts($event)" *ngIf="canLoadMore">
    <ion-infinite-scroll-content loadingSpinner="bubbles" [loadingText]="lang.loading"></ion-infinite-scroll-content>
  </ion-infinite-scroll>

  <ion-list *ngIf="!canLoadMore && loadCount === 5" class="load-more-button">
    <ion-button (click)="manualLoad()">
      {{ lang.more }}
    </ion-button>
  </ion-list>

  <app-footer></app-footer>
</ion-content>

<ion-grid>
  <ion-row>
    <ion-col size="12">
      <div class="container about-us-text">
        <ng-container *ngIf="appLanguage === 'sl'">
          <h1>O nas</h1>

          <p>
            Poslanstvo kvadrat.si je nuditi svojim uporabnikom najboljša možna
            orodja za prodajo, nakup in najem nepremičnin. To želi doseči z
            nenehnim razvojem in uporabo naj naprednejših tehnologij ter
            spoznanj iz znanosti.
          </p>
          <p>
            Razvijalci in strokovnjaki iz nepremičninske industrije so združili
            moči za razvoj portala kvadrat, da postane vodilni nepremičninski
            portal. Dolgoročno je cilj ustvariti ekosistem, ki temelji na
            preglednosti in pravičnosti.
          </p>
          <p>
            Na nepremičninski platformi, ki jo upravlja kvadrat, lahko
            oglaševalci navedejo lastnosti, uporabniki pa lahko poiščejo te
            oglase. Za iskalce nepremičnin in zasebne uporabnike je uporaba
            platforme brezplačna.
          </p>
        </ng-container>

        <!-- English -->
        <ng-container *ngIf="appLanguage === 'en'">
          <h1>About us</h1>

          <p>
            kvadrat.si aims to operate and further develop a real estate
            platform and the associated services. Software developers and
            representatives from the real estate industry have joined forces to
            further develop the existing portal kvadrat to make it a leading
            real estate portal. In the long term, the aim is to create a real
            estate ecosystem which is based on transparency and fairness.
          </p>
          <p>
            On the property platform operated by kvadrat, advertisers can list
            properties and users can search for these listings. For private
            users, use of the platform is in principle free of charge.
          </p>
          <p>
            In pursuit of the aforementioned ecosystem, the services of
            providers from the real estate industry are also being continuously
            expanded for the users.
          </p>
        </ng-container>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { DataService } from './data.service';

@Injectable()
export class PostsService {

  // Link to our api
  API: string;

  constructor(
    private http: HttpClient,
    private dataService: DataService
  ) {
    this.API = this.dataService.API;
  }

  // List all posts
  getAllPosts(page: number, limit: number) {
    // const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.get(`${this.API}/posts/all?page=${page}&limit=${limit}`);
  }

  getPostsUser(userId: string) {
    // console.log(userId);
    return this.http.get(`${this.API}/posts/user/${userId}`);
  }

  addPost(userId: string, text: string) {
    // console.log(ad, user, post, replyToPost, replyToUser);
    return this.http.post(`${this.API}/posts/addpost`, {
      userId,
      text,
    });
  }

  editPost(postId: string, text: string, userId: string) {
    // console.log(ad, user, post, replyToPost, replyToUser);
    return this.http.post(`${this.API}/posts/edit`, {
      postId,
      text,
      userId
    });
  }

  // Delete post
  deletePost(postId: string, userid: string) {
    // console.log(postId);
    return this.http.delete(`${this.API}/posts/delete?id=${postId}`, { headers: { userid }});
  }

  // get single Post
  verifySinglePost(postId: string, userId: string) {
    const headers = new HttpHeaders().set("Content-Type", "application/json").set("userId", userId);
    return this.http.get(`${this.API}/posts/single-check/${postId}`, {
      headers
    });
  }

  // Approve post
  approvePost(postId: string, userId: string, authorId: string) {
    return this.http.post(`${this.API}/posts/approve/${postId}`, {
      userId,
      authorId
    });
  }

  // Reject post
  rejectPost(postId: string, userId: string, authorId: string) {
    return this.http.post(`${this.API}/posts/reject/${postId}`, {
      userId,
      authorId
    });
  }
}

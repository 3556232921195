<ion-list class="language-popover" lines="full">
  <ion-item type="button"
            button="true"
            class="flag-button"
            (click)="setLanguage('sl')">
    <img class="language-flag" src="assets/images/slovenia-flag-round.png" alt="slovensko">
    <span>Slovenščina</span>
  </ion-item>
  <ion-item type="button"
            button="true"
            class="flag-button"
            (click)="setLanguage('en')">
    <img class="language-flag" src="assets/images/britain-flag-round.png" alt="english">
    <span>English</span>
  </ion-item>
</ion-list>
import { Component, OnInit } from "@angular/core";
// import { Platform } from "@ionic/angular";
import { animate, style, transition, trigger } from "@angular/animations";
import { register } from "swiper/element/bundle";

import { LanguageService } from "./services/language.service";
import { LoginService } from "./services/login.service";
import { DataService } from "./services/data.service";
// import { WindowService } from "./services/window.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
  animations: [
    trigger(
      'loaderAnimation', [
        transition(':enter', [
          style({opacity: 0}),
          animate('200ms', style({opacity: 1}))
        ]),
        transition(':leave', [
          style({opacity: 1}),
          animate('200ms', style({opacity: 0}))
        ])
      ]
    )
  ],
})
export class AppComponent implements OnInit {
  lang: any;
  loggedIn: boolean;
  currentUserId = "";
  API: string;
  prefersDarkListener: any;
  languageParams: string | null;
  loader = true;

  constructor(
    // private platform: Platform,
    private languageService: LanguageService,
    private loginService: LoginService,
    private dataService: DataService,
    // private windowService: WindowService
  ) {
    // this.initializeApp();
    // this.lang = this.languageService['en'];
  }

  ngOnInit() {
    // SET LANGUAGE
    this.languageService.language$.subscribe((value) => {
      // console.log(this.languageService[value]);
      this.lang = this.languageService[value];
      this.setHomepageLink(value);
    });
        
    // console.log("loader: ", this.loader);
    this.dataService.isBrowser$.subscribe((isBrowser) => {
      // console.log(isBrowser);
      if (isBrowser) {
        register();
        this.dataService.loggedIn$.subscribe((value) => {
          this.loggedIn = value;
          if (this.loginService.currentUser()) {
            this.currentUserId = this.loginService.currentUser()._id;
          } else {
            this.currentUserId = "";
          }
        });
        this.loader = false;
        // console.log("loader: ", this.loader);
        // setTimeout(() => {
        // }, 5000);
        
        // this.initializeApp();
      }
    });
  }

  // initializeApp() {
  //   this.platform.ready().then(() => {
  //     this.statusBar.styleDefault();
  //     this.splashScreen.hide();
  //   });
  // }

  setHomepageLink(language: string) {
    if (language === "en") {
      this.languageParams = "en";
    } else {
      this.languageParams = null;
    }
  }

  logout() {
    this.loginService.logout();
  }
}

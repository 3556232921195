<ion-grid>
  <ion-row>
    <ion-col size="12">
      <div class="container about-us-text">
        <ng-container *ngIf="appLanguage === 'sl'">
          <p>Nova različica 2024, veljavna od 12. junija 2024 do preklica:</p>
          <h1>1 Uporabnost in pravne informacije</h1>

          <h2>Preambula</h2>

          <p>
            kvadrat.si je namenjen delovanju in nadaljnem razvoju nepremičninske
            platforme in pripadajočih storitev.
          </p>
          <p>
            Na nepremičninski platformi, ki jo upravlja kvadrat, lahko
            oglaševalci navedejo lastnosti, uporabniki pa lahko poiščejo te
            oglase. Za zasebne uporabnike je uporaba platforme načeloma
            brezplačna.
          </p>
          <h2>Hierarhija nadzornih sistemov</h2>
          <p>
            Sedanji Splošni pogoji poslovanja (SPP) urejajo temeljno razmerje
            med uporabniki in podjetjem kvadrat.
          </p>
          <p>
            Te SPP dopolnjuje ločena »Politika oglaševanja in smernice«. Poleg
            teh SPP, ta pravilnik določa tudi pogodbeno razmerje med uporabniki
            in podjetjem kvadrat glede na nepremičninsko platformo kvadrat.si. V
            primeru morebitnega protislovja, imajo SPP prednost pred »Politiko
            oglaševanja«.
          </p>
          <p>
            V zvezi z varstvom podatkov mora biti obrazec, ki vsebuje kvadratovo
            "Politiko zasebnosti". upoštevan poleg teh SPP.
          </p>
          <p>
            Za dodatne storitve, ki jih ponuja kvadrat, ki so na voljo
            uporabnikom, veljajo ločene »Smernice za plačljive storitve«, ki
            dopolnjujejo te SPP, kot tudi »Smernice za naročila oglaševanja od
            poslovnih strank za spletno mesto in mobilno aplikacijo«, ki sta
            pomožni sestavni del teh SPP in v njih navedeni. Ponovno, v primeru
            kakršne koli nedoslednosti, imajo ti SPP prednost pred „Smernicami
            za plačljive storitve“ in »Smernicami za oglaševanje naročil
            poslovnih strank za spletno mesto in mobilne aplikacije".
          </p>
          <p>
            Med registracijo so uporabniki jasno in pravno zadostno obveščeni o
            teh SPP prek zaslonov uporabnikovih naprav. Ko uporabniki predložijo
            svojo izjavo o nameri (tj SPP) s klikom na ustrezne ikone (kar se
            potrdi s kljukico), ti SPP postanejo izrecna sestavina pogodbe,
            sklenjene med uporabnikom in družbo kvadrat.
          </p>
          <p>
            Z obiskom spletne strani kvadrat.si za neregistrirane uporabnike
            implicitno veljajo ti SPP. Če zadevni anonimni uporabnik noče
            sprejeti teh SPP, mora takoj zapustiti spletno stran.
          </p>
          <p>Definicije:</p>
          <ul>
            <li>
              - Uporabnik: Uporabniki so opredeljeni kot vse fizične ali pravne
              osebe, ki dostopajo do storitev, ki jih ponuja kvadrat na
              platformi kvadrat.si.
            </li>
            <li>
              - Oglasi: oglasi so neodvisno konfigurirane predstavitve
              lastnosti, ustvarjene za namen objave.
            </li>
            <li>
              - Plačljive storitve: Poleg brezplačnih osnovnih storitev za
              fizične osebe, kvadrat zagotavlja tudi dodatne plačljive storitve,
              ki se imenujejo »plačane storitve«.
            </li>
          </ul>

          <h1>2 Pravice intelektualne lastnine</h1>

          <p>
            Vse pravice do informacij, elementov in vsebine platforme pripadajo
            kvadratu (in posebne avtorske pravice in pravice blagovne znamke),
            in kjer ni tako, so pravice do uporabe odobrene v korist kvadrata s
            strani tretje osebe.
          </p>
          <p>
            kvadrat ni lastnik vseh blagovnih znamk, ki se pojavljajo v
            informacijah, dokumentih in podatkih na spletni strani kvadrat.si. V
            teh primerih je potrebno spoštovati pravice do blagovne znamke
            prizadetih tretjih oseb.
          </p>
          <p>
            kvadrat in z njim povezani logotipi so registrirane blagovne znamke
            kvadrata. Noben del kvadrat.si ni zasnovan tako, da bi uporabnikom
            podelil licenco ali pravico do uporabe slik, registrirane blagovne
            znamke ali logotipa. Prenos ali kopiranje vsebin kvadrat.si ne
            pomeni prenosa katerekoli pravice do programske opreme ali elementov
            na spletnem mestu uporabnikom. kvadrat si pridržuje vse pravice (in
            posebne avtorske in blagovne znamke) za vse gradivo na kvadrat.si in
            bo uporabil vsa razpoložljiva sredstva za uveljavljanje teh pravic v
            celoti.
          </p>

          <h1>3 Relevantnost objavljenih informacij</h1>
          <p>
            Podatki, objavljeni na kvadrat.si, ne predstavljajo zbiranja ponudbe
            oz ponudba ali priporočilo za najem, nakup, prodajo, financiranje
            ali zavarovanje nepremičnin ali drugih naložb, instrumente ali
            vplivati ​​na druge transakcije. Poleg tega je pravica do začasnega
            najema oz. prodaje oglaševanih nepremičnin izrecno pridržana.
          </p>
          <p>
            kvadrat ni namenjen uporabnikom, za katere velja zakonodaja, ki
            prepoveduje objavo — ali dostop do — kvadrat.si (zaradi narodnosti
            osebe, prebivališča ali kako drugače). Uporabniki, za katere veljajo
            te omejitve, nimajo dovoljenja uporabljati kvadrat.si.
          </p>

          <h1>4 Garancija</h1>
          <p>
            kvadrat ne prevzema odgovornosti za vsebino oglasov, ki jih gosti.
            To vključuje zlasti slikovni material, izvlečke iz zemljevidov,
            predloge financiranja, ponudbe zavarovanj itd.
          </p>
          <p>
            kvadrat ni dolžan preverjati seznamov in informacij, objavljenih na
            svoji platformi za natančnost.
          </p>
          <p>
            Vse informacije na kvadrat.si, razen osebnih podatkov (glej ločeno
            “Pravilnik politike zasebnosti”), se lahko kadarkoli spremenijo brez
            predhodnega obvestila.
          </p>
          <p>
            Poleg tega kvadrat ne prevzema nikakršne odgovornosti in ne jamči,
            da: - funkcije kvadrat.si ne bodo prekinjene in so brez napak - da
            bodo napake odpravljene - programsko ali drugo gradivo, ki je
            dostopno preko kvadrat.si (vključno s partnerskimi stranmi in mikro
            stranmi) ali da na strežniku ni virusov ali škodljivih komponent.
          </p>

          <h1>5 Izjava o omejitvi odgovornosti</h1>

          <p>
            kvadrat odgovarja le za škodo, ki nastane uporabnikom kvadrata z
            uporabo njegove storitve in izdelkov, če je mogoče dokazati njegovo
            skrajno malomarno ali namerno ravnanje.
          </p>
          <p>
            Kakršna koli odgovornost kvadrata za škodo ali posledično škodo,
            zlasti tiste, ki izhaja iz dostopa do elementov kvadrat.si oziroma
            njihove uporabe, je maksimalno izključena v obsegu, ki ga dovoljuje
            zakon.
          </p>
          <p>
            kvadrat zavrača vso odgovornost v zvezi z objavo oglasov, ki
            vsebujejo predmete, ki so že prodani ali dani v najem.
          </p>
          <p>
            kvadrat torej ne odgovarja za zlonamerno tehnologijo in za škodo, ki
            jo povzročijo računalniški virusi, vohunska programska oprema in/ali
            podobna zlonamerna programska oprema. kvadrat tudi ne prevzema
            nobene odgovornosti za posledice obratovalnih motenj, ki so
            posledica kakršne koli okvare ali katere služijo odpravljanju okvar,
            vzdrževanju in uvajanju novih tehnologij.
          </p>

          <h1>6 Povezave do in z drugih spletnih mest</h1>

          <p>
            kvadrat ne pregleduje spletnih mest tretjih oseb, ki so povezane s
            platformo kvadrat.si in ne odgovarja za vsebino teh zunanjih strani
            ali drugih spletnih mest, na katere so povezane povezave kvadrat.si.
            Takšni zunanji internetni naslovi vsebujejo informacije, ki so
            ustvarjene, objavljene, vzdrževane ali kako drugače dane na voljo s
            strani organizacij in posameznikov, ki so zakonito neodvisni od
            kvadrata. kvadrat ne odgovarja za vsebino teh strani, prav tako ne
            odobrava, podpira ali potrjuje informacije na zunanjih spletnih
            mestih ali povezanih naslovih ki jih vsebuje.
          </p>
          <p>
            Spletna mesta tretjih oseb, ki so dostopna prek povezav iz oglasov
            nepremičnin na kvadrat.si in spletnih straneh tretjih oseb, ki
            ponujajo povezave na kvadrat.si, delujejo povsem izven vpliva
            kvadrat. Iz tega razloga kvadrat.si ne prevzema odgovornosti za
            točnost, popolnost in zakonitost vsebine takšnih spletnih mest ter
            za ponudbe ali storitve, ki jih vsebujejo. Uporabniki se na ta
            spletna mesta povezujejo na lastno odgovornost. Poleg tega
            postavitev povezav, ki vodijo s spletnih strani tretjih na
            kvadrat.si vedno zahtevajo predhodno odobritev kvadrata.
          </p>
          <p>
            Funkcije (tako imenovani vtičniki) širokega nabora ponudnikov
            platform družbenih medijev (npr kot Facebook) so integrirani v
            kvadrat.si. Ti vtičniki uporabnikom omogočajo skupno rabo vsebine
            socialnih omrežij. Če se med brskanjem po spletnem mestu prikaže
            vtičnik (npr. gumb »všeč mi je« od Facebook), se samodejno vzpostavi
            povezava s strežniki tega spletnega mesta. To omogoča podatke, ki
            jih je treba s kvadrat.si posredovati tem tretjim ponudnikom. Če je
            uporabnik hkrati prijavljen v omrežje tretje osebe, je obisk lahko
            registriran na spletno mesto uporabnikovega omrežnega računa.
            kvadrat nima nadzora nad tem postopkom.
          </p>

          <h1>7 Piškotki</h1>

          <p>
            Piškotki se uporabljajo za optimizacijo spletne strani kvadrat.si in
            beleženje ogledov strani. Ti piškotki so popolnoma anonimni in ne
            vsebujejo nobenih podatkov, povezanih z identiteto uporabnika.
          </p>
          <p>
            Piškotki se uporabljajo tudi za prikaz ciljne vsebine in oglaševanja
            uporabnikom na podlagi njihovih interesov. Dodatne informacije o tej
            temi najdete v obrazcu "Politika zasebnosti".
          </p>

          <h1>8 Nezakonito ravnanje</h1>
          <p>
            Če kvadrat ugotovi nezakonito ravnanje uporabnika, je upravičen do
            zaklepa in takojšnjega izbrisa celotnega zadevnega uporabnikovega
            računa.
          </p>

          <h1>9 Prekinitev brez obvestila s strani kvadrat</h1>

          <p>
            kvadrat si pridržuje pravico do enostranske odpovedi pogodbenega
            razmerja, sklenjenega z uporabniki, kadar po lastni presoji in s
            takojšnjim učinkom v primeru, da se platformo kvadrat.si in/ali
            storitve kvadrata uporabniki uporabljajo/zlorabljajo za namene, ki
            niso pogodbeno dogovorjeni.
          </p>

          <h1>10 Končne določbe</h1>

          <p>
            kvadrat si pridržuje pravico, da kadarkoli ter brez predhodnega
            obvestila spremeni svojo spletno storitev brez navedbe razlogov
            (zlasti za brisanje oglasov), da ga pretvori v drugo obliko/prenese
            v drugo partnerstvo, in delno ali v celoti prenehal z delovanjem
            kvadrat.si. Soglasje registriranih uporabnikov za to ni potrebno in
            taka zahteva po predhodnih informacijah ne obstaja. V tem primeru
            uporabniki nimajo pravice do uporabe posameznih funkcij ali
            splošnega dostopa do storitve.
          </p>
          <p>
            Te SPP lahko kvadrat kadarkoli spremeni. Trenutno različico SPP si
            uporabniki lahko ogledajo na 'www.kvadrat.si'. Registrirani
            uporabniki bodo o vsaki spremembi SPP ustrezno obveščeni. Ikono za
            potrditev veljavnosti nove različice SPP morajo registrirani
            uporabniki znova klikniti ob naslednji prijavi. Prejem nove
            različice SPP izrecno potrdi kljukica, ki se pojavi naknadno.
          </p>
          <p>
            V primeru morebitnih nejasnosti in/ali nasprotij med slovensko,
            nemško, francosko, italijansko in/ali angleško različico teh SPP, je
            samo slovenska različica teh SPP dokončna.
          </p>
          <p>
            Če je katerakoli določba teh pogojev v celoti ali delno neveljavna
            in/ali neučinkovita, veljavnost in/ali učinkovitost preostalih
            določb ali njihovih delov ostane nespremenjena. Neveljavne in/ali
            neučinkovite določbe se nadomestijo z novimi, ki se na pravno
            učinkovit način čim bolj približajo smislu in namenu neveljavnih
            in/ali neučinkovitih določb. Enako velja za ostale pravne luknje.
          </p>
          <p>Za te SPP velja izključno slovenska zakonodaja.</p>
          <p>
            Pristojnost urejajo ustrezne zavezujoče pravne organizacije. V
            kolikor se te določbe ne uporabljajo, je izključna pristojnost za
            vse vrste postopkov sedež kvadrata oz. ima kvadrat pravico
            preganjati uporabnika na pristojnem sodišču ali organu njegovega
            stalnega prebivališča/sedeža ali na katerem koli drugem primernem
            sodišču.
          </p>
          <p>Avtorske pravice kvadrat</p>
          <p>Splošni pogoji z dne 12.06.2024</p>
        </ng-container>

        <!-- English -->
        <ng-container *ngIf="appLanguage === 'en'">
          <p>
            New version 2024, valid from 12th of june 2024 until revoked:
          </p>

          <h1>1 Applicability and legal information</h1>

          <h2>Preamble</h2>

          <p>
            kvadrat.si, aims to operate and further develop a property platform
            and the associated services.
          </p>
          <p>
            On the property platform operated by kvadrat, advertisers can list
            properties and users can search for these listings. For private
            users, use of the platform is in principle free of charge.
          </p>

          <h2>Hierarchy of control systems</h2>
          <p>
            The present General Terms and Conditions (GTCs) regulate the
            fundamental legal relationship between the users and kvadrat.
          </p>
          <p>
            These GTCs are supplemented by the separate “Insertion and Usage
            Policy”. In addition to these GTCs, this policy also specifies the
            contractual relationship between the users and kvadrat in respect of
            the property platform kvadrat.si. In the case of a possible
            contradiction, the GTCs shall take precedence over the "Insertion
            and Usage Policy".
          </p>
          <p>
            With regard to data protection, the form containing kvadrat’s
            "Privacy Policy" must be observed in addition to these GTCs.
          </p>
          <p>
            For additional services offered by kvadrat, which are also available
            to the users, the additional services offered by kvadrat are also
            subject to the separate "Guidelines for Paid Services", which
            supplement these GTCs, as well as the "Guidelines for Advertising
            Orders from Business Customers for the Website and Mobile App",
            which are an ancillary component of these GTCs and specified
            therein. Again, in the event of any inconsistency, these GTCs shall
            take precedence over the "Guidelines for Paid Services" and the
            "Guidelines for Advertising Orders from Business Customers for the
            Website and Mobile App".
          </p>
          <p>
            During registration, users are given clear and legally sufficient
            notice of these GTCs via their end device's screen. When users
            submit their declaration of intent (i.e. their acceptance of the
            GTCs) by clicking the corresponding icons (which is confirmed via
            the appearance of a check mark), these GTCs become an explicit
            component of the contract concluded between the respective user and
            kvadrat.si.
          </p>
          <p>
            By visiting the website "kvadrat.si", non-registered users are
            implicitly subject to these GTCs. If an anonymous user concerned
            refuses to accept these GTC, they are required to exit the website
            immediately.
          </p>
          <p>Definitions:</p>
          <ul>
            <li>
              - User: Users are defined as any natural or legal persons who
              access services offered by kvadrat on the kvadrat.si platform.
            </li>
            <li>
              - Listings: Listings are independently configured representations
              of properties created for the purpose of publication.
            </li>
            <li>
              - Paid services: In addition to its free basic services for
              private individuals, kvadrat also provides additional paid
              services, which are referred to as "paid services".
            </li>
          </ul>

          <h1>2 Intellectual property rights</h1>

          <p>
            All rights to the information, elements and content of the platform
            belong to kvadrat (in particular copyright and trademark rights),
            and where this is not the case, usage rights for these have been
            granted by third parties in favour of kvadrat.
          </p>
          <p>
            kvadrat is not the owner of all the trademarks which appear in the
            information, documents and data contained on its website. In this
            regard, the trademark rights of the affected third parties must be
            respected.
          </p>
          <p>
            kvadrat and the associated logos are registered trademarks of
            kvadrat. No part of kvadrat.si is designed in such a way that grants
            users a licence or right to utilise a picture, registered trademark
            or logo. The downloading or copying of kvadrat.si does not entail a
            transfer of any rights to the software or elements on the site to
            users. kvadrat reserves all rights (in particular copyright and
            trademark rights) to all material on kvadrat.si and will employ
            every available means to enforce these rights in full.
          </p>

          <h1>3 Relevance of the published information</h1>

          <p>
            The information published on kvadrat.si does not constitute a
            solicitation of an offer, or an offer or recommendation to rent,
            buy, sell, finance or insure properties or other investment
            instruments or to affect other transactions. Furthermore, the right
            to interim leasing or interim sale of the advertised properties is
            expressly reserved.
          </p>
          <p>
            kvadrat is not intended for users who are subject to legislation
            prohibiting publication on — or access to — kvadrat.si (due to the
            nationality of the person, residence or otherwise). Users who are
            subject to such limitations are not permitted to access kvadrat.si.
          </p>

          <h1>4 Guarantee</h1>

          <p>
            kvadrat assumes no responsibility for the content of the listings
            hosted by it. This includes in particular the corresponding image
            data, extracts from town maps, financing proposals, insurance
            offers, etc.
          </p>
          <p>
            kvadrat is not obliged to check the listings and information
            published on its platform for accuracy.
          </p>
          <p>
            All information on kvadrat.si, with the exception of personal data
            (see the separate "Privacy Policy" form), may be changed by kvadrat
            at any time without notice.
          </p>
          <p>
            Furthermore, kvadrat accepts no responsibility for, and gives no
            guarantee, that: <br />
            - the features of kvadrat.si will not be interrupted and are
            error-free <br />
            - errors will be remedied <br />
            - software or other material that is accessible via kvadrat.si
            (including its partner sites and microsites) or the server is free
            from viruses or harmful components.
          </p>

          <h1>5 Disclaimer</h1>

          <p>
            kvadrat shall only be liable for damages incurred by users of
            kvadrat via the use of its services and products if its grossly
            negligent or intentional conduct can be proven.
          </p>
          <p>
            Any liability on the part of kvadrat for damages or consequential
            damages, in particular those resulting from access to the elements
            of kvadrat.si or their use, is excluded to the maximum extent
            permitted by law.
          </p>
          <p>
            kvadrat disclaims all liability in connection with the publication
            of properties that have already been sold or leased.
          </p>
          <p>
            kvadrat is therefore not liable for malicious technology and for
            damage caused by computer viruses, spyware and/or similar malware.
            kvadrat also accepts no liability for the consequences of
            operational interruptions caused by malfunctions of any kind or
            which serve to remedy malfunctions, maintenance and the introduction
            of new technologies.
          </p>

          <h1>6 Links to and from other websites</h1>

          <p>
            kvadrat does not review the third-party websites that are linked to
            the kvadrat.si platform and is not responsible for the content of
            these external sites or other websites that are linked to
            kvadrat.si. Such external internet addresses contain information
            that is created, published, maintained or otherwise made available
            by organisations and individuals that are legally independent of
            kvadrat. kvadrat is not responsible for the content of these sites,
            nor does it approve, support or confirm information contained in
            external sites or linked addresses contained therein.
          </p>
          <p>
            Third-party websites that are reached via links from property
            listings on kvadrat.si and third-party websites which offer links on
            kvadrat.si operate entirely beyond the influence of kvadrat. For
            this reason, kvadrat.si accepts no responsibility for the accuracy,
            completeness and legality of the content of such websites, or for
            any offers or services contained therein. Users connect to these
            websites at their own risk. Additionally, the placement of links
            that lead from third-party websites to kvadrat.si always requires
            prior approval by kvadrat.
          </p>
          <p>
            Features (so-called plug-ins) from a wide range of providers of
            social media platforms (such as Facebook) are integrated into
            kvadrat.si. These plug-ins allow users to share content in social
            networks. If a plug-in appears while browsing the web site (e.g. a
            "like" button from Facebook), a connection to the servers of that
            site is automatically established. This enables data to be
            transmitted from kvadrat.si to these third-party providers. If the
            user is simultaneously logged into the third party's network, the
            visit may be registered on the website of the user's network
            account. kvadrat has no control over this process.
          </p>

          <h1>7 Cookies</h1>

          <p>
            Cookies are used to optimise the website kvadrat.si and to record
            the page views. These cookies are completely anonymous and contain
            no data related to the user’s identity.
          </p>
          <p>
            Cookies are also used to show targeted content and advertising to
            users based on their interests. Further information on this topic
            can be found in the form "Privacy Policy".
          </p>

          <h1>8 Unlawful conduct</h1>
          <p>
            Where kvadrat identifies unlawful conduct on the part of a user, it
            is entitled to lock and delete the entire affected account
            immediately.
          </p>

          <h1>9 Termination without notice by kvadrat</h1>

          <p>
            kvadrat reserves the right to unilaterally terminate the contractual
            relationship concluded with users at any time at its own discretion
            and with immediate effect in the event that the kvadrat.si platform
            and/or the services provided by kvadrat are used/misused by users
            for purposes other than the contractually agreed purposes.
          </p>

          <h1>10 Final provisions</h1>

          <p>
            kvadrat reserves the right to change its online service at any time
            without notice for any reason (in particular to delete listings), to
            convert it into another form/transfer it to another partnership or
            to partially or entirely cease the operation of kvadrat.si. The
            registered users' consent is not required for this, and no such
            prior information requirement exists. Consequently, users do not
            have a right to use specific features or to access the service at
            all.
          </p>
          <p>
            These GTCs may be changed by kvadrat at any time. The current
            version of the GTCs can be viewed at "www.kvadrat.si". Registered
            users will be informed accordingly about any such changes to the
            GTCs. In order to ensure that the new version of the GTCs is valid,
            an icon must be clicked again by the registered users the next time
            they log in. Receipt of the new version of the GTCs is expressly
            confirmed by the check mark that subsequently appears.
          </p>
          <p>
            In case of any possible ambiguity and/or contradictions between the
            Slovenian, German, French, Italian and/or English versions of these
            GTCs, only the Slovenian version of these GTCs shall be definitive.
          </p>
          <p>
            Should any provision of these conditions be wholly or partially
            invalid and/or ineffective, the validity and/or effectiveness of the
            remaining provisions or parts thereof shall remain unaffected. The
            invalid and/or ineffective provisions shall be replaced by new
            provisions which, in a legally effective manner, come as close as
            possible to the meaning and purpose of the invalid and/or
            ineffective provisions. The same applies to any loopholes.
          </p>
          <p>These GTCs are exclusively subject to slovenian law.</p>
          <p>
            The jurisdiction is governed by the relevant binding legal
            provisions. To the extent that these provisions are not applicable,
            the exclusive jurisdiction for all types of proceedings is the
            headquarters of kvadrat, or kvadrat shall have the right to
            prosecute the user in the competent court or authority of their
            domiciles/head offices or in any other suitable court.
          </p>
          <p>Copyright by kvadrat</p>
          <p>General Terms & Conditions as of 06/12/2024</p>
        </ng-container>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>

import {
  Component,
  OnInit,
  HostListener,
  Input,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { ModalController } from "@ionic/angular";

import { BucketService } from "../../services/bucket.service";
import { DataService } from "../../services/data.service";
import { WindowService } from "../../services/window.service";
import { ViewHistoryService } from "../../services/view-history.service";
import { SocketService } from "../../services/socket.service";

import { PreviewModalComponent } from "../modals/preview-modal/preview-modal.component";
import { CurrentUser } from "../../model/current-user";
import { AdCardComponent } from "../ad-card/ad-card.component";
import { AdStatus } from "../../model/enums/ad-status";

@Component({
  selector: "app-last-viewed",
  templateUrl: "./last-viewed.component.html",
  styleUrls: ["./last-viewed.component.scss"],
})
export class LastViewedComponent implements OnInit {
  mobileView: boolean;
  colNum = "3";
  currUserViewHistory = [];
  loadingResults = true;

  @Input() lang: any;
  @Input() appLanguage: any;
  @Input() currentUser: CurrentUser;
  @ViewChildren("adCard") cards: QueryList<AdCardComponent>;

  constructor(
    private bucketService: BucketService,
    private modalCtrl: ModalController,
    private viewHistoryService: ViewHistoryService,
    private dataService: DataService,
    private windowService: WindowService,
    private socketService: SocketService
  ) {}

  ngOnInit(): void {
    this.dataService.isBrowser$.subscribe((isBrowser) => {
      // console.log(isBrowser);
      if (isBrowser) {
        this.toggleMobileView();
        this.toggleCardView();

        this.socketService.adChanges$.subscribe((msg) => {
          // if (msg["type"] === "favoriteChanges") {
          //   this.updateFavorite(msg["itemId"]);
          // }
          if (msg["type"] === "adActivated" || msg["type"] === "adDeactivated" || msg["type"] === "userViewHistoryUpdated") {
            this.getUserViewHistory(msg["itemId"]);
          }
        });

        this.socketService.adPhotoChanges$.subscribe((msg) => {
          const existingItem = this.currUserViewHistory.find(
            (ad) => ad._id === msg["itemId"]
          );

          if (existingItem && msg["type"] === "photosUpdated") {
            this.listAdImages(existingItem);
          }
        });
      }
    });

    this.dataService.viewHistoryIndex$.subscribe((index) => {
      this.getUserViewHistory(this.currentUser._id);
    });
  }

  // updateFavorite(itemId: string) {
  //   const adToUpdate = this.currUserViewHistory.find((ad) => ad._id === itemId);
  //   if (adToUpdate) {
  //     this.refreshCardFavorite(adToUpdate._id);
  //   }
  // }

  // refreshCardFavorite(itemId: string) {
  //   this.cards.forEach((element) => {
  //     if (element.item._id === itemId) {
  //       element.checkFavorite();
  //     }
  //   });
  // }

  getUserViewHistory(userId: string) {
    this.viewHistoryService.listUserViewHistory(userId).subscribe((result) => {
      this.currUserViewHistory = [];
      result["viewHistories"].forEach((element) => {
        this.pushAdToList(
          element.adId,
          this.currUserViewHistory,
          element.created
        );
      });
    });
  }

  pushAdToList(adId, itemList, updatedDate) {
    this.bucketService.getSingleAd(adId, "").subscribe((res) => {
      if (!res) {
        return;
      }
      const ad = res;
      this.checkAdExpiry(ad);
      if (ad["adExpired"]) {
        return;
      }
      this.listAdImages(ad);
      this.setUpdatedDate(ad, updatedDate);
      itemList.push(ad);
    });
  }

  pushAdToActiveList(adId, itemList) {
    this.bucketService.getSingleAd(adId, "").subscribe((res) => {
      const ad = res;
      this.checkAdExpiry(ad);
      if (ad["adExpired"]) {
        return;
      }
      this.listAdImages(ad);
      // todo: fix sort order
      this.currUserViewHistory = [...itemList, ad];
    });
  }

  listAdImages(ad): void {
    this.bucketService.listAdImages(ad._id, 3).subscribe((images) => {
      ad["photos"] = images["files"];
      ad.imageUrls = ad.photos.map(image => this.dataService.API + '/files/image/' + image._id);
    });
  }

  checkAdExpiry(ad) {
    if (!ad) {
      return;
    }
    
    if (
      ad.statusOfAd[ad.statusOfAd.length - 1].status === AdStatus.Active
      // && new Date(ad.statusOfAd[ad.statusOfAd.length - 1].changed).getTime() > new Date().getTime() - 30 * 24 * 60 * 60 * 1000
    ) {
      ad["adExpired"] = false;
      return;
    }
    ad["adExpired"] = true;
  }

  setUpdatedDate(ad, updatedDate) {
    ad["updatedDate"] = updatedDate;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.toggleMobileView();
    this.toggleCardView();
  }

  toggleMobileView() {
    if (this.windowService.nativeWindow.innerWidth <= "767") {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
  }

  toggleCardView() {
    if (this.windowService.nativeWindow.innerWidth <= "600") {
      this.colNum = "12";
    } else if (
      this.windowService.nativeWindow.innerWidth >= "601" &&
      this.windowService.nativeWindow.innerWidth <= "900"
    ) {
      this.colNum = "6";
    } else if (
      this.windowService.nativeWindow.innerWidth >= "901" &&
      this.windowService.nativeWindow.innerWidth <= "1200"
    ) {
      this.colNum = "4";
    } else if (this.windowService.nativeWindow.innerWidth >= "1201") {
      this.colNum = "3";
    }
  }

  async openPreviewModal(item) {
    const previewModal = await this.modalCtrl.create({
      component: PreviewModalComponent,
      componentProps: {
        updateSaveFavorite: (itemId) => {
          // this.refreshFavoriteCount(itemId);
        },
        updateFavoriteCount: (itemId) => {
          // this.refreshAdBannerFavorite(itemId);
        },
        updateAdView: (itemId) => {
          // this.refreshAdView(itemId);
        },
        lang: this.lang,
        appLanguage: this.appLanguage,
        ad: item,
      },
      cssClass: "preview-modal",
    });
    previewModal.present();
  }
}

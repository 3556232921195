import { Component, OnInit, HostListener } from "@angular/core";
import { MenuController, ModalController } from "@ionic/angular";

import { LanguageService } from "../../services/language.service";
import { LocalStorageService } from "../../services/localstorage.service";
import { LoginService } from "../../services/login.service";
import { DataService } from "../../services/data.service";
import { WindowService } from "../../services/window.service";
import { RealEstateAgencyService } from "../../services/re-agency.service";
import { BucketService } from "../../services/bucket.service";
import { MetaService } from "../../services/meta.service";

import { CurrentUser } from "../../model/current-user";
import { ActivatedRoute, Router } from "@angular/router";
import { LocationLink } from "../../model/location-link";

@Component({
  selector: "app-agency-page",
  templateUrl: "./agency-page.component.html",
  styleUrls: ["./agency-page.component.scss"],
}) /*extends CompleterBaseData*/
export class AgencyPageComponent implements OnInit {
  // variables
  ads: any;
  currentUser: CurrentUser;
  appLanguage: string;
  lang: any;
  loggedIn = false;
  layoutGrid: boolean;
  mobileView: boolean;
  title = "Kvadrat";
  latestAds = false;
  locations: LocationLink[] = []; // todo: Location type
  isBrowser = false;

  constructor(
    private dataService: DataService,
    private localStorageService: LocalStorageService,
    public modalCtrl: ModalController,
    private loginService: LoginService,
    private languageService: LanguageService,
    private menuCtrl: MenuController,
    private windowService: WindowService,
    private route: ActivatedRoute,
    private router: Router, // private locationService: LocationService, // private location: Location
    private realEstateAgencyService: RealEstateAgencyService,
    private bucketService: BucketService,
    private metaService: MetaService,
  ) {
    // this.lang = this.languageService['en'];
    // languageService.language$.subscribe(value => {
    //   this.lang = this.languageService[value];
    //   console.log(value);
    //   this.setMetaInfo();
    // });
  }

  ngOnInit() {
    // this.route.data.subscribe((data: { locations: any; language: string }) => {
    //   this.lang = this.languageService[data.language["language"]];
    //   this.locations = data.locations;
    //   this.setMetaInfo();
    // });

    // SET LANGUAGE
    this.languageService.language$.subscribe((value) => {
      this.appLanguage = value;
      this.lang = this.languageService[value];
    });

    this.API = this.dataService.API;
    
    // GET VIEW
    this.dataService.isBrowser$.subscribe((isBrowser) => {
      this.isBrowser = isBrowser;
      if (isBrowser) {
        // this.getCounties();
        this.toggleMobileView();

        this.getUrl();
        // SET LAYOUT
        if (
          this.localStorageService &&
          this.localStorageService.getItem("gridView")
        ) {
          this.layoutGrid = JSON.parse(
            this.localStorageService.getItem("gridView")
          );
        } else {
          // FALLBACK
          this.layoutGrid = false;
        }

        this.dataService.loggedIn$.subscribe((val) => {
          this.loggedIn = val;
          this.currentUser = this.loginService.currentUser();
        });
      }
    });
  }

  path = "";
  agency: any;
  API = "";
  getUrl() {
    this.route.url.subscribe((url) => {
      // console.log(url);
      // console.log(decodeURI(url));
      const pathId = url[1].path;
      this.path = url[0].path;

      this.getAgencyData(pathId);
    });
  }

  getAgencyData(agencyId: string): void {
    this.realEstateAgencyService
      .getRealEstateAgencySingle(agencyId)
      .subscribe((res) => {
        console.log(res);
        
        // this.ad = res;
        if (res && res["_id"]) {
          this.agency = res;
          this.getAgencyCoverImage(this.agency);
          this.getAgencyLogoImage(this.agency);
          this.setMetaInfo();
        }
      });
  }

  // TODO: get directly by adId and Type
  getAgencyCoverImage(agency): void {
    this.bucketService.listAgencyImages(agency._id, 0).subscribe((images) => {      
      let agencyImages = images["files"];
      agency["coverPhoto"] = agencyImages.find(image => image.metadata.type === 0)?._id;
    });
  }

  getAgencyLogoImage(agency): void {
    this.bucketService.listAgencyImages(agency._id, 1).subscribe((images) => {      
      let agencyImages = images["files"];
      agency["logo"] = agencyImages.find(image => image.metadata.type === 1)?._id;
    });
  }

  setMetaInfo() {
    this.metaService.setMetaDataObject({
      lang: this.lang,
      title: `${this.agency.companyName} - Kvadrat`,
      description: this.agency.address,
      keywords: "buy, sell, rent, condo, flat, apartment, house",
      url: this.windowService?.nativeWindow?.location.origin || "https://kvadrat.si",
      image: (this.windowService?.nativeWindow?.location.origin || "https://kvadrat.si") + "/assets/images/condo-323780.jpg" // TODO: replace with agency image
    });
  }

  enableLatestAds(event) {
    this.latestAds = event;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.toggleMobileView();
  }

  toggleMobileView() {
    if (this.windowService.nativeWindow.innerWidth <= "767") {
      this.mobileView = true;
      this.menuCtrl.swipeGesture(true);
    } else {
      this.mobileView = false;
      this.menuCtrl.swipeGesture(false);
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AlertController,
  PopoverController,
} from '@ionic/angular';

import { LanguageService } from '../../services/language.service';
import { SearchSubscriptionService } from '../../services/search-subscription.service';

import { PopoverLanguageComponent } from '../popovers/language-menu/language-menu-popover.component';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: 'unsubscribe.component.html'
})
export class UnsubscribeComponent implements OnInit {

  appLanguage: string;
  lang: any;

  constructor(
    private popoverCtrl: PopoverController,
    private languageService: LanguageService,
    private router: Router,
    private alertCtrl: AlertController,
    private route: ActivatedRoute,
    private searchSubscriptionService: SearchSubscriptionService,
  ) { }

  ngOnInit() {
    this.languageService.language$.subscribe(value => {
      this.appLanguage = value;
      this.lang = this.languageService[value];
    });

    this.route.url.subscribe((url) => {
      switch (url[0].path) {
        case "unsubscribe-listings":
          this.route.queryParams.subscribe((params) => {
            // console.log(params);
            this.handleUnsubscribe(params.email, params.random);
          });
          break;
        default:
          this.router.navigate(["/"]);
      }
      // console.log(this.path);
    });
  }

  async handleUnsubscribe(email, random) {

    this.searchSubscriptionService.removeAllUserSearchSubscription(email, random).subscribe(async res => {
      let title = "";
      if (res["success"]) {
        title = "Obveščanje je bilo uspešno izklopljeno";
      } else {
        title = "Napaka pri zahtevi. Prosimo poskusi ponovno.";
      }
        
      const alert = await this.alertCtrl.create({
        header: title,
        // subHeader: this.lang[msg],
        buttons: [
          {
            text: this.lang["ok"],
            role: "cancel",
            handler: () => {
              // console.log('Cancel clicked');
              this.router.navigate(["/"], {
                queryParams: { l: this.appLanguage === "en" ? "en" : null },
              });
            },
          },
        ],
      });
      await alert.present();

    })
  }

  // navbar add button
  async presentLanguagePopover(ev) {
    const popover = await this.popoverCtrl.create({
      component: PopoverLanguageComponent,
      componentProps: {
        setLang: (language) => {
          this.setLang(language);
        },
        lang: this.lang
      },
      event: ev,
    });
    popover.present();
  }

  setLang(language) {
    this.languageService.langChange(language);
    this.lang = this.languageService[language];
  }

  goToHomepage() {
    this.router.navigate(
      ['/'],
      { queryParams: { l: this.appLanguage === "en" ? "en" : null } }
    );
  }
}

import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { DecimalPipe } from "@angular/common";

import { Router, ActivatedRoute } from "@angular/router";
import { PopoverController, ModalController, ToastController } from "@ionic/angular";
import { Subscription } from "rxjs";
// import { LatLng, LatLngBounds, Map, Marker } from "leaflet";

import { BucketService } from "../../services/bucket.service";
import { LanguageService } from "../../services/language.service";
import { LoginService } from "../../services/login.service";
import { DataService } from "../../services/data.service";
import { UserDataService } from "../../services/user-data.service";
import { WindowService } from "../../services/window.service";
import { LeafletService } from "../../services/leaflet.service";
import { SocketService } from "../../services/socket.service";
import { MetaService } from "../../services/meta.service";
import { UserQueryService } from "../../services/user-query.service";
import { SearchSubscriptionService } from "../../services/search-subscription.service";

import { PreviewModalComponent } from "../modals/preview-modal/preview-modal.component";
import { AdBannerComponent } from "../ad-banner/ad-banner.component";
import { MapViewMenuComponent } from "../popovers/map-view/map-view-popover.component";
import { CurrentUser } from "../../model/current-user";
import { AdCardComponent } from "../ad-card/ad-card.component";
import { AdStatus } from "../../model/enums/ad-status";
import { IAdClient } from "../../model/db/ad";

@Component({
  selector: "app-result-page",
  templateUrl: "./result-page.component.html",
  styleUrls: ["./result-page.component.scss"],
})
export class ResultPageComponent implements OnInit {
  // variables
  ads: IAdClient[] = [];
  currentUser: CurrentUser;
  currentUserFavorites = [];
  appLanguage: string;
  lang: any;
  loggedIn = false;
  mobileView: boolean;
  title = "Kvadrat";
  adImages = [];
  email = "";
  emailInvalid = false;
  termsAgreed = true;
  captcha: string;

  lat = 46.1403;
  lng = 14.88;

  resultPageMap: any; // Map;
  resultNum = 0;
  bodyElement: any;
  deltaY = 0;
  viewMode = "list-map";
  cardView = "";
  colNum = "12";
  mapPinType = "pin";
  markerGroup: any; // = leaflet.layerGroup();
  loadingResults = true;
  sideMenuWidth: number;
  sortOrder = { sort: "datemin" };
  coordParams = {
    nelat: null,
    nelng: null,
    swlat: null,
    swlng: null,
  };
  oldUrlString = "";
  selectedAdId = "";
  selectedMarker: any;
  oldIcon: any;
  searchQuerySaved = false;
  currentQueryParams: {[key: string]: any} = {}
  queryParamSubscription: Subscription;
  queryToAdsSubscription: Subscription;
  selectedAdSubscription: Subscription;
  /**
  * ### Important
  * Used to prevent `fitBounds` if map is moved manually
  * because it triggers a second move event
  */
  mapMovedManually = false;

  @ViewChild("menuWrap", { read: ElementRef }) menuWrap: ElementRef;
  // @ViewChild("mapRef", { read: ElementRef }) mapElmentRef: ElementRef;
  @ViewChildren("adBanner") adBanners: QueryList<AdBannerComponent>;
  @ViewChildren("adCard") cards: QueryList<AdCardComponent>;

  constructor(
    // private el: ElementRef,
    private bucketService: BucketService,
    private userDataService: UserDataService,
    private dataService: DataService,
    private loginService: LoginService,
    private languageService: LanguageService,
    private router: Router,
    private route: ActivatedRoute,
    private modalCtrl: ModalController,
    private popoverCtrl: PopoverController,
    private decimalPipe: DecimalPipe,
    private detector: ChangeDetectorRef,
    private windowService: WindowService,
    private leafletService: LeafletService,
    private socketService: SocketService,
    private metaService: MetaService,
    private userQueryService: UserQueryService,
    private searchSubscriptionService: SearchSubscriptionService,
    private toastCtrl: ToastController,
  ) {}

  // Angular 2 Life Cycle event when component has been initialized
  ngOnInit() {
    this.dataService.loggedIn$.subscribe((val) => {
      this.loggedIn = val;
      this.currentUser = this.loginService.currentUser();
    });

    // SET LANGUAGE
    this.languageService.language$.subscribe((value) => {
      this.appLanguage = value;
      this.lang = this.languageService[value];
    });

    this.dataService.isBrowser$.subscribe((isBrowser) => {
      if (isBrowser) {
        // SET LAYOUT
        if (this.windowService.nativeWindow.localStorage) {
          const viewModeStorage = localStorage.getItem("viewMode");
          if (viewModeStorage !== null) {
            this.viewMode = viewModeStorage;
          }
          const cardViewStorage = localStorage.getItem("cardView");
          if (cardViewStorage !== null) {
            this.cardView = cardViewStorage;
          }
        } else {
          // FALLBACK
          // this.layoutGrid = false;
        }

        // GET VIEW
        this.toggleMobileView();
        this.toggleCardView();

        this.socketService.adChanges$.subscribe((msg) => {
          // if (msg["type"] === "favoriteChanges") {
          //   this.updateFavorite(msg["itemId"]);
          // }
          if (msg["type"] === "adActivated") {
            this.updateActiveList(msg["itemId"], true);
          }
          if (msg["type"] === "adDeactivated") {
            this.updateActiveList(msg["itemId"], false);
          }
        });
      }
    });

    if (!this.selectedAdSubscription || this.selectedAdSubscription.closed) {
      this.subscribeToMapAndListSync();
    }
  }

  ionViewWillLeave() {
    if (this.resultPageMap) {
      this.resultPageMap.off();
      this.resultPageMap.remove();
    }
    this.resultPageMap = undefined;

    if (this.queryParamSubscription) {
      this.queryParamSubscription.unsubscribe();
    }
    if (this.queryToAdsSubscription) {
      this.queryToAdsSubscription.unsubscribe();
    }
    if (this.selectedAdSubscription) {
      this.selectedAdSubscription.unsubscribe();
    }
  }

  ionViewDidEnter() {
    if (this.viewMode.includes("map")) {
      if (this.resultPageMap === undefined) {
        this.loadmap();
        // this.resultPageMap.invalidateSize(); no apparent difference
      }
    }

    if (!this.queryParamSubscription || this.queryParamSubscription.closed) {
      this.subscribeUrlToQuery();
    }
  }

  resolved(captchaResponse: string) {
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.captcha = captchaResponse;
  }

  subscribeToSearchQuery(): void {
    if (!this.emailInvalid && this.captcha && this.termsAgreed) {

      // maybe locType should be saved as well
      const { sort, l, locType, vicinity, ...cleanQuery } = this.currentQueryParams;
        this.searchSubscriptionService.addSearchSubscription(this.email, cleanQuery)
          .subscribe(async () => {
            // this.getUserQueries(this.currentUser._id);
            this.email = "";
            this.emailInvalid = true;

            const toast = await this.toastCtrl.create({
              message: this.lang["subscriptionActive"],
              duration: 3000,
            });
            toast.present();
          });
    }
  }

  validateEmail(email: string) {
    const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

    this.emailInvalid = !EMAIL_REGEXP.test(email);
  }

  subscribeToMapAndListSync(): void {
    this.selectedAdSubscription = this.dataService.selectedAd$.subscribe((selectedAdId) => {
      if (this.selectedAdId === selectedAdId || this.ads?.length === 0) {
        return;
      }
      
      this.selectedAdId = selectedAdId;

      if (selectedAdId !== "") {
        if (this.ads.length > 0) {
          const selectedAd = this.ads.find(
            (ad) => ad._id === this.selectedAdId
          );
          if (!selectedAd || !selectedAd["markerId"]) {
            return;
          }
          if (this.markerGroup) {
            this.selectedMarker = this.markerGroup._layers[selectedAd["markerId"]];
            if (this.selectedMarker) {
              if (this.selectedMarker.getIcon().options.iconUrl?.includes("marker-icon")) {
                let icon = this.selectedMarker.getIcon();
                icon.options.iconUrl = icon.options.iconUrl.replace("marker", "orange-marker");
                this.selectedMarker.setIcon(icon);
                return;
              }

              if (this.selectedMarker.getIcon().options.className?.includes("my-div-icon")) {
                let icon = this.selectedMarker.getIcon();
                icon.options.className = icon.options.className.replace("my-div-icon", "my-div-icon orange");
                this.selectedMarker.setIcon(icon);
              }
            }
          }
        }
      } else {
        // clear marker styling
        if (this.selectedMarker) {
          if (this.selectedMarker.getIcon().options.iconUrl?.includes("orange-marker")) {
            let icon = this.selectedMarker.getIcon();
            icon.options.iconUrl = icon.options.iconUrl.replace("orange-marker", "marker");
            this.selectedMarker.setIcon(icon);
            return;
          }

          if (this.selectedMarker.getIcon().options.className?.includes("orange")) {
            let icon = this.selectedMarker.getIcon();
            icon.options.className = icon.options.className.replace("orange", "");
            this.selectedMarker.setIcon(icon);
          }
        }
        this.selectedMarker = null;
      }
    });
  }

  saveSearch(searchSaved: boolean) {
    if (!this.currentUser) {
      return;
    }

    this.dataService.queryParamObject$.subscribe((object) => {
      // console.log(object);
      this.saveUserQuery(this.currentUser._id, object);
    });

    // TODO if searchSaved, remove query
  }

  checkIfQuerySaved(userId, query) {
    this.userQueryService
      .checkUserQuery(userId, query)
      .subscribe((response) => {
        this.searchQuerySaved = response["isUserQuery"];
      });
  }

  saveUserQuery(userId, query) {
    this.userQueryService.addUserQuery(userId, query).subscribe((result) => {
      // console.log(result);
      this.checkIfQuerySaved(userId, query);
    });
  }

  updateActiveList(itemId: string, isActive: boolean) {
    if (isActive) {
      this.pushAdToList(itemId, this.ads);
    }
    this.removeAdFromList(itemId);
  }

  removeAdFromList(itemId: string) {
    this.ads = this.ads.filter((item) => item._id !== itemId);
    if (this.viewMode.includes("map")) {
      this.markerGroup.clearLayers();
      this.drawMarkers(this.ads);
    }
  }

  pushAdToList(adId, itemList) {
    console.log(adId);
    
    this.bucketService.getSingleAd(adId, "").subscribe((res: IAdClient) => {
      const ad = res;
      this.checkAdExpiry(ad);
      if (ad["adExpired"]) {
        return;
      }
      this.loadAdImages(ad, true);
      this.ads = [...itemList, ad];
      if (
        this.viewMode === "map" ||
        this.viewMode === "list-map" ||
        this.viewMode === "card-map"
      ) {
        this.markerGroup.clearLayers();
        this.drawMarkers(this.ads);
      }
    });
  }

  checkAdExpiry(ad) {
    if (
      ad.statusOfAd[ad.statusOfAd.length - 1].status === AdStatus.Active
      // && new Date(ad.statusOfAd[ad.statusOfAd.length - 1].changed).getTime() > new Date().getTime() - 30 * 24 * 60 * 60 * 1000
    ) {
      ad["adExpired"] = false;
    } else {
      ad["adExpired"] = true;
    }
  }

  setMetaInfo(metaInfo: string) {
    this.metaService.setMetaDataObject({
      lang: this.lang,
      title: `Kvadrat - ${metaInfo}`,
      description: metaInfo,
      keywords: "buy, sell, rent, condo, flat, apartment, house",
      url: this.windowService?.nativeWindow?.location.origin || "https://kvadrat.si",
      image: (this.windowService?.nativeWindow?.location.origin || "https://kvadrat.si") + "/assets/images/condo-323780.jpg"
    });
  }

  subscribeUrlToQuery() {
    this.queryParamSubscription = this.route.queryParams.subscribe((params) => {
      const newParams = { ...params };
      this.sortOrder.sort = params["sort"] || "datemin";
      newParams["sort"] = this.sortOrder.sort;

      let metaDescription = params.adType === "buy" ? `${this.lang[params.adType]}` : `${this.lang["forRent"]}`;
      params.propTypes?.split(",").forEach((propType: string) => {
        metaDescription += `, ${this.lang.adPropertyType[propType]}`;
      });

      this.setMetaInfo(metaDescription);

      this.dataService.setQueryParamObject(newParams);
    });

    if (!this.queryToAdsSubscription || this.queryToAdsSubscription.closed) {
      this.subscribeQueryToGetAds();
    }
  }

  setSortOrder(sortOrder: string) {
    if (sortOrder === this.sortOrder.sort) {
      return;
    }
    this.loadingResults = true;
    this.sortOrder.sort = sortOrder;

    const newUrlString = this.router
      .createUrlTree(["/r"], { queryParams: { ...this.currentQueryParams, ...{ sort: sortOrder } } })
      .toString();
    this.router.navigateByUrl(newUrlString);
  }

  // todo: unused
  setSideMenuWidth() {
    // set fixed child width from parent relative width
    // detector for angular change detection lifecycle warning
    const menuWrapper = document.getElementById("menu-wrap");
    if (menuWrapper) {
      this.sideMenuWidth = menuWrapper.clientWidth;
      // console.log(this.sideMenuWidth);
      this.detector.detectChanges();
    }
  }

  // viewMode button
  setViewMode(event: string) {
    if (this.viewMode === event) {
      return;
    }
    this.viewMode = event;
    if (event === "card" || event === "list") {
      if (this.resultPageMap) {
        this.resultPageMap.off();
        this.resultPageMap.remove();
        this.resultPageMap = undefined;
      }

      const { nelat, nelng, swlat, swlng, ...newPropertyObject } = this.currentQueryParams;

      const newUrlString = this.router
      .createUrlTree(["/r"], { queryParams: { ...newPropertyObject } })
      .toString();
      
      this.router.navigateByUrl(newUrlString);

      if (event === "card") {
        this.toggleCardView();
      }
    }

    if (
      this.viewMode === "map" ||
      this.viewMode === "list-map" ||
      this.viewMode === "card-map"
    ) {
      // wait for map node to render
      setTimeout(() => {
        const { location, locType, ...newPropertyObject } = this.currentQueryParams;
        const newUrlString = this.router
        .createUrlTree(
          ["/r"], // Get uri
          { queryParams: { ...newPropertyObject } } // Pass all parameters inside queryParamsObj
        )
        .toString();
      
        this.router.navigateByUrl(newUrlString);

        if (this.resultPageMap === undefined) {
          this.loadmap();
        }

        this.resultPageMap.invalidateSize();
        this.drawMarkers(this.ads); // or true

        if (event === "card-map") {
          this.toggleCardView();
        }

      }, 300);
    }

    localStorage.setItem("viewMode", this.viewMode);
  }

  subscribeQueryToGetAds(): void {
    this.queryToAdsSubscription = this.dataService.queryParamObject$.subscribe((params) => {
      this.getFilteredAdsFromQuery(params);
      this.currentQueryParams = params;
   
      if (this.currentUser) {
        this.checkIfQuerySaved(this.currentUser._id, params);
      }
    });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.toggleMobileView();
    this.toggleCardView();
    // if (this.menuWrap) {
    //   this.sideMenuWidth = this.menuWrap.nativeElement.getBoundingClientRect().width;
    // }
  }


  toggleMobileView() {
    if (
      this.windowService.nativeWindow.innerWidth <= "1140" &&
      this.viewMode === "list-map"
    ) {
      this.viewMode = "card-map";
    }

    if (this.windowService.nativeWindow.innerWidth <= "767") {
      this.mobileView = true;
      if (this.viewMode === "card-map" || this.viewMode === "list-map") {
        this.viewMode = "card";
        this.resultPageMap = undefined;
      }
      if (this.viewMode === "list") {
        this.viewMode = "card";
      }
      // this.menuCtrl.swipeEnable(true);
    } else {
      this.mobileView = false;
      // this.menuCtrl.swipeEnable(false);
    }
    localStorage.setItem("viewMode", this.viewMode);
  }

  toggleCardView() {
    if (
      this.windowService.nativeWindow.innerWidth <= "991" &&
      this.viewMode === "card-map"
    ) {
      this.cardView = "card-map-1";
      this.colNum = "12";
    } else if (
      this.windowService.nativeWindow.innerWidth >= "992" &&
      this.windowService.nativeWindow.innerWidth <= "1600" &&
      this.viewMode === "card-map"
    ) {
      this.cardView = "card-map-2";
      this.colNum = "6";
    } else if (
      this.windowService.nativeWindow.innerWidth >= "1601" &&
      this.viewMode === "card-map"
    ) {
      this.cardView = "card-map-3";
      this.colNum = "4";
    } else if (
      this.windowService.nativeWindow.innerWidth <= "600" &&
      this.viewMode === "card"
    ) {
      this.cardView = "card-1";
      this.colNum = "12";
    } else if (
      this.windowService.nativeWindow.innerWidth >= "601" &&
      this.windowService.nativeWindow.innerWidth <= "900" &&
      this.viewMode === "card"
    ) {
      this.cardView = "card-2";
      this.colNum = "6";
    } else if (
      this.windowService.nativeWindow.innerWidth >= "901" &&
      this.windowService.nativeWindow.innerWidth <= "1200" &&
      this.viewMode === "card"
    ) {
      this.cardView = "card-3";
      this.colNum = "4";
    } else if (
      this.windowService.nativeWindow.innerWidth >= "1201" &&
      this.viewMode === "card"
    ) {
      this.cardView = "card-4";
      this.colNum = "3";
    }
    localStorage.setItem("cardView", this.cardView);
  }

  getFilteredAdsFromQuery(params: object) {
    this.bucketService
      .getFiltered(params)
      .subscribe((ads) => {
        if (ads["ads"] === undefined) {
          // TODO: there was an error, show error or reload page or redirect
          this.loadingResults = false;
        } else if (ads["ads"].length < 1) {
          this.ads = ads["ads"];
          this.resultNum = this.ads.length;
          this.loadingResults = false;
          this.markerGroup?.clearLayers();
        } else {
          this.ads = ads["ads"];
          this.resultNum = this.ads.length;

          this.setElapsedAdTime(this.ads);
          this.setAdAmenitiesArray(this.ads);
          this.setUserName(this.ads);

          this.ads.forEach((element: IAdClient, idx: number) => {
            this.loadAdImages(element, idx === this.ads.length - 1);
          });

          if (this.viewMode.includes("map")) {
            this.drawMarkers(this.ads);
          }
        }
      });
  }

  setElapsedAdTime(ads: IAdClient[]) {
    ads.forEach(element => {
      // days
      element["elapsed"] = Math.floor(
        (new Date().getTime() - new Date(element["created"]).getTime()) /
          86400000
      );
      // todo: hours, minutes if smaller time
    });
  }

  setUserName(ads: IAdClient[]) {
    ads.forEach(element => {
      this.userDataService.getName(element.author).subscribe((res: object) => {
        element["userName"] = res["name"];
      });
    });
  }

  // TODO: check if this is needed
  setAdAmenitiesArray(ads) {
    for (let i = 0; i < ads.length; i++) {
      const element = ads[i];

      element["amenitiesArray"] = Object.keys(element["amenities"])
        .filter((key) => {
          return element["amenities"][key];
        })
        .slice(0, 6)
        .map((item) => this.lang["amenities"][item])
        .join(", ")
        .slice(0, -2);
    }
  }

  async opanMapDataMenu(ev: MouseEvent) {
    const popoverViewModel = await this.popoverCtrl.create({
      component: MapViewMenuComponent,
      componentProps: {
        setMapViewMenu: (viewMode: string) => {
          this.setMapPinType(viewMode);
        },
        lang: this.lang,
        mapView: this.mapPinType,
      },
      event: ev,
    });
    popoverViewModel.present();
  }

  setMapPinType(pinType: string) {
    this.mapPinType = pinType;
    this.drawMarkers(this.ads);
  }

  drawMarkers(ads: IAdClient[]) {
    if (!this.leafletService.L || this.resultPageMap === undefined) {
      return;
    }
    const bounds = this.leafletService.L.latLngBounds(); // :LatLngBounds
    this.markerGroup.clearLayers();

    ads.forEach(element => {
      // const latLng = new LatLng(element.located.position.Latitude, element.located.position.Longitude);
      const latLng = [
        element.located.position.Latitude,
        element.located.position.Longitude,
      ];
  
      let marker = this.getMarkerElement(latLng, this.mapPinType, element); // :Marker
        
      marker.on("click", ($event) => {
        this.openPreviewModal(element);
      });
  
      marker.on("mouseover", ($event) => {
        this.setActiveProperty(element._id);
      });
  
      marker.on("mouseout", ($event) => {
        this.setActiveProperty("");
      });
  
      marker.addTo(this.markerGroup);
      element.markerId = this.markerGroup.getLayerId(marker);
  
      bounds.extend(latLng);
    });

    if (!this.mapMovedManually && this.resultPageMap && bounds.isValid()) {
      this.resultPageMap.fitBounds(bounds, { padding: [10, 10] });
    }

    this.mapMovedManually = false;
    this.loadingResults = false;
  }

  // any > LatLng, leaflet, Marker
  getMarkerElement(latLng: any, pinType: string, element: IAdClient): any {
    let marker: any; // Marker
    let htmlString: string;
    let markerClassName: string;
    let markerIconAnchor: number[];

    if (pinType === "pin") {
      return this.leafletService.L.marker(latLng);
    }

    switch (pinType) {
      case "price":
        markerClassName = "my-div-icon";
        markerIconAnchor = [28, 32];
        htmlString = element["price"]["type"] === 1 ?
          `<div>${element["price"]["current"]} €/m<div class="bottom-arrow"></div></div>` :
          `<div>${element["price"]["current"] / 1000} k €<div class="bottom-arrow"></div></div>`;
        break;
      case "adSize":
        markerClassName = "my-div-icon";
        markerIconAnchor = [28, 32];
        htmlString = `<div>${element["floorSize"]} m&#178;<div class="bottom-arrow"></div></div>`;
        break;
      case "pricePerM":
        markerClassName = "my-div-icon price-per-m";
        markerIconAnchor = [38, 32];
        htmlString = `<div>
          ${this.decimalPipe.transform(element["price"]["current"] / element["floorSize"], "1.0-1")} €/m&#178;
          <div class="bottom-arrow"></div>
        </div>`;
        break;
    }

    marker = this.leafletService.L.marker(latLng, {
      icon: new this.leafletService.L.divIcon({
        className: markerClassName,
        html: htmlString,
        iconAnchor: markerIconAnchor,
      }),
    });

    return marker;
  }

  setActiveProperty(propertyId: string): void {
    this.dataService.setSelectedAdSource(propertyId);
  }

  async openPreviewModal(item) {
    const previewModal = await this.modalCtrl.create({
      component: PreviewModalComponent,
      componentProps: {
        // updateList: (name) => {
        //   // this.updateList();
        // },
        // item: item,
        updateSaveFavorite: (itemId) => {
          // this.refreshFavoriteCount(itemId);
        },
        updateFavoriteCount: (itemId) => {
          // this.refreshAdBannerFavorite(itemId);
        },
        updateAdView: (itemId) => {
          this.refreshAdView(itemId);
        },
        lang: this.lang,
        appLanguage: this.appLanguage,
        ad: item,
      },
      cssClass: "preview-modal",
    });
    previewModal.present();
  }

  refreshAdView(itemId) {
    this.adBanners.forEach((element) => {
      if (element.item._id === itemId) {
        element.countAdViews();
      }
    });
  }

  refreshFavoriteCount(itemId) {
    this.adBanners.forEach((element) => {
      if (element.item._id === itemId) {
        element.countAdFavorites();
      }
    });
  }

  loadAdImages(ad: IAdClient, isLastAd: boolean): void {
    this.bucketService.listAdImages(ad._id, 3).subscribe((images) => {
      ad.photos = images["files"];
      ad.imageUrls = ad.photos.map(image => this.dataService.API + '/files/image/' + image._id);

      if (isLastAd) {
        this.loadingResults = false;
      }
    });
  }

  // deprecated, TODO: remove
  imgDecoded(item: string) {
    return encodeURI(item);
  }

  loadmap() {
    if (!this.leafletService.L || this.resultPageMap) {
      return;
    }
    
    this.resultPageMap = this.leafletService.L.map("map");
    // this.resultPageMap.setView(new LatLng(this.lat, this.lng), 9);
    this.resultPageMap.setView([this.lat, this.lng], 9);

    this.leafletService.L.tileLayer(
      "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      {
        maxZoom: 18,
        zoomDelta: 0.5,
        // accessToken: 'your.mapbox.access.token'
      }
    ).addTo(this.resultPageMap);

    this.resultPageMap.attributionControl.addAttribution(
      'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
    );
    
    this.markerGroup = this.leafletService.L.layerGroup();
    this.markerGroup.addTo(this.resultPageMap);

    // wait for map to finish loading, zooming
    setTimeout(() => {
      this.setupMapMoveCallback();
    }, 1500);
  }

  setupMapMoveCallback() {
    if (!this.resultPageMap) {
      return;
    }

    this.resultPageMap.on("dragend", () => {
      this.handleMapMoveOrZoom();
    });

    this.resultPageMap.on("zoomend", () => {
      // console.log("zoomend"); // still not fixed, if search modal on result page
      this.handleMapMoveOrZoom();
    });
  }

  handleMapMoveOrZoom(): void {
    this.mapMovedManually = true;
    this.loadingResults = true;
    const mapBounds = this.resultPageMap?.getBounds();

    const coordParams = {
      nelat: mapBounds.getNorthEast().lat.toFixed(5),
      nelng: mapBounds.getNorthEast().lng.toFixed(5),
      swlat: mapBounds.getSouthWest().lat.toFixed(5),
      swlng: mapBounds.getSouthWest().lng.toFixed(5),
    };

    // replace location and locType with coordinates
    const { location, locType, ...rest } = this.currentQueryParams;
    const newParams = { ...rest, ...coordParams };

    const newUrlString = this.router
      .createUrlTree(["/r"], { queryParams: newParams })
      .toString();
    
      this.router.navigateByUrl(newUrlString);
  }
}

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { LoginService } from "./services/login.service";

import { HomeComponent } from "./components/home/home.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { ProfileFavoritesComponent } from "./components/profile-favorites/profile-favorites.component";
import { ProfileViewHistoryComponent } from "./components/profile-view-history/profile-view-history.component";
import { ProfileSavedSearchesComponent } from "./components/profile-saved-searches/profile-saved-searches.component";
import { ProfileMessagesComponent } from "./components/profile-messages/profile-messages.component";
import { ProfileCommentsComponent } from "./components/profile-comments/profile-comments.component";
import { RegisterComponent } from "./components/register/register.component";
import { DetailPageComponent } from "./components/detail-page/detail-page.component";
import { ResultPageComponent } from "./components/result-page/result-page.component";
import { AdFormComponent } from "./components/ad-form/ad-form.component";
import { PhotosComponent } from "./components/photos/photos.component";
import { LocationLinkResolver } from "./resolvers/location-link.resolver";
import { SingleAdResolver } from "./resolvers/single-ad.resolver";
import { CurrentLanguageResolver } from "./resolvers/current-language.resolver";
import { AdImageResolver } from "./resolvers/ad-image.resolver";
import { BasePageComponent } from "./components/base-page/base-page.component";
import { AgencyPageComponent } from "./components/agency-page/agency-page.component";
import { AgencyFormComponent } from "./components/agency-form/agency-form.component";
import { AgencyListComponent } from "./components/agency-list/agency-list.component";
import { UnsubscribeComponent } from "./components/unsubscribe/unsubscribe.component";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { WallPageComponent } from "./components/wall-page/wall-page.component";
import { PostCheckPageComponent } from "./components/post-check-page/post-check-page.component";

const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
    // pathMatch: "full",
    resolve: {
      locations: LocationLinkResolver,
      language: CurrentLanguageResolver,
    },
    // data: {
    //   // preload: true,
    //   reuseRoute: true
    // }
  },
  { 
    path: "r",
    component: ResultPageComponent
  },
  // {
  //   path: "m",
  //   component: ResultPageComponent
  // }, // old map view
  {
    path: "about",
    component: BasePageComponent,
    data: {
      title: "about",
    },
  },
  {
    path: "terms",
    component: BasePageComponent,
    data: {
      title: "terms",
    },
  },
  {
    path: "privacy",
    component: BasePageComponent,
    data: {
      title: "privacy",
    },
  },
  {
    path: "advertising",
    component: BasePageComponent,
    data: {
      title: "advertising",
    },
  },
  {
    path: "contact-us",
    component: BasePageComponent,
    data: {
      title: "contact-us",
    },
  },
  {
    path: "ad/:id",
    component: DetailPageComponent,
    resolve: {
      ad: SingleAdResolver,
      language: CurrentLanguageResolver,
      images: AdImageResolver,
    },
    // canActivate: [SingleAdResolver],
    // data: {
    //   preload: true
    // }
  },
  // {
  //   path: "p/",
  //   redirectTo: "",
  //   pathMatch: "full"
  // },
  {
    path:
    "p/:id",
    component: ProfileComponent },
  {
    path: "re",
    component: AgencyListComponent,
    data: {
      title: "Agencies",
    },
  },
  {
    path: "re/:id",
    component: AgencyPageComponent,
    data: {
      title: "Agency",
    },
  },
  // {
  //   path: "re-new",
  //   component: AgencyFormComponent,
  //   data: {
  //     title: "Add Agency",
  //   },
  // },
  {
    path: "login",
    component: RegisterComponent
  },
  {
    path: "verify",
    component: RegisterComponent },
  {
    path: "unsubscribe-listings",
    component: UnsubscribeComponent
  },
  { 
    path: "n",
    component: AdFormComponent,
    canActivate: [LoginService]
  },
  {
    path: "msg", // TODO add userId
    component: ProfileMessagesComponent,
    canActivate: [LoginService],
  },
  {
    path: "comments",
    component: ProfileCommentsComponent,
    canActivate: [LoginService],
  },
  {
    path: "fav",
    component: ProfileFavoritesComponent,
    canActivate: [LoginService],
  },
  {
    path: "history",
    component: ProfileViewHistoryComponent,
    canActivate: [LoginService],
  },
  {
    path: "searches",
    component: ProfileSavedSearchesComponent,
    canActivate: [LoginService],
  },
  { 
    path: "ed/:id",
    component: AdFormComponent,
    canActivate: [LoginService]
  },
  {
    path: "photos/:id",
    component: PhotosComponent,
    canActivate: [LoginService],
  },
  {
    path: "agency-photos/:id",
    component: PhotosComponent,
    canActivate: [LoginService],
  },
  {
    path: "single-check/:id",
    component: DetailPageComponent,
    resolve: {
      ad: SingleAdResolver,
      language: CurrentLanguageResolver,
      images: AdImageResolver,
    },
    canActivate: [LoginService],
  },
  {
    path: "post-check/:id",
    component: PostCheckPageComponent,
    // canActivate: [LoginService],
  },
  {
    path: "re-edit/:id",
    component: AgencyFormComponent,
    canActivate: [LoginService]
  },
  { 
    path: "posts",
    component: WallPageComponent
  },
  { 
    path: "not-found",
    component: PageNotFoundComponent
  },
  // { path: "", pathMatch: 'full', redirectTo: "h" },
  // { path: "**", redirectTo: "not-found" },
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        initialNavigation: "enabledBlocking",
        // preloadingStrategy: "PreloadAllModules",
        // relativeLinkResolution: 'legacy',
        // enableTracing: true,
        // urlUpdateStrategy: "eager",
        // paramsInheritanceStrategy: "always",
      },
    ),
  ],
  // providers: [
  //   provideRouter(
  //     routes,
  //     withEnabledBlockingInitialNavigation(), // doesn't navigate at all
  //     // withTransitionViews({ skipInitialTransition: true })
  //   )
  // ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

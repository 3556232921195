import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';

@Injectable()
export class FlagService {

  API: string;

  constructor(
    private http: HttpClient,
    private dataService: DataService,
  ) {
    this.API = this.dataService.API;
  }

  listItemFlags(itemId) {
    return this.http.get(`${this.API}/flags/list?itemId=${itemId}`);
  }

  flagItem(
    itemId: string,
    flaggerId: string,
    itemOwnerId: string,
    itemType: number, // 0-ad, 1-comment
    flagReason: number, // 0-adExpired, 1-profanity, 2-offensive, 3-spam, 4-other
    description = "",
  ) {
    return this.http.post(`${this.API}/flags/add`, {
      itemId,
      flaggerId,
      itemOwnerId,
      itemType,
      flagReason,
      description
    });
  }

}

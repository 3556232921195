<ion-header>
  <ion-toolbar color="primary">
    <ion-title>
      {{ lang.createPost }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="post-modal-content">
  <ion-grid>
    <ion-item>
      <ion-avatar class="user-avatar">
        <app-profile-photo #profilePhoto [userId]="currentUser?._id" [size]="36">
        </app-profile-photo>
      </ion-avatar>
      <ion-label>
        {{ currentUser?.name }}
      </ion-label>
    </ion-item>
  
    <ion-item>
      <ion-textarea rows="7"
                    type="text"
                    class="message-textarea"
                    name="description"
                    (ngModelChange)="updateMessage($event)"
                    [ngModel]="message">
      </ion-textarea>
    </ion-item>
      
    <ion-item class="message-counter"
              [ngClass]="{ 'length-warning': messageMaxLength - message.length < 50 }"
              *ngIf="message.length > 700">
      {{ messageRemaining }}
    </ion-item>

    <!-- <ion-item>
      <ion-thumbnail>
        <img class="photos-thumbnail" src="../../../../assets/images/image.png" alt="photos"/>
      </ion-thumbnail>
      <ion-label>
        {{ lang.addPhoto }}
      </ion-label>
    </ion-item> -->

    <app-file-upload *ngIf="currentUser && currentUser._id"
                      #fileUpload
                      [adId]="postId"
                      [path]="'posts'"
                      [userId]="currentUser._id"
                      (uploaded)="finalizePosting()"></app-file-upload>
                      
                      <!-- [userId]="user.userId" -->

  </ion-grid>
  <div class="post-button-section">
    <ion-button expand="block" (click)="submitPost()"
                [disabled]="message.length < 3 || message.length > messageMaxLength">
      {{ lang.post }}
    </ion-button>
  </div>
</ion-content>
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';

@Injectable()
export class AdViewService {

  API: string;

  constructor(
    private http: HttpClient,
    private dataService: DataService,
  ) {
    this.API = this.dataService.API;
  }

  listUserAdView(adId) {
    return this.http.get(`${this.API}/property-view/property/${adId}`);
  }

  addAdView(adId: string,) {
    return this.http.post(`${this.API}/property-view/add`, {
      adId
    });
  }

  // not userd
  removeAdView(adId: string) {
    return this.http.post(`${this.API}/property-view/remove`, {
      adId
    });
  }

  // checkAdView(adId: string) {
  //   return this.http.post(`${this.API}/property-view/check`, {
  //     adId
  //   });
  // }

  countAdView(adId: string) {
    return this.http.get(`${this.API}/property-view/count?adId=${adId}`);
  }
}

import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import {
  PopoverController,
  ModalController,
  AlertController,
} from "@ionic/angular";
import { Router } from "@angular/router";

import { LanguageService } from "../../services/language.service";
import { UserDataService } from "../../services/user-data.service";
import { DataService } from "../../services/data.service";

import { LoginModalComponent } from "../modals/login-modal/login-modal.component";
import { SearchModalComponent } from "../modals/search-modal/search-modal.component";
import { SortAdsPopoverComponent } from "../popovers/sort-ads/sort-ads-popover.component";
import { ViewModeMenuComponent } from "../popovers/view-mode/view-mode-popover.component";
import { PopoverProfileComponent } from "../popovers/profile-menu/profile-menu-popover.component";
import { PopoverLanguageComponent } from "../popovers/language-menu/language-menu-popover.component";

@Component({
  selector: "app-main-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class MainHeaderComponent implements OnInit {
  @Input() mobileView: boolean;
  @Input() title: string;
  @Input() currentUser: any;
  @Input() resultNum = 0;
  @Input() viewMode: string;
  @Input() sortOrder: any; // todo convert to string type, only used in result-page.component
  @Output() updateViewMode = new EventEmitter<string>();
  @Output() updateSortOrder = new EventEmitter<string>();
  @Output() updateAds = new EventEmitter<object>();
  @Output() saveSearchEmit = new EventEmitter<boolean>();
  // @Input() currentUrl: string;
  // @Input() path: string;
  @Input() basePath: string;
  @Input() searchQuerySaved = false;

  // layoutGrid;
  searchMode = false;
  desktopMenu = true;
  searchVal = "";
  appLanguage: string;
  lang: any;
  // path = 'r';
  filterNumber = 0;

  constructor(
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private popoverCtrl: PopoverController,
    private router: Router,
    private languageService: LanguageService,
    private userDataService: UserDataService,
    private dataService: DataService,
  ) {}

  ngOnInit() {
    // SET LANGUAGE
    this.languageService.language$.subscribe((value) => {
      this.appLanguage = value;
      this.lang = this.languageService[value];
    });

    this.dataService.queryParamObject$
      // .pipe(take(1))
      .subscribe((params: any) => {
        const { sort, l, nelat, nelng, swlat, swlng, ...newPropertyObject } = params;
        this.filterNumber = Object.keys(newPropertyObject).length;
      });
}

  goToNewAgencyForm(): void {
    this.router.navigate(["re-new"]);
  }

  saveSearch() {
    if (!this.currentUser) {
      this.presentLoginModal();
      return;
    }

    this.saveSearchEmit.emit(this.searchQuerySaved);
  }

  async openSearchModal() {
    const searchModal = await this.modalCtrl.create({
      component: SearchModalComponent,
      componentProps: {
        lang: this.lang,
        basePath: this.basePath,
        updateAds: (queryParams) => {
          this.updateAds.emit(queryParams);
        },
      },
    });
    await searchModal.present();
  }

  setViewMode(viewModeSet) {
    // console.log(viewModeSet);
    this.updateViewMode.emit(viewModeSet);
  }

  async vieWModePopover(ev) {
    const popoverViewMode = await this.popoverCtrl.create({
      component: ViewModeMenuComponent,
      componentProps: {
        lang: this.lang,
        mobileView: this.mobileView,
        setViewMode: (viewModeVal) => {
          this.setViewMode(viewModeVal);
        },
      },
      event: ev,
    });
    await popoverViewMode.present();
  }

  async openSortMenu(ev: MouseEvent) {
    const sortAdsPopover = await this.popoverCtrl.create({
      component: SortAdsPopoverComponent,
      componentProps: {
        lang: this.lang,
        setSortOrder: (sortOrder: string) => {
          this.setSortOrder(sortOrder);
        },
      },
      event: ev,
      // sortOrder: this.sortOrder,
    });
    await sortAdsPopover.present();
  }

  setSortOrder(sortOrder) {
    this.updateSortOrder.emit(sortOrder);
  }

  goToHome() {
    this.router.navigate([""], {
      queryParams: { l: this.appLanguage === "en" ? "en" : null },
    });
  }

  openProfileAction(ev) {
    // console.log(this.user);
    if (this.currentUser) {
      this.presentProfilePopover(ev);
    } else {
      this.presentLoginModal();
    }
  }

  handleNewAction() {
    if (this.currentUser) {
      // check if user verified
      this.userDataService
        .checkUserValidation(this.currentUser._id)
        .subscribe(async (response) => {
          // console.log(response);
          if (response["status"]) {
            this.router.navigate(["/n"], {
              queryParams: { l: this.appLanguage === "en" ? "en" : null },
            });
          } else {
            const notVerifiedAlert = await this.alertCtrl.create({
              header: "Not verified", // this.lang['delete'],
              subHeader:
                "It appears that the user is not verified. Check your email and confirm your registration", // this.lng['deleteConfirm'],
              // subTitle: 'Removing the ad can not be undone. Ste prepričani, da želite izbrisati oglas?', // this.lng['deleteConfirm'],
              buttons: [
                {
                  text: this.lang["ok"], // this.lng['cancel'],
                  role: "cancel",
                  handler: () => {
                    // console.log('Cancel clicked');
                  },
                },
              ],
            });
            await notVerifiedAlert.present();
          }
        });
    } else {
      this.presentLoginModal();
    }
  }

  async presentLoginModal() {
    const modal = await this.modalCtrl.create({
      component: LoginModalComponent,
      componentProps: {
        lang: this.lang,
        appLanguage: this.appLanguage,
        updateList: (name) => {
          // this.updateList();
        },
      },
      cssClass: "login-modal",
      // item: item,
    });
    await modal.present();
  }

  async presentLanguagePopover(ev) {
    const popover = await this.popoverCtrl.create({
      component: PopoverLanguageComponent,
      componentProps: {
        setLang: (language) => {
          this.langChange(language);
        },
        lang: this.lang,
      },
      event: ev,
    });
    await popover.present();
  }

  // profile menu popover
  async presentProfilePopover(ev) {
    const popover = await this.popoverCtrl.create({
      component: PopoverProfileComponent,
      componentProps: {
        user: this.currentUser,
        langChange: (language: string) => {
          this.langChange(language);
        },
      },
      event: ev,
    });
    await popover.present();
  }

  langChange(language: string) {
    this.languageService.langChange(language);
  }

  getItems(ev: any) {
    // Reset items back to all of the items
    // this.initializeItems();
    // set val to the value of the searchbar
    // this.searchVal = '';
    this.searchVal = ev.target.value;
    if (this.searchVal !== "" && this.searchVal !== undefined) {
      this.searchVal = this.searchVal.trim().toLowerCase();
    }
    // this.search.emit(this.searchVal);
  }

  onClear(ev) {
    this.searchVal = "";
    // ev.stopPropagation();
    // this.search.emit(this.searchVal);
  }
}

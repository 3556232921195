import { Component, OnInit, Input } from "@angular/core";
import {
  ModalController,
  PopoverController,
  AlertController,
  ToastController,
} from "@ionic/angular";

import { LikesService } from "../../services/likes.service";
import { CommentService } from "../../services/comment.service";
import { FlagService } from "../../services/flag.service";
import { UserDataService } from "../../services/user-data.service";
import { SocketService } from "../../services/socket.service";

import { LoginModalComponent } from "../modals/login-modal/login-modal.component";
import { KebabOptionsPopoverComponent } from "../popovers/kebab-options/kebab-options-popover.component";
import { FlagReasonsPopoverComponent } from "../popovers/flag-reasons/flag-reasons-popover.component";
import { CurrentUser } from "../../model/current-user";

// Comment Component
@Component({
  selector: "app-comments",
  templateUrl: "./comments.component.html",
  styleUrls: ["./comments.component.scss"],
})
export class CommentsComponent implements OnInit {
  @Input() appLanguage: string;
  @Input() lang: any;
  @Input() adId: any;
  @Input() authorId: any; // Author of the item
  @Input() commentType = 0; // Ad = 0,  Image = 1,  Agency = 2,  User = 3,  Post = 4
  // @Input() ad: any;
  @Input() set currentUser(value: CurrentUser) {
    if (value) {
      this.currentUserId = value._id;
      this._currentUser = value;
    } else {
      this.currentUserId = "";
    }
  }

  get currentUser(): CurrentUser {
    return this._currentUser;
  }
  
  @Input() loggedIn: any;

  comments: any[];
  question = "";
  replyQuestion = "";
  commentCount = 0;
  replyForm = false;
  replyToReplyForm = false;
  replyCommentId = "";
  replyReplyId = "";
  deleteAlert: any;
  editCommentId = "";
  editMessage = "";
  messageMaxLength = 280;
  messageRemaining = null;
  currentUserId = "";
  // tslint:disable-next-line:variable-name
  _currentUser: CurrentUser;

  constructor(
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private popoverCtrl: PopoverController,
    private userDataService: UserDataService,
    private likesService: LikesService,
    private commentService: CommentService,
    private flagService: FlagService,
    private toastCtrl: ToastController,
    private socketService: SocketService,
  ) {}

  ngOnInit() {
    this.initializeComments(this.adId);

    this.socketService.adChanges$.subscribe((msg) => {
      if (msg["itemId"] === this.adId) {
        if (msg["type"] === "commentChanges") {
          this.initializeComments(this.adId);
          // this.updateComments(this.adId);
        }

        if (msg["type"] === "likeChanges") {
          let myComment = this.comments.find((comment) => comment._id === msg["itemId"]);
          if (myComment) {
            this.getLikes(msg["itemId"], myComment);
          } else {
            this.comments.forEach((comment) => {
              let myReply = comment.repliesArray.find((reply) => reply._id === msg["itemId"]);
              if (myReply) {
                this.getLikes(msg["itemId"], myReply);
              }
            });          
          }
        }
      }
    });
  }

  countMessageRemaining(question) {
    this.messageRemaining = this.messageMaxLength - question.length;
  }

  // comment options popover
  async presentCommentOptionsPopover(ev: Event, comment: any) {
    const popover = await this.popoverCtrl.create({
      component: KebabOptionsPopoverComponent,
      componentProps: {
        flagComment: () => {
          this.flagComment(ev, comment);
        },
        editComment: () => {
          this.editComment(comment);
        },
        deleteComment: () => {
          this.deleteComment(comment);
        },
        // comment,
        itemOwnerId: comment.userId,
        lang: this.lang,
      },
      event: ev,
    });
    popover.present();
  }

  // flag popover
  async presentFlagReasonsPopover(ev: Event, comment: any): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: FlagReasonsPopoverComponent,
      componentProps: {
        flagItem: (flagComment: any, flagReason: number, description: string) => {
          this.sendFlag(flagComment, flagReason, description);
        },
        comment,
        lang: this.lang,
      },
      cssClass: "flag-reasons-popover",
      // bug in positioning on bottom of screen, gets cut off if description is open
      // event: ev,
    });
    popover.present();
  }

  flagComment(ev: Event, comment: string): void {
    if (this._currentUser === undefined) {
      this.presentLoginModal();
      return null;
    }

    this.presentFlagReasonsPopover(ev, comment);
  }

  sendFlag(comment: any, flagReason: number, description: string): void {
    this.flagService
      .flagItem(
        comment._id,
        this._currentUser._id,
        comment.userId,
        2, // 2-comment
        flagReason,
        description
      ).subscribe(async (response) => {
        const toast = await this.toastCtrl.create({
          message: response["message"],
          duration: 3000,
        });
        toast.present();
      });
  }

  editComment(comment) {
    if (this._currentUser === undefined) {
      this.presentLoginModal();
      return null;
    }

    this.editCommentId = comment._id;
    this.editMessage = comment.message;
  }

  sendEditComment(comment, editMessage, parentComment?): void {
    // this.editCommentId = '';
    // console.log(editMessage);
    if (comment.userId !== this._currentUser._id) {
      return null;
    }
    this.commentService
      .editComment(comment._id, editMessage, this._currentUser._id, comment.commentType)
      .subscribe(async (response) => {
        if (comment.replyToCommentId === null) {
          this.initializeComments(this.adId);
          // this.updateComments(this.adId);
        } else {
          this.getReplies(parentComment);
        }
        const toast = await this.toastCtrl.create({
          message: "Komentar posodobljen.",
          duration: 3000,
        });
        toast.present();

        this.editCommentId = "";
        this.editMessage = "";
      });
  }

  deleteComment(comment) {
    // console.log(comment);
    if (this._currentUser === undefined) {
      // console.log("vpišite se");
      this.presentLoginModal();
      return null;
    }

    // with confirmation
    this.openDeleteAlert(comment);

    // without confirmation
    // this.deleteCommentConfirm(comment);
  }

  async openDeleteAlert(comment) {
    if (!this.deleteAlert) {
      this.deleteAlert = await this.alertCtrl.create({
        header: "Brisanje", // this.lang['delete'],
        subHeader: "Ste prepričani, da želite izbrisati komentar?", // this.lng['deleteConfirm'],
        buttons: [
          {
            text: "V redu", // this.lang['ok'],
            handler: () => {
              // this.makePublic(item);
              this.deleteCommentConfirm(comment);
              this.deleteAlert = undefined;
            },
            cssClass: "delete-confirm",
          },
          {
            text: "Prekliči", // this.lng['cancel'],
            role: "cancel",
            handler: () => {
              // console.log('Cancel clicked');
              this.deleteAlert = undefined;
            },
          },
        ],
      });

      await this.deleteAlert.present();
    }
  }

  deleteCommentConfirm(comment) {
    this.commentService
      .deleteComment(comment._id, this._currentUser._id)
      .subscribe(async (response) => {
        this.initializeComments(this.adId);
        // this.updateComments(this.adId);

        const toast = await this.toastCtrl.create({
          message: "Komentar izbrisan.",
          duration: 3000,
        });
        toast.present();
      });
  }

  // updateComments(adId: string) {
  //   this.commentService.getCommentsAd(adId).subscribe((commentResponse) => {

  //     let newComments = commentResponse["comments"];
  //     this.commentCount = commentResponse["commentCount"];

  //     this.comments.forEach(oldComment => {
  //       let existingComment = newComments.find(newComment => newComment._id === oldComment._id);
  //       if (!existingComment) {
  //         let index = this.comments.findIndex(comment => comment._id === oldComment._id);
  //         this.comments.splice(index, 1);
  //       } else {
  //         oldComment.message = existingComment.message;
  //         oldComment.lastChange = existingComment.lastChange;
  //         if (oldComment.repliesOpen) {
  //           oldComment.repliesArray.forEach(oldReply => {
  //             let existingReply = existingComment.repliesArray?.find(newReply => newReply._id === oldReply._id);
  //             if (!existingReply) {
  //               let index = oldComment.repliesArray.findIndex(reply => reply._id === oldReply._id);
  //               oldComment.repliesArray.splice(index, 1);
  //             } else {
  //               oldReply.message = existingReply.message;
  //               oldReply.lastChange = existingReply.lastChange;
  //             }
  //           });
  //         }
  //       }
  //     });

  //     newComments.forEach(newComment => {
  //       let existingComment = this.comments.find(oldComment => oldComment._id === newComment._id);
  //       if (!existingComment) {
  //         newComment["repliesArray"] = [];
  //         newComment["repliesOpen"] = false;
  //         this.getUserName(newComment.userId, newComment);
  //         this.getLikes(newComment._id, newComment);
  //         this.comments.push(newComment);
  //       }
  //     });
  //   });
  // }

  initializeComments(adId: string) {
    this.commentService.getCommentsAd(adId, this.commentType).subscribe((commentResponse) => {
      this.comments = commentResponse["comments"];
      this.commentCount = commentResponse["commentCount"];
      // console.log(this.comments);

      for (let i = 0; i < this.comments.length; i++) {
        const comment = this.comments[i];
        comment["repliesArray"] = [];
        comment["repliesOpen"] = false;
        this.getUserName(comment.userId, comment);
        this.getLikes(comment._id, comment);
      }
    });
  }

  getLikes(itemId, item) {
    this.likesService.countItemLikes(itemId).subscribe((res: object[]) => {
      // console.log(res);
      item.likes = res["count"];
    });
  }

  voteOnItem(item: any, itemOwnerId: string, vote: number) {
    if (this._currentUser === undefined) {
      // console.log("vpišite se");
      this.presentLoginModal();
      return null;
    }
    this.likesService
      .voteOnItem(item._id, this._currentUser._id, itemOwnerId, vote)
      .subscribe(async (res: any) => {
        // console.log("res from vote");
        // console.log(res);
        if (res["code"] === 200) {
          this.getLikes(item._id, item);
        } else if (res["code"] === 403) {
          const toast = await this.toastCtrl.create({
            message: res["message"],
            duration: 3000,
          });
          toast.present();
        }
      });
  }

  getUserName(userId, user) {
    this.userDataService.getName(userId).subscribe((res: object) => {
      user["userName"] = res["name"];
    });
  }

  getReplyToName(userId, user) {
    this.userDataService.getName(userId).subscribe((res: object) => {
      user["replyToName"] = res["name"];
    });
  }

  openReplies(comment) {
    if (comment.repliesArray.length > 0) {
      comment["repliesOpen"] = true;
    } else {
      this.getReplies(comment);
    }
  }

  closeReplies(comment) {
    comment["repliesOpen"] = false;
  }

  getReplies(comment) {
    this.commentService
      .getReplies(this.adId, comment._id, this.commentType)
      .subscribe((replies: any[]) => {
        // console.log(replies);

        for (let j = 0; j < replies.length; j++) {
          const element_reply = replies[j];
          this.getUserName(element_reply.userId, element_reply);
          this.getReplyToName(element_reply.replyToUserId, element_reply);
          this.getLikes(element_reply._id, element_reply);
        }

        comment["repliesArray"] = replies;
        comment["repliesOpen"] = true;
      });
  }

  addComment(comment: string): void {
    if (this._currentUser === undefined) {
      // console.log("vpišite se");
      this.presentLoginModal();
      return null;
    }
    this.question = "";
    this.commentService
      .addComment(this.adId, this._currentUser._id, comment, null, null, this.authorId, this.commentType)
      .subscribe((res: object) => {
        this.initializeComments(this.adId);
        // this.updateComments(this.adId);
      });
  }

  addReplyComment(userId, comment) {
    // console.log({
    //   replyQuestion: this.replyQuestion,
    //   commentId: this.replyCommentId,
    //   user: userId
    // });
    // console.log(this._currentUser);

    if (this._currentUser === undefined) {
      // console.log('vpišite se');
      this.presentLoginModal();
      return null;
    }

    this.commentService
      .addComment(
        this.adId,
        this._currentUser._id,
        this.replyQuestion,
        this.replyCommentId,
        userId,
        this.authorId,
        this.commentType
      )
      .subscribe((res: object): void => {
        this.replyQuestion = "";
        this.replyForm = false;
        this.replyToReplyForm = false;
        // this.getComments(this.adId);
        this.getReplies(comment);
      });
  }

  async presentLoginModal() {
    const modal = await this.modalCtrl.create({
      component: LoginModalComponent,
      componentProps: {
        updateList: (name) => {
          // this.updateList();
        },
        // item: item,
        lang: this.lang,
        appLanguage: this.appLanguage,
      },
      cssClass: "login-modal",
    });
    modal.present();
  }
}

import {
  Injectable,
} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Resolve,
  RouterStateSnapshot
} from '@angular/router';
import { DecimalPipe } from '@angular/common';

import { BucketService } from '../services/bucket.service';
import { combineLatest, map, Observable, of } from 'rxjs';
import { MetaService } from '../services/meta.service';
import { LanguageService } from '../services/language.service';
import { DataService } from '../services/data.service';
// import { take } from "rxjs/operators";

// Needed to preload data for server side rendering and SEO
@Injectable()
export class SingleAdResolver implements Resolve<any> {
  API = "";

  constructor(
    private bucketService: BucketService,
    private metaService: MetaService,
    private languageService: LanguageService,
    private dataService: DataService,
    private decimalPipe: DecimalPipe,
  ) {  
    this.API = this.dataService.API;
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): any {
    // return this.bucketService.getSingleAd(route.params['id'], '');
    return this.getAdAndSetMeta(route, state);
  }

  // temporary workaround. Find another way to set metadata before resolvers finish
  getAdAndSetMeta (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    // let currentLanguage = route.queryParams["l"] === "en" ? "en" : "sl";
    let currentLanguage = of(route.queryParams["l"] === "en" ? this.languageService["en"] : this.languageService["sl"]);
    let adImages = this.bucketService.listAdImages(route.params['id'], 3);
    let singleAd = this.bucketService.getSingleAd(route.params['id'], '');

    // calling our API service like we would usually do but instead of a subscribe, we do a pipe map.
    // return this.bucketService.getSingleAd(route.params['id'], '').pipe(
    return combineLatest([currentLanguage, adImages, singleAd]).pipe(
      map(
        ([currentLanguage, adImages, singleAd]) => {
          let ad: any = singleAd;
          let lang: any = currentLanguage;
          let images: any = adImages;
          let metaImageUrl: string;

          if (images["files"].length > 0) {
            ad.photos = images["files"];
            metaImageUrl = this.API + "/files/image/" + ad.photos[0]._id;
          } else {
            metaImageUrl = "/assets/images/condo-323780.jpg";
          }

          this.metaService.setMetaDataObject({
            lang: lang,
            title: `${lang.adTsPropertyType[ad.adPropertyType]}, ${
              lang[ad.adType === 0 ? "buy" : "forRent"]
            }, ${ad.located.street}, ${ad.located.postal} ${
              ad.located.district
            }`,
            description: `${ad.floorSize} m&#178;, ${ad.roomNum} ${lang["rooms"]}, ${
              this.decimalPipe.transform(ad.price.current, "1.1-2") } € ${ad.price.type === 1 ? "/ " + lang["month"] : ""}`,
            keywords: `${lang.adTsPropertyType[ad.adPropertyType]}, ${
              lang[ad.adType === 0 ? "buy" : "forRent"]
            }, ${ad.located.street}, ${ad.located.postal}, ${
              ad.located.district
            }`,
            url: `https://kvadrat.si/ad/${ad._id}`,
            image: metaImageUrl,
            type: "website",
          });
          
          return ad;
          // return true;
        }
      ));
  }
}

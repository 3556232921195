<ion-header class="shrinkable">
  <app-main-header
    [mobileView]="mobileView"
    [title]="title"
    [currentUser]="currentUser"
  ></app-main-header>
</ion-header>

<ion-content fullscreen class="form-content">
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <form *ngIf="currentUser" [formGroup]="adForm" (ngSubmit)="postAd()">
          <div class="form-extras">
            <div id="form-map" style="width: 100%; height: 100%"></div>
          </div>
          <ion-list class="form-inputs">
            <ion-item-divider>
              {{ lang.location }}
            </ion-item-divider>

            <ion-item *ngIf="address && adForm">
              <label [ngClass]="{ danger: adForm.controls.location?.value === 'xxx' }">
                {{ address }}
              </label>
            </ion-item>
          </ion-list>

          <ion-radio-group formControlName="adType" ngDefaultControl (ngModelChange)="adTypeChanged($event)">
            <ion-row>
              <ion-item-divider>
                {{ lang.adType }}
              </ion-item-divider>
              <ion-col>
                <!-- todo buy 0, rent 1 -->
                <ion-item lines="none">
                  <ion-radio justify="start" labelPlacement="end" [value]="0">{{ lang.buy}}</ion-radio>
                </ion-item>
              </ion-col>
              <ion-col>
                <ion-item lines="none">
                  <ion-radio justify="start" labelPlacement="end" [value]="1">{{ lang.forRent }}</ion-radio>
                </ion-item>
              </ion-col>
            </ion-row>
          </ion-radio-group>

          <ion-radio-group formControlName="adPropertyType" ngDefaultControl>
            <ion-list lines="none">
              <ion-item-divider>
                {{ lang.adPropType }}
              </ion-item-divider>
              <ion-item>
                <ion-radio justify="start" labelPlacement="end" [value]="0">{{ lang.adPropertyType.house }}</ion-radio>
              </ion-item>
              <ion-item>
                <ion-radio justify="start" labelPlacement="end" [value]="1">{{ lang.adPropertyType.flat }}</ion-radio>
              </ion-item>
              <ion-item>
                <ion-radio justify="start" labelPlacement="end" [value]="2" >{{ lang.adPropertyType.apartment }}</ion-radio>
              </ion-item>
              <ion-item>
                <ion-radio justify="start" labelPlacement="end" [value]="3">{{ lang.adPropertyType.room }}</ion-radio>
              </ion-item>
              <ion-item>
                <ion-radio justify="start" labelPlacement="end" [value]="4">{{ lang.adPropertyType.weekend }}</ion-radio>
              </ion-item>
              <ion-item>
                <ion-radio justify="start" labelPlacement="end" [value]="5">{{ lang.adPropertyType.estate }}</ion-radio>
              </ion-item>
              <ion-item>
                <ion-radio justify="start" labelPlacement="end" [value]="6">{{ lang.adPropertyType.commercial }}</ion-radio>
              </ion-item>
              <ion-item>
                <ion-radio justify="start" labelPlacement="end" [value]="7">{{ lang.adPropertyType.farm }}</ion-radio>
              </ion-item>
              <ion-item>
                <ion-radio justify="start" labelPlacement="end" [value]="8">{{ lang.adPropertyType.parking }}</ion-radio>
              </ion-item>
            </ion-list>
          </ion-radio-group>

          <ion-list>
            <ion-item-divider *ngIf="adForm.get('adPropertyType').value !== null &&
                                     adForm.get('adPropertyType').value !== 5 &&
                                     adForm.get('adPropertyType').value !== 8">
              {{ lang.spaces }}
            </ion-item-divider>

            <ion-item *ngIf="adForm.get('adPropertyType').value === 1">
              <ion-select formControlName="flatType"
                          (ngModelChange)="flatTypeChanged($event)"
                          [label]="lang.flatType"
                          interface="popover">
                <ion-select-option [value]="0">{{ lang.flatTypes.garconiere }}</ion-select-option>
                <ion-select-option [value]="1">{{ lang.flatTypes.flat1 }}</ion-select-option>
                <ion-select-option [value]="1.5">{{ lang.flatTypes.flat15 }}</ion-select-option>
                <ion-select-option [value]="2">{{ lang.flatTypes.flat2 }}</ion-select-option>
                <ion-select-option [value]="2.5">{{ lang.flatTypes.flat25 }}</ion-select-option>
                <ion-select-option [value]="3">{{ lang.flatTypes.flat3 }}</ion-select-option>
                <ion-select-option [value]="3.5">{{ lang.flatTypes.flat35 }}</ion-select-option>
                <ion-select-option [value]="4">{{ lang.flatTypes.flat4 }}</ion-select-option>
                <ion-select-option [value]="4.5">{{ lang.flatTypes.flat45 }}</ion-select-option>
                <ion-select-option [value]="5">{{ lang.flatTypes.flat5 }}</ion-select-option>
                <ion-select-option [value]="6">{{ lang.flatTypes.other }}</ion-select-option>
              </ion-select>
            </ion-item>

            <ion-item lines="none"
              *ngIf="
                adForm.get('adPropertyType').value !== null &&
                adForm.get('adPropertyType').value !== 5 &&
                adForm.get('adPropertyType').value !== 8">
              <ion-input type="number" formControlName="floorSize">
                {{ lang.livingSpace }} (m&#178;)
              </ion-input>
            </ion-item>

            <ion-item lines="none"
              *ngIf="
                adForm.get('adPropertyType').value !== null &&
                adForm.get('adPropertyType').value !== 5 &&
                adForm.get('adPropertyType').value !== 8 &&
                adForm.get('adPropertyType').value !== 1 &&
                adForm.get('adPropertyType').value !== 3
              "
            >
              <ion-input type="number" formControlName="roomNum" step="0.5">{{
                lang.roomNumber
              }}</ion-input>
            </ion-item>

            <ion-item lines="none"
              *ngIf="
                adForm.get('adPropertyType').value !== null &&
                adForm.get('adPropertyType').value !== 5 &&
                adForm.get('adPropertyType').value !== 8
              "
            >
              <ion-input type="number" formControlName="bathNum">{{
                lang.bathroomNumber
              }}</ion-input>
            </ion-item>

            <ion-item lines="none"
              *ngIf="
                adForm.get('adPropertyType').value !== null &&
                adForm.get('adPropertyType').value !== 5 &&
                adForm.get('adPropertyType').value !== 3 &&
                adForm.get('adPropertyType').value !== 6 &&
                adForm.get('adPropertyType').value !== 8
              "
            >
              <ion-input type="number" formControlName="bedroomNum">{{
                lang.bedroomNumber
              }}</ion-input>
            </ion-item>

            <ion-row
              *ngIf="
                adForm.get('adPropertyType').value !== null &&
                adForm.get('adPropertyType').value !== 5 &&
                adForm.get('adPropertyType').value !== 8
              "
            >
              <ion-col size="12" size-sm="6" class="size-cols">
                <ion-item lines="none">
                  <ion-input
                    name="balcony"
                    type="number"
                    formControlName="balconyNumber"
                    >{{ lang.balconyNumber }}</ion-input
                  >
                </ion-item>
              </ion-col>
              <ion-col size="12" size-sm="6" class="size-cols">
                <ion-item lines="none">
                  <ion-input
                    name="balconySize"
                    type="number"
                    formControlName="balconySize"
                    >{{ lang.balconyArea }} (m&#178;)</ion-input
                  >
                </ion-item>
              </ion-col>
            </ion-row>
          <ion-item lines="none"
              *ngIf="
                adForm.get('adPropertyType').value !== null &&
                adForm.get('adPropertyType').value !== 5 &&
                adForm.get('adPropertyType').value !== 8">
              <ion-input type="number" formControlName="parkingPlaces">{{
                lang.parkingNumber
              }}</ion-input>
            </ion-item>

            <ion-item lines="none"
              *ngIf="
                adForm.get('adPropertyType').value !== null &&
                adForm.get('adPropertyType').value !== 5 &&
                adForm.get('adPropertyType').value !== 8">
              <ion-input type="number" formControlName="garage">{{
                lang.garageNumber
              }}</ion-input>
            </ion-item>

            <ion-item-divider
              *ngIf="
                adForm.get('adPropertyType').value !== null &&
                adForm.get('adPropertyType').value !== 5 &&
                adForm.get('adPropertyType').value === 1">
              {{ lang.energyClass }}
            </ion-item-divider>
   
            <ion-item
              *ngIf="
                adForm.get('adPropertyType').value !== null &&
                adForm.get('adPropertyType').value !== 5 &&
                adForm.get('adPropertyType').value === 1">
              <ion-select
                formControlName="energyClass"
                label="{{ lang.energyCertificate }}"
                interface="popover">
                <ion-select-option [value]="1">A1 (0 - 10 kWh/m&#178;)</ion-select-option>
                <ion-select-option [value]="2">A2 (10 - 15 kWh/m&#178;)</ion-select-option>
                <ion-select-option [value]="3">B1 (15 - 25 kWh/m&#178;)</ion-select-option>
                <ion-select-option [value]="4">B2 (25 - 35 kWh/m&#178;)</ion-select-option>
                <ion-select-option [value]="5">C (35 - 60 kWh/m&#178;)</ion-select-option>
                <ion-select-option [value]="6">D (60 - 105 kWh/m&#178;)</ion-select-option>
                <ion-select-option [value]="7">E (105 - 150 kWh/m&#178;)</ion-select-option>
                <ion-select-option [value]="8">F (150 - 210 kWh/m&#178;)</ion-select-option>
                <ion-select-option [value]="9">G (210 - 300+ kWh/m&#178;)</ion-select-option>
                <ion-select-option [value]="10">{{ lang.certificateInDevelopment }}</ion-select-option>
                <ion-select-option [value]="11">{{ lang.certificateNotMandatory }}</ion-select-option>
                <ion-select-option [value]="12">{{ lang.certificateNotPossible }}</ion-select-option>
                <ion-select-option [value]="13">{{ lang.recordedValue }}</ion-select-option>
              </ion-select>
            </ion-item>

            <ion-item
              *ngIf="
                adForm.get('adPropertyType').value !== null &&
                adForm.get('adPropertyType').value !== 5 &&
                adForm.get('adPropertyType').value === 1 &&
                adForm.controls.energyClass.value === 13">
              <ion-input type="number" formControlName="energyClassManual">
                {{ lang.measuredIn }} kWh/m&#178;
              </ion-input>
            </ion-item>

            <ion-item-divider>
              {{ lang.adPropertyType.estate }}
            </ion-item-divider>

            <ion-item>
              <ion-input type="number" formControlName="lotSize"
                >{{ lang.space }} (m&#178;)</ion-input
              >
            </ion-item>

            <ion-item class="read-write">
              <ion-select
                formControlName="slope"
                label="{{ lang.slopeOfEstate }}"
                interface="popover">
                <ion-select-option [value]="0">{{ lang.noSlope }}</ion-select-option>
                <ion-select-option [value]="1">{{ lang.moderateSlope }}</ion-select-option>
                <ion-select-option [value]="2">{{ lang.steepSlope }}</ion-select-option>
              </ion-select>
            </ion-item>

            <ion-item class="slope-direction">
              <ion-select
                formControlName="slopeDirection"
                label="{{ lang.slopeDirection }}"
                interface="popover">
                <ion-select-option [value]="0">{{ lang.north }}</ion-select-option>
                <ion-select-option [value]="1">{{ lang.east }}</ion-select-option>
                <ion-select-option [value]="2">{{ lang.south }}</ion-select-option>
                <ion-select-option [value]="3">{{ lang.west }}</ion-select-option>
              </ion-select>
            </ion-item>

            <ion-item-divider>
              <span *ngIf="adForm.get('adType').value === 0">
                {{ lang.price }}
              </span>
              <span *ngIf="adForm.get('adType').value === 1">
                {{ lang.rent }}
              </span>
            </ion-item-divider>

            <div formGroupName="price">
              <ion-item lines="none">
                <ion-input type="number" formControlName="current">
                  <span *ngIf="!adForm.get('price').value.current">EUR</span>
                  <span *ngIf="adForm.get('price').value.current">
                    {{ adForm.get("price").value.current | number : "1.2-2" }} €
                  </span>
                </ion-input>
              </ion-item>

              <!-- bad class name -->
              <ion-item class="slope-direction">
                <ion-select
                  formControlName="type"
                  label="{{ lang.paymentTerms }}"
                  interface="popover">
                  <ion-select-option *ngIf="adForm.get('adType').value === 0" [value]="0">{{ lang.payOnce}}</ion-select-option>
                  <ion-select-option *ngIf="adForm.get('adType').value === 1" [value]="1">{{ lang.payMonthly }}</ion-select-option>
                  <ion-select-option *ngIf="adForm.get('adType').value === 1" [value]="2">{{ lang.payDaily }}</ion-select-option>
                </ion-select>
              </ion-item>
            </div>

            <ion-item-divider *ngIf="adForm.get('adType').value === 1">
              {{ lang.deposit }}
            </ion-item-divider>

            <ion-item *ngIf="adForm.get('adType').value === 1" lines="none">
              <ion-input type="number" formControlName="deposit">
                <span *ngIf="!adForm.get('deposit').value">EUR</span>
                <span *ngIf="adForm.get('deposit').value">
                  {{ adForm.get("deposit").value | number : "1.2-2" }} €
                </span>
              </ion-input>
            </ion-item> <!-- -->

            <ion-item-divider>
              {{ lang.fees }}
            </ion-item-divider>

            <ion-item>
              <ion-input type="number" formControlName="fee" lines="none">
                <span *ngIf="!adForm.get('fee').value">EUR</span>
                <span *ngIf="adForm.get('fee').value"
                  >{{ adForm.get("fee").value | number : "1.2-2" }} €</span
                >
              </ion-input>
            </ion-item> <!-- -->

            <ion-item-divider
              *ngIf="
                adForm.get('adPropertyType').value !== null &&
                adForm.get('adType').value === 1 &&
                adForm.get('adPropertyType').value !== 5 &&
                adForm.get('adPropertyType').value !== 8">
              {{ lang.moveIn }}
            </ion-item-divider>

            <ion-item lines="none"
              *ngIf="
                adForm.get('adPropertyType').value !== null &&
                adForm.get('adType').value === 1 &&
                adForm.get('adPropertyType').value !== 5 &&
                adForm.get('adPropertyType').value !== 8"
              >
              <ion-datetime-button class="calendar-button" datetime="datetime1" [locale]="locale"></ion-datetime-button>
              <ion-modal [keepContentsMounted]="true" #moveInModal>
                <ng-template>
                  <ion-datetime id="datetime1"
                                [value]="moveInString"
                                [showDefaultButtons]="true"
                                [doneText]="lang.ok"
                                [cancelText]="lang.cancel"
                                [firstDayOfWeek]="1"
                                presentation="date"
                                color="primary"
                                [min]="moveInOutMinDate"
                                [max]="moveInOutMaxDate"
                                (ionChange)="setMovieInAndMoveOut($event, 'moveIn')"
                                [locale]="locale"></ion-datetime>
                                <!-- ; moveInModal.dismiss() -->
                </ng-template>
              </ion-modal>
            </ion-item>

            <ion-item-divider
              *ngIf="
                adForm.get('adPropertyType').value !== null &&
                adForm.get('adType').value === 1 &&
                adForm.get('adPropertyType').value !== 5 &&
                adForm.get('adPropertyType').value !== 8">
              {{ lang.moveOut }}
            </ion-item-divider>

            <ion-item lines="none"
              *ngIf="
                adForm.get('adPropertyType').value !== null &&
                adForm.get('adType').value === 1 &&
                adForm.get('adPropertyType').value !== 5 &&
                adForm.get('adPropertyType').value !== 8">
              <ion-datetime-button class="calendar-button" datetime="datetime2" [locale]="locale"></ion-datetime-button>
              <ion-modal [keepContentsMounted]="true" #moveOutModal>
                <ng-template>
                  <ion-datetime id="datetime2"
                                [value]="moveOutString"
                                [showDefaultButtons]="true"
                                [doneText]="lang.ok"
                                [cancelText]="lang.cancel"
                                [firstDayOfWeek]="1"
                                presentation="date"
                                color="primary"
                                [min]="moveInOutMinDate"
                                [max]="moveInOutMaxDate"
                                (ionChange)="setMovieInAndMoveOut($event, 'moveOut')"
                                [locale]="locale"></ion-datetime>
                                <!--
                                
                                ; moveOutModal.dismiss() -->
                </ng-template>
              </ion-modal>
            </ion-item>
          </ion-list>
 
          <ion-list
            *ngIf="
              adForm.get('adPropertyType').value !== null &&
              adForm.get('adPropertyType').value !== 5 &&
              adForm.get('adPropertyType').value !== 8">
            <ion-item-divider>
              {{ lang.maintenanceMonthly }}
            </ion-item-divider>
            <ion-row>
              <ion-col size="12" size-sm="6">
                <ion-item lines="none">
                  <ion-input type="number" formControlName="summerCost">
                    <span>{{ lang.inSummer }}
                      <span *ngIf="adForm.get('summerCost').value">
                        : <strong>{{ adForm.get("summerCost").value | number : "1.2-2" }} €</strong>
                      </span>
                    </span>
                  </ion-input>
                </ion-item>
              </ion-col>
              <ion-col size="12" size-sm="6">
                <ion-item lines="none">
                  <ion-input type="number" formControlName="winterCost">
                    <span>{{ lang.inWinter }}
                      <span *ngIf="adForm.get('winterCost').value">
                        :<strong>{{ adForm.get("winterCost").value | number : "1.2-2"}} €</strong>
                      </span>
                    </span>
                  </ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
          </ion-list>

          <ion-list
            *ngIf="
              adForm.get('adPropertyType').value !== null &&
              adForm.get('adPropertyType').value !== 5 &&
              adForm.get('adPropertyType').value !== 8">
            <ion-item-divider>
              {{ lang.propertyAge }}
            </ion-item-divider>

            <ion-row>
              <ion-col size="12" size-sm="6">
                <ion-item lines="none">
                  <ion-input type="number" formControlName="yearBuilt">{{
                    lang.yearBuilt
                  }}</ion-input>
                </ion-item>
              </ion-col>
  
              <ion-col size="12" size-sm="6">
                <ion-item lines="none">
                  <ion-input type="number" formControlName="lastAdaptation">{{
                    lang.yearAdaptation
                  }}</ion-input>
                </ion-item>
              </ion-col>
            </ion-row>


            <ion-item lines="none"></ion-item>
          </ion-list>

          <ion-row
            *ngIf="
              adForm.get('adPropertyType').value !== null &&
              adForm.get('adPropertyType').value !== 5 &&
              adForm.get('adPropertyType').value !== 8">
            <ion-item-divider>
              {{ lang.floor }}
            </ion-item-divider>
            <ion-col>
              <ion-item lines="none">
                <ion-input type="number" placeholder="0 - pritličje" formControlName="floor">
                  {{ lang.floor }}
                </ion-input>
              </ion-item>
            </ion-col>
            <ion-col>
              <ion-item lines="none">
                <ion-input type="number" formControlName="numFloors">{{ lang.numFloors }}</ion-input>
              </ion-item>
            </ion-col>
          </ion-row>

          <ion-list>
            <div formGroupName="heating"
                 *ngIf="adForm.get('adPropertyType').value !== null &&
                   adForm.get('adPropertyType').value !== 5 &&
                   adForm.get('adPropertyType').value !== 8">
              <ion-item-divider>
                {{ lang.heatingType }}
              </ion-item-divider>

              <ion-item>
                <ion-checkbox justify="start" labelPlacement="end" formControlName="electricityHeating">
                  {{ lang.heatingTypes.electricity }}
                </ion-checkbox>
              </ion-item>

              <ion-item>
                <ion-checkbox justify="start" labelPlacement="end" formControlName="gasHeating">
                  {{ lang.heatingTypes.gas }}
                </ion-checkbox>
              </ion-item>

              <ion-item>
                <ion-checkbox justify="start" labelPlacement="end" formControlName="oilHeating">
                  {{ lang.heatingTypes.oil }}
                </ion-checkbox>
              </ion-item>

              <ion-item>
                <ion-checkbox justify="start" labelPlacement="end" formControlName="woodCarbon">
                  {{ lang.heatingTypes.woodCoal }}
                </ion-checkbox>
              </ion-item>

              <ion-item>
                <ion-checkbox justify="start" labelPlacement="end" formControlName="floorHeating">
                  {{ lang.heatingTypes.floorHeating }}
                </ion-checkbox>
              </ion-item>
            </div>

            <ion-item-divider>
              {{ lang.equipment }}
            </ion-item-divider>

           <ion-item class="read-write">
             <ion-select
               *ngIf="
                 adForm.get('adPropertyType').value !== null &&
                 adForm.get('adPropertyType').value !== 5 &&
                 adForm.get('adPropertyType').value !== 8"
               formControlName="furniture"
               label="{{ lang.equippedIn }}"
               interface="popover">
               <ion-select-option [value]="0">{{ lang.equippedAmount.none }}</ion-select-option>
               <ion-select-option [value]="1">{{ lang.equippedAmount.partial }}</ion-select-option>
               <ion-select-option [value]="2">{{ lang.equippedAmount.full }}</ion-select-option>
             </ion-select>
           </ion-item>

            <div formGroupName="amenities">
  
              <ion-item
                *ngIf="
                  adForm.get('adPropertyType').value !== null &&
                  adForm.get('adPropertyType').value !== 5 &&
                  adForm.get('adPropertyType').value !== 8">
                <ion-checkbox justify="start" labelPlacement="end" formControlName="kitchenBasic">
                  {{ lang.amenities.kitchenBasic }}
                </ion-checkbox>
              </ion-item>
  
              <ion-item>
                <ion-checkbox justify="start" labelPlacement="end" formControlName="internet">
                  {{ lang.amenities.internet }}
                </ion-checkbox>
              </ion-item>
  
              <ion-item>
                <ion-checkbox justify="start" labelPlacement="end" formControlName="opticalFiber">
                  {{ lang.amenities.opticalFiber }}
                </ion-checkbox>
              </ion-item>
  
              <ion-item
                *ngIf="
                  adForm.get('adPropertyType').value !== null &&
                  adForm.get('adPropertyType').value !== 5 &&
                  adForm.get('adPropertyType').value !== 8">
                <ion-checkbox justify="start" labelPlacement="end" formControlName="ac">
                  {{ lang.amenities.ac }}
                </ion-checkbox>
              </ion-item>
  
              <ion-item
                *ngIf="
                  adForm.get('adPropertyType').value !== null &&
                  adForm.get('adPropertyType').value !== 5 &&
                  adForm.get('adPropertyType').value !== 8">
                <ion-checkbox justify="start" labelPlacement="end" formControlName="alarm">
                  {{ lang.amenities.alarm }}
                </ion-checkbox>
              </ion-item>
  
              <ion-item>
                <ion-checkbox justify="start" labelPlacement="end" formControlName="heatPump">
                  {{ lang.amenities.heatPump }}
                </ion-checkbox>
              </ion-item>
  
              <ion-item
                *ngIf="
                  adForm.get('adPropertyType').value !== null &&
                  adForm.get('adPropertyType').value !== 5 &&
                  adForm.get('adPropertyType').value !== 8">
                <ion-checkbox justify="start" labelPlacement="end" formControlName="lift">
                  {{ lang.amenities.lift }}
                </ion-checkbox>
              </ion-item>
  
              <ion-item>
                <ion-checkbox justify="start" labelPlacement="end" formControlName="disability">
                  {{ lang.amenities.disability }}
                </ion-checkbox>
              </ion-item>
  
              <ion-item
                *ngIf="
                  adForm.get('adPropertyType').value !== null &&
                  adForm.get('adPropertyType').value !== 5 &&
                  adForm.get('adPropertyType').value !== 8">
                <ion-checkbox justify="start" labelPlacement="end" formControlName="animalsAllowed">
                  {{ lang.amenities.animalsAllowed }}
                </ion-checkbox>
              </ion-item>
  
              <ion-item
                *ngIf="
                  adForm.get('adPropertyType').value !== null &&
                  adForm.get('adPropertyType').value !== 5 &&
                  adForm.get('adPropertyType').value !== 8">
                <ion-checkbox justify="start" labelPlacement="end" formControlName="washer">
                  {{ lang.amenities.washer }}
                </ion-checkbox>
              </ion-item>
  
              <ion-item
                *ngIf="
                  adForm.get('adPropertyType').value !== null &&
                  adForm.get('adPropertyType').value !== 5 &&
                  adForm.get('adPropertyType').value !== 8">
                <ion-checkbox justify="start" labelPlacement="end" formControlName="dishwasher">
                  {{ lang.amenities.dishwasher }}
                </ion-checkbox>
              </ion-item>
  
              <ion-item>
                <ion-checkbox justify="start" labelPlacement="end" formControlName="pool">
                  {{ lang.amenities.pool }}
                </ion-checkbox>
              </ion-item>
  
              <ion-item>
                <ion-checkbox justify="start" labelPlacement="end" formControlName="solar">
                  {{ lang.amenities.solar }}
                </ion-checkbox>
              </ion-item>

              <ion-item
                *ngIf="
                  adForm.get('adPropertyType').value !== null &&
                  adForm.get('adPropertyType').value !== 5 &&
                  adForm.get('adPropertyType').value !== 8">
                <ion-checkbox justify="start" labelPlacement="end" formControlName="storageArea">
                  {{ lang.storageArea }}
                </ion-checkbox>
              </ion-item>
  
              <ion-item *ngIf="adForm.get('adPropertyType').value === 5">
                <ion-checkbox justify="start" labelPlacement="end" formControlName="constructionReady">
                  {{ lang.amenities.constructionReady }}
                </ion-checkbox>
              </ion-item>
  
              <ion-item>
                <ion-checkbox justify="start" labelPlacement="end" formControlName="firstTime">
                  {{ lang.amenities.firstTime }}
                </ion-checkbox>
              </ion-item>
            </div>

            <ion-item-divider>
              {{ lang.neighborhoodDistances }}
            </ion-item-divider>

            <div formGroupName="vicinity">
              <ion-item>
                <ion-input type="number" step="0.1" formControlName="kindergarden">{{ lang.kindergarten }}</ion-input>
              </ion-item>
              <ion-item>
                <ion-input type="number" step="0.1" formControlName="basicSchool">{{ lang.elementarySchool}}</ion-input>
              </ion-item>  
              <ion-item>
                <ion-input type="number" step="0.1" formControlName="higherSchool">{{ lang.highSchool }}</ion-input>
              </ion-item>  
              <ion-item>
                <ion-input type="number" step="0.1" formControlName="postOffice">{{ lang.postOffice }}</ion-input>
              </ion-item>  
              <ion-item>
                <ion-input type="number" step="0.1" formControlName="medicalStation">{{ lang.medicalStation }}</ion-input>
              </ion-item>  
              <ion-item>
                <ion-input type="number" step="0.1" formControlName="pharmacy">{{ lang.pharmacy }}</ion-input>
              </ion-item>  
              <ion-item>
                <ion-input type="number" step="0.1" formControlName="market">{{ lang.market }}</ion-input>
              </ion-item>  
              <ion-item>
                <ion-input type="number" step="0.1" formControlName="busStation">{{ lang.busStation }}</ion-input>
              </ion-item>  
              <ion-item>
                <ion-input type="number" step="0.1" formControlName="trainStation">{{ lang.trainStation }}</ion-input>
              </ion-item>  
              <ion-item>
                <ion-input type="number" step="0.1" formControlName="highway">{{ lang.highway }}</ion-input>
              </ion-item>  
              <ion-item>
                <ion-input type="number" step="0.1" formControlName="park">{{ lang.park }}</ion-input>
              </ion-item>  
              <ion-item>
                <ion-input type="number" step="0.1" formControlName="fitnessAndSport">{{ lang.fitnessAndSport }}</ion-input>
              </ion-item>
            </div>

            <ion-item-divider>
              {{ lang.descriptionAd }}
            </ion-item-divider>

            <ion-item>
              <ion-textarea
                rows="5"
                type="text"
                class="message-textarea"
                name="description"
                formControlName="description">
              </ion-textarea>
            </ion-item>
    
            <ion-item>
              <ion-input type="text" formControlName="propertyId">
                {{ lang.propertyId }}
              </ion-input>
            </ion-item>
    
            <ion-item lines="none">
              <ion-button
                [disabled]="
                  address === lang.addressNotFound ||
                  address === lang.setAddressOnMap ||
                  adForm.get('price').value.current == null"
                expand="block"
                type="submit"
                size="default">
                {{ lang.saveAndPhotos }}
                <ion-spinner *ngIf="saving" name="bubbles"></ion-spinner>
              </ion-button>
            </ion-item><!--   -->
            <p *ngIf="address === lang.addressNotFound || address === lang.setAddressOnMap">
              {{ lang.setAddressOnMap }}
            </p>
            <p *ngIf="adForm.get('price').value.current == null">
              {{ lang.priceRequired }}
            </p>
          </ion-list> 
        </form>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

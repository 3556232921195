<ion-header class="preview-modal-header">
  <ion-toolbar color="dark">
    <ion-title>
      <!-- <span *ngIf="!mobileView">
        {{ ad.located.street }}, {{ ad.located.postal }} {{ ad.located.district }}
      </span> -->
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="photo-preview-content">
  <app-image-slider *ngIf="imageUrls && imageUrls.length > 0" [images]="imageUrls" [imgHeight]="500" [photoPreview]="photoPreview">
  </app-image-slider>
</ion-content>
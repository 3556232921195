<ion-row class="comments">
  <ion-col size="12" *ngIf="currentUser">
    <h1>{{ lang.questionsAndComments }} ({{ commentCount }})</h1>

    <ion-list>
      <ion-item class="new-comment-item">
        <ion-avatar>
          <!-- <img src="assets/images/profile-300x300.jpg"> -->
          <app-profile-photo [userId]="currentUserId"> </app-profile-photo>
        </ion-avatar>
        <ion-label>
          <input
            class="new-comment-input"
            [(ngModel)]="question"
            name="comment"
            type="text"
            (ngModelChange)="countMessageRemaining(question)"
            placeholder="{{ lang.commentInputPlaceholder }}."
          /><!-- </ion-input> -->
          <p
            class="message-counter"
            [ngClass]="{
              'length-warning': messageMaxLength - question.length < 50
            }"
            *ngIf="question.length > 140"
          >
            {{ messageRemaining }}
          </p>
          <p>
            <ion-button
              [disabled]="question.length < 1"
              size="small"
              fill="clear"
              (click)="question = ''"
            >
              {{ lang.cancel }}
            </ion-button>
            <ion-button
              [disabled]="question.length < 2 || messageRemaining < 0"
              size="small"
              (click)="addComment(question)">
              {{ lang.addComment }}
            </ion-button>
          </p>
        </ion-label>
      </ion-item>
    </ion-list>

    <ion-list>
      <ng-container *ngFor="let comment of comments">
        <ion-item class="comment-item">
          <ion-avatar>
            <!-- <img src="assets/images/profile-300x300.jpg"> -->
            <app-profile-photo #userPhoto [userId]="comment.userId">
            </app-profile-photo>
          </ion-avatar>
          <ion-label class="ion-text-wrap">
            <p>
              <b [ngClass]="{ 'ad-owner': authorId === comment.userId }">{{
                comment.userName
              }}</b>
              <span class="comment-date">
                {{ comment.lastChange | date : "dd.MM.yyyy" }} {{ lang.at }}
                {{ comment.lastChange | date : "H:mm" }}
              </span>
            </p>
            <p *ngIf="editCommentId !== comment._id" class="comment-message">
              {{ comment.message }}
            </p>
            <input
              *ngIf="editCommentId === comment._id"
              class="edit-comment-input"
              [(ngModel)]="editMessage"
              (ngModelChange)="countMessageRemaining(editMessage)"
              name="editcomment"
              type="text"
              autofocus
            />
            <p
              class="message-counter"
              [ngClass]="{
                'length-warning': messageMaxLength - editMessage.length < 50
              }"
              *ngIf="editMessage.length > 140 && editCommentId === comment._id"
            >
              {{ messageRemaining }}
            </p>
            <p *ngIf="editCommentId === comment._id">
              <ion-button
                [disabled]="editMessage.length < 1"
                size="small"
                fill="clear"
                (click)="editCommentId = ''; editMessage = ''"
              >
                {{ lang.cancel }}
              </ion-button>
              <ion-button
                [disabled]="editMessage.length < 2 || messageRemaining < 0"
                size="small"
                (click)="sendEditComment(comment, editMessage)">
                {{ lang.addComment }}
              </ion-button>
            </p>
            <div>
              <ion-button
                class="thumbs-button"
                fill="clear"
                (click)="voteOnItem(comment, comment.userId, 1)"
              >
                <ion-icon slot="icon-only" name="thumbs-up-outline"></ion-icon>
                <span
                  *ngIf="comment.likes > 0"
                  class="likes-num"
                  >{{ comment.likes }}</span
                >
              </ion-button>
              <ion-button
                class="thumbs-button"
                fill="clear"
                (click)="voteOnItem(comment, comment.userId, -1)"
              >
                <ion-icon slot="icon-only" name="thumbs-down-outline"></ion-icon>
              </ion-button>
              <ion-button
                (click)="
                  replyForm = true;
                  replyCommentId = comment._id;
                  replyQuestion = ''
                "
                fill="clear"
              >
                {{ lang.addReply }}
              </ion-button>
            </div>
            <div *ngIf="replyForm && replyCommentId === comment._id">
              <ion-item class="reply-comment-item reply-form">
                <ion-avatar>
                  <!-- <img src="assets/images/profile-300x300.jpg"> -->
                  <app-profile-photo [userId]="currentUserId"> </app-profile-photo>
                </ion-avatar>
                <ion-label>
                  <input
                    class="reply-comment-input"
                    [(ngModel)]="replyQuestion"
                    (ngModelChange)="countMessageRemaining(replyQuestion)"
                    name="replycomment"
                    type="text"
                    placeholder="{{ lang.commentInputPlaceholder }}."
                  /><!-- </ion-input> -->
                  <p
                    class="message-counter"
                    [ngClass]="{
                      'length-warning':
                        messageMaxLength - replyQuestion.length < 50
                    }"
                    *ngIf="
                      replyQuestion.length > 140 &&
                      replyForm &&
                      replyCommentId === comment._id
                    "
                  >
                    {{ messageRemaining }}
                  </p>
                  <p>
                    <ion-button size="small" fill="clear" (click)="replyQuestion = ''; replyForm = false">
                      {{ lang.cancel }}
                    </ion-button>
                    <ion-button [disabled]="(replyQuestion && replyQuestion.length < 2) || messageRemaining < 0"
                                size="small" (click)="addReplyComment(comment.userId, comment)">
                      {{ lang.addComment }}
                    </ion-button>
                  </p>
                </ion-label>
              </ion-item>
            </div>
            <p
              *ngIf="!comment.repliesOpen && comment.repliesCount > 0"
              class="show-hide-replies"
              (click)="openReplies(comment)"
            >
              {{ lang.showReplies }} ({{ comment.repliesCount }})
              <ion-icon name="chevron-down-outline"></ion-icon>
            </p>
            <p
              class="show-hide-replies"
              *ngIf="comment.repliesOpen && comment.repliesCount > 0"
              (click)="closeReplies(comment)"
            >
              {{ lang.hideReplies }}
              <ion-icon name="chevron-up-outline"></ion-icon>
            </p>
          </ion-label>
          <ion-button
            fill="clear"
            class="more-button"
            (click)="presentCommentOptionsPopover($event, comment)"
          >
            <ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
          </ion-button>
        </ion-item>
        <ng-container
          *ngIf="
            comment.repliesArray &&
            comment.repliesArray.length > 0 &&
            comment.repliesOpen
          "
        >
          <ion-item
            class="reply-comment-item"
            *ngFor="let reply of comment.repliesArray">
            <ion-avatar>
              <!-- <img src="assets/images/profile-300x300.jpg"> -->
              <app-profile-photo [userId]="reply.user"> </app-profile-photo>
            </ion-avatar>
            <ion-label class="ion-text-wrap">
              <p>
                <b [ngClass]="{ 'ad-owner': authorId === reply.user }">
                  {{ reply.userName }}</b>
                <span class="reply-date">
                  {{ reply.lastChange | date : "dd.MM.yyyy" }} ob
                  {{ reply.lastChange | date : "H:mm" }}
                </span>
              </p>
              <p *ngIf="editCommentId !== reply._id">
                <b>&#64;{{ reply.replyToName }}</b> {{ reply.message }}
              </p>
              <input
                *ngIf="editCommentId === reply._id"
                class="edit-comment-input"
                [(ngModel)]="editMessage"
                (ngModelChange)="countMessageRemaining(editMessage)"
                name="editcomment"
                type="text"
                autofocus/>
              <p
                class="message-counter"
                [ngClass]="{
                  'length-warning': messageMaxLength - editMessage.length < 50
                }"
                *ngIf="editMessage.length > 140 && editCommentId === reply._id">
                {{ messageRemaining }}
              </p>
              <p *ngIf="editCommentId === reply._id">
                <ion-button
                  [disabled]="editMessage.length < 1"
                  size="small"
                  fill="clear"
                  (click)="editCommentId = ''; editMessage = ''">
                  {{ lang.cancel }}
                </ion-button>
                <ion-button
                  [disabled]="editMessage.length < 2 || messageRemaining < 0"
                  size="small"
                  (click)="sendEditComment(reply, editMessage, comment)">
                  {{ lang.addComment }}
                </ion-button>
              </p>
              <div>
                <ion-button
                  class="thumbs-button"
                  fill="clear"
                  (click)="voteOnItem(reply, reply.userId, 1)"
                >
                  <ion-icon slot="icon-only" name="thumbs-up-outline"></ion-icon>
                  <span
                    *ngIf="reply.likes > 0"
                    class="likes-num"
                  >
                    {{ reply.likes }}
                  </span>
                </ion-button>
                <ion-button
                  class="thumbs-button"
                  fill="clear"
                  (click)="voteOnItem(reply, reply.userId, -1)"
                >
                  <ion-icon slot="icon-only" name="thumbs-down-outline"></ion-icon>
                </ion-button>
                <ion-button
                  (click)="
                    replyToReplyForm = true;
                    replyForm = false;
                    replyCommentId = comment._id;
                    replyReplyId = reply._id;
                    replyQuestion = ''
                  "
                  fill="clear"
                >
                  {{ lang.addReply }}
                </ion-button>
              </div>

              <div *ngIf="replyToReplyForm && replyReplyId === reply._id">
                <ion-item class="reply-comment-item reply-form">
                  <ion-avatar>
                    <!-- <img src="assets/images/profile-300x300.jpg"> -->
                    <app-profile-photo [userId]="currentUserId"> </app-profile-photo>
                  </ion-avatar>
                  <ion-label>
                    <input
                      class="reply-comment-input"
                      [(ngModel)]="replyQuestion"
                      (ngModelChange)="countMessageRemaining(replyQuestion)"
                      name="replycomment"
                      type="text"
                      placeholder="{{ lang.commentInputPlaceholder }}."
                    /><!-- </ion-input> -->
                    <p
                      class="message-counter"
                      [ngClass]="{
                        'length-warning':
                          messageMaxLength - replyQuestion.length < 50
                      }"
                      *ngIf="
                        replyQuestion.length > 140 &&
                        replyToReplyForm &&
                        replyReplyId === reply._id
                      "
                    >
                      {{ messageRemaining }}
                    </p>
                    <p>
                      <ion-button
                        size="small"
                        fill="clear"
                        (click)="
                          replyQuestion = '';
                          replyToReplyForm = false;
                          replyReplyId = ''">
                        {{ lang.cancel }}
                      </ion-button>
                      <ion-button
                        [disabled]="(replyQuestion && replyQuestion.length < 2) || messageRemaining < 0"
                        size="small" (click)="addReplyComment(reply.userId, comment)">
                        {{ lang.addComment }}
                      </ion-button>
                    </p>
                  </ion-label>
                </ion-item>
              </div>
            </ion-label>
            <ion-button
              fill="clear"
              class="more-button"
              (click)="presentCommentOptionsPopover($event, reply)"
            >
              <ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
            </ion-button>
          </ion-item>
        </ng-container>
      </ng-container>
    </ion-list>
  </ion-col>

  <ion-col size="12" *ngIf="!currentUser">
    <h1>{{ lang.questionsAndComments }} ({{ commentCount }})</h1>
    <p>{{ lang.loginToViewComments }}</p>
  </ion-col>
</ion-row>

import { Component, OnInit, Input, HostListener } from "@angular/core";
import { MenuController } from "@ionic/angular";
import { ActivatedRoute } from "@angular/router";

import { CurrentUser } from "../../model/current-user";
import { LanguageService } from "../../services/language.service";
import { DataService } from "../../services/data.service";
import { LoginService } from "../../services/login.service";
import { WindowService } from "../../services/window.service";
import { MetaService } from "../../services/meta.service";
import { IMetaData } from "src/app/model/meta-data";

// Comment Component
@Component({
  selector: "app-base-page",
  templateUrl: "./base-page.component.html",
  styleUrls: ["./base-page.component.scss"],
})
export class BasePageComponent implements OnInit {
  mobileView: boolean;
  title = "about";
  currentUser: CurrentUser;
  appLanguage: string;
  lang: any;
  loggedIn = false;
  isBrowser = false;

  constructor(
    private languageService: LanguageService,
    private dataService: DataService,
    private loginService: LoginService,
    private windowService: WindowService,
    private menuCtrl: MenuController,
    private route: ActivatedRoute,
    private metaService: MetaService,
  ) {}

  ngOnInit() {
    // SET LANGUAGE
    this.languageService.language$.subscribe((value) => {
      this.appLanguage = value;
      this.lang = this.languageService[value];
    });

    this.dataService.loggedIn$.subscribe((val) => {
      this.loggedIn = val;
      this.currentUser = this.loginService.currentUser();
    });

    // GET VIEW
    this.dataService.isBrowser$.subscribe((isBrowser) => {
      this.isBrowser = isBrowser;
      if (isBrowser) {
        // this.getCounties();
        this.toggleMobileView();
      }
    });

    this.title = this.route.snapshot.data.title;
    this.setMetaInfo(this.title);
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.toggleMobileView();
  }

  toggleMobileView() {
    if (this.windowService.nativeWindow.innerWidth <= "767") {
      this.mobileView = true;
      this.menuCtrl.swipeGesture(true);
    } else {
      this.mobileView = false;
      this.menuCtrl.swipeGesture(false);
    }
  }

  setMetaInfo(title: string) {
    let pageData: IMetaData = this.setPageData(title)
    this.metaService.setMetaDataObject({
      title: pageData.title,
      description: pageData.description,
      keywords: pageData.keywords,
      url: this.windowService?.nativeWindow?.location.origin || "https://kvadrat.si",
      image: (this.windowService?.nativeWindow?.location.origin || "https://kvadrat.si") + "/assets/images/condo-323780.jpg"
    });
  }

  setPageData(title: string): IMetaData {
    switch (title) {
      case "about":
        return { 
          title: this.lang.aboutUs,
          description: "O nas",
          keywords: "predstavitev podjetja, buy, sell, rent, condo, flat, apartment, house",
        }
      case "terms":
        return { 
          title: this.lang.termsAndConditions2,
          description: this.lang.termsAndConditions2,
          keywords: "predstavitev podjetja, buy, sell, rent, condo, flat, apartment, house",
        }
      case "privacy":
        return { 
          title: this.lang.privacyPolicy,
          description: this.lang.privacyPolicy,
          keywords: `${this.lang.privacyPolicy}, buy, sell, rent, condo, flat, apartment, house`,
        }
      case "contact-us":
        return { 
          title: this.lang.contact2,
          description: "Kontaktirajte nas s pomočjo spodnjega obrazca.",
          keywords: "Kontakt, kontaktni obrazec, buy, sell, rent, condo, flat, apartment, house",
        }
      case "advertising":
        return { 
          title: this.lang.advertising,
          description: "Kontaktirajte nas s pomočjo spodnjega obrazca.",
          keywords: "Kontakt, kontaktni obrazec, buy, sell, rent, condo, flat, apartment, house",
        }
    
      default:
        return { 
          title: this.lang.about,
          description: "O nas",
          keywords: "predstavitev podjetja, buy, sell, rent, condo, flat, apartment, house",
        }
    }
  }
}

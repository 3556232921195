import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
// import { CompleterData, CompleterItem } from 'ng2-completer';

import { DataService } from '../services/data.service';
import { LanguageService } from './language.service';

@Injectable()
// export class LocationService extends Subject<CompleterItem[]> implements CompleterData {
export class LocationService {

  lang: any;
  API: string;

  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
    private dataService: DataService,
  ) {
    // super();
    this.API = this.dataService.API;
    languageService.language$.subscribe(value => {
      this.lang = this.languageService[value];
    });
  }

  search(term: string) {
    // const dataService = new DataService;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + this.dataService.sessionToken$);

    return this.http.get(`${this.API}/locations/alllocations?s=${term}`, {
      headers
      })
      .pipe(
        map(data => {
          const matches = (data as Array<any>).map((episode: any) => this.convertToItem(episode)) // .filter(episode => !!episode) as CompleterItem[];
          // this.next(matches);
          return matches;
        })
      )
      // .subscribe();
  }

  public cancel() {
    // Handle cancel
  }

  public convertToItem(data: any): any {
  // public convertToItem(data: any): CompleterItem | null {
    if (!data) {
      return null;
    }

    let description = '';
    switch (data.des) {
      case 'z':
        description = this.lang.zip;
        break;
      case 'd':
        description = this.lang.city;
        break;
      case 'c':
        description = this.lang.county;
        break;
      case 'co':
        description = this.lang.country;
        break;
      default:
        break;
    }
    // data will be string if an initial value is set
    return {
      title: typeof data === 'string' ? data : data.q,
      description,
      originalObject: data
    };
    // } as CompleterItem;
  }

  getCounties() {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.http.get(`${this.API}/locations/counties`, { headers });
  }

  addLocation(locationObject) {
    return this.http.post(`${this.API}/locations/addlocation`, locationObject);
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  PopoverController,
} from '@ionic/angular';

import { LanguageService } from '../../services/language.service';
import { PopoverLanguageComponent } from '../popovers/language-menu/language-menu-popover.component';

@Component({
  selector: 'app-register',
  templateUrl: 'register.component.html'
})
export class RegisterComponent implements OnInit {

  appLanguage: string;
  lang: any;
  formType = 'login';

  constructor(
    private popoverCtrl: PopoverController,
    private languageService: LanguageService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.languageService.language$.subscribe(value => {
      this.appLanguage = value;
      this.lang = this.languageService[value];
    });
  }

  // navbar add button
  async presentLanguagePopover(ev) {
    const popover = await this.popoverCtrl.create({
      component: PopoverLanguageComponent,
      componentProps: {
        setLang: (language) => {
          this.setLang(language);
        },
        lang: this.lang
      },
      event: ev,
    });
    popover.present();
  }

  setLang(language) {
    this.languageService.langChange(language);
    this.lang = this.languageService[language];
  }

  goToHomepage() {
    this.router.navigate(
      ['/'],
      { queryParams: { l: this.appLanguage === "en" ? "en" : null } }
    );
  }
}

<ion-list class="photo-options-popover" lines="full">
  <ion-item button
            class="comment-menu-button"
            (click)="view()">
    <ion-icon slot="start" name="eye"></ion-icon>
    <span>View</span>
  </ion-item>
  <ion-item button
            class="comment-menu-button"
            (click)="delete()">
    <ion-icon slot="start" name="trash"></ion-icon>
    <span>Delete</span>
    <!-- <span>{{ lang.delete }}</span> -->
  </ion-item>
</ion-list>
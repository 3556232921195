<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="share-modal-content">
  <app-login [lang]='lang'
             [appLanguage]='appLanguage'
             [formType]='formType'
             (loggedInResponse)="loggedInResponse($event)"></app-login>
</ion-content>
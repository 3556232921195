import {
  Component,
  OnInit,
  HostListener,
  Input,
  Output,
  EventEmitter,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { ModalController } from "@ionic/angular";

import { DataService } from "../../services/data.service";
import { BucketService } from "../../services/bucket.service";
import { WindowService } from "../../services/window.service";
import { SocketService } from "../../services/socket.service";

import { PreviewModalComponent } from "../modals/preview-modal/preview-modal.component";
import { AdCardComponent } from "../ad-card/ad-card.component";
import { IAdClient } from "../../model/db/ad";

// function _window(): any {
//   return window;
// }

@Component({
  selector: "app-latest-properties",
  templateUrl: "./latest-ads.component.html",
  styleUrls: ["./latest-ads.component.scss"],
})
export class LatestAdsComponent implements OnInit {
  mobileView: boolean;
  colNum = "3";
  ads = [];
  loadingResults = true;

  @Input() lang: any;
  @Input() appLanguage: any;
  @Output() latestAdsActive: EventEmitter<boolean> = new EventEmitter();
  @ViewChildren("adCard") cards: QueryList<AdCardComponent>;

  constructor(
    private dataService: DataService,
    private bucketService: BucketService,
    private windowService: WindowService,
    private modalCtrl: ModalController,
    private socketService: SocketService,
  ) {}

  ngOnInit(): void {
    this.getLatest();

    this.dataService.isBrowser$.subscribe((isBrowser) => {
      // console.log(isBrowser);
      if (isBrowser) {
        this.toggleMobileView();
        this.toggleCardView();

        this.socketService.adChanges$.subscribe((msg) => {
          if (msg["type"] === "adActivated") {
            this.getLatest();
            // this.updateActiveList(msg["itemId"], true);
          }

          if (msg["type"] === "adDeactivated") {
            const existingItem = this.ads.find((ad) => ad._id === msg["itemId"]);
            
            if (existingItem) {
              // this.updateActiveList(msg["itemId"], false);
              this.getLatest();
              // if (msg["type"] === "favoriteChanges") {
              //   this.updateFavorite(msg["itemId"]);
              // }
            }
          }
        });

        this.socketService.adPhotoChanges$.subscribe((msg) => {
          const existingItem = this.ads.find((ad) => ad._id === msg["itemId"]);

          if (existingItem && msg["type"] === "photosUpdated") {
            this.listAdImages(existingItem, true);
          }
        });
      }
    });
  }

  // updateFavorite(itemId: string) {
  //   const adToUpdate = this.ads.find((ad) => ad._id === itemId);
  //   if (adToUpdate) {
  //     this.refreshCardFavorite(adToUpdate._id);
  //   }
  // }

  // updateActiveList(itemId: string, isActive: boolean) {
  //   if (isActive) {
  //     this.pushAdToList(itemId, this.ads);
  //     return;
  //   }
  //   this.removeAdFromList(itemId);
  // }

  // removeAdFromList(itemId: string) {
  //   this.ads = this.ads.filter((item) => item._id !== itemId);
  // }

  // pushAdToList(adId, itemList) {
  //   this.bucketService.getSingleAd(adId, "").subscribe((res) => {
  //     const ad = res;
  //     this.checkAdExpiry(ad);
  //     if (ad["adExpired"]) {
  //       return;
  //     }
  //     this.ads = [ad, ...itemList];
  //     this.listAdImages(ad["_id"], true);
  //   });
  // }

  // checkAdExpiry(ad) {
  //   if (
  //     ad.statusOfAd[ad.statusOfAd.length - 1].status === "active" &&
  //     new Date(ad.statusOfAd[ad.statusOfAd.length - 1].changed).getTime() >
  //       new Date().getTime() - 30 * 24 * 60 * 60 * 1000
  //   ) {
  //     ad["adExpired"] = false;
  //   } else {
  //     ad["adExpired"] = true;
  //   }
  // }

  // refreshCardFavorite(itemId: string) {
  //   this.cards.forEach((element) => {
  //     if (element.item._id === itemId) {
  //       element.checkFavorite();
  //     }
  //   });
  // }

  getLatest() {
    this.bucketService.getLatestAds().subscribe((result: any[]) => {
      this.ads = result;
      // console.log(this.ads);

      for (let i = 0; i < this.ads.length; i++) {
        const element = this.ads[i];
        // this.checkAdExpiry(element);
        this.listAdImages(element, i === this.ads.length - 1);
      }

      if (this.ads.length > 0) {
        this.latestAdsActive.emit(true);
      }
    });
  }

  listAdImages(ad: IAdClient, endOfArray: boolean): void {
    this.bucketService.listAdImages(ad._id, 3).subscribe((images) => {
      for (let i = 0; i < this.ads.length; i++) {
        const element = this.ads[i];
        if (element._id === ad._id) {
          element.photos = images["files"];
          element.imageUrls = element.photos.map(image => this.dataService.API + '/files/image/' + image._id);
        }
      }
      if (endOfArray) {
        this.loadingResults = false;
        // this.latestAdsActive.emit(true);
      }
    });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.toggleMobileView();
    this.toggleCardView();
  }

  toggleMobileView() {
    if (this.windowService.nativeWindow.innerWidth <= "767") {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
  }

  toggleCardView() {
    if (this.windowService.nativeWindow.innerWidth <= "600") {
      this.colNum = "12";
    } else if (
      this.windowService.nativeWindow.innerWidth >= "601" &&
      this.windowService.nativeWindow.innerWidth <= "900"
    ) {
      this.colNum = "6";
    } else if (
      this.windowService.nativeWindow.innerWidth >= "901" &&
      this.windowService.nativeWindow.innerWidth <= "1200"
    ) {
      this.colNum = "4";
    } else if (this.windowService.nativeWindow.innerWidth >= "1201") {
      this.colNum = "3";
    }
  }

  async openPreviewModal(item): Promise<void> {
    const previewModal = await this.modalCtrl.create({
      component: PreviewModalComponent,
      componentProps: {
        // updateList: (name) => {
        //   // this.updateList();
        // },
        // item: item,
        updateSaveFavorite: (itemId) => {
          // this.refreshFavoriteCount(itemId);
        },
        updateFavoriteCount: (itemId) => {
          // this.refreshAdBannerFavorite(itemId);
        },
        updateAdView: (itemId) => {
          // this.refreshAdView(itemId);
        },
        lang: this.lang,
        appLanguage: this.appLanguage,
        ad: item,
      },
      cssClass: "preview-modal",
    });
    await previewModal.present();
  }
}

<ion-header class="login-header">
  <ion-toolbar color="primary">

    <!-- <ion-title>Kvadrat</ion-title> -->
    <img
      [routerLink]="'/'"
      class="page-logo"
      src="../../../assets/images/k-logo-text-50-white.png"
      alt="kvadrat"
    />
    <ion-buttons slot="end">
      <ion-button class="flag-button" (click)="presentLanguagePopover($event)">
        <span *ngIf="appLanguage === 'en'">EN</span>
        <span *ngIf="appLanguage === 'sl'">SI</span>
        <img
          *ngIf="appLanguage === 'en'"
          class="language-flag"
          src="assets/images/britain-flag-round.png"
          alt="english"
        />
        <img
          *ngIf="appLanguage === 'sl'"
          class="language-flag"
          src="assets/images/slovenia-flag-round.png"
          alt="slovene"
        />
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen class="unsubscribe-page">
</ion-content>

import { Component, OnInit, Input, ViewChild, AfterViewChecked, ElementRef } from '@angular/core';
import { IonicSlides } from "@ionic/angular";

// import { DataService } from '../../services/data.service';

@Component({
  selector: "app-image-slider",
  templateUrl: "image-slider.component.html",
  styleUrls: ["./image-slider.component.scss"],
})
export class ImageSliderComponent implements OnInit, AfterViewChecked {
  slideIndex = 1;

  @Input() images: string[];
  @Input() imgHeight: number;
  @Input() photoPreview = false;
  @ViewChild("swiper") slides: ElementRef | undefined;
  swiperModules = [IonicSlides];
  // API = "";

  constructor(
    // private dataService: DataService
  ) {}

  ngOnInit() {
    // this.API = this.dataService.API;
  }

  ngAfterViewChecked(): void {
    if (this.slides?.nativeElement.swiper) {
      this.slides?.nativeElement.swiper.update();
    }
  }

  async slideChanged() {
    if ((await this.slides?.nativeElement.swiper.activeIndex) < this.images.length) {
      this.slideIndex = (await this.slides?.nativeElement.swiper.activeIndex) + 1;
    } else {
      this.slideIndex = this.images.length;
    }
  }

  slideNext(): void {
    if (this.slideIndex === this.images.length) {
      this.slides?.nativeElement.swiper.slideTo(0);
      this.slideIndex = 1;
      return;
    }
    this.slides?.nativeElement.swiper.slideNext();
  }

  slidePrev(): void {
    if (this.slideIndex === 1) {
      this.slides?.nativeElement.swiper.slideTo(this.images.length);
      this.slideIndex = this.images.length;
      return;
    }
    this.slides?.nativeElement.swiper.slidePrev();
  }
}

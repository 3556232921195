import { Component } from "@angular/core";
import { NavParams, PopoverController } from "@ionic/angular";

// FlagReasons Popover component
@Component({
  templateUrl: "./flag-reasons-popover.component.html",
  styleUrls: ["./flag-reasons-popover.component.scss"],
})
export class FlagReasonsPopoverComponent {
  lang: any;
  comment: any;
  adId: string;

  flagReason = 1;
  flagExplanation = "";
  messageMaxLength = 280;
  messageRemaining = null;

  constructor(
    // public viewCtrl: ViewController,
    private popoverCtrl: PopoverController,
    public params: NavParams
  ) {
    this.lang = params.get("lang");
    this.comment = params.get("comment");
    this.adId = params.get("adId");
    // console.log(this.comment);
    if (this.adId) {
      this.flagReason = 0;
    }
  }

  flag(): void {
    this.params.get("flagItem")(this.comment ?? this.adId, this.flagReason, this.flagExplanation);
    this.popoverCtrl.dismiss();
  }

  countMessageRemaining(explanation: string): void {
    this.messageRemaining = this.messageMaxLength - explanation.length;
  }
}

<ion-header class="shrinkable">
  <app-main-header [mobileView]='mobileView'
                   [title]='title'
                   [resultNum]='resultNum'
                   [viewMode]='viewMode'
                   [searchQuerySaved]='searchQuerySaved'
                   (saveSearchEmit)="saveSearch($event)"
                   (updateViewMode)="setViewMode($event)"
                   [sortOrder]='sortOrder'
                   (updateSortOrder)="setSortOrder($event)"
                   [basePath]="'r'"
                   [currentUser]='currentUser'></app-main-header>
</ion-header>

<ion-content fullscreen
             class="listpage">
  <div class="loader" id="loader-2" *ngIf="loadingResults"></div>
  <ion-grid id="list-content" [style.opacity]="loadingResults ? '0.6' : '1'">
    <ion-row>
      <ion-col *ngIf="viewMode !== 'map'" class="ad-list"
               [ngClass]="{'ad-list-mode': viewMode === 'list-map' || viewMode === 'list'}">
        <ng-container *ngIf="!loadingResults && ads && ads.length === 0">
          <div class="no-results">
            <img class="search-icon" src="../../../assets/images/search-300.png" alt="search icon">
            <p>
              {{ lang.noResultsFound }} <a [routerLink]="['']">{{ lang.search2 }}</a> 
              <span class="lowercase"> {{ lang.or }} <a [routerLink]="['/posts']">{{ lang.createPost }}</a></span>              
            </p>
          </div>
          <div class="subscribe-search">
            <p>{{ lang.subscribeToSearchMessage }}</p>
            <ion-item class="ion-no-padding email">
              <ion-input autocomplete="on"
                         [ngModel]="email"
                         (ngModelChange)="email = $event; validateEmail(email)"
                         placeholder="Email"
                         type="email"
                         name="email"
                         required="true">
              </ion-input>
            </ion-item>
            <ion-text class="error-message" color="danger"
                      *ngIf="email !== '' && emailInvalid">
              <span>{{ lang.emailNotValid }}</span>
            </ion-text>
            <ion-item class="checkbox-item" lines="none">
              <ion-label>
                <span>
                  {{ lang.iAgreeTo }} <a [routerLink]="['/terms']">
                  {{ lang.termsAndConditions }}</a>.
                </span>
              </ion-label>
              <ion-checkbox class="terms-checkbox"
                            color="primary"
                            [(ngModel)]="termsAgreed">
              </ion-checkbox>
            </ion-item>
            <ion-button expand="block"
                    color="secondary"
                    [disabled]="emailInvalid || !termsAgreed || !captcha"
                    (click)="subscribeToSearchQuery()">
              <ion-icon name="notifications-outline"></ion-icon>
              {{ lang.notifyMe }}
            </ion-button>
            <br>
            <ion-item lines="none">
              <re-captcha (resolved)="resolved($event)" siteKey="6LeOEsUkAAAAADzfqBPAIRcnYfQQ1qZb9-0bOO91"></re-captcha>
            </ion-item>
          </div>
        </ng-container>
        <ng-container *ngIf="ads && ads.length > 0 && viewMode !== 'map'">
          <ion-list *ngIf="viewMode === 'list' || viewMode === 'list-map'">
            <app-card-wide *ngFor="let item of ads; let i = index"
                           #adBanner
                           [item]="item"
                           [imageUrls]="item.imageUrls"
                           [lang]="lang"
                           [appLanguage]="appLanguage"
                           [currentUser]="currentUser"
                           [selectedAdId]="selectedAdId"
                           (openPreviewEmitter)="openPreviewModal(item)">
            </app-card-wide>
          </ion-list>
  
          <ion-row *ngIf="viewMode === 'card' || viewMode === 'card-map'"
                   [ngClass]="cardView">
            <ion-col [attr.size]="colNum ? colNum : '3'"
                     *ngFor="let item of ads; let i = index">
              <app-prop-card (openPreviewEmitter)="openPreviewModal(item)"
                           #adCard
                           [item]="item"
                           [imageUrls]="item.imageUrls"
                           [selectedAdId]="selectedAdId"
                           [appLanguage]="appLanguage"
                           [lang]="lang">
              </app-prop-card>
            </ion-col>
          </ion-row>
        </ng-container>
      </ion-col>

      <ion-col class="map-col"
               #menuWrap
               id="menu-col-wrap"
               *ngIf="viewMode === 'list-map' || viewMode === 'card-map' || viewMode === 'map'">
        <ion-button (click)="opanMapDataMenu($event)"
                    class="mapview-button"
                    size="small"
                    shape="round"
                    *ngIf="!loadingResults && ads && ads.length !== 0"
                    [ngStyle]="{'top': (deltaY + 6) + 'px'}">{{ lang.data }}</ion-button>
        <div id="map"
             #mapRef
             style="width: 100%"
             [ngStyle]="{'top': deltaY + 'px'}"></div>
      </ion-col>

    </ion-row>
  </ion-grid>

</ion-content>

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertController } from "@ionic/angular";

// Services
import { BucketService } from "../../services/bucket.service";
import { LoginService } from "../../services/login.service";
import { UserDataService } from "../../services/user-data.service";
import { LanguageService } from "../../services/language.service";
import { DataService } from "../../services/data.service";
import { WindowService } from "../../services/window.service";
import { RealEstateAgencyService } from "../../services/re-agency.service";

// Model
import { CurrentUser } from "../../model/current-user";
import { AdStatus } from "../../model/enums/ad-status";

@Component({
  selector: "app-photos",
  templateUrl: "photos.component.html",
  styleUrls: ["./photos.component.scss"],
})
export class PhotosComponent implements OnInit {
  title: string;
  appLanguage: string;
  lang: any;
  ad: any;
  agency: any;
  mobileView = true;
  currentUser: CurrentUser;
  address: string;
  path = "photos";

  currUserAds: any[] = [];
  removeAdAlert: any; // Alert;
  adLimitAlert: any; // Alert;
  activationAlert: any; // Alert;
  // adExpired: boolean;
  API = "";

  constructor(
    private bucketService: BucketService,
    private loginService: LoginService,
    private router: Router,
    private route: ActivatedRoute,
    private userDataService: UserDataService,
    private alertCtrl: AlertController,
    private languageService: LanguageService,
    private dataService: DataService,
    private windowService: WindowService,
    private realEstateAgencyService: RealEstateAgencyService,
  ) {
    // console.log('Hello PhotosComponent Component');
    this.title = "Dodajte fotografije";
  }

  ngOnInit() {
    this.API = this.dataService.API;
    this.languageService.language$.subscribe((value) => {
      this.appLanguage = value;
      this.lang = this.languageService[value];
      // this.updateList();
    });
    this.currentUser = this.loginService.currentUser();
    this.getUrl();
    if (!this.currentUser._id) {
      return this.router.navigate(["/"], {
        queryParams: { l: this.appLanguage === "en" ? "en" : null },
      });
    }

    this.dataService.isBrowser$.subscribe((isBrowser) => {
      if (isBrowser) {
        this.toggleMobileView();
      }
    });
  }

  toggleMobileView() {
    if (this.windowService.nativeWindow.innerWidth <= "767") {
      this.mobileView = true;
      // this.menuCtrl.swipeEnable(true);
    } else {
      this.mobileView = false;
      // this.menuCtrl.swipeEnable(false);
    }
  }

  async showActivationAlert() {
    this.activationAlert = await this.alertCtrl.create({
      header: this.lang["activationAlertTitle"], // this.lang['delete'],
      subHeader: this.lang["activationAlertMessage"], // this.lng['deleteConfirm'],
      // subTitle: 'Removing the ad can not be undone. Ste prepričani, da želite izbrisati oglas?', // this.lng['deleteConfirm'],
      buttons: [
        {
          text: "Ok", // this.lng['cancel'],
          role: "cancel",
          handler: () => {
            // console.log('Cancel clicked');
            this.activationAlert = undefined;
          },
        },
      ],
    });

    this.activationAlert.present();
  }

  getUrl() {
    this.route.url.subscribe((url) => {
      // console.log(url);
      // console.log(decodeURI(url));
      const pathId = url[1].path;
      this.path = url[0].path;

      if (this.path === "agency-photos") {
        this.getAgencyData(pathId);
      } else if (this.path === "photos") {
        this.getAdsByUser(this.currentUser._id);
        this.getAd(pathId, "user");
      }
    });
  }

  getAgencyData(agencyId: string): void {
    this.realEstateAgencyService
      .getRealEstateAgencySingle(agencyId)
      .subscribe((res) => {
        console.log(res);
        
        // this.ad = res;
        if (res && res["_id"]) {
          this.agency = res;
          this.listAdImages(this.agency._id);
        }
      });
  }

  getAd(adId, userId) {
    this.bucketService.getSingleAd(adId, userId).subscribe((res) => {
      if (!res["_id"] || this.currentUser._id !== res["author"]) {
        return this.router.navigate(["/"], {
          queryParams: { l: this.appLanguage === "en" ? "en" : null },
        });
      }
      this.ad = res;
      this.checkAdExpiry(this.ad);
      this.listAdImages(adId);
      const addr = res["located"];
      this.address = `${addr.street}, ${addr.postal} ${addr.district}`;
    });
  }

  listAdImages(id) {
    this.bucketService.listAdImages(id, 3).subscribe((images) => {
      // console.log(images);
      if (this.path === "photos") {
        if (images["files"]) {
          this.ad.photos = images["files"];
          // console.log(this.ad.photos);
          if (this.ad.photos.length < 1) {
            if (!this.activationAlert) {
              this.showActivationAlert();
            }
          }
        }
      } else if (this.path === "agency-photos") {
        let agencyImages = images["files"];
        this.agency["coverPhoto"] = agencyImages.find(image => image.metadata.cover === 1)?._id;
        this.agency["logo"] = agencyImages.find(image => image.metadata.logo === 1)?._id;
      }
    });
  }

  deleteImage(imageId) {
    this.bucketService.deleteImage(imageId, this.currentUser._id).subscribe(() => {
      if (this.path === "photos") {
        this.listAdImages(this.ad._id);
      } else if (this.path === "agency-photos") {
        this.listAdImages(this.agency._id);
      }
    });
  }

  // toggleActivate(ad) {
  //   this.userDataService
  //     .getUserAdLimit(this.currentUser._id)
  //     .subscribe((adLimitResponse) => {
  //       if (ad.adExpired) {
  //         // check user ad limit first
  //         const activeAds = this.currUserAds.filter(
  //           (item) => item["adExpired"] === false
  //         );
  //         if (adLimitResponse["userAdLimit"] > activeAds.length) {
  //           this.activateAd(ad, this.currentUser._id);
  //         } else {
  //           this.adLimitAlertOpen(adLimitResponse["userAdLimit"]);
  //         }
  //         // deactivate
  //       } else {
  //         this.deactivateAd(ad, this.currentUser._id);
  //       }
  //     });
  // }

  // activateAd(ad: any, currUserId: string) {
  //   this.bucketService
  //     .activateAd(ad._id, currUserId)
  //     .subscribe((activateResult) => {
  //       const updateIndex = this.currUserAds.findIndex(
  //         (item) => item._id === ad._id
  //       );
  //       this.currUserAds[updateIndex].statusOfAd = activateResult["statusOfAd"];
  //       this.ad.statusOfAd = activateResult["statusOfAd"];
  //       this.checkAdExpiry(ad);
  //       this.checkAdExpiry(this.currUserAds[updateIndex]);
  //     });
  // }

  // deactivateAd(ad: any, currUserId: string) {
  //   this.bucketService
  //     .deactivateAd(ad._id, currUserId)
  //     .subscribe((deactivateResult) => {
  //       const updateIndex = this.currUserAds.findIndex(
  //         (item) => item._id === ad._id
  //       );
  //       this.currUserAds[updateIndex].statusOfAd =
  //         deactivateResult["statusOfAd"];
  //       this.ad.statusOfAd = deactivateResult["statusOfAd"];
  //       this.checkAdExpiry(ad);
  //       this.checkAdExpiry(this.currUserAds[updateIndex]);
  //     });
  // }

  getAdsByUser(userId: string) {
    this.bucketService.getAdsByUser(userId).subscribe((result: any[]) => {
      this.currUserAds = result;
      for (let i = 0; i < this.currUserAds.length; i++) {
        const element = this.currUserAds[i];
        element["photos"] = [];
        this.checkAdExpiry(element);
      }
      // console.log(this.currUserAds);
    });
  }

  checkAdExpiry(ad) {
    if (
      ad.statusOfAd[ad.statusOfAd.length - 1].status === AdStatus.Active
      // && new Date(ad.statusOfAd[ad.statusOfAd.length - 1].changed).getTime() > new Date().getTime() - 30 * 24 * 60 * 60 * 1000
    ) {
      ad["adExpired"] = false;
    } else {
      ad["adExpired"] = true;
    }
  }

  // async removeAlertOpen(ad) {
  //   if (!this.removeAdAlert) {
  //     this.removeAdAlert = await this.alertCtrl.create({
  //       header: "Delete ad", // this.lang['delete'],
  //       subHeader:
  //         "Deleting the ad can not be undone. Are you sure you want to delete it?", // this.lng['deleteConfirm'],
  //       // subTitle: 'Removing the ad can not be undone. Ste prepričani, da želite izbrisati oglas?', // this.lng['deleteConfirm'],
  //       buttons: [
  //         {
  //           text: "Cancel", // this.lng['cancel'],
  //           role: "cancel",
  //           handler: () => {
  //             // console.log('Cancel clicked');
  //             this.removeAdAlert = undefined;
  //           },
  //         },
  //         {
  //           text: "Delete ad", // this.lang['ok'],
  //           handler: () => {
  //             // this.makePublic(item);
  //             this.removeAd(ad._id, this.currentUser._id);
  //             this.removeAdAlert = undefined;
  //           },
  //           cssClass: "delete-confirm",
  //         },
  //       ],
  //     });

  //     this.removeAdAlert.present();
  //   }
  // }

  async removeAgencyAlertOpen(agency: any): Promise<void> {
    if (!this.removeAdAlert) {
      this.removeAdAlert = await this.alertCtrl.create({
        header: "Delete real estate agency", // this.lang['delete'],
        subHeader:
          "Deleting the real estate agency can not be undone. Are you sure you want to delete it?", // this.lng['deleteConfirm'],
        // subTitle: 'Removing the ad can not be undone. Ste prepričani, da želite izbrisati oglas?', // this.lng['deleteConfirm'],
        buttons: [
          {
            text: "Cancel", // this.lng['cancel'],
            role: "cancel",
            handler: () => {
              // console.log('Cancel clicked');
              this.removeAdAlert = undefined;
            },
          },
          {
            text: "Delete agency", // this.lang['ok'],
            handler: () => {
              // this.makePublic(item);
              this.removeReAgency(agency._id, agency.author);
              this.removeAdAlert = undefined;
            },
            cssClass: "delete-confirm",
          },
        ],
      });

      this.removeAdAlert.present();
    }
  }

  async adLimitAlertOpen(adLimit = 1) {
    if (!this.adLimitAlert) {
      this.adLimitAlert = await this.alertCtrl.create({
        header: "Ad limit reached", // this.lang['delete'],
        subHeader:
          "Your current ad limit is" +
          " " +
          adLimit +
          ". You can not have more active ads at this time.", // this.lng['deleteConfirm'],
        // subTitle: 'Removing the ad can not be undone. Ste prepričani, da želite izbrisati oglas?', // this.lng['deleteConfirm'],
        buttons: [
          {
            text: "Ok", // this.lng['cancel'],
            role: "cancel",
            handler: () => {
              // console.log('Cancel clicked');
              this.adLimitAlert = undefined;
            },
          },
        ],
      });

      this.adLimitAlert.present();
    }
  }

  removeAd(adId, userId) {
    this.bucketService.removeAd(adId, userId).subscribe((response) => {
      // update list of ads
      // this.getAdsByUser(userId);
      return this.router.navigate(["/p", this.currentUser._id], {
        queryParams: { l: this.appLanguage === "en" ? "en" : null },
      });
    });
  }

  removeReAgency(agencyId, authorId) {
    if (this.currentUser._id !== authorId) {
      
    }
    this.realEstateAgencyService.deleteRealEstateAgency(agencyId).subscribe((response) => {
      // update list of ads
      // this.getAdsByUser(userId);
      return this.router.navigate(["/p", this.currentUser._id], {
        queryParams: { l: this.appLanguage === "en" ? "en" : null },
      });
    });
  }
}

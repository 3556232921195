import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataService } from './data.service';
import { User } from '../model/user';

@Injectable()
export class UserDataService {

  API: string;

  constructor(
    private http: HttpClient,
    private dataService: DataService,
  ) {
    this.API = this.dataService.API;
  }

  getUserData(userId: string) {
    return this.http.get(`${this.API}/users/data/${userId}`);
  }

  getUserPhoto(userId: string) {
    return this.http.get(`${this.API}/files/profile/${userId}`);
  }

  deletetUserPhoto(userId: string, currentUserId: string) {
    const headers = new HttpHeaders().set("userid", currentUserId);
    return this.http.delete(`${this.API}/files/profile/${userId}`, { headers });
  }

  updateUserData(userData: Partial<User>, userId: string) {
    return this.http.post(`${this.API}/users/data/${userId}`, userData);
  }

  getName(userId: string) {
    return this.http.get(`${this.API}/users/name/${userId}`);
  }

  checkUserValidation(userId: string) {
    return this.http.get(`${this.API}/users/user-valid/${userId}`);
  }

  checkUserNameTaken(name: string) {
    return this.http.get(`${this.API}/users/name-taken/${name}`);
  }

  checkPhone(userId: string) {
    return this.http.get(`${this.API}/users/phone-check/${userId}`);
  }

  getPhone(userId: string) {
    return this.http.get(`${this.API}/users/phone-get/${userId}`);
  }

  getUserAdLimit(userId: string) {
    return this.http.get(`${this.API}/users/ad-limit/${userId}`);
  }
}

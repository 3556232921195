<ion-item *ngIf="lang"
          class="app-card-wide"
          [ngClass]="{'active': selectedAdId === item._id}"
          (click)="openPrevieModal()"
          (mouseenter)="setActiveProperty(item._id)"
          (mouseleave)="setActiveProperty('')">
  <ion-thumbnail (click)="$event.stopPropagation()">
    <!-- <img *ngIf="item.filename" src="http://localhost:3000/api/files/{{ item.filename }}"> -->
    <!-- <img *ngIf="item.photos && item.photos.length > 0" src="http://localhost:3000/api/files/image/{{ item.photos[0]._id }}"> -->
    <app-image-slider *ngIf="item.photos && item.photos.length > 0" [images]="imageUrls" [imgHeight]="160"></app-image-slider>
  </ion-thumbnail>
  <ion-label>
    <div class="main-content">
      <h2 [ngClass]="{'active': selectedAdId === item._id}">
        <ion-icon slot="start" name="location"></ion-icon> {{ item.located.street }}, {{ item.located.postal }} {{
        item.located.district }}
      </h2>
      <p class="ad-prop-type">
        {{ lang.adTsPropertyType[item.adPropertyType] }} -
        <span *ngIf="item.adType === 1">
          {{ lang.forRent }} ({{ lang.from }} {{ item.moveIn | date:'dd.MM.yyyy' }} - {{ lang.to }} {{ item.moveOut | date:'dd.MM.yyyy' }})
        </span>
        <span *ngIf="item.adType === 0">{{ lang.buy }}</span>
      </p>
      <p *ngIf="isNotBuilding" class="size">{{ item.lotSize | number }} m&#178;</p>
      <p *ngIf="!isNotBuilding" class="size">
        {{ item.floorSize | number }} m&#178;,&nbsp;
        <span *ngIf="item.roomNum !== 0">{{ (item.roomNum || '/')}} {{ lang.rooms }}</span>
        <span *ngIf="item.roomNum === 0">garsonjera</span>
      </p>
      <!-- <p class="amenities" *ngIf="item.amenitiesArray"><small>{{ item.amenitiesArray }}</small></p> -->
      <p><span><b>{{ lang.published }}:</b> {{ item.elapsed }} {{lang.days}}</span></p>
      
      <p (click)="$event.stopPropagation()"><span><b>{{ lang.author }}:</b> {{ item.userName }}</span></p>
    </div>
    
    <div class="right-card-panel">
      <a class="new-tab-link" target="_blank" (click)="$event.stopPropagation()" [href]="'/ad/' + item._id">
        <ion-icon name="open"></ion-icon>
      </a>
      <p class="like-link" (click)="toggleFavorite(item._id, item.author);$event.stopPropagation()">
        <ion-icon slot="start" *ngIf="!isFavorite" name="heart-outline"></ion-icon>
        <ion-icon slot="start" *ngIf="isFavorite" name="heart"></ion-icon>
        <span *ngIf="!isFavorite">{{ lang.save }}</span> 
        <span *ngIf="isFavorite">{{ lang.saved }}</span> 
      </p>
      <p class="price">
        {{ item.price.current | number }} €
        <span *ngIf="item.price.type === 1"> / {{ lang.month }}</span>
      </p>
      <p class="price-per-m">
        <small>
          {{ item.price.current / item.floorSize | number:'1.1-2' }} €/m&#178;
        </small>
      </p>
      <p class="social-data">
        <span>
          <ion-icon name="heart-outline"></ion-icon>&nbsp;<span>{{ favoritesCount }}</span>
        </span>
        <span>
          <ion-icon name="eye-outline"></ion-icon>&nbsp;<span>{{ adViewsCount }}</span>
        </span>   
      </p>
    </div>
  </ion-label>
</ion-item>

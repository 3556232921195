import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { UserDataService } from '../../../services/user-data.service';

import { take } from 'rxjs/operators';

// Login Modal Component
@Component({
  templateUrl: './phone-form.component.html',
  styleUrls: ['./phone-form.component.scss']
})
export class PhoneFormComponent implements OnInit {

  lang: any;
  appLanguage: any;
  phoneNumber = '';
  currentUserId = '';

  constructor(
    private modalCtrl: ModalController,
    public params: NavParams,
    private userDataService: UserDataService,
  ) {
    this.lang = params.get('lang');
    this.appLanguage = params.get('appLanguage');
    this.currentUserId = params.get('currentUserId');
  }

  ngOnInit() { }

  submit() {
    const userData = {
      phoneNumber: this.phoneNumber,
    };

    this.userDataService.updateUserData(userData, this.currentUserId)
      .pipe(
        take(1)
      )
      .subscribe(response => {
        this.phoneNumber = '';
        this.modalCtrl.dismiss();
        this.params.get('checkPhone')();
      });
  }

  // close button
  dismiss() {
    this.phoneNumber = '';
    this.modalCtrl.dismiss();
    this.params.get('checkPhone')();
  }
}
